import React, { useState, useCallback, useEffect } from 'react'
import { AutoComplete, Input } from 'antd'
import { history } from 'index'
import { getAPI } from 'services/jwt'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import AIFileAssistant from 'components/modal/aiFileAssistant'
import { scrollAndHighlight } from 'components/other/utility'

const EnhancedSearchComponent = () => {
  const [searchText, setSearchText] = useState('')
  const [jumpSuggestions, setJumpSuggestions] = useState([])
  const [options, setOptions] = useState([])
  const globalSearchFocus = useSelector(state => state.settings.globalSearchFocus)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [isAIModalVisible, setIsAIModalVisible] = useState(false)
  const [aiSearchText, setAiSearchText] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (globalSearchFocus) {
      inputRef?.current?.focus()
      setDropdownVisible(true)
      // make globalSearchFocus false in redux
      dispatch({
        type: 'settings/SET_STATE',
        payload: { globalSearchFocus: false },
      })
    }
  }, [globalSearchFocus])

  const fetchJumpSuggestions = useCallback(
    debounce(async query => {
      if (query) {
        try {
          const data = await getAPI('ai', 'jump_suggestions', { query })
          setJumpSuggestions(data.suggestions)
        } catch (error) {
          console.error('Error fetching jump suggestions:', error)
        }
      } else {
        setJumpSuggestions([])
      }
    }, 100),
    [],
  )

  useEffect(() => {
    fetchJumpSuggestions(searchText)
  }, [searchText])

  useEffect(() => {
    filteredOptions()
  }, [jumpSuggestions])

  const filteredOptions = () => {
    const localOptions = []
    if (!searchText) {
      localOptions.push({
        label: (
          <span className="font-weight-medium font-size-14 ml-2 text-gray-400 pt-4">Jump to</span>
        ),
        options: [
          {
            value: 'Create Invoice',
            url: '/create/invoice',
            key: 'invoice',
            label: (
              <span>
                <i className="fa-light fa-location-arrow mr-2 ml-0 pl-0 text-gray-500"></i>
                <span className="font-weight-medium">Create Invoice</span>
              </span>
            ),
          },
          {
            value: 'Payments',
            url: '/paymentsTimeline',
            key: 'payments',
            label: (
              <span>
                <i className="fa-light fa-location-arrow mr-2 ml-0 pl-0 text-gray-500"></i>
                <span className="font-weight-medium">Payments</span>
              </span>
            ),
          },
          {
            value: 'Insights',
            url: '/analytics',
            key: 'insights',
            label: (
              <span>
                <i className="fa-light fa-location-arrow mr-2 ml-0 pl-0 text-gray-500"></i>
                <span className="font-weight-medium">Insights</span>
              </span>
            ),
          },
        ],
      })
    } else {
      if (jumpSuggestions.length > 0) {
        localOptions.push({
          label: (
            <span className="font-weight-medium font-size-14 ml-2 text-gray-400 pt-4">Jump to</span>
          ),
          options: jumpSuggestions.map((option, index) => ({
            ...option,
            key: index,
            value: option.value,
            label: (
              <span>
                <i className="fa-light fa-location-arrow mr-2 ml-0 pl-0 text-gray-500"></i>
                <span className="font-weight-medium">{option.value}</span>
              </span>
            ),
          })),
        })
      }
    }

    localOptions.push({
      label: <span className="font-weight-medium font-size-14 ml-2 text-gray-400">Ask AI</span>,
      options: [
        {
          value: (
            <span>
              <i className="fa-light fa-sparkles mr-2 ml-0 pl-0 text-gray-500"></i>
              <span className="font-weight-medium">
                Ask SwipeAI{searchText ? ': "' + searchText + '"' : ''}
              </span>
            </span>
          ),
          isAI: true,
        },
      ],
    })

    localOptions.push({
      label: (
        <div className="w-full border-t pt-2 pb-1 px-0 mx-0 font-size-12">
          <span className="font-size-14 ml-2 text-gray-400">
            <i className="fa far fa-arrow-up border p-1 py-1 rounded-md"></i>{' '}
            <i className="fa far fa-arrow-down mr-2 border p-1 py-1 rounded-md"></i>
            Navigate
            <i className="fa far fa-arrow-turn-down-left mr-2 ml-4 border p-1 py-1 rounded-md"></i>
            Select
            <span className="ml-4 border py-1 mr-2 rounded-md"> esc </span>Clear
          </span>
        </div>
      ),
      options: [],
    })
    setOptions(localOptions)
  }

  const handleAISearch = text => {
    setAiSearchText(text)
    setIsAIModalVisible(true)
  }

  const onSelect = async (value, option) => {
    if (option.isAI) {
      handleAISearch(searchText)
      setSearchText('')
      inputRef.current.blur()
    } else {
      getAPI('ai', 'jump_reviews', {
        query: searchText,
        value: option.value,
        url: option.url,
      }).catch(error => {
        console.log('Error submitting feedback:', error)
      })
      setSearchText('')
      inputRef.current.blur()
      const url = new URL(option.url, window.location.origin)
      history.push(option.url)
    }
  }

  const onSearch = value => {
    setSearchText(value)
  }

  const inputRef = React.useRef()
  const focusInput = () => {
    const input = inputRef.current
    if (input) {
      input.focus()
      setDropdownVisible(true)
    }
  }

  const handleFocus = () => {
    setDropdownVisible(true)
  }

  const handleBlur = () => {
    setSearchText('')
    setDropdownVisible(false)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const element = urlParams.get('ai_search')
    if (element && element == 'true') {
      setTimeout(() => {
        focusInput() // Focus input after 3 seconds
      }, 3000)
      scrollAndHighlight(element)
    }
  }, [])

  return (
    <>
      <div
        className="flex justify-end items-center"
        style={{ width: '100%', maxWidth: '700px' }}
        // id="highlight-input"
        data-beacon-id="true"
      >
        <AutoComplete
          value={searchText}
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          allowClear
          style={{ width: '100%' }}
          ref={inputRef}
          open={dropdownVisible}
        >
          <Input
            prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
            suffix={!searchText ? <p className="text-gray-300">ctrl+k</p> : null}
            placeholder="Search or jump to..."
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </AutoComplete>
      </div>
      <AIFileAssistant
        visible={isAIModalVisible}
        onClose={() => setIsAIModalVisible(false)}
        initialSearchText={aiSearchText}
      />
    </>
  )
}

export default EnhancedSearchComponent
