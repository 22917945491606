import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Input, Button, Modal, List, Tooltip, message } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import ReactMarkdown from 'react-markdown'
import { getAPI } from 'services/jwt'

const AIModal = forwardRef((props, ref) => {
  const { onContinue } = props
  const [isVisible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [messageHistory, setMessageHistory] = useState([])
  const [placeholderOptions, setPlaceholderOptions] = useState(['Type your message...'])
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0)
  const [threadId, setThreadId] = useState('start')
  const [isTyping, setIsTyping] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [key, setKey] = useState('')
  const [featureCategory, setFeatureCategory] = useState('')
  const [latestText, setLatestText] = useState('')
  const [context, setContext] = useState('')
  const [textType, setTextType] = useState('')

  const chatContainerRef = useRef(null)
  const inputRef = useRef(null)
  const [shouldInitiateCall, setShouldInitiateCall] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholderIndex(prevIndex => (prevIndex + 1) % placeholderOptions.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [placeholderOptions])

  useEffect(() => {
    if (shouldInitiateCall && context && featureCategory) {
      handleSend(context)
      setShouldInitiateCall(false)
    }
  }, [shouldInitiateCall, context, featureCategory])

  useEffect(() => {
    return () => {
      if (window.interval) clearInterval(window.interval)
      if (window.typingInterval) clearInterval(window.typingInterval)
    }
  }, [])

  const openModal = (key, defaultResponse, feature_Category, context, textType) => {
    setKey(key)
    setFeatureCategory(feature_Category)
    setContext(context)
    setTextType(textType)
    setInputValue(defaultResponse)
    setMessageHistory([])
    setPlaceholderOptions(['Type your message...'])
    setVisible(true)
    setShouldInitiateCall(true)
  }

  useImperativeHandle(ref, () => ({
    openModal,
  }))

  const focusInput = () => {
    if (inputRef.current) {
      const textAreaElement = inputRef.current?.resizableTextArea?.textArea
      if (textAreaElement) {
        textAreaElement.focus()
      }
    }
  }

  useEffect(() => {
    if (isVisible) {
      focusInput()
    }
  }, [isVisible])

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messageHistory])

  const showTypingEffect = (responseText, currentMessageIndex) => {
    let index = 0
    setIsTyping(true)

    const typingInterval = setInterval(() => {
      if (index < responseText.length) {
        setMessageHistory(prevHistory => {
          const newHistory = [...prevHistory]
          newHistory[currentMessageIndex] = {
            ...newHistory[currentMessageIndex],
            content: responseText.slice(0, index + 1),
          }
          return newHistory
        })
        index++
      } else {
        clearInterval(typingInterval)
        setIsTyping(false)
        focusInput()
      }
    }, 20)
  }

  const handleSend = async (manualQuery = null) => {
    const query = manualQuery || inputValue
    if (!query.trim() || isTyping) return

    setMessageHistory(prevHistory => [
      ...prevHistory,
      { type: 'user', content: query, timestamp: new Date() },
    ])
    setInputValue('')

    try {
      setIsLoading(true)
      const response = await getAPI('ai', 'chat', {
        feature_category: featureCategory,
        feature: ' ',
        user_query: query,
        thread_id: threadId,
        question: `You are performing this task: ${context}. This is the current version you are trying to improve or completely rewrite:\n${latestText}`,
      })

      if (response && response.success) {
        setIsLoading(false)
        setIsTyping(true)
        if (response.warning) {
          message.warning(response.message)
          setIsTyping(false)
          return
        }
        const info = response.content ?? response.message ?? {}
        const updatedText = info?.description ?? info?.terms ?? info?.notes ?? ''
        setLatestText(updatedText)
        const followUps = info?.follow_ups ?? []
        setPlaceholderOptions(followUps)
        setThreadId(info.thread_id)

        setMessageHistory(prevHistory => [
          ...prevHistory,
          { type: 'ai', content: '', timestamp: new Date() },
        ])
        showTypingEffect(updatedText, messageHistory.length + 1)
      } else {
        throw new Error('Failed to get AI response')
      }
    } catch (error) {
      console.error('Error calling AI service:', error)
      setIsTyping(false)
      setMessageHistory(prevHistory => [
        ...prevHistory,
        { type: 'error', content: 'An error occurred. Please try again.', timestamp: new Date() },
      ])
    }
  }

  const handleImprove = type => {
    let query = ''
    switch (type) {
      case 'improve':
        query = 'Please improve the following text'
        break
      case 'professional':
        query = 'Please make the following text professional'
        break
      case 'grammar':
        query = 'Please fix any grammatical or spelling error.'
        break
      case 'shorten':
        query = 'Please make this content shorter.'
        break
      default:
        return
    }
    handleSend(query)
  }

  const renderMessage = (message, index) => (
    <div className="flex flex-col">
      <div
        key={index}
        className={`flex flex-row message ${message.type} ${
          message.type === 'user' ? 'justify-end' : 'justify-start'
        } mt-2 mb-2`}
      >
        <div
          style={{
            maxWidth: '70%',
            padding: '12px 16px',
            borderRadius: '18px',
          }}
          className={`font-size-16 ${message.type === 'user' ? 'bg-gray-75' : 'bg-white'}`}
        >
          {message.type === 'ai' ? (
            <div className="flex flex-start">
              <div className="mr-4">
                <div
                  className="rounded-full flex items-center justify-center border"
                  style={{ width: '40px', height: '40px' }}
                >
                  <i className="fa far fa-sparkles fa-lg"></i>
                </div>
              </div>
              <div className="pt-2">
                <ReactMarkdown
                  components={{
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        style={{ textDecoration: 'underline', lineHeight: '1.5' }}
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    h1: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-4"
                        style={{ lineHeight: '2' }}
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-4"
                        style={{ lineHeight: '1.9' }}
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-2"
                        style={{ lineHeight: '1.8' }}
                        {...props}
                      />
                    ),
                    h4: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-2"
                        style={{ lineHeight: '1.7' }}
                        {...props}
                      />
                    ),
                    h5: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-2"
                        style={{ lineHeight: '1.6' }}
                        {...props}
                      />
                    ),
                    h6: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold text-gray-400 my-2"
                        style={{ lineHeight: '1.5' }}
                        {...props}
                      />
                    ),
                    // Handle ** text as bold
                    strong: ({ node, ...props }) => (
                      <strong
                        className="font-weight-medium text-gray-800"
                        style={{ lineHeight: '1.5' }}
                        {...props}
                      />
                    ),
                    // Handle normal text
                    p: ({ node, ...props }) => (
                      <p className="my-1 text-gray-500" style={{ lineHeight: '1.5' }} {...props} />
                    ),
                    // Handle normal text
                    span: ({ node, ...props }) => (
                      <>
                        <span
                          className="my-1 text-gray-500"
                          style={{ lineHeight: '1.5' }}
                          {...props}
                        />
                      </>
                    ),
                    li: ({ node, ...props }) => (
                      <li
                        className="my-1 text-gray-500"
                        style={{ lineHeight: '1.5', marginLeft: '1.5em' }}
                        {...props}
                      />
                    ),
                    ul: ({ node, ...props }) => (
                      <ul
                        className="my-2 text-gray-500 list-disc"
                        style={{ paddingLeft: '1em', lineHeight: '1.5' }}
                        {...props}
                      />
                    ),
                    ol: ({ node, ...props }) => (
                      <ol
                        className="my-2 text-gray-500 list-decimal"
                        style={{ paddingLeft: '1em', lineHeight: '1.5' }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          ) : (
            <p style={{ margin: 0 }}>{message.content}</p>
          )}
        </div>
      </div>
      {index === messageHistory.length - 1 && message.type === 'ai' && !isTyping && (
        <Button
          type="primary"
          size="small"
          onClick={handleModalContinue}
          className="self-start w-auto ml-20"
        >
          Use this
        </Button>
      )}
    </div>
  )

  const renderThinkingAnimation = () => (
    <div className="flex flex-start mb-4">
      <div className="mr-4">
        <div
          className="rounded-full flex items-center justify-center border"
          style={{ width: '40px', height: '40px' }}
        >
          <i className="fa far fa-sparkles fa-lg"></i>
        </div>
      </div>
      <div className="thinking-animation flex items-center">
        <div className="circle bg-gray-500"></div>
        <div className="circle bg-gray-500"></div>
        <div className="circle bg-gray-500"></div>
      </div>
    </div>
  )

  const handleModalClose = () => {
    setVisible(false)
    setMessageHistory([])
    setThreadId('start')
    setPlaceholderOptions(['Type your message...'])
    setInputValue('')
    setContext('')
    setFeatureCategory('')
  }

  const handleModalContinue = () => {
    setVisible(false)
    if (textType === 'HTML') {
      const html = messageHistory[messageHistory.length - 1].content
      onContinue(key, html)
    } else {
      onContinue(key, messageHistory[messageHistory.length - 1].content)
    }
  }

  return (
    <Modal
      title={
        <>
          Ask Swipe AI <i className="fa-sharp-duotone fa-sparkles ml-2 text-pink"></i>
        </>
      }
      visible={isVisible}
      onCancel={handleModalClose}
      footer={null}
      width="70vw"
      centered
    >
      <div
        ref={chatContainerRef}
        className="px-4 mx-4 bg-white"
        style={{
          height: '50vh',
          overflowY: 'auto',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <List dataSource={messageHistory} renderItem={renderMessage} />
        {isLoading && renderThinkingAnimation()}
      </div>
      <div className="mb-2 mt-4 px-4 mx-4 flex space-x-2">
        <Button onClick={() => handleImprove('improve')} disabled={isTyping || isLoading}>
          Improve It!
        </Button>
        <Button onClick={() => handleImprove('professional')} disabled={isTyping || isLoading}>
          Make it Professional!
        </Button>
        <Button onClick={() => handleImprove('grammar')} disabled={isTyping || isLoading}>
          Fix Grammar & Spellings!
        </Button>
        <Button onClick={() => handleImprove('shorten')} disabled={isTyping || isLoading}>
          Shorten It!
        </Button>
      </div>
      <div className="mb-2 mt-4 px-4 mx-4 flex space-x-3">
        <Input.TextArea
          ref={inputRef}
          placeholder={placeholderOptions[currentPlaceholderIndex]}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onPressEnter={e => {
            if (
              e.key === 'Enter' &&
              !e.shiftKey &&
              !(isTyping || !inputValue.trim() || isLoading)
            ) {
              e.preventDefault()
              handleSend()
            }
          }}
          autoSize={{ minRows: 1, maxRows: 4 }}
          style={{ borderRadius: '20px', resize: 'none', flexGrow: 1, marginRight: '10px' }}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={() => handleSend()}
          disabled={isTyping || !inputValue.trim() || isLoading}
        >
          Send
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <span className="font-size-12 text-gray-400">
          This is AI generated response. Please double check before using it.
        </span>
      </div>
      <style jsx>{`
        .thinking-animation {
          display: flex;
          align-items: center;
        }
        .circle {
          width: 10px;
          height: 10px;
          margin: 0 5px;
          border-radius: 50%;
          animation: pulse 1.5s infinite ease-in-out;
        }
        .circle:nth-child(2) {
          animation-delay: 0.2s;
        }
        .circle:nth-child(3) {
          animation-delay: 0.4s;
        }
        @keyframes pulse {
          0%,
          100% {
            transform: scale(0.5);
            opacity: 0.5;
          }
          50% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </Modal>
  )
})

export const AIButton = ({
  keyProp,
  initialText,
  featureCategory,
  aimodalRef,
  context,
  textType = '',
}) => {
  const handleClick = e => {
    e.stopPropagation()
    if (aimodalRef && aimodalRef.current) {
      setTimeout(() => {
        const contextValue = typeof context === 'function' ? context() : context
        aimodalRef.current.openModal(keyProp, initialText, featureCategory, contextValue, textType)
      }, 50)
    }
  }

  return (
    <Tooltip title="Generate using AI">
      <Button type="secondary" className="" size="small" onClick={handleClick}>
        <span className="text-pink">
          <i className="fa-sharp-duotone fa-solid fa-sparkles"></i> AI
        </span>
      </Button>
    </Tooltip>
  )
}

export default AIModal
