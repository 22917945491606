import {
  Affix,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Collapse,
  DatePicker,
  Empty,
  Input,
  InputNumber,
  Layout,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  message,
  notification,
} from 'antd'
import {
  formatNumber,
  getCessAmountV2,
  getCessOnQtyValue,
  getItemNetAmount,
  getRowTotalV2,
  getTaxAmountV2,
  getTotalCessAmountV2,
  getTotalDiscountV2,
  getTotalNetAmountV2,
} from 'components/other/calculation'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { history } from 'index'
import React, { Fragment } from 'react'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
const { TabPane } = Tabs

import CustomerFrom from 'components/forms/customerForm'
import InvoiceThemeForm from 'components/forms/invoiceTheme'
import NewBankForm from 'components/forms/newBankForm'
import ProductForm from 'components/forms/productForm'
import VendorFrom from 'components/forms/vendorform'
import EnhancedPartyAnalysis from 'components/other/PartyAnalysis'
import AgEnhancedTable from 'components/other/agProductsTable'
import EnhancedTable from 'components/other/productsTable'

import InvoiceSettingsForm from 'components/forms/InvoiceSettings'
import CustomColumnsForm from 'components/forms/customColumns'
import { EnhancedSignatureForm } from 'components/forms/onlySignatureForm'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import {
  getCountries,
  getTdsSections,
  get_categories,
  get_tcs_sections,
  get_top_custom_fields,
} from 'redux/document/actions'

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import BetaBadge from 'components/badges/beta'
import ProBadge from 'components/badges/pro'
import { EnhancedEInvoiceNICCreds } from 'components/forms/eInvoiceNicCreds'
import { EnhancedNICCreds } from 'components/forms/nicCreds'
import NotesTermsDrawer from 'components/forms/notesTermsDrawer'
import InfoIcon from 'components/info/info_icon'
import { CouponsDrawer } from 'components/modal/coupons/CouponsDrawer'
import TutorialVideoModal from 'components/modal/tutorial'
import CopyText from 'components/other/CopyText'
import { UserAvatar, isFreeEInvoiceUser } from 'components/other/utility'
import { motion } from 'framer-motion'
import EnhancedEwayBill from 'pages/ewayBills/ewaybill'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import store from 'store'

import EditCompany from 'components/forms/editCompany'
import SectionHeader from 'components/other/sectionHeader'

import classNames from 'classnames'
import FeatureBar from 'components/cleanui/layout/FeatureBar'
import Footer from 'components/cleanui/layout/Footer'
import SupportChat from 'components/cleanui/layout/SupportChat'
import DocumentPrefixes from 'components/forms/documentPrefixes'
import AIModal, { AIButton } from 'components/modal/ai'
import FinancialYearModal from 'components/modal/financialYearModal'
import InsufficientItemsModal from 'components/modal/insufficientItems'
import PurchaseConfirm from 'components/modal/purchaseConfirm'
import { dispatch_to_doc_types } from 'components/new_invoice/utils'
import { PartyType, getBase64 } from 'components/other/utility'
import SerialNumberComponent from 'components/serialNumber'
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import {
  add_invoice_data,
  get_payment_modes,
  remove_all_invoices_data,
  update_invoice_data,
} from 'redux/document/actions'
import { get_warehouses } from 'redux/warehouses/actions'
import { INSUFFICIENT_STOCK } from 'utils/errorCodes'
import { trackEvent } from 'utils/netcore'

import UpgradeToEinvEwb from 'components/other/UpgradeToEinvEwb'
import GetItemSuggestions from 'components/other/getItemSuggestions'
import queryString from 'query-string'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  AdditonalCharge,
  Address,
  Bank,
  Batch,
  Company,
  CustomHeader,
  Document,
  DocumentSettings,
  GetDocumentResponse,
  Item,
  Party,
  Signature,
  UserState,
} from 'redux/types'
import {
  ADMIN,
  FREE_USER_EINVOICE_LIMIT,
  FREE_USER_EWAYBILL_LIMIT,
  FREE_USER_EXPORT_LIMIT,
} from 'utils/constants'
import { mergeItemsAndItemHeaders } from 'utils/utilFunctions'

const { TextArea } = Input
const { Option } = Select
const { Panel } = Collapse
const dotOptions: { minimumFractionDigits: number; maximumFractionDigits: number } = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}
dayjs.extend(utc)
const initialItems = [
  {
    label: '',
    key: '1',
  },
]
const emptyPaymentDetails = {
  payment_method: undefined,
  bank_id: undefined,
  amount: 0,
  payment_notes: '',
}

interface CreateDocProps {
  isContentMaxWidth: boolean
  isAppMaxWidth: boolean
  isGrayBackground: boolean
  isSquaredBorders: boolean
  isCardShadow: boolean
  isBorderless: boolean
  isTopbarFixed: boolean
  isGrayTopbar: boolean
  company_details: any
  isDocumentCreated: boolean

  onRef: any
  onCancel: any
  dispatch: any
  invoice_settings: DocumentSettings
  invoice_tabs: any
  top_fields: any
  external_create: boolean
  document_type: string
  document: any
  permissions: any
  user: UserState
  stock_in_warehouses: any
  stock_out_warehouses: any
  default_warehouse_id: number
  customer_id: number
  tds_sections: any
  tcs_sections: any
  countries: any
  units: any
  product_categories: any
  warehouses: any
  document_settings: DocumentSettings
  custom_columns: any
  invoices_created: any
  currencies: any
}

interface DocState {
  from_document_type: string
  redirect: string
  to_document_type: string
  loadingSave: boolean
  loadingPrint: boolean
  draftReqPending: boolean
  new_suffix: string
  flag: boolean
  customerName: string
  openDatePicker: boolean
  description: number
  has_extra_charges: number
  extraDiscount: number
  extraDiscountPer: number
  customerData: Party[] | []
  autocompleteData: any[]
  selectedCustomer: number
  name: string
  companyDetails: Company
  new_hash_id: string
  states: []
  invoiceData: Document | undefined
  dataSource: Item[]
  is_pos: boolean
  count: number
  doc_count: number
  bank_details: Bank[]
  items_reverse: boolean
  duplicate: boolean
  selected_bank: Bank | undefined
  signatures: Signature[]
  selectedSignature: Signature | { signature_name: string; id: any; image: string } | undefined
  serial_number: string
  document_date: any
  supplier_invoice_date: any
  supplier_invoice_serial_number: string
  due_date: any
  reference: string
  terms: string
  notes: string | undefined
  convert: boolean
  preview: boolean
  showEwayBill: boolean
  addFlag: boolean
  edit: boolean
  create: boolean
  multiple_gst: []
  is_subscription: number
  subscription_document_type: string
  prefix: string
  suffix: string
  doc_number: number | null | undefined
  order_serial_number: string
  sub_serial_number: string
  rzp_order_id: string
  rzp_payment_id: string
  id: number
  company_id: number
  is_ewaybill: number
  is_create_einvoice: number
  priceListWarnShown: boolean
  selectedPriceList: number | null
  price_lists: any[]
  taxes: any[]
  attachments: any[]
  amountReceived: number
  fullyPaid: boolean
  paymentMethod: string
  image_url: string
  customer_details:
    | Party
    | {
        billing_address: Address[]
        shipping_address: Address[]
      }
  vendor_details: Party | undefined
  selected_customer_shipping_address: Address | undefined
  selected_company_shipping_address: Address | { addr_id: number } | undefined
  custom_headers: CustomHeader[]
  loaded: boolean
  roundoff: number
  roundoff_value: number
  document_type: string
  party_type: string
  document_title: string
  list_name: string
  rcm: number
  rcm_value: number
  with_tax: number
  hide_totals: number
  export_invoice: number
  is_multi_currency: number
  export_type: string | null
  shipping_bill_number: string
  shipping_bill_date: string
  shipping_port_code: string
  countries: []
  currencies: []
  country: number
  currency: string
  currency_symbol: string
  conversion_factor: number | undefined
  is_tds: number
  is_tcs: number
  tds_selected_section: number | undefined
  tcs_selected_section: number | undefined
  tcs_amount: number
  tcs_applicable_on: string
  tds_applicable_on: string
  tds_amount: number
  subscription_start_date: any
  subscription_end_date: any
  repeatType: string
  repeat_every: number
  send_email: number
  send_sms: number
  invoice_count: number
  product_categories: []
  paymentNotes: string
  payment_status: string
  document_notes: any[]
  document_terms: any[]
  selected_note: number | undefined
  selected_term: number | undefined
  coupons_visible: boolean
  coupon_details: {
    coupon_id: number
    coupon_code: string
    discount: number
    message: string
    is_edit: boolean
  }
  total_amount: number
  searchVisible: boolean
  exclusive_notes: string
  exta_discount_type: string
  is_draft: boolean
  prefixes: []
  suffixes: []
  custom_headers_suggestions: any[]
  item_header_suggestions: any[]
  warning: {
    message: string
    show_warning: boolean
    skip_warning: boolean
  }
  notesLabel: string
  termsLabel: string
  updatedNotes: string
  updatedTerms: string
  isNotesEntered: boolean
  isTermsEntered: boolean
  showNotes: boolean
  showTerms: boolean
  isNotesLoading: boolean
  isTermsLoading: boolean
  page: number
  num_records: number
  old_items_before_edit: Item[]
  document_discount_type: string
  prev_total_amount: number
  credit_limit_warning_shown: boolean
  product_update_warning_shown: boolean
  immovable_tax_type: number
  movement: string
  warehouse_id: number | undefined
  company_shipping_addr_id: number
  activeKey: string
  items: { key: string; label: string }[]
  isCreated: boolean
  visible: boolean
  isClosed: boolean
  auto_draft: {
    id: number
    doc_count: number
  }
  showDraftMessage: boolean
  lastDataSourceUpdated: Dayjs
  saveDocument: boolean
  keyClose: string
  top_fields: []
  suggested_fields: []
  customerDiscount: number
  new_serial_number: string
  new_doc_number: number
  insufficientStockModal: {
    isOpen: boolean
    items: any
    message?: string
    errorCode: string
  }
  is_prefix: boolean
  isConversionTracked: boolean
  new_prefix: string
  document_custom_additional_charges: AdditonalCharge[]
  custom_additional_charges: AdditonalCharge[]
  showNotesLabelError: boolean
  showTermsLabelError: boolean
  paymentsList: any[]
  recent_tds: any
  recent_tcs: any
  item_headers: any[]
  export_docs_count: number
  bank_form_visible: boolean
  noOfExportDocsLeft: number
}

class CreateDoc extends React.PureComponent<CreateDocProps, DocState> {
  tutorialRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/
  customNotesRef: any = React.createRef()
  documentPrefixesRef: any = React.createRef()
  purchaseConfirmRef: any = React.createRef()
  subscriptionPlanRef: any = React.createRef()
  financialYearRef: any = React.createRef()
  docNumberRef: any = React.createRef()
  inputEl: any = React.createRef()
  ai: any = React.createRef()
  getItemSuggestionsRef: any = React.createRef()

  table: any
  einvoice_nic_creds: any
  ewaybill: any
  nic_creds: any
  customer: any
  vendor: any
  EditCompanyForm: any
  ledger: any
  bankForm: any
  signatureForm: any
  form: any

  timeout: any
  draftTimeout: any
  newTabIndex: number
  autoDraftInterval: any
  initialState: DocState
  save: any
  custom_column: any
  invoiceSettingsForm: any
  invoiceThemeForm: any
  subscription: any
  analysis: any
  static defaultProps: { external_create: boolean }

  constructor(props: any) {
    super(props)
    this.inputEl = React.createRef()
    this.timeout = null
    this.draftTimeout = null
    this.newTabIndex = 1
    this.autoDraftInterval = null

    this.state = {
      loadingSave: false,
      loadingPrint: false,
      draftReqPending: false,
      flag: false,
      new_suffix: '',
      new_hash_id: '',
      to_document_type: '',
      duplicate: false,
      convert: false,
      create: false,
      custom_additional_charges: [],
      customerName: '',
      openDatePicker: false,
      description:
        store.get('show_invoice_description') == undefined
          ? 1
          : store.get('show_invoice_description'),
      has_extra_charges: 0,
      extraDiscount: 0,
      extraDiscountPer: 0,
      customerData: [],
      autocompleteData: [],
      selectedCustomer: -1,
      states: [],
      invoiceData: undefined,
      dataSource: [],
      is_pos: false,
      count: 1,
      doc_count: 0,
      bank_details: [],
      items_reverse: false,
      selected_bank: {
        bank_name: '',
        bank_no: '',
        branch_name: '',
        ifsc: '',
        id: -1,
        is_default: 0,
      },
      signatures: [{ signature_name: 'No Signature', id: -1, image: '', is_default: 1 }],
      selectedSignature: { signature_name: '', id: -1, image: '' },
      serial_number: '',
      document_date: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      supplier_invoice_date: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      supplier_invoice_serial_number: '',
      due_date: dayjs()
        .add(this.props?.invoice_settings?.default_due_date, 'days')
        .utcOffset(330)
        .format(this.dateFormat),
      reference: '',
      terms: '',
      notes: '',
      redirect: '',
      preview: false,
      showEwayBill: false,
      addFlag: false,
      edit: false,
      multiple_gst: [],
      is_subscription: 0,
      subscription_document_type: 'invoice',
      prefix: '',
      suffix: '',
      doc_number: 0,
      order_serial_number: '',
      sub_serial_number: '',
      rzp_order_id: '',
      rzp_payment_id: '',
      id: 0,
      company_id: 0,
      is_ewaybill: 0,
      is_create_einvoice: 0,
      priceListWarnShown: false,
      selectedPriceList: null,
      price_lists: [],
      taxes: [],
      attachments: [],
      amountReceived: 0,
      fullyPaid: false,
      paymentMethod: 'Cash',
      image_url: '',
      customer_details: {
        billing_address: [],
        shipping_address: [],
      },
      vendor_details: undefined,
      selected_customer_shipping_address: undefined,
      custom_headers: [],
      loaded: false,
      roundoff: this.props.invoice_settings?.round_off,
      roundoff_value: 0,
      document_type: 'invoice',
      party_type: 'customer',
      document_title: 'Invoice',
      list_name: 'sales',
      rcm: 0,
      rcm_value: 0,
      with_tax: 0,
      hide_totals: 0,
      export_invoice: 0,
      is_multi_currency: 0,
      export_type: null,
      shipping_bill_number: '',
      shipping_bill_date: '',
      shipping_port_code: '',
      countries: [],
      currencies: [],
      country: 179,
      currency: 'USD',
      currency_symbol: '$',
      conversion_factor: 1,
      is_tds: 0,
      is_tcs: 0,
      tds_selected_section: undefined,
      tcs_selected_section: undefined,
      recent_tds: undefined,
      recent_tcs: undefined,
      tcs_amount: 0,
      tcs_applicable_on: 'total_amount',
      tds_applicable_on: 'net_amount',
      tds_amount: 0,
      subscription_start_date: dayjs().format(this.dateFormat),
      subscription_end_date: dayjs().format(this.dateFormat),
      repeatType: 'days',
      repeat_every: 1,
      send_email: 0,
      send_sms: 0,
      invoice_count: 0,
      product_categories: [],
      paymentNotes: '',
      payment_status: 'pending',
      document_notes: [],
      document_terms: [],
      selected_note: 0,
      selected_term: 0,
      coupons_visible: false,
      coupon_details: {
        coupon_id: -1,
        coupon_code: '',
        discount: 0,
        message: '',
        is_edit: false,
      },
      total_amount: 0,
      searchVisible: false,
      exclusive_notes: '',
      exta_discount_type: 'discount',
      is_draft: false,
      prefixes: [],
      suffixes: [],
      custom_headers_suggestions: [],
      item_header_suggestions: [],
      warning: {
        message: '',
        show_warning: false,
        skip_warning: false,
      },
      notesLabel: '',
      termsLabel: '',
      updatedNotes: '',
      updatedTerms: '',
      isNotesEntered: false,
      isTermsEntered: false,
      showNotes: false,
      showTerms: false,
      isNotesLoading: false,
      isTermsLoading: false,
      page: 0,
      num_records: 25,
      old_items_before_edit: [],
      document_discount_type: 'total_amount',
      prev_total_amount: 0,
      credit_limit_warning_shown: false,
      product_update_warning_shown: false,
      immovable_tax_type: 0,
      warehouse_id: undefined,
      company_shipping_addr_id: -1,
      activeKey: initialItems[0].key,
      items: initialItems,
      isCreated: false,
      visible: false,
      isClosed: false,
      auto_draft: {
        id: 0,
        doc_count: 0,
      },
      showDraftMessage: false,
      lastDataSourceUpdated: dayjs(),
      saveDocument: false,
      keyClose: '',
      top_fields: [],
      suggested_fields: [],
      customerDiscount: 0,
      new_serial_number: '',
      new_doc_number: 0,
      insufficientStockModal: {
        isOpen: false,
        items: [],
        message: '',
        errorCode: '',
      },
      paymentsList: [],
      isConversionTracked: false,
      is_prefix: true,
      new_prefix: '',
      document_custom_additional_charges: [],
      from_document_type: '',
      name: '',
      companyDetails: {
        shipping_address: [],
        company_id: 0,
        gstin: '',
        country_code: '',
        sez: 0,
        country: '',
        labels: {
          state: '',
          pincode: '',
          gst: '',
        },
        currency_symbol: '',
        is_export: false,
        is_composite: 0,
        tax_labels: {
          CGST: '',
          SGST: '',
          IGST: '',
          UTGST: '',
        },
        tax_id_label: '',
      },
      selected_company_shipping_address: undefined,
      movement: '',
      showNotesLabelError: false,
      showTermsLabelError: false,
      item_headers: [],
      export_docs_count: 0,
      bank_form_visible: false,
      noOfExportDocsLeft: 0,
    }
    this.initialState = this.state
    this._save = this._save.bind(this)
    this.save = _.throttle(this._save, 2000)
  }

  onCloseInsufficientStockModal = () => {
    this.setState({
      insufficientStockModal: {
        ...this.state.insufficientStockModal,
        isOpen: false,
      },
    })
  }

  updateInsufficientModalItems = ({ items }: any) => {
    this.setState({
      insufficientStockModal: {
        ...this.state.insufficientStockModal,
        items,
      },
    })
  }

  onChangeTab = async (newActiveKey: string) => {
    if (!this.state.isCreated && this.state.dataSource.length > 0) {
      if (this.draftTimeout) {
        await this.save('1', true, true)
      }
    }

    const payload = {
      key: this.state.activeKey,
      data: { ...this.state },
    }
    await this.props.dispatch(update_invoice_data(payload))
    const isCreated = this.state.isCreated
    const activeKey = this.state.activeKey
    const invoiceState = this.props.invoice_tabs.filter(
      (tab: { key: string }) => tab.key == newActiveKey,
    )[0]
    // new items array keep track of updated items
    let new_items = [...this.state.items]
    if (isCreated) {
      // if current active key tab was created then we will remove that tab
      new_items = this.state.items.filter(item => item.key != activeKey)
    }
    this.setState({
      ...invoiceState.data,
      loaded: true,
      items: isCreated ? new_items : this.state.items,
      showDraftMessage: false,
    })
    this.draftTimeout = null
  }

  addNewTab = async () => {
    if (!this.state.isCreated && this.state.dataSource.length > 0) {
      // on adding new tab if there is change in product and is not created yet then we will auto draft
      if (this.draftTimeout) {
        await this.save('1', true, true)
      }
    }
    const currentKey = this.state.activeKey
    const isCreated = this.state.isCreated
    const newActiveKey = `${++this.newTabIndex}`
    let newPanes = [...this.state.items]
    let new_doc_number = this.state.doc_number || 0
    this.props.invoice_tabs.map((tab: any) => {
      if (tab.data.prefix == this.state.prefix && tab.data.doc_number > new_doc_number) {
        new_doc_number = tab.data.doc_number
      }
    })
    new_doc_number = new_doc_number ? Number(new_doc_number) + 1 : 1

    newPanes.push({
      label: `${this.state.prefix + '' + new_doc_number}`,
      key: newActiveKey,
    })
    let new_items = [...this.state.items]
    const payload = {
      key: newActiveKey,
      data: {
        ...this.initialState,
        items: newPanes,
        activeKey: newActiveKey,
        loaded: true,
        prefixes: this.state.prefixes,
        prefix: this.state.prefix,
        new_prefix: this.state.new_prefix,
        is_pos: this.state.is_pos,
        doc_number: new_doc_number,
        auto_draft: {
          id: 0,
          doc_count: 0,
        },
      },
      items: newPanes,
    }
    // if(this.state.dataSource.length>0){
    //   await this.save('1',true,true)
    // }
    this.props.dispatch(
      update_invoice_data({
        key: this.state.activeKey,
        data: { ...this.state },
      }),
    )

    this.setState({
      ...this.initialState,
      activeKey: newActiveKey,
      items: newPanes,
      loaded: true,
      prefixes: this.state.prefixes,
      prefix: this.state.prefix,
      new_prefix: this.state.new_prefix,
      is_pos: this.state.is_pos,
      doc_number: new_doc_number,
      auto_draft: {
        id: 0,
        doc_count: 0,
      },
      showDraftMessage: false,
    })

    this.props.dispatch(add_invoice_data(payload))
    this.draftTimeout = null
  }

  removeTab = async (targetKey: string) => {
    let newActiveKey = this.state.activeKey
    let lastIndex = -1
    this.state.items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const newPanes = this.state.items.filter(item => item.key !== targetKey)
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        newActiveKey = newPanes[0].key
      }
    }
    await this.props.dispatch(
      update_invoice_data({
        data: {
          ...this.state,
          isClosed: true,
        },
        key: targetKey,
      }),
    )
    const invoiceState = this.props.invoice_tabs.filter(
      (tab: { key: string }) => tab.key == newActiveKey,
    )[0]

    this.setState({
      ...invoiceState.data,
      loaded: true,
      activeKey: newActiveKey,
      items: newPanes,
      showDraftMessage: false,
    })
  }
  onEditTab = (targetKey: string, action: string) => {
    if (action === 'add') {
      if (store.get('paid') == 0) {
        // free users cannot create multiple tabs
        this.subscriptionPlanRef.current.openModal('doc_multiple_tabs')
      } else {
        this.addNewTab()
      }
    } else {
      this.removeTab(targetKey)
    }
  }
  getSuggestedColumns = async () => {
    const data = await getAPIData('v2/custom_fields', 'custom_headers')
    if (data) {
      let top_custom_fields = this.props.top_fields.filter((item: any) => {
        if (item.type == 'header') {
          return true
        }
      })
      top_custom_fields = top_custom_fields.filter((item: any) => {
        if (data.custom_headers.find((column: any) => column.label == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({ suggested_fields: top_custom_fields })
    }
  }
  affix = (x: any) => {
    this.setState({ searchVisible: x })
  }

  onRCMChange = (e: any) => {
    // console.log(e)
    var rcm_value = 0
    if (e) {
      rcm_value = parseFloat(this.getTotalTax().toFixed(2)) + this.extraChargesAmount('tax_amount')
    }
    this.setState({ rcm_value: rcm_value, rcm: e }, () => {
      this.onChangeTcsApplicable(this.state.tcs_applicable_on)
      this.onRoundOffChange(this.state.roundoff)
    })
  }
  onWithTaxChange() {
    let dataSource = [...this.state.dataSource]
    if (this.state.with_tax == 1) {
      dataSource = dataSource.map((item: Item) => {
        if (this.state.export_type?.includes('Deemed')) {
          item.tax = 0.1
        }
        item.price_with_tax = (item.unit_price * (item.tax + item.cess)) / 100 + item.unit_price

        item.discount = item.discount
        item.discount_value = Number(
          ((item.price_with_tax * item.qty * item.discount) / 100).toFixed(2),
        )
        item.discount_price_with_tax_value = Number(
          ((item.price_with_tax * item.discount) / 100).toFixed(2),
        )
        item.discount_net_value = Number(((item.price * item.qty * item.discount) / 100).toFixed(2))
        item.discount_unit_price_value = Number(((item.price * item.discount) / 100).toFixed(2))
        item.total_amount = item.price_with_tax * item.qty * (1 - item.discount / 100)
        item.tax_amount = Number(
          (
            (item.net_amount * (item.tax + item.cess)) / 100 +
            (item.cess_on_qty_value ? item.cess_on_qty_value : 0)
          ).toFixed(2),
        )
        return item
      })
    } else {
      dataSource = dataSource.map(item => {
        item.price_with_tax = item.unit_price

        item.discount = item.discount
        item.discount_value = Number(((item.price_with_tax * item.discount) / 100).toFixed(2))
        item.discount_price_with_tax_value = Number(
          ((item.price_with_tax * item.discount) / 100).toFixed(2),
        )
        item.discount_net_value = Number(((item.price * item.discount) / 100).toFixed(2))
        item.discount_unit_price_value = Number(((item.price * item.discount) / 100).toFixed(2))
        item.total_amount = item.price_with_tax * item.qty
        return item
      })

      if (!this.state.edit) {
        let document_custom_additional_charges = this.state.document_custom_additional_charges
        document_custom_additional_charges = document_custom_additional_charges.map(item => {
          item.tax = 0
          item.total_amount = item.net_amount
          return item
        })
        this.updateExtraCharges({
          document_custom_additional_charges,
          has_extra_charges: this.state.has_extra_charges,
        })
      }
    }
    this.setState({ dataSource: dataSource }, () => {
      this.onChangeFullyPaid(this.state.fullyPaid)
      this.onRoundOffChange(this.state.roundoff)
    })
  }

  onRoundOffChange = (a: any) => {
    if (a) {
      var decimal = 0
      if (this.state.with_tax) {
        decimal = Number(
          this.getTotal() +
            Number(this.state.tcs_amount) +
            Number(this.extraChargesAmount('total_amount')) -
            Number(this.state.extraDiscount) -
            Number(this.state.rcm_value),
        )
      } else {
        decimal =
          Number(getTotalNetAmountV2(this.state.dataSource)) +
          Number(this.state.tcs_amount) +
          Number(this.extraChargesAmount('total_amount')) -
          Number(this.state.extraDiscount) -
          Number(this.state.rcm_value)
      }
      decimal = Number(decimal.toFixed(5))
      var remainder = decimal - parseInt(Number(decimal).toFixed(0))
    } else {
      var remainder = 0
    }

    if (remainder >= 0.5) {
      remainder = remainder - 1
    }
    this.setState({ roundoff_value: remainder, roundoff: a ? 1 : 0 })
  }

  addLogo = async ({ onSuccess, onError, file }: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('user', 'update_logo', form)
    if (data?.success) {
      onSuccess(null, file)
      this.setState({ image_url: file.url || file.preview })
      message.success(data.message)
    } else {
      message.error(data?.message ?? 'something went wrong!')
      onError()
    }
  }

  refreshBanks = async () => {
    var data = await getAPIData('user', 'bank_details')
    var default_bank = data.bank_details.filter((bank: any) => bank.is_default == 1)
    if (default_bank.length == 0) {
      var default_bank = data.bank_details[0]
    } else {
      var default_bank = default_bank[0]
    }
    this.setState({
      bank_details: data.bank_details,
      selected_bank: default_bank,
    })
  }
  disabledEndDate = (current: Dayjs) => {
    const start = dayjs(this.state.subscription_start_date, this.dateFormat)
    if (dayjs().add(-1, 'days') >= current) return true
    return current && current <= start.endOf('day')
  }
  refreshSignatures = async () => {
    var data = await getAPIData('signature', 'get')
    var default_signature = data.signatures.filter((signature: any) => signature.is_default == 1)
    if (default_signature.length == 0) {
      var default_signature = data.signatures[data.signatures.length - 1]
    } else {
      var default_signature = default_signature[0]
    }
    this.setState({
      signatures: data.signatures,
      selectedSignature: data.signatures[data.signatures.length - 1],
    })
  }
  getCompayDetails = async (document_type: string) => {
    const data = await getAPIData(
      'v2/doc',
      'get_company_details?' + queryString.stringify({ document_type: document_type, is_web: 1 }),
    )
    return data
  }
  refreshNotesTerms = async () => {
    var data = await this.getCompayDetails(this.state.document_type)

    const find = (element: any) => element.is_default == 1
    if (data?.success) {
      const selected_notes = data.custom_notes.findIndex(find)
      const selected_terms = data.custom_terms.findIndex(find)
      this.setState({
        selected_note: selected_notes == -1 ? 0 : selected_notes,
        selected_term: selected_terms == -1 ? 0 : selected_terms,
        document_notes: data.custom_notes,
        document_terms: data.custom_terms,
        notes:
          data.custom_notes.length == 0
            ? ''
            : data.custom_notes[selected_notes == -1 ? 0 : selected_notes].notes,
        terms:
          data.custom_terms.length == 0
            ? ''
            : data.custom_terms[selected_terms == -1 ? 0 : selected_terms].notes,
        prefixes: data.prefixes,
      })
    }
  }

  getUnitDetails = (
    units: { unit_id: number; alternative_unit: string; conversion_rate: number }[],
    unit_id: number,
    item_unit: string,
    conversion_rate: number,
  ) => {
    var unit = units.filter(
      (unit_item: { unit_id: number; alternative_unit: string; conversion_rate: number }) =>
        (unit_item.unit_id == unit_id || unit_item.alternative_unit == item_unit) &&
        unit_item.conversion_rate == conversion_rate,
    )
    if (unit.length == 0) {
      return {
        unit_id: 0,
        unit: '',
      }
    }
    return {
      unit_id: unit[0].unit_id,
      unit: unit[0].alternative_unit,
    }
  }

  populateDocValues = async (data: GetDocumentResponse, duplicate: boolean, convert: boolean) => {
    this.setState({ loaded: false, prev_total_amount: data.invoice_details.total_amount })
    let received_party_type = PartyType(data.invoice_details.document_type)
    let serial_number_batches: { [key: string]: any[] } = {}
    let batch_products: any[] = []
    let results = data.invoice_details.items.map((row: Item, index: number) => {
      var custom_cols = Object.fromEntries(
        (row?.custom_col_names ?? []).map((key: string, index1: number) => [
          key,
          row?.custom_col_values?.[index1],
        ]),
      )

      delete row?.custom_col_names
      delete row?.custom_col_values

      let unitobj = this.getUnitDetails(
        row?.units,
        row?.unit_id,
        row?.item_unit,
        row?.conversion_rate,
      )

      //batch products
      if (row.has_batches == 1) {
        batch_products.push({
          ...row,
          batch_id: row.batch_id,
          added_qty: row.qty * row.conversion_rate,
          key: index + 1,
          unit_id: unitobj.unit_id,
          unit: unitobj.unit,
          conversion_rate: row.conversion_rate,
        })
      } else if (row.has_batches == 2 && row.batches) {
        let serial_key = String(row.product_id) + '_' + String(row.variant_id)

        if (!serial_number_batches[serial_key]) {
          serial_number_batches[serial_key] = []
        }
        serial_number_batches[serial_key].push(
          ...row.batches?.map(batch => ({
            ...batch,
            price_with_tax: row.price_with_tax,
            unit_price: row.unit_price,
            price: row.unit_price,
          })),
        )
      }

      return {
        ...row,
        key: index + 1,
        position: index + 1,
        ...custom_cols,
        discount: Number(row.discount),
        discount_value: Number((row.discount * row.price_with_tax * row.qty) / 100),
        discount_price_with_tax_value: Number((row.price_with_tax * row.discount) / 100),
        discount_unit_price_value: Number((row.unit_price * row.discount) / 100),
        discount_net_value: Number((row.unit_price * row.discount * row.qty) / 100),
        id: String(row.product_id) + '_' + String(row.variant_id == undefined ? 0 : row.variant_id),
        value: row.product_name + (row.variant_name == '' ? '' : ' ' + row.variant_name),
        qty: row.qty * row.conversion_rate,
        unit_id: unitobj.unit_id,
        unit: unitobj.unit,
        selected_discount_type: row?.selected_discount_type || 'discount',
        is_cess_on_qty_applicable: row.cess_on_qty_value ? true : false,
        tax_amount: row.tax_amount + row.cess_on_qty_value,
        product_discount: row.master_data.discount,
        selling_price: row.master_data.price_with_tax,
        batches: row.batches || [],
      }
    })

    //separate batches with same product id
    let batches: any[] = []

    batch_products.forEach((row: Item) => {
      let index = batches.findIndex(
        batch => batch.product_id == row.product_id && batch.variant_id == row.variant_id,
      )
      if (index == -1) {
        batches.push({
          product_id: row.product_id,
          variant_id: row.variant_id,
          batches: [
            {
              batch_id: row.batch_id,
              added_qty: row.added_qty,
              old_qty: row.added_qty,
              is_edit: this.state.edit ? 1 : 0,
              batch_uid: String(row.batch_id) + '_' + String(0),
              is_duplicate: false,
              price_with_tax: row.price_with_tax,
              unit_price: row.unit_price,
              price: row.unit_price,
              key: row.key,
              unit: row.item_unit,
              unit_id: row.unit_id,
              conversion_rate: row.conversion_rate,
            },
          ],
        })
      }
      if (index != -1) {
        //find the batch with same batch id and get uid
        let same_batches = batches[index].batches.filter(
          (batch: { batch_id: number }) => batch.batch_id == row.batch_id,
        )
        let uid = String(row.batch_id) + '_' + String(0)
        let is_duplicate = false
        if (same_batches.length > 0) {
          let uid = same_batches[same_batches.length - 1].batch_uid
          uid = uid.split('_')
          uid = String(row.batch_id) + '_' + String(Number(uid[1]) + 1)
          is_duplicate = true
        }
        batches[index].batches.push({
          batch_id: row.batch_id,
          added_qty: row.added_qty,
          old_qty: row.added_qty,
          is_edit: this.state.edit ? 1 : 0,
          batch_uid: uid,
          is_duplicate: is_duplicate,
          key: row.key,
          price_with_tax: row.price_with_tax,
          unit_price: row.unit_price,
          price: row.unit_price,
          unit: row.item_unit,
          unit_id: row.unit_id,
          conversion_rate: row.conversion_rate,
        })
      }
    })
    if (this.allowBatches(data.invoice_details.document_type)) {
      results = results.map(item => {
        if (item.has_batches == 1) {
          item.batches = batches.find(
            batch => batch.product_id == item.product_id && batch.variant_id == item.variant_id,
          ).batches

          item.batch_uid = item.batches.find((batch: Batch) => batch.key == item.key)?.batch_uid
        } else if (item.has_batches == 2) {
          item.batches =
            serial_number_batches[String(item.product_id) + '_' + String(item.variant_id)]

          item.batches = item?.batches?.map(batch => ({
            ...batch,
            added_qty: 1,
            batch_uid: String(batch.batch_id) + '_' + String(0),
            is_edit: this.state.edit ? 1 : 0,
            old_qty: 1,
          }))
        }

        return item
      })
    }

    if (data.invoice_details.is_export == 1 || data.invoice_details.is_multi_currency == 1) {
      results = results.map(item => {
        item.export_unit_price =
          item.unit_price / data.invoice_details.export_details[0].conversion_factor
        item.export_price_with_tax =
          item.price_with_tax / data.invoice_details.export_details[0].conversion_factor
        return item
      })
    }

    var selectedBankId = this.state.bank_details.find(
      ({ id }) => id === data.invoice_details.bank_id,
    )
    var default_bank: any = this.state.bank_details.filter((bank: Bank) => bank.is_default == 1)
    if (default_bank.length == 0) {
      default_bank = this.state.bank_details.length > 0 ? this.state.bank_details[0] : {}
    } else {
      default_bank = default_bank[0]
    }
    if (!window.location.href.includes('convert')) {
      default_bank = selectedBankId
    }

    if (this.state.party_type == 'customer' && received_party_type != 'vendor') {
      await this.updateCustomer({
        ...data.invoice_details.customer,
        id: data.invoice_details.customer_id,
      })
    }
    if (data.invoice_details.customer.price_list_id != -1) {
      this.setState({ selectedPriceList: data.invoice_details.customer.price_list_id }, () => {
        if (this.table) {
          this.table.refreshAutocompleteProducts()
        }
      })
    }

    data.invoice_details.custom_headers.map((item, index) => {
      data.invoice_details.document_custom_headers.map((item2, index2) => {
        if (item2.header_id == item.header_id) {
          data.invoice_details.custom_headers[index]['value'] = item2.value
        }
      })
    })

    if (data.invoice_details.hasOwnProperty('export_details')) {
      if (data.invoice_details.export_details.length > 0) {
        const export_details = data.invoice_details.export_details[0]

        let shipping_bill_date =
          export_details.shipping_bill_date == '0000-00-00'
            ? ''
            : dayjs(export_details.shipping_bill_date, 'ddd, DD MMM YYYY HH:mm:ss')
                .add(5.5, 'hours')
                .format('DD-MM-YYYY')
        this.setState({
          export_invoice: data.invoice_details.is_export,
          is_multi_currency: data.invoice_details.is_multi_currency,
          shipping_bill_date: shipping_bill_date,
          shipping_port_code: export_details.shipping_port_code
            ? export_details.shipping_port_code
            : '',
          shipping_bill_number: export_details.shipping_bill_number
            ? export_details.shipping_bill_number
            : '',
          export_type: export_details.export_type,
          country: export_details.country_id,
          conversion_factor: Number(formatNumber(export_details.conversion_factor)),
          currency: export_details.currency_code,
          currency_symbol: export_details.currency_symbol,
        })
      }
    }
    const tds_condition =
      this.state.document_type == 'invoice' ||
      this.state.document_type == 'sales_return' ||
      this.state.document_type == 'purchase' ||
      this.state.document_type == 'purchase_return' ||
      this.state.document_type == 'subscription'

    let selectedCustomer: number = -1
    if (
      this.state.party_type == 'customer' &&
      received_party_type != 'vendor' &&
      data.invoice_details.customer.customer_id != 0
    ) {
      selectedCustomer = data.invoice_details.customer.customer_id
    } else if (
      this.state.party_type == 'vendor' &&
      received_party_type != 'customer' &&
      data.invoice_details.customer.vendor_id != 0
    ) {
      selectedCustomer = data.invoice_details.customer.vendor_id
    }

    let customerData: Party[] = []
    if (
      this.state.party_type == 'customer' &&
      received_party_type != 'vendor' &&
      data.invoice_details.customer.customer_id != 0
    ) {
      customerData = [
        {
          ...data.invoice_details.customer,
          id: data.invoice_details.customer.customer_id,
          value: data.invoice_details.customer.name,
          notes: data.invoice_details.customer.name,
        },
      ]
    } else if (
      this.state.party_type == 'vendor' &&
      received_party_type != 'customer' &&
      data.invoice_details.customer.vendor_id != 0
    ) {
      customerData = [
        {
          ...data.invoice_details.customer,
          id: data.invoice_details.customer.vendor_id,
          value: data.invoice_details.customer.name,
          notes: data.invoice_details.customer.name,
        },
      ]
    }
    if (customerData.length > 0 && customerData[0]?.shipping_address == undefined) {
      customerData[0].shipping_address = []
    }
    let company_shipping_addr_id = data.invoice_details.company_shipping_addr_id
    if (window.location.href.includes('convert')) {
      company_shipping_addr_id = -1

      if (this.state.from_document_type == 'estimate' && this.state.to_document_type == 'invoice') {
        const deletedItems = results.filter((item: any) => item.is_delete == 1)
        if (deletedItems.length > 0) {
          Modal.confirm({
            title: 'Confirm',
            width: 600,
            content: (
              <div>
                <p>
                  Some items have been deleted from your inventory. Would you like to remove them
                  from the bill?
                </p>
                <h6 className="mt-1">Deleted Items:</h6>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  {deletedItems.map((item: any) => (
                    <li key={item.id} className="mb-2">
                      {item.product_name}
                    </li>
                  ))}
                </ul>
              </div>
            ),
            onOk: () => {
              results = results.filter((item: any) => item.is_delete != 1)
              this.setState({ dataSource: results })
            },
            onCancel: () => {},
            cancelText: 'Continue',
            okText: 'Yes, Remove',
            cancelButtonProps: { type: 'danger' },
          })
        }
      }
    }

    const document_item_headers = data.invoice_details.document_item_headers?.map((item: any) => {
      return {
        id: item.id,
        key: item.position,
        value: item.header_value,
        product_name: item.header_value,
        is_item_header: true,
      }
    })

    const mergedData = mergeItemsAndItemHeaders(results, document_item_headers)

    this.setState(
      {
        customerData: customerData,
        customer_details: customerData.length > 0 ? customerData[0] : this.state.customer_details,
        name: data.invoice_details.customer.name,
        selectedCustomer: selectedCustomer,
        // customerName: data.invoice_details.customer.name,
        company_shipping_addr_id: data.invoice_details.company_shipping_addr_id,
        selected_company_shipping_address: this.state.companyDetails.shipping_address.find(
          ({ addr_id }) => addr_id === company_shipping_addr_id,
        ),
        selected_customer_shipping_address:
          customerData.length > 0
            ? customerData[0].shipping_address.find(
                ({ addr_id }) => addr_id === data.invoice_details.customer_shipping_addr_id,
              )
            : undefined,
        is_tds: tds_condition ? data.invoice_details.is_tds : 0,
        tds_amount:
          data.invoice_details.is_tds && tds_condition
            ? data.invoice_details.tds_details?.[0]?.tds_amount
            : 0,
        tds_selected_section:
          data.invoice_details.is_tds && tds_condition
            ? data.invoice_details.tds_details?.[0]?.tds_id
            : undefined,
        is_tcs: tds_condition ? data.invoice_details.is_tcs : 0,
        tcs_amount:
          data.invoice_details.is_tcs && tds_condition
            ? data.invoice_details.tcs_details?.[0]?.tcs_amount
            : 0,
        tcs_selected_section:
          data.invoice_details.is_tcs && tds_condition
            ? data.invoice_details.tcs_details?.[0]?.tds_id
            : undefined,
        tds_applicable_on: data.invoice_details.is_tds
          ? data.invoice_details.tds_details?.[0]?.apply_on
          : 'net_amount',
        tcs_applicable_on: data.invoice_details.is_tcs
          ? data.invoice_details.tcs_details?.[0]?.apply_on
          : 'total_amount',
        warehouse_id:
          data.invoice_details.warehouse_id == -1 ? undefined : data.invoice_details.warehouse_id,
        item_headers: mergedData.filter((item: any) => item.is_item_header) ?? [],
      },
      () => {
        const serial_number = data.invoice_details.serial_number
        const document_suffix = data.invoice_details.document_suffix
        const new_serial_number = data.invoice_details.serial_number.substring(
          0,
          serial_number.length - document_suffix.length,
        )
        this.setState(
          {
            dataSource: mergedData.filter((item: any) => !item.is_item_header),
            old_items_before_edit: [...mergedData.filter((item: any) => !item.is_item_header)],
            edit: this.state.edit,
            count: results.length,
            serial_number:
              duplicate || convert ? data.new_serial_number : data.invoice_details.serial_number,
            sub_serial_number:
              duplicate || convert ? data.new_serial_number : data.invoice_details.serial_number,
            id: data.invoice_details.id,
            order_serial_number:
              this.state.from_document_type == 'online_order'
                ? data.invoice_details.serial_number
                : '',

            rzp_order_id:
              this.state.from_document_type == 'online_order'
                ? data.invoice_details.rzp_order_id
                : '',
            rzp_payment_id:
              this.state.from_document_type == 'online_order'
                ? data.invoice_details.rzp_payment_id
                : '',
            new_hash_id: data.invoice_details.new_hash_id,
            doc_count: data.invoice_details.doc_count,
            is_subscription:
              this.state.document_type == 'subscription' ? 1 : data.invoice_details.is_subscription,
            supplier_invoice_date:
              this.state.document_type == 'purchase'
                ? data.invoice_details.supplier_doc_date
                : dayjs().format(this.dateFormat),
            supplier_invoice_serial_number:
              this.state.document_type == 'purchase'
                ? data.invoice_details.supplier_invoice_serial_number
                : '',
            document_date:
              convert || duplicate
                ? dayjs().format(this.dateFormat)
                : data.invoice_details.start_date,
            due_date:
              convert || duplicate
                ? dayjs()
                    .add(this.props?.invoice_settings?.default_due_date, 'days')
                    .utcOffset(330)
                    .format(this.dateFormat)
                : data.invoice_details.end_date,
            // document_title: data.invoice_details.document_title,
            reference: data.invoice_details.reference,
            terms: convert && !this.state.is_draft ? this.state.terms : data.invoice_details.terms,
            exclusive_notes:
              convert && !this.state.is_draft
                ? this.state.exclusive_notes
                : data.invoice_details.exclusive_notes,
            hide_totals: convert ? 0 : data.invoice_details.hide_totals,
            notes: convert && !this.state.is_draft ? this.state.notes : data.invoice_details.notes,
            name: data.invoice_details.customer.name,
            is_pos: data.invoice_details.invoice_type == 'b2c',
            description: data.invoice_details.show_description,
            selectedSignature:
              this.state.signatures.find(({ image }) => image === data.invoice_details.signature) ||
              this.state.selectedSignature,
            selected_bank: default_bank,
            prefix:
              convert || duplicate
                ? data.invoice_details.prefix
                : new_serial_number.replace(
                    new RegExp(
                      data.invoice_details.doc_number +
                        '(?![\\s\\S]*' +
                        data.invoice_details.doc_number +
                        ')',
                    ),
                    '',
                  ),
            new_prefix: convert
              ? ''
              : new_serial_number.replace(
                  new RegExp(
                    data.invoice_details.doc_number +
                      '(?![\\s\\S]*' +
                      data.invoice_details.doc_number +
                      ')',
                  ),
                  '',
                ),
            suffix: convert || duplicate ? data.invoice_details.suffix : document_suffix,
            new_suffix: convert ? '' : document_suffix,
            company_id: data.invoice_details.company_id,
            attachments: data.invoice_details.attachments,
            custom_headers: data.invoice_details.custom_headers,
            with_tax:
              convert &&
              this.state.document_type !== 'pro_forma_invoice' &&
              this.state.document_type !== 'sales_return' &&
              !this.state.is_draft &&
              data.invoice_details.company.is_composite == 0 &&
              !data.invoice_details.is_export
                ? 1
                : data.invoice_details.with_tax,

            roundoff: data.invoice_details.roundoff,
            roundoff_value: data.invoice_details.roundoff_value,
            rcm: data.invoice_details.rcm,
            rcm_value: data.invoice_details.rcm == 1 ? data.invoice_details.tax_amount : 0,
            doc_number:
              duplicate || convert
                ? data.invoice_details.new_doc_number
                : data.invoice_details.doc_number,

            coupon_details: data.invoice_details.coupon_details,
            invoiceData: data.invoice_details,
            immovable_tax_type: data.invoice_details.immovable_tax_type,
          },
          async () => {
            if (this.table) {
              await this.table.edit(this.state.edit)
            }
            // console.log(this.state.dataSource)
            let newData = this.state.dataSource.map((item, index) => {
              return this.table?.onCustomChange(
                item.qty,
                item.key,
                'Quantity',
                'qty',
                this.state.dataSource,
              )
            })
            clearTimeout(this.draftTimeout)
            this.draftTimeout = null
            this.setState({ dataSource: newData })
            if (this.table) {
              await this.table?.dataUpdate(newData, this.state.list_name)
            }

            if (data.invoice_details.is_subscription) {
              this.setState({
                subscription_start_date: dayjs(data.invoice_details.subscription.start_time).format(
                  this.dateFormat,
                ),

                subscription_end_date: dayjs(data.invoice_details.subscription.end_time).format(
                  this.dateFormat,
                ),
                repeatType: data.invoice_details.subscription.repeat_unit,
                repeat_every: data.invoice_details.subscription.repeat_every,
                send_email: data.invoice_details.subscription.send_email,
                send_sms: data.invoice_details.subscription.send_sms,
                invoice_count: data.invoice_details.subscription.invoice_count,
              })
            }

            var custom_additional_charges = data.invoice_details.custom_additional_charges.map(
              (item, index) => {
                const index1 = data.invoice_details.document_custom_additional_charges.findIndex(
                  item1 => item1.additional_charge_id == item.additional_charge_id,
                )
                if (index1 != -1) {
                  return {
                    ...item,
                    ...data.invoice_details.document_custom_additional_charges[index1],
                    sac_code: item.sac_code,
                  }
                } else {
                  return {
                    ...item,
                    total_amount: 0,
                    tax: 0,
                    tax_amount: 0,
                    percent: 0,
                    net_amount: 0,
                  }
                }
              },
            )
            custom_additional_charges = custom_additional_charges.filter(
              item => !(item.total_amount == 0 && (item.is_delete == 1 || item.is_active == 0)),
            )
            this.setState(
              {
                extraDiscount: data.invoice_details.extra_discount,
                extraDiscountPer: Number(
                  (
                    (Number(data.invoice_details.extra_discount) * 100) /
                    (this.getTotal() ? this.getTotal() : 1)
                  ).toFixed(2),
                ),
                has_extra_charges: data.invoice_details.has_extra_charges,
                document_custom_additional_charges: custom_additional_charges,
                custom_additional_charges: data.invoice_details.custom_additional_charges,
              },
              () => this.onRoundOffChange(this.state.roundoff),
            )
          },
        )
      },
    )
    this.setState({ loaded: true })
  }

  async fetchDetails() {
    // get document type
    if (!this.props.external_create) {
      var document_type = ''
      if (window.location.href.includes('create')) {
        var url_items = window.location.href.split('/create/')
        var document_type = url_items[url_items.length - 1].split('/')[0]
        var from_document_type = ''
        var to_document_type = ''
        var create = true
        var edit = false
        var duplicate = false
        var convert = false
      } else if (window.location.href.includes('edit')) {
        var url_items = window.location.href.split('/edit/')
        var document_type = url_items[url_items.length - 1].split('/')[0]
        var from_document_type = ''
        var to_document_type = ''
        var create = false
        var edit = true
        var duplicate = false
        var convert = false
      } else if (window.location.href.includes('duplicate')) {
        var url_items = window.location.href.split('/duplicate/')
        var document_type = url_items[url_items.length - 1].split('/')[0]
        var from_document_type = ''
        var to_document_type = ''
        var create = false
        var edit = false
        var duplicate = true
        var convert = false
      } else if (window.location.href.includes('convert')) {
        var url_items = window.location.href.split('/convert/')
        var from_document_type = url_items[url_items.length - 1].split('/')[0]
        var to_document_type = url_items[url_items.length - 1].split('/')[1]
        var document_type = to_document_type
        var create = false
        var edit = false
        var duplicate = false
        var convert = true
      } else {
        var from_document_type = ''
        var to_document_type = ''
        var document_type = ''
        var create = false
        var edit = false
        var duplicate = false
        var convert = true
      }
    } else {
      var document_type = this.props.document_type
      var from_document_type = ''
      var to_document_type = ''
      var create = true
      var edit = false
      var duplicate = false
      var convert = false
      var movement = ''
    }

    var req = { document_type: document_type, is_web: 1 }

    if (document_type == 'invoice') {
      var party_type = 'customer'
      var document_title = 'Invoice'
      var list_name = 'sales'
      var movement = 'out'
    } else if (document_type == 'purchase') {
      var party_type = 'vendor'
      var document_title = 'Purchase'
      var list_name = 'purchases'
      var movement = 'in'
    } else if (document_type == 'purchase_order') {
      var party_type = 'vendor'
      var document_title = 'Purchase Order'
      var list_name = 'purchase_orders'
      var movement = ''
    } else if (document_type == 'estimate') {
      var party_type = 'customer'
      var document_title = 'Quotation'
      var list_name = 'estimates'
      var movement = ''
    } else if (document_type == 'sales_return') {
      var party_type = 'customer'
      var document_title = 'Sales Return / Credit Note'
      var list_name = 'sales_returns'
      var movement = 'in'
    } else if (document_type == 'delivery_challan') {
      var party_type = 'customer'
      var document_title = 'Delivery Challan'
      var list_name = 'delivery_challans'
      var movement =
        this.props.document.invoiceSettings.track_stock_for_delivery_challan == 1 ? 'out' : ''
    } else if (document_type == 'purchase_return') {
      var party_type = 'vendor'
      var document_title = 'Purchase Return / Debit Note'
      var list_name = 'purchase_returns'
      var movement = 'out'
    } else if (document_type == 'pro_forma_invoice') {
      var party_type = 'customer'
      var document_title = 'Pro Forma Invoice'
      var list_name = 'pro_forma_invoices'
      var movement = ''
    } else if (document_type == 'subscription') {
      var party_type = 'customer'
      var document_title = 'Subscription'
      var list_name = 'subscriptions'
      var movement = 'in'
    } else if (document_type == 'online_order') {
      var party_type = 'customer'
      var document_title = 'Online Order'
      var list_name = 'onlineorders'
      var movement = 'in'
    } else {
      var party_type = ''
      var document_title = ''
      var list_name = ''
      var movement = ''
    }
    var data = await this.getCompayDetails(document_type)
    let default_warehouse_id = undefined
    if (this.props.default_warehouse_id && this.props.default_warehouse_id != -1) {
      let stock_in_warehouse_ids =
        this.props.stock_in_warehouses?.map((item: any) => item.warehouse_id) ?? []
      let stock_out_warehouse_ids =
        this.props.stock_out_warehouses?.map((item: any) => item.warehouse_id) ?? []
      if (movement == 'in' && stock_in_warehouse_ids?.includes(this.props.default_warehouse_id)) {
        default_warehouse_id = this.props.default_warehouse_id
      } else if (
        movement == 'out' &&
        stock_out_warehouse_ids?.includes(this.props.default_warehouse_id)
      ) {
        default_warehouse_id = this.props.default_warehouse_id
      } else {
        default_warehouse_id = this.props.default_warehouse_id
      }
    } else {
      if (movement == 'in') {
        default_warehouse_id = this.props.stock_in_warehouses[0]?.warehouse_id
      } else if (movement == 'out') {
        default_warehouse_id = this.props.stock_out_warehouses[0]?.warehouse_id
      } else {
        default_warehouse_id =
          this.props?.warehouses?.length > 0 ? this.props.warehouses[0]?.warehouse_id : undefined
      }
    }
    if (data && data.success) {
      // set default bank and signature
      var default_banks = data.bank_details.filter((bank: Bank) => bank.is_default == 1)
      var default_bank = default_banks.length
        ? default_banks[0]
        : data.bank_details[data.bank_details.length - 1]

      var default_signatures = data.signatures.filter(
        (signature: Signature) => signature.is_default == 1,
      )
      var default_signature = default_signatures.length
        ? default_signatures[0]
        : data.signatures[data.signatures.length - 1]

      data.custom_headers.map((item: CustomHeader, index: number) => {
        if (item.placeholder != '') {
          data.custom_headers[index]['value'] = item.placeholder
        }
      })
      const find = (element: any) => element.is_default == 1
      const selected_notes = data.custom_notes.findIndex(find)
      const selected_terms = data.custom_terms.findIndex(find)
      if (window.location.href.includes('create')) {
        data.company_details.shipping_address = data.company_details.shipping_address.filter(
          (item: Address) => item.is_delete == 0,
        )
      }

      var document_custom_additional_charges = data.custom_additional_charges.map(
        (item: any, index: number) => {
          const currentItem = this.state.document_custom_additional_charges?.find(
            (item1: AdditonalCharge) => item1.additional_charge_id == item.additional_charge_id,
          )

          return {
            name: item.name,
            additional_charge_id: item.additional_charge_id,
            total_amount: currentItem?.total_amount || 0,
            tax: currentItem?.tax || 0,
            tax_amount: currentItem?.tax_amount || 0,
            percent: currentItem?.percent || 0,
            net_amount: currentItem?.net_amount || 0,
            sac_code: item.sac_code,
            type: item.type,
          }
        },
      )
      this.setState(
        {
          prefixes: data.prefixes,
          suffixes: data.suffixes,
          document_notes: data.custom_notes,
          document_terms: data.custom_terms,
          selected_note: selected_notes == -1 ? 0 : selected_notes,
          selected_term: selected_terms == -1 ? 0 : selected_terms,
          bank_details: data.bank_details,
          paymentMethod:
            default_bank?.bank_name != 'Cash'
              ? this.props?.document?.paymentModes?.[0]?.value
              : 'Cash',
          with_tax:
            data.company_details.gstin?.length != 15 ||
            (data.company_details.is_composite == 1 && !document_type.includes('purchase'))
              ? 0
              : 1,
          signatures: data.signatures,
          selected_bank: default_bank,
          serial_number: data.serial_number,
          sub_serial_number: data.serial_number,
          notes:
            data.custom_notes.length == 0
              ? ''
              : data.custom_notes[selected_notes == -1 ? 0 : selected_notes].notes,
          terms:
            data.custom_terms.length == 0
              ? ''
              : data.custom_terms[selected_terms == -1 ? 0 : selected_terms].notes,
          prefix: create ? data.prefix : '',
          suffix: create ? data.suffix : '',
          company_id: data.company_id,
          doc_number: create ? data.doc_number : '',
          taxes: data.taxes,
          selectedSignature: default_signature,
          image_url: data.logo,
          custom_headers: data.custom_headers,
          document_type: document_type,
          party_type: party_type,
          document_title: document_title,
          movement: movement,
          warehouse_id: default_warehouse_id,
          is_subscription: document_type == 'subscription' ? 1 : 0,
          list_name: list_name,
          create: create,
          edit: edit,
          duplicate: duplicate,
          convert: convert,
          from_document_type: from_document_type,
          to_document_type: to_document_type,
          is_draft: from_document_type == 'draft' ? true : false,
          companyDetails: data.company_details,
          document_discount_type: data.company_details.discount_type,
          autocompleteData: [],
          selected_company_shipping_address:
            data.company_details.shipping_address.length && document_type.includes('purchase')
              ? data.company_details.shipping_address[0]
              : {},
          document_custom_additional_charges: document_custom_additional_charges,
          custom_additional_charges: data.custom_additional_charges,
          prev_total_amount: 0,
          credit_limit_warning_shown: false,
          recent_tds: Object.keys(data.recent_tds ?? {}).length == 0 ? undefined : data.recent_tds,
          recent_tcs: Object.keys(data.recent_tcs ?? {}).length == 0 ? undefined : data.recent_tcs,
          export_docs_count: data.export_docs_count, // contains both export and multi currency docs
          noOfExportDocsLeft: Math.max(FREE_USER_EXPORT_LIMIT - data.export_docs_count, 0),
        },
        async () => {
          var data = await getAPI('product', 'get_price_lists', { search: '' })
          if (data && data.success) {
            this.setState({ price_lists: data.price_lists })
          }

          if (this.props.external_create) {
            let customer_id = this.props.customer_id
            var req = {
              id: customer_id,
            }
            var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)

            if (data?.success) {
              if (
                data[this.state.party_type + '_details'][0].is_tds == 1 &&
                data[this.state.party_type + '_details'][0].tds_section_id != 0 &&
                (this.state.document_type == 'invoice' ||
                  this.state.document_type == 'sales_return' ||
                  this.state.document_type == 'purchase' ||
                  this.state.document_type == 'purchase_return' ||
                  this.state.document_type == 'subscription')
              ) {
                this.setState({ is_tds: 1 })
              }
              if (data[this.state.party_type + '_details'][0].tds_section_id != 0) {
                this.setState({
                  tds_selected_section: data[this.state.party_type + '_details'][0].tds_section_id,
                })
              }
              if (
                data[this.state.party_type + '_details'][0].is_tcs == 1 &&
                data[this.state.party_type + '_details'][0].tcs_section_id != -1 &&
                (this.state.document_type == 'invoice' ||
                  this.state.document_type == 'sales_return' ||
                  this.state.document_type == 'purchase' ||
                  this.state.document_type == 'purchase_return' ||
                  this.state.document_type == 'subscription')
              ) {
                this.setState({ is_tcs: 1 })
              }
              if (data[this.state.party_type + '_details'][0].tcs_section_id != -1) {
                this.setState({
                  tcs_selected_section: data[this.state.party_type + '_details'][0].tcs_section_id,
                })
              }
              if (data[this.state.party_type + '_details'][0]['price_list_id'] != -1) {
                this.setState(
                  {
                    selectedPriceList: data[this.state.party_type + '_details'][0]['price_list_id'],
                  },
                  () => {
                    this.table?.refreshAutocompleteProducts()
                  },
                )
              }

              this.setState({
                customer_details: data.customer_details[0],
                selected_customer_shipping_address:
                  data.customer_details[0]['shipping_address'].length > 0
                    ? data.customer_details[0]['shipping_address'][0]
                    : {},
              })

              this.setState({
                customerData: [
                  {
                    ...data.customer_details[0],
                    id:
                      this.state.party_type == 'customer'
                        ? data.customer_details[0].customer_id
                        : data.customer_details[0].vendor_id,
                    value: data.customer_details[0].name,
                    notes: data.customer_details[0].name,
                  },
                ],
                name: data.customer_details[0].name,
                selectedCustomer:
                  this.state.party_type == 'customer'
                    ? data.customer_details[0].customer_id
                    : data.customer_details[0].vendor_id,
              })
            }
          }

          let payment_default_data = {
            ...emptyPaymentDetails,
            payment_method:
              this.state.selected_bank?.id == 0
                ? 'Cash'
                : this.props?.document?.paymentModes?.[0]?.value,
            bank_id: this.state.selected_bank?.id != -1 ? this.state.selected_bank?.id : undefined,
            key: 1,
          }

          this.setState({ loaded: false, paymentsList: [payment_default_data] })

          // auto select customer/vendor from id
          if (window.location.href.includes('create')) {
            var url_items = window.location.href.split('/create/')
            if (url_items[url_items.length - 1].split('/').length > 1) {
              var customer_id = parseInt(url_items[url_items.length - 1].split('/')[1])
              var req = {
                id: customer_id,
              }

              var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
              if (data?.success) {
                if (data[this.state.party_type + '_details'][0]['price_list_id'] != -1) {
                  this.setState(
                    {
                      selectedPriceList:
                        data[this.state.party_type + '_details'][0]['price_list_id'],
                    },
                    () => {
                      this.table?.refreshAutocompleteProducts()
                    },
                  )
                }

                this.setState({
                  customer_details: data.customer_details[0],
                  selected_customer_shipping_address:
                    data.customer_details[0]['shipping_address'].length > 0
                      ? data.customer_details[0]['shipping_address'][0]
                      : {},
                  customerData: [
                    {
                      ...data.customer_details[0],
                      id:
                        this.state.party_type == 'customer'
                          ? data.customer_details[0].customer_id
                          : data.customer_details[0].vendor_id,
                      value: data.customer_details[0].name,
                      notes: data.customer_details[0].name,
                    },
                  ],
                  name: data.customer_details[0].name,
                  selectedCustomer:
                    this.state.party_type == 'customer'
                      ? data.customer_details[0].customer_id
                      : data.customer_details[0].vendor_id,
                })
              }
            }
          }

          if (window.location.href.includes('edit')) {
            var url_items = window.location.href.split('/edit/')
            var document_type = url_items[url_items.length - 1].split('/')[0]
            var new_hash_id = url_items[url_items.length - 1].split('/')[1]

            const req = {
              document_type: document_type,
              new_hash_id: new_hash_id,
              is_pdf: false,
              identifier: 'create',
            }

            var data = await getAPI('v2/doc', 'get_invoice', req)

            if (data?.success) {
              this.setState(
                {
                  loaded: true,
                  payment_status: data.invoice_details.payment_status,
                  document_title: data.invoice_details.document_title,
                  selected_note: undefined,
                  selected_term: undefined,
                  document_discount_type: data.invoice_details.discount_type,
                  company_shipping_addr_id: data.invoice_details.company_shipping_addr_id,
                  description: data.invoice_details.show_description ? 1 : 0,
                },
                async () => {
                  await this.populateDocValues(data, false, false)
                },
              )
            }
          } else if (window.location.href.includes('duplicate')) {
            var url_items = window.location.href.split('/duplicate/')
            var document_type = url_items[url_items.length - 1].split('/')[0]
            var new_hash_id = url_items[url_items.length - 1].split('/')[1]

            const req = {
              document_type: document_type,
              new_hash_id: new_hash_id,
              duplicate: this.state.duplicate,
              convert: this.state.convert,
              convert_from: this.state.from_document_type,
              convert_to: this.state.to_document_type,
              is_pdf: false,
            }

            var data = await getAPI('v2/doc', 'get_invoice', req)

            if (data?.success) {
              this.setState(
                { loaded: true, document_discount_type: data.invoice_details.discount_type },
                async () => {
                  await this.populateDocValues(data, true, false)
                },
              )
            }
          } else if (window.location.href.includes('convert')) {
            var url_items = window.location.href.split('/convert/')
            var document_type = url_items[url_items.length - 1].split('/')[0]
            var new_hash_id = url_items[url_items.length - 1].split('/')[2]

            const req = {
              document_type: from_document_type,
              new_hash_id: new_hash_id,
              duplicate: this.state.duplicate,
              convert: this.state.convert,
              convert_from: this.state.from_document_type,
              convert_to: this.state.to_document_type,
              is_pdf: false,
            }

            var data = await getAPI('v2/doc', 'get_invoice', req)

            if (data?.success) {
              this.setState(
                {
                  loaded: true,
                  document_discount_type: data.invoice_details.discount_type,
                  isConversionTracked:
                    data.invoice_details?.invoice_settings?.is_conversion_tracked,
                },
                async () => {
                  await this.populateDocValues(data, false, true)
                },
              )
            }
          }
          this.setState({ loaded: true })
          this.customerSearchAPI('')

          if (
            this.state.document_type == 'invoice' &&
            this.props?.user?.selectedCompany?.country_code == 'IN' &&
            (this.state.document_title == 'Bill of Supply' ||
              this.state.companyDetails.is_composite == 1)
          ) {
            this.onDocumentTitleChange('Bill of Supply')
          }
        },
      )
    }

    // console.log(this.props)
    if (this.props?.tds_sections?.length == 0) {
      await this.props.dispatch(getTdsSections())
    }
    if (this.props?.tcs_sections?.length == 0) {
      await this.props.dispatch(get_tcs_sections())
    }
    if (this.props?.countries?.length == 0) {
      await this.props.dispatch(getCountries())
    }
    if (this.props?.product_categories?.length == 0) {
      await this.props.dispatch(get_categories())
    }
    if (this.props?.warehouses == undefined) {
      await this.props.dispatch(get_warehouses())
    }
    if (this.props?.document?.paymentModes?.length == 0) {
      await this.props.dispatch(get_payment_modes())
    }
    // if (this.props?.port_codes?.length == 0) {
    //   await this.props.dispatch(get_port_codes())
    // }

    if (this.props.invoice_settings?.show_custom_header_suggestions) {
      const custom_headers_suggestions = await getAPI('utils', 'get_suggestions', {
        type: 'custom_headers',
        document_type,
      })
      if (custom_headers_suggestions?.success) {
        this.setState({ custom_headers_suggestions: custom_headers_suggestions?.suggestions })
      }
    }
    const item_header_suggestions = await getAPI('utils', 'get_suggestions', {
      type: 'item_headers',
      document_type,
    })
    if (item_header_suggestions?.success) {
      this.setState({ item_header_suggestions: item_header_suggestions?.suggestions })
    }

    this.setState({
      items: this.state.items.map(item =>
        item.key == this.state.activeKey
          ? {
              ...item,
              label: this.state.prefix + this.state.doc_number,
            }
          : item,
      ),
    })
    const payload = {
      key: '1',
      data: this.state,
    }
    this.initialState = this.state
    await this.props.dispatch(add_invoice_data(payload))
  }

  async componentDidMount() {
    await this.fetchDetails()

    // Change to cookies
    // this.financialYearRef.current.showModal()

    if (this.props.top_fields.length == 0) {
      this.props.dispatch(get_top_custom_fields())
    }
    await this.getSuggestedColumns()
    if (
      window.location.pathname.includes('create')
      //  || window.location.pathname.includes('edit')
    ) {
      // this.startAutoDrafting()
    }
  }

  onChangeFullyPaid = (e: any) => {
    const initialData = this.state.paymentsList[0]
    const selectedBankId = this.state.selected_bank?.id
    if (e) {
      this.setState(
        {
          fullyPaid: e,
          amountReceived: this.getTotalAmount() - this.state.tds_amount,
          paymentsList: [
            {
              payment_method: selectedBankId == 0 ? 'Cash' : initialData.payment_method,
              amount: this.getTotalAmount() - this.state.tds_amount,
              payment_notes: initialData.payment_notes,
              bank_id: selectedBankId != 0 ? initialData.bank_id : selectedBankId,
            },
          ],
        },
        // () => {
        //   const selectedBank = this.state.bank_details.find(({ id }) => id === initialData.bank_id)
        //   if (selectedBank?.bank_name == 'Cash') {
        //     this.setState({ selected_bank: selectedBank, paymentMethod: 'Cash' })
        //   }
        // },
      )
    } else {
      this.setState({
        fullyPaid: e,
        amountReceived: this.state.amountReceived,
      })
    }
  }

  onChangePaymentMethod = (e: string, id: any) => {
    if (e == 'Cash') {
      const selectedBank = this.state.bank_details.find(({ bank_name }) => bank_name == 'Cash')
      this.setState({
        selected_bank: this.state.fullyPaid ? selectedBank : this.state.selected_bank,
        paymentMethod: this.state.fullyPaid ? 'Cash' : this.state.paymentMethod,
      })
      return selectedBank?.id
    } else {
      var selectedBank = this.state.selected_bank
      if (id && id != 0) {
        return id
      }
      // message.error('Please select a bank')
      return selectedBank?.id != 0 ? selectedBank?.id : undefined
    }
  }

  onChangeAmountReceived = (e: any) => {
    this.setState({ amountReceived: e })
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    clearTimeout(this.draftTimeout)
    this.save.cancel()
    this.draftTimeout = null
    this.setState = (state, callback) => {
      return
    }
    // this.stopAutoDrafting()
  }

  disabledDate = (current: Dayjs) => {
    return current && current < dayjs(this.state.document_date, this.dateFormat)
  }
  disabledBackDates = (current: Dayjs) => {
    return (
      this.props?.permissions?.allow_back_dated_invoice == 0 &&
      current &&
      current < dayjs(this.state.document_date, this.dateFormat)
    )
  }

  onSubscriptionDateChange = (date: Dayjs, string: string | string[], type: string) => {
    if (type == 'start') {
      this.setState({ subscription_start_date: string, subscription_end_date: string })
    } else if (type == 'end') {
      this.setState({ subscription_end_date: string })
    }
  }

  renderTitle = (title: string) => {
    return (
      <Button
        block
        type="action-o"
        className="font-weight-bolder"
        onClick={() =>
          this.state.party_type == 'customer'
            ? this.customer.onAdd(this.state.customerName)
            : this.vendor.onAdd(this.state.customerName)
        }
      >
        <i className="fa fa-plus-circle mr-2" />
        {title}
      </Button>
    )
  }

  onCustomerSelect = async (customer: any, option: Party) => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
    if (data && data.success) {
      if (
        data.customer_details[0]?.gstin &&
        (this.state.export_type == 'Export under bond/LUT' ||
          this.state.export_type == 'Export with IGST')
      ) {
        message.error(`${this.state.export_type} is not allowed with GSTIN Customer`)
        return
      }
      if (data.customer_details[0].shipping_address.length) {
        this.setState(
          {
            customer_details: data.customer_details[0],
            selected_customer_shipping_address: data.customer_details[0]['shipping_address'][0],
          },
          this.handleCountryOfSupplyChange,
        )
      } else {
        this.setState({
          customer_details: data.customer_details[0],
          selected_customer_shipping_address: undefined,
        })
      }
      if (data.customer_details[0].export_customer == 1) {
        this.setState(
          prevState => ({
            export_invoice: store.get('is_export') == 1 ? 1 : prevState.export_invoice,
            with_tax: !(
              prevState.export_type?.includes('LUT') || prevState.export_type?.includes('without')
            )
              ? 1
              : 0,
          }),
          () => this.onWithTaxChange(),
        )
      }
      if (data.customer_details[0].is_sez == 1) {
        var export_section = 'SEZ without IGST Payment'
        if (this.state.with_tax == 1) {
          export_section = 'SEZ with IGST Payment'
        }
        this.setState(prevState => ({
          export_invoice: store.get('is_export') == 1 ? 1 : prevState.export_invoice,
          export_type: export_section,
        }))
      }
      if (this.state.party_type == 'customer' && data.customer_details[0]['price_list_id'] != -1) {
        // this.onPriceListChange(data.customer_details[0]['price_list_id'])
        this.setState({ selectedPriceList: data.customer_details[0]['price_list_id'] }, () => {
          this.table?.refreshAutocompleteProducts()
        })
      }
      if (
        data.customer_details[0].is_tds == 1 &&
        data.customer_details[0].tds_section_id != 0 &&
        (this.state.document_type == 'invoice' ||
          this.state.document_type == 'sales_return' ||
          this.state.document_type == 'purchase' ||
          this.state.document_type == 'purchase_return')
      ) {
        this.setState({ is_tds: 1 })
      }
      if (data.customer_details[0].tds_section_id != 0) {
        this.setState({ tds_selected_section: data.customer_details[0].tds_section_id })
      }
      if (
        data.customer_details[0].is_tcs == 1 &&
        data.customer_details[0].tcs_section_id != -1 &&
        (this.state.document_type == 'invoice' ||
          this.state.document_type == 'sales_return' ||
          this.state.document_type == 'purchase' ||
          this.state.document_type == 'purchase_return' ||
          this.state.document_type == 'subscription')
      ) {
        this.setState({ is_tcs: 1 })
      }
      if (data.customer_details[0].tcs_section_id != -1) {
        this.setState({ tcs_selected_section: data.customer_details[0].tcs_section_id })
      }
    }

    const index = this.state.customerData.findIndex(item => item.id === option.id)
    if (index == -1) {
      option = { ...data?.customer_details?.[0], ...option }
      this.setState(
        {
          selectedCustomer: option.id,
          customerName: '',
          customerData: this.state.edit ? [option] : [...this.state.customerData, option],
          autocompleteData: [],
        },
        () => {
          let customerDiscount = 0
          if (this.state.customerData.length == 1) {
            customerDiscount = this.state.customerData[0].discount
          }
          this.setState({ customerDiscount: customerDiscount })
          this.onCouponsApply(false)
        },
      )
    } else {
      message.warning('Customer already added.')
      this.setState({
        autocompleteData: [],
      })
    }
    this.customerSearchAPI('')
  }

  onVendorSelect = async (data: any, option: Party) => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
    // console.log(data);
    if (data && 'success' in data) {
      if (data?.success) {
        this.setState({ vendor_details: data.vendor_details[0] })
        option = { ...data.vendor_details[0], ...option }
        if (
          data.vendor_details[0].is_tds == 1 &&
          data.vendor_details[0].tds_section_id != 0 &&
          (this.state.document_type == 'invoice' ||
            this.state.document_type == 'sales_return' ||
            this.state.document_type == 'purchase' ||
            this.state.document_type == 'purchase_return')
        ) {
          this.setState({ is_tds: 1 })
        }
        if (data.vendor_details[0].tds_section_id != 0) {
          this.setState({ tds_selected_section: data.vendor_details[0].tds_section_id })
        }
        if (
          data.vendor_details[0].is_tcs == 1 &&
          data.vendor_details[0].tcs_section_id != -1 &&
          (this.state.document_type == 'invoice' ||
            this.state.document_type == 'sales_return' ||
            this.state.document_type == 'purchase' ||
            this.state.document_type == 'purchase_return')
        ) {
          this.setState({ is_tcs: 1 })
        }
        if (data.vendor_details[0].tcs_section_id != -1) {
          this.setState({ tcs_selected_section: data.vendor_details[0].tcs_section_id })
        }
        if (data.vendor_details[0]['price_list_id'] != -1) {
          this.setState({ selectedPriceList: data.vendor_details[0]['price_list_id'] }, () => {
            this.table?.refreshAutocompleteProducts()
          })
        }
      }
    }

    const index = this.state.customerData.findIndex(item => item.id === option.id)
    if (index == -1) {
      this.setState(
        {
          selectedCustomer: option.id,
          customerName: '',
          customerData:
            this.state.document_type == 'purchase' || this.state.document_type == 'purchase_return'
              ? [option]
              : [...this.state.customerData, option],
          autocompleteData: [],
        },
        () => {
          let customerDiscount = 0
          if (this.state.customerData.length == 1) {
            customerDiscount = this.state.customerData[0].discount
          }
          this.setState({ customerDiscount: customerDiscount })
        },
      )
    } else {
      message.warning('Vendor already added.')
      this.setState({
        autocompleteData: [],
      })
    }
    this.customerSearchAPI('')
  }

  updateCustomer = async (option: Party) => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/customer', 'get_details', req)
    if (data && data.success) {
      let selected_customer_shipping_address = data.customer_details?.[0]?.shipping_address.length
        ? data.customer_details[0]['shipping_address'][0]
        : {}

      this.setState({
        customer_details: data.customer_details[0],
        selected_customer_shipping_address: selected_customer_shipping_address,
        customerDiscount: data.customer_details[0]?.discount,
      })
    }
  }

  getStatusClass = (data: number) => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  customerSearchAPI = async (searchText: string, isSearch: boolean = true) => {
    const { page, num_records } = this.state

    const req = {
      query: searchText,
      page: this.state.page,
      num_records: num_records,
    }

    const data = await getAPI(this.state.party_type, 'search', req)
    if (data) {
      var parties = this.state.party_type == 'customer' ? data.customers : data.vendors
      const allData = parties.map((value: Party) => {
        return {
          value: (
            <span>
              <span>{value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}</span>
              {this.props.permissions.ledgers == 1 && (
                <span
                  className="font-size-12 float-right font-weight-bold"
                  style={{ color: this.getStatusClass(value.balance + value.opening_balance) }}
                >
                  <span className="font-size-12 mr-1">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                    'en-IN',
                    dotOptions,
                  )}
                </span>
              )}
              <p className="font-size-12 mb-0">
                <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                <span className="float-right text-gray-500">
                  {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                </span>
              </p>
            </span>
          ),
          id: this.state.party_type == 'customer' ? value.customer_id : value.vendor_id,
          key: this.state.party_type == 'customer' ? value.customer_id : value.vendor_id,
          notes: value.name,
          discount: value.discount,
        }
      })

      if (isSearch) {
        this.setState({
          autocompleteData: [...allData],
          page: 0,
        })
      } else {
        this.setState({
          autocompleteData: [...this.state.autocompleteData, ...allData],
        })
      }
    }
  }

  onCustomerSearch = async (searchText: string) => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ selectedCustomer: -1, customerName: searchText })
    this.timeout = setTimeout(() => {
      this.customerSearchAPI(searchText)
    }, 700)
  }

  allowBatches = (document_type: string) => {
    return document_type == 'estimate' ||
      document_type == 'purchase_order' ||
      document_type == 'pro_forma_invoice'
      ? false
      : true
  }

  allowWarehouses = () => {
    let document_types: { [key: string]: boolean } = {
      invoice: true,
      sales_return: true,
      purchase: true,
      purchase_return: true,
      subscription: true,
      delivery_challan: this.props.document.invoiceSettings.track_stock_for_delivery_challan == 1,
      pro_forma_invoice: false,
      purchase_order: false,
      estimate: false,
    }

    return document_types[this.state.document_type]
  }
  onPurchaseUpdateConfirm = (selectedProducts: Item[]) => {
    let dataSource = [...this.state.dataSource]
    dataSource = dataSource.map(item => {
      item.is_update_purchase_columns = selectedProducts.includes(item.id)
      return item
    })
    this.setState({ dataSource, product_update_warning_shown: true }, () => {
      this.save(this.state.redirect)
    })
  }

  validateDeductions = (items: Item[]) => {
    const deductionsTaxWise: { [key: number]: number } = {}
    this.state.document_custom_additional_charges.forEach((charge: AdditonalCharge) => {
      if (charge.type == -1 && charge.total_amount > 0 && charge.tax != 0) {
        deductionsTaxWise[charge.tax] = (deductionsTaxWise[charge.tax] || 0) + charge.total_amount
      }
    })

    if (Object.keys(deductionsTaxWise).length == 0) {
      return true
    }

    const itemTaxWise: { [key: number]: number } = {}
    items.forEach(item => {
      // Change from Math.max to summing the total amounts
      itemTaxWise[item.tax] = (itemTaxWise[item.tax] || 0) + item.total_amount
    })

    const allTaxRatesPresent = Object.keys(deductionsTaxWise).every(tax => tax in itemTaxWise)

    if (!allTaxRatesPresent) {
      message.error('Tax rate of the deduction is not present in the items')
      return false
    }

    const anyDeductionAmount = Object.keys(deductionsTaxWise).some(
      (tax: any) => deductionsTaxWise[tax] > itemTaxWise[tax],
    )
    if (anyDeductionAmount) {
      notification.open({
        message: 'Error',
        description:
          'Deduction amount must be less than or equal to the total amount of items with the same tax rate',
        duration: 10,
        icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
        style: {
          borderRadius: '10px',
          backgroundColor: '#ffffff',
        },
      })
      return false
    }
    return true
  }

  scrollToElement = (id: any) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  validateCustomHeaders = () => {
    let has_custom_header_error = false
    const verified_custom_headers = this.state.custom_headers.map(header => {
      header.is_error = false
      if (header.is_required && (header.value == '' || header.value == undefined)) {
        header.is_error = true
        has_custom_header_error = true
      }
      return header
    })
    if (has_custom_header_error) {
      this.setState({ custom_headers: verified_custom_headers }, () => {
        this.scrollToElement('document_custom_headers')
      })
      return false
    }
    return true
  }
  docValidations = (is_draft: boolean, redirect: string, save_draft: boolean) => {
    if (!is_draft) {
      if (
        this.props?.permissions?.allow_back_dated_invoice == 0 &&
        this.state.document_date &&
        dayjs(this.state.document_date, this.dateFormat).isBefore(dayjs(), 'day')
      ) {
        return [false, "You don't have the permission to create back dated bill"]
      }
      if (this.state.export_invoice && this.state.conversion_factor == undefined) {
        return [false, 'Conversion Factor cannot be empty']
      }

      if (this.state.export_invoice && !this.state.export_type) {
        return [false, 'Please select Export Type']
      }

      if (this.state.doc_number == undefined || this.state.doc_number == null) {
        return [false, this.state.document_title + ' Number cannot be empty']
      }
      if (this.state.customerData.length == 0 && !this.state.is_pos) {
        if (this.state.customerName != '') {
          document.body.scrollIntoView({ behavior: 'smooth' })
          if (this.state.party_type == 'customer') {
            this.customer.onAdd(this.state.customerName)
          } else {
            this.vendor.onAdd(this.state.customerName)
          }
        } else {
          document.body.scrollIntoView({ behavior: 'smooth' })
          if (this.inputEl.current) {
            ;(this.inputEl.current as HTMLInputElement).focus()
          }
        }
        return [false, 'Please Add ' + this.state.party_type]
      }

      if (
        this.props.invoice_settings?.supplier_invoice_serial_number &&
        this.state.document_type == 'purchase'
      ) {
        if (!this.state.supplier_invoice_serial_number) {
          return [false, 'Please add Supplier Invoice Serial No.']
        }
      }

      if (this.state.is_tds == 1 && this.state.tds_selected_section == undefined) {
        return [false, 'Please select TDS section']
      }
      if (this.state.is_tcs && this.state.tcs_selected_section == undefined) {
        return [false, 'Please select TCS section']
      }
      if (
        this.state.is_tds == 1 &&
        (this.state.tds_amount == undefined || this.state.tds_amount == 0)
      ) {
        return [false, 'TDS amount cannot be empty']
      }
      if (this.state.is_tcs && (this.state.tcs_amount == undefined || this.state.tcs_amount == 0)) {
        return [false, 'TCS amount cannot be empty']
      }
      if (this.state.repeat_every == undefined && this.state.is_subscription) {
        return [false, 'Repeat Unit cannot be empty']
      }
      if (
        this.state.export_invoice &&
        this.state.conversion_factor &&
        isNaN(this.state.conversion_factor)
      ) {
        return [false, 'Conversion Factor cannot be empty']
      }
      if (
        this.state.amountReceived > 0 &&
        this.state.paymentMethod != 'Cash' &&
        this.state.selected_bank?.id == 0
      ) {
        return [false, 'Please select a bank account']
      }
      if (this.state.amountReceived < 0) {
        return [false, 'Amount Received cannot be negative']
      }
      if (
        this.props?.warehouses?.length > 0 &&
        this.state.warehouse_id == undefined &&
        this.allowWarehouses() &&
        this.state.document_type != 'online_order'
      ) {
        return [false, 'Please select a warehouse']
      }

      if (!this.validateCustomHeaders() && this.state.document_type != 'online_order') {
        return [false, '']
      }
      let items = [...this.state.dataSource]
      if (items.length == 0) {
        this.table?.focusProductSearch()
        return [false, 'Please add at least one product to proceed']
      }

      if (this.allowBatches(this.state.document_type)) {
        let filterdItems = items.filter(
          item =>
            (item.batch_id == 0 && item.has_batches == 1) ||
            (item.has_batches == 2 && item?.serial_numbers?.length == 0),
        )
        if (filterdItems.length > 0) {
          this.table.openSelectBatches()
          return [false, '']
        }
      }

      for (var i = 0; i < items.length; i++) {
        if (items[i].price < 0 && !is_draft) {
          return [false, 'Negative values are not allowed for Unit Price.']
        }
      }
    }
    if (
      !this.state.product_update_warning_shown &&
      this.props.document_settings.purchase_update_columns.length > 0 &&
      this.state.document_type == 'purchase' &&
      !save_draft &&
      !is_draft
    ) {
      // this.showPurchaseConfirmationModal(redirect)
      this.setState({ redirect: redirect }, () => {
        this.purchaseConfirmRef.current.showModal()
      })
      return [false, '']
    }

    return [true, '']
  }

  // Creates array of those items that are insufficient in stock
  // from all the items in the invoice
  mergeInsufficientItemsArray = (allItems: Item[], insufficientItems: Item[]) => {
    return allItems
      .filter(item1 =>
        insufficientItems.some(
          item2 => item1.product_id === item2.product_id && item1.variant_id === item2.variant_id,
        ),
      )
      .map(item1 => {
        const matchingItem = insufficientItems.find(
          item2 => item1.product_id === item2.product_id && item1.variant_id === item2.variant_id,
        )
        return {
          ...item1,
          available_qty: matchingItem?.available_qty,
          warehouse_id: matchingItem?.warehouse_id,
        }
      })
  }

  deleteInsufficientItem = ({ key }: any) => {
    this.table.onDelete(key)

    // To make sure the below functions run after
    // this.table.onDelete has completed its execution
    setTimeout(() => {
      const newModalItems = this.mergeInsufficientItemsArray(
        this.state.dataSource,
        this.state.insufficientStockModal.items,
      )
      this.updateInsufficientModalItems({ items: newModalItems })
    }, 0)
  }

  _save = async (
    redirect: string,
    is_draft: boolean = false,
    save_draft: boolean = false,
    save_duplicate: boolean = false,
  ) => {
    let limit_exceeded_customers: any[] = []

    if (!is_draft) {
      clearTimeout(this.draftTimeout)
    }

    const [isValid, msg] = this.docValidations(is_draft, redirect, save_draft)
    if (!isValid) {
      if (msg != '') {
        message.error(msg)
      }
      return
    }
    if (!this.state.edit && !is_draft && !this.validateAmount()) {
      return
    }
    if (!is_draft && !this.checkAllValidations()) {
      return
    }

    let items = [...this.state.dataSource]
    items = items.map(item => {
      if (this.state.document_type == 'purchase' && !is_draft) {
        item['purchase_update_columns'] = {}
        if (
          this.props.document_settings?.purchase_update_columns
            .split(',')
            .includes('price_with_tax')
        ) {
          item.purchase_update_columns['price_with_tax'] = item['selling_price']
        }
        if (this.props.document_settings?.purchase_update_columns.split(',').includes('discount')) {
          item.purchase_update_columns['discount'] = item['product_discount']
        }
        if (
          this.props.document_settings?.purchase_update_columns
            .split(',')
            .includes('purchase_price')
        ) {
          item.purchase_update_columns['purchase_price'] =
            item['cess_on_qty_value'] > 0
              ? item.price_with_tax - item.cess_on_qty
              : item.price_with_tax

          item.purchase_update_columns['purchase_unit_price'] = item.unit_price
        }
        if (
          this.props.document_settings?.purchase_update_columns
            .split(',')
            .includes('custom_columns')
        ) {
          item.purchase_update_columns['custom_columns'] = {}
          this.props.custom_columns
            .filter((obj: any) => obj.is_active)
            .map((product_custom_column: any) => {
              item.purchase_update_columns.custom_columns[product_custom_column.name] =
                item[product_custom_column.name]
            })
        }
      }
      item.net_amount = Object.keys(item).includes('net_amount')
        ? parseFloat(String(item.net_amount))
        : parseFloat(String(getItemNetAmount(item)))

      return {
        ...item,
        tax_amount: this.state.with_tax == 1 ? (item.net_amount * item.tax) / 100 : 0,
        total_amount:
          this.state.with_tax == 1
            ? parseFloat(String(getRowTotalV2(item)))
            : parseFloat(String(getItemNetAmount(item))),
        price_with_tax: this.state.with_tax == 1 ? item.price_with_tax : item.unit_price,
        cess_amount: this.state.with_tax == 1 ? getCessAmountV2(item) : 0,
      }
    })
    // return

    if (!this.validateDeductions(items) && !is_draft) {
      return
    }

    /* if product has serial numbers and doing purchase */
    let serialNumberItems: Item[] = []
    for (let i = 0; i < items.length; i++) {
      if (items[i].has_batches == 2 && this.allowBatches(this.state.document_type)) {
        for (let j = 0; j < items[i]?.serial_numbers?.length; j++) {
          serialNumberItems.push({
            ...items[i],
            discount_amount: items[i].discount_amount / items[i].qty,
            discount_net_value: items[i].discount_net_value / items[i].qty,
            discount_price_with_tax_value: items[i].discount_price_with_tax_value / items[i].qty,
            discount_unit_price_value: items[i].discount_unit_price_value / items[i].qty,
            discount_value: items[i].discount_value / items[i].qty,
            net_amount: items[i].net_amount / items[i].qty,
            total_amount: items[i].total_amount / items[i].qty,
            tax_amount: items[i].tax_amount / items[i].qty,
            batch_id: items[i].serial_numbers[j].batch_id,
            qty: 1,
            batch_no: items[i].serial_numbers[j].batch_no,
          })
        }
        items.splice(i, 1)
        i--
      }
    }
    items = [...items, ...serialNumberItems]

    if (serialNumberItems.length > 0) {
      //sort using keys to sustain the order of items
      items.sort((a, b) => a.key - b.key)
    }

    if (
      !this.state.credit_limit_warning_shown &&
      // this.state.party_type == 'customer' &&
      (this.state.document_type == 'invoice' || this.state.document_type == 'purchase') &&
      !save_draft
    ) {
      this.state.customerData.map(customer => {
        let customer_condition =
          customer.credit_limit != 0 &&
          customer.balance +
            this.state.prev_total_amount +
            this.state.amountReceived +
            customer.credit_limit -
            this.getTotalAmount() <
            0
        let vendor_condition =
          customer.credit_limit != 0 &&
          customer.balance -
            this.state.prev_total_amount -
            customer.credit_limit +
            this.getTotalAmount() >
            0

        if (this.state.party_type == 'customer' ? customer_condition : vendor_condition) {
          limit_exceeded_customers.push(<span className="font-weight-bold"> {customer.name} </span>)
          limit_exceeded_customers.push(', ')
        }
      })
      if (limit_exceeded_customers.length > 0) {
        limit_exceeded_customers.pop()
      }
    }

    if (limit_exceeded_customers.length > 0 && !is_draft) {
      Modal.confirm({
        title: this.state.party_type == 'customer' ? 'Credit' : 'Debit' + ' Limit Exceeded',
        content: (
          <>
            <p>Are you sure you want to proceed?</p>
            <p>
              Limit exceeded for:
              {limit_exceeded_customers.map((customer, index) => {
                return customer
              })}
            </p>
          </>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirm',
        onOk: () => {
          this.setState(
            {
              credit_limit_warning_shown: true,
            },
            () => this.save(redirect),
          )
        },
        onCancel: () => {},
      })
      return
    }

    if (!save_draft) {
      // don't load save buttons if its auto drafting
      this.setState({ loadingSave: true })
    }
    let with_tax = this.state.with_tax
    if (
      this.state.export_invoice &&
      (this.state.export_type == 'Export with IGST' ||
        this.state.export_type == 'SEZ with IGST Payment' ||
        this.state.export_type == 'Deemed Export')
    ) {
      with_tax = 1
    }
    if (
      this.state.export_invoice &&
      (this.state.export_type == 'Export under bond/LUT' ||
        this.state.export_type == 'SEZ without IGST Payment')
    ) {
      with_tax = 0
    }
    if (save_draft || is_draft) {
      if (
        this.state.is_tds &&
        (this.state.tds_selected_section == undefined ||
          this.state.tds_amount == undefined ||
          this.state.tds_amount == 0)
      ) {
        this.setState({ is_tds: 0 })
      }
      if (
        this.state.is_tcs &&
        (this.state.tcs_selected_section == undefined ||
          this.state.tcs_amount == undefined ||
          this.state.tcs_amount == 0)
      ) {
        this.setState({ is_tcs: 0 })
      }
    }
    const suffix = this.state.suffix ? String(this.state.suffix) : ''

    const final_data = mergeItemsAndItemHeaders(items, this.state.item_headers)
    const document_item_headers_formatted = final_data
      .filter((item: any) => item.is_item_header === true)
      .map((item: any) => {
        return {
          header_value: item.product_name,
          // Determine the position based on how many items are there before this header
          position:
            final_data.filter((i: any) => i.is_item_header === undefined && i.key < item.key)
              .length + 1,
        }
      })

    // make them in incremental order and make unused keys undefined
    const items_keys_ordered = items.map((item, index) => {
      return {
        ...item,
        editorState: undefined,
        value: undefined,
        serial_numbers: [],
        batches: [],
        units: [],
        // master_data: {},
        key: index + 1,
      }
    })

    let paymentsData = this.state.paymentsList
    if (paymentsData.length == 1) {
      const data = this.state.paymentsList[0]
      const amount = data.amount || 0
      if (amount == 0) {
        const newData = {
          ...emptyPaymentDetails,
          bank_id: -1,
          payment_method: 'Cash',
        }
        paymentsData = [newData]
      }
    }

    const req = {
      customer_id: this.state.customerData.map(party => {
        return party.id
      }),

      notes: this.state.notes,

      bank_id:
        this.state.document_type == 'purchase_return' ||
        this.state.document_type == 'purchase_order'
          ? 0
          : this.state.selected_bank?.id ?? 0,
      due_date: this.state.due_date,
      document_date: this.state.document_date,
      supplier_invoice_date:
        this.state.document_type == 'purchase'
          ? this.state.supplier_invoice_date
          : dayjs().format(this.dateFormat),
      supplier_invoice_serial_number:
        this.state.document_type == 'purchase' ? this.state.supplier_invoice_serial_number : '',
      reference:
        this.state.from_document_type == 'online_order'
          ? this.state.order_serial_number
          : this.state.reference,
      order_serial_number:
        this.state.from_document_type == 'online_order' ? this.state.order_serial_number : '',
      rzp_order_id: this.state.from_document_type == 'online_order' ? this.state.rzp_order_id : '',
      rzp_payment_id:
        this.state.from_document_type == 'online_order' ? this.state.rzp_payment_id : '',
      has_extra_charges: this.state.has_extra_charges,
      show_description: this.state.description,
      serial_number:
        save_duplicate && this.state.new_serial_number != ''
          ? this.state.new_serial_number
          : String(this.state.prefix) + String(this.state.doc_number) + suffix,
      suffix: suffix,
      terms: this.state.terms,
      exclusive_notes: this.state.exclusive_notes,
      tax_amount:
        with_tax == 1
          ? parseFloat(this.getTotalTax(items_keys_ordered).toFixed(2)) +
            this.extraChargesAmount('tax_amount')
          : this.extraChargesAmount('tax_amount'),
      net_amount: parseFloat(String(getTotalNetAmountV2(items_keys_ordered))),
      total_discount:
        Number(this.state.extraDiscount) +
        getTotalDiscountV2(items_keys_ordered, this.state.document_discount_type),
      extra_discount: Number(this.state.extraDiscount),
      total_amount: parseFloat(
        (
          this.getTotal(items_keys_ordered) +
          Number(this.state.tcs_amount) +
          this.extraChargesAmount('total_amount') -
          Number(this.state.extraDiscount) -
          Number(this.state.roundoff_value) -
          Number(this.state.rcm_value)
        ).toFixed(2),
      ),
      items: items_keys_ordered,
      document_item_headers: document_item_headers_formatted,
      signature: this.state.selectedSignature?.image,
      id:
        is_draft && this.state.auto_draft.id != 0
          ? this.state.auto_draft.id
          : // : this.state.edit && save_draft
            // ? 0
            this.state.id,
      attachments: this.state.attachments,
      payment_method: this.state.paymentMethod,
      amount_received: parseFloat(String(this.state.amountReceived)),
      paymentNotes: this.state.paymentNotes,
      payment_notes: this.state.paymentNotes,
      customer_shipping_addr_id: this.state.selected_customer_shipping_address
        ? 'addr_id' in this.state.selected_customer_shipping_address
          ? this.state.selected_customer_shipping_address.addr_id
          : -1
        : -1,
      company_shipping_addr_id: this.state.selected_company_shipping_address
        ? 'addr_id' in this.state.selected_company_shipping_address
          ? this.state.selected_company_shipping_address.addr_id
          : -1
        : -1,
      custom_headers: this.state.custom_headers,
      roundoff: this.state.roundoff,
      roundoff_value: this.state.roundoff_value,
      document_type:
        (is_draft && this.state.convert && this.state.from_document_type == 'draft') ||
        (is_draft && this.state.auto_draft.id != 0)
          ? //  || (save_draft && this.state.edit)
            'draft'
          : this.state.document_type,
      doc_number:
        save_duplicate && this.state.new_doc_number != 0
          ? this.state.new_doc_number
          : this.state.doc_number,
      rcm: this.state.rcm,
      with_tax: with_tax,
      hide_totals: this.state.hide_totals,
      document_title: this.state.document_title,
      document_custom_additional_charges: this.state.document_custom_additional_charges,
      is_export: this.state.export_invoice ? 1 : 0,
      is_multi_currency: this.state.is_multi_currency ? 1 : 0,
      export_invoice_details: {
        shipping_bill_date: this.state.shipping_bill_date,
        shipping_bill_number: this.state.shipping_bill_number,
        shipping_port_code: this.state.shipping_port_code,
        export_type: this.state.is_multi_currency ? 'Multi Currency' : this.state.export_type,
        conversion_factor: parseFloat(String(this.state.conversion_factor)),
        country_id: this.state.country,
        currency_id: this.props?.countries?.filter(
          (item: any) => item.currency_code == this.state.currency,
        )[0]?.country_id,
      },
      is_tds: this.state.is_tds,

      tds_details: {
        tds_amount: this.state.tds_amount,
        ...this.props.tds_sections.filter(
          (item: any) => item.tds_id == this.state.tds_selected_section,
        )[0],
        apply_on: this.state.tds_applicable_on,
      },
      is_tcs: this.state.is_tcs,
      tcs_details: {
        tcs_amount: this.state.tcs_amount,
        ...this.props.tcs_sections.filter(
          (item: any) => item.tds_id == this.state.tcs_selected_section,
        )[0],
        apply_on: this.state.tcs_applicable_on,
      },
      cess_amount:
        this.state.with_tax == 1 ? parseFloat(String(getTotalCessAmountV2(items_keys_ordered))) : 0,
      cess_on_qty_value:
        this.state.with_tax == 1 ? parseFloat(String(getCessOnQtyValue(items_keys_ordered))) : 0,
      convert: {
        convert_from:
          window.location.pathname.includes('create') && !is_draft && this.state.auto_draft.id != 0
            ? 'draft'
            : this.state.from_document_type,
        doc_count:
          window.location.pathname.includes('create') && !is_draft && this.state.auto_draft.id != 0
            ? this.state.auto_draft.doc_count
            : this.state.doc_count,
      },
      is_subscription: this.state.is_subscription,
      subscription: {
        start_time: this.state.subscription_start_date,
        end_time: this.state.subscription_end_date,
        // serial_number: this.state.selected_serial_number,
        sub_serial_number: this.state.sub_serial_number,
        document_type: this.state.document_type,
        subscription_document_type: this.state.subscription_document_type,
        // doc_count: this.state.doc_count,
        repeat: Math.abs(parseInt(String(this.state.repeat_every))),
        repeat_type: this.state.repeatType.toLowerCase(),
        send_email: this.state.send_email,
        send_sms: this.state.send_sms,
      },
      payment_status: this.state.payment_status,
      coupon_details: this.state.coupon_details,
      source: 0, // source is used to identify the invoice is created from web or mobile
      is_draft,
      skip_warning: this.state.warning.skip_warning,
      discount_type: this.state.document_discount_type,
      immovable_tax_type: this.state.immovable_tax_type,
      warehouse_id: this.state.warehouse_id,
      payments_list: !this.state.edit ? paymentsData : undefined,
    }

    var data: {
      doc_type: string
      success: boolean
      message: string
      warning: boolean
      new_serial_number: string
      new_doc_number: number
      error_code: string
      insufficient_items: Item[]
      id: number
      exceeded: boolean
      doc_count: number
      new_hash_id: string
    } = {
      success: false,
      message: '',
      warning: false,
      new_serial_number: '',
      new_doc_number: 0,
      error_code: '',
      insufficient_items: [],
      id: 0,
      exceeded: false,
      doc_count: 0,
      doc_type: '',
      new_hash_id: '',
    }
    if (save_draft) {
      // if its auto draft then display message
      this.setState({ showDraftMessage: true })
    }

    if (
      this.state.edit ||
      (this.state.convert && this.state.from_document_type == 'draft' && is_draft) ||
      (is_draft && this.state.auto_draft.id != 0)
    ) {
      data = await getAPI('v2/doc', 'edit', req)
      clearTimeout(this.draftTimeout)
      this.draftTimeout = null

      if (!data) {
        this.setState({ loadingSave: false, loadingPrint: false })
        return
      }

      if (!data?.success) {
        message.error(data?.message ?? 'something went wrong!')
        return
      }

      if (data && data.warning && data.error_code === INSUFFICIENT_STOCK && this.state.edit) {
        this.setState({
          insufficientStockModal: {
            isOpen: true,
            items: this.mergeInsufficientItemsArray(this.state.dataSource, data.insufficient_items),
            message: data.message,
            errorCode: data.error_code,
          },
          loadingSave: false,
          loadingPrint: false,
        })

        return
      }

      if (data.warning && this.state.edit) {
        this.setState({
          warning: {
            ...this.state.warning,
            message: data.message,
            show_warning: true,
          },
          new_serial_number: data.new_serial_number,
          new_doc_number: data.new_doc_number,
          loadingSave: false,
          loadingPrint: false,
        })
        return
      }

      if (window.location.pathname.includes('create')) {
        if (!save_draft) {
          if (is_draft) {
            // user clicked save as draft button
            message.success('Saved as draft successfully')
          }
          this.setState(
            {
              isCreated: true,
            },
            async () => {
              await this.props.dispatch(
                update_invoice_data({
                  key: this.state.activeKey,
                  data: { ...this.state, showDraftMessage: false },
                }),
              )
            },
          )
        }
      } else {
        message.success(data.message)
      }
    } else {
      data = await getAPI('v2/doc', 'create', req)
      clearTimeout(this.draftTimeout)
      this.draftTimeout = null

      if (!data) {
        this.setState({ loadingSave: false, loadingPrint: false })

        return
      }

      if (!data?.success) {
        message.error(data?.message ?? 'something went wrong!')
        this.setState({ loadingSave: false, loadingPrint: false })
        return
      }

      if (data && data.warning && data.error_code === INSUFFICIENT_STOCK) {
        this.setState({
          insufficientStockModal: {
            isOpen: true,
            items: this.mergeInsufficientItemsArray(this.state.dataSource, data.insufficient_items),
            message: data.message,
            errorCode: data.error_code,
          },
          loadingSave: false,
        })

        return
      }

      if (data.warning) {
        this.setState({
          warning: {
            ...this.state.warning,
            message: data.message,
            show_warning: true,
          },
          new_serial_number: data.new_serial_number,
          new_doc_number: data.new_doc_number,
        })
        return
      }

      if (!save_draft) {
        if (is_draft) {
          // user clicked save as draft button
          message.success('Saved as draft successfully')
        } else {
          // user clicked save button
          message.success(data.message)
        }
        this.setState(
          {
            isCreated: true,
          },
          async () => {
            await this.props.dispatch(
              update_invoice_data({
                key: this.state.activeKey,
                data: { ...this.state, showDraftMessage: false },
              }),
            )
          },
        )
      } else {
        this.setState(
          {
            auto_draft: {
              id: data.id,
              doc_count: data.doc_count,
            },
          },
          async () => {
            await this.props.dispatch(
              update_invoice_data({
                key: this.state.activeKey,
                data: {
                  ...this.state,
                  showDraftMessage: false,
                },
              }),
            )
          },
        )
      }
    }

    if (save_draft) {
      // hide saving as draft message after timeout
      setTimeout(() => {
        this.setState({ showDraftMessage: false })
      }, 1000)
    }

    this.setState({ loadingPrint: false, loadingSave: false })

    if (this.state.edit && data) {
      if (data.exceeded) {
        notification.open({
          message: <span className="text-danger">Error</span>,
          description: data.message,
          icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffe6e6',
          },
          duration: 10,
        })
        return
      }
    }

    if (data.success && !save_draft) {
      // publish hit rate
      this.getItemSuggestionsRef?.current?.publishHitRate(
        this.state.document_type,
        data.doc_count,
        this.state.dataSource.length,
      )
      if (redirect == '1') {
        if (this.state.is_ewaybill && !this.state.is_create_einvoice) {
          message.success('Invoice created successfully! Fill E-Waybill details')
          this.handleEwayBill(data.new_hash_id, this.state.document_type)
        } else if (this.state.is_create_einvoice && !this.state.is_ewaybill) {
          message.success('Invoice created successfully! Creating E-Invoice Now')
          await this.handleEInvoice(data.new_hash_id, this.state.document_type)
          this.changeRoute()
        } else if (this.state.is_ewaybill && this.state.is_create_einvoice) {
          message.success('Invoice created successfully! Creating E-Invoice Now')
          let create_einvoice = await this.handleEInvoice(
            data.new_hash_id,
            this.state.document_type,
          )
          if (!create_einvoice) {
            message.error({
              content:
                'Error while creating E-Invoice! Please check the details and try again. Redirecting to E-Waybill Creation.',
              duration: 5,
            })
          }
          setTimeout(() => {
            this.handleEwayBill(data.new_hash_id, this.state.document_type)
          }, 5000)
        } else {
          if (!this.props.invoices_created) {
            this.props.dispatch({
              type: 'welcome/COMPANY_DETAILS_ADDED',
            })
            this.changeRoute()
          } else {
            if (is_draft) {
              this.changeRoute('drafts')
              return
            }
            if (
              !save_draft &&
              (!window.location.pathname.includes('create') || this.state.items.length == 1)
            ) {
              this.changeRoute()
            }
          }
        }
      } else {
        history.push('/save_print/' + this.state.document_type + '/' + data.new_hash_id)
      }

      trackEvent({
        eventName: this.state.edit ? 'edit_document' : 'create_document',
        eventAttributes: {
          success: data.success ? 1 : 0,
          doc_type: this.state.document_type,
          message: data.message,
          doc_count: this.state.edit ? this.state.doc_count : data.doc_count,
          ...(this.state.edit ? { exceeded: data.exceeded ? 1 : 0 } : {}), // only for edit
        },
      })
    }
  }
  changeRoute = (tab = '') => {
    let route = '/' + this.state.list_name
    if (this.state.document_type != 'online_order') {
      route = '/list' + route
    }
    if (tab != '') {
      route = route + '?tab=' + tab
    }
    history.push(route)
  }

  onWarehouseChange = (warehouse_id: number) => {
    this.setState({ warehouse_id }, () => {
      this.onPriceListChange(this.state.selectedPriceList, 'warehouse_change')
    })
  }

  beforeUpload = (file: any) => {
    const isLt3M = file.size / 1024 / 1024 < 3
    if (!isLt3M) {
      message.error('File must smaller than 3MB!')
    }
    return isLt3M
  }

  customRequest = async ({ onSuccess, onError, file }: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('utils', 'attach_files', form)
    if (data?.success) {
      this.setState({ attachments: [...this.state.attachments.slice(-2), ...data.attachments] })
      message.success(data.message)
      onSuccess(null, file)
    } else {
      message.error(data?.message ?? 'something went wrong!')
      onError()
    }
  }

  handleChange = (file: any) => {
    if (file.status == 'removed') {
      const newFiles = this.state.attachments.filter(file1 => file1.uid !== file.uid)
      this.setState({ attachments: newFiles })
    }
  }

  onDueDateChange = (date: Dayjs, string: string | string[]) => {
    this.setState({ due_date: string, openDatePicker: false })
  }

  onDocumentDateChange = (date: Dayjs, string: string | string[]) => {
    this.setState({
      document_date: string,
      supplier_invoice_date:
        this.state.document_title == 'Purchase' &&
        dayjs(this.state.document_date, this.dateFormat).isSame(dayjs(), 'day')
          ? string
          : this.state.supplier_invoice_date,
    })
  }

  onSupplierInvoiceDateChange = (date: Dayjs, string: string | string[]) => {
    this.setState({ supplier_invoice_date: string })
  }

  onShippingBillDateChange = (date: Dayjs, string: any) => {
    this.setState({ shipping_bill_date: string })
  }

  onAIContinue = (key: any, value: any) => {
    this.setState({ [key]: value } as Pick<DocState, keyof DocState>)
  }

  onChange = (e: any) => {
    const { name, value } = e.target

    if (name == 'notes' || name == 'terms') {
      const is_note_term_entered = name === 'terms' ? 'isTermsEntered' : 'isNotesEntered'
      this.setState(
        {
          [name]: value,
          [is_note_term_entered]: value.trim() !== '',
          updatedTerms: value,
        } as Pick<DocState, keyof DocState>,
        () => {
          if (!this.state[is_note_term_entered]) {
            this.setState({
              showNotes: name == 'notes',
              showTerms: name == 'terms',
            })
          }
        },
      )
    } else {
      this.setState({ [name]: value } as Pick<DocState, keyof DocState>)
    }
  }

  tagDelete = (e: any, itemTag: any) => {
    e.preventDefault()
    const newData = this.state.customerData.filter(data => data.id != itemTag.id)
    this.setState({
      customerData: [...newData],
      customer_details:
        newData?.length > 0
          ? { ...newData[newData?.length - 1] }
          : { billing_address: [], shipping_address: [] },
      selected_customer_shipping_address: undefined,
    })
  }

  handleEInvoice = async (new_hash_id: string, document_type: string = 'invoice') => {
    const hide = message.loading({
      content: 'Generating EInvoice...',
      duration: 0,
    })
    var data = await getAPIData('user', 'einvoice_verify_nic_creds')
    if (data?.redirect) {
      let einvoice_create = await this.einvoice_nic_creds?.createEInvoiceNew(
        new_hash_id,
        document_type,
      )
      hide()
      return einvoice_create
    } else {
      this.einvoice_nic_creds.showDrawer(true, new_hash_id, document_type)
      hide()
    }
    return true
  }

  handleEwayBill = async (new_hash_id: string, document_type: string = 'invoice') => {
    var data = await getAPIData('user', 'verify_nic_creds')
    if (data?.redirect) {
      this.setState({ showEwayBill: true }, () => {
        this.ewaybill.showModal(document_type, new_hash_id)
        message.success('Enter E-Waybill details')
      })
    } else {
      this.nic_creds?.showDrawer(true, new_hash_id, this.state.document_type)
    }
  }
  onExportChange = () => {
    const dataSource = this.state.dataSource.map(item => {
      item.export_unit_price = item.unit_price / (this.state.conversion_factor || 1)
      item.export_price_with_tax = item.price_with_tax / (this.state.conversion_factor || 1)
      return item
    })
    if (this.state.document_type == 'purchase' || this.state.document_type == 'purchase_order') {
      this.setState({ export_type: 'Import' })
    }
    this.setState({ dataSource, immovable_tax_type: 0 })
  }

  getTdsAmount(products: Item[] = []) {
    let items = products.length > 0 ? products : this.state.dataSource
    let tds_amount = this.state.tds_amount
    if (
      this.state.is_tds &&
      this.state.tds_selected_section != undefined &&
      this.state.payment_status == 'pending'
    ) {
      // console.log(this.props.tds_sections, this.state.tds_selected_section)
      let tds_selected_section_data = this.props.tds_sections.filter(
        (item: any) => item.tds_id == this.state.tds_selected_section,
      )[0]
      tds_amount =
        this.state.tds_applicable_on == 'net_amount'
          ? Number(
              (
                (getTotalNetAmountV2(items) + this.extraChargesAmount('net_amount')) *
                (tds_selected_section_data.tax / 100)
              ).toFixed(2),
            )
          : Number(this.getTotalAmount() * (tds_selected_section_data.tax / 100))
    }

    return tds_amount
  }

  dataUpdate = (data: Item[], just_data_update: boolean = false) => {
    data = data.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      }
    })
    if (just_data_update) {
      this.setState({ dataSource: data })
      return
    }
    var rcm_value = this.state.rcm
      ? parseFloat(this.getTotalTax(data).toFixed(2)) + this.extraChargesAmount('tax_amount')
      : 0
    var extraDiscount = ((Number(this.state.extraDiscountPer) / 100) * this.getTotal(data)).toFixed(
      2,
    )

    /*tcs amount*/
    let tcs_amount = 0
    if (
      this.state.is_tcs &&
      this.state.tcs_selected_section != undefined &&
      this.state.payment_status == 'pending'
    ) {
      tcs_amount = this.onChangeTcsApplicable(this.state.tcs_applicable_on, data, false)
    }

    /* round off  */
    var decimal = this.state.with_tax
      ? this.getTotal(data) +
        Number(tcs_amount) +
        this.extraChargesAmount('total_amount') -
        Number(extraDiscount) -
        Number(rcm_value)
      : getTotalNetAmountV2(data) +
        Number(tcs_amount) +
        this.extraChargesAmount('total_amount') -
        Number(extraDiscount) -
        Number(rcm_value)

    if (this.state.roundoff) {
      var remainder = decimal - parseInt(String(decimal))
      if (remainder >= 0.5) {
        remainder = remainder - 1
      }
    } else {
      var remainder = 0
    }
    decimal = decimal - remainder

    this.setState(
      {
        dataSource: data,
        roundoff_value: remainder,
        amountReceived: this.state.fullyPaid ? decimal : this.state.amountReceived,
        tds_amount: this.getTdsAmount(data),
        extraDiscount: Number(extraDiscount),
        rcm_value: rcm_value,
        tcs_amount: tcs_amount,
        lastDataSourceUpdated: dayjs(),
      },
      () => {
        this.onCouponsApply(true)
        this.onChangeFullyPaid(this.state.fullyPaid)
        if (this.table) {
          this.table.updateAdditionalCharges()
        }
        this.savedataSourceUpdatedTime()
      },
    )
  }

  itemHeadersUpdate = (data: any) => {
    this.setState({ item_headers: data })
  }

  savedataSourceUpdatedTime = async () => {
    if (!window.location.pathname.includes('create') || this.state.is_subscription) return
    const currentTime = dayjs()
    if (
      currentTime.diff(this.state.lastDataSourceUpdated, 'seconds') < 10 ||
      this.state.draftReqPending
    ) {
      clearTimeout(this.draftTimeout)
      this.draftTimeout = setTimeout(this.savedataSourceUpdatedTime, 10000)
    } else {
      if (!this.state.isCreated) {
        // this.setState({showDraftMessage:true})
        this.setState({ draftReqPending: true })
        await this._save('1', true, true)
        this.setState({ draftReqPending: false })

        // setTimeout(()=>{
        //   this.setState({showDraftMessage:false})
        // },2000)
      }
    }
  }
  extraChargesAmount = (name: string) => {
    let result = this.state.document_custom_additional_charges.map(
      a => parseFloat(String(a[name])) * a.type,
    )
    if (result.length) {
      return result.reduce((previous_value, current_value) => previous_value + current_value)
    } else return 0
  }
  onDiscountTypeChange = (e: any) => {
    let data_source = this.state.dataSource
    let selected_discount_value = 'discount_value'
    if (e == 'unit_price') {
      selected_discount_value = 'discount_unit_price_value'
    } else if (e == 'price_with_tax') {
      selected_discount_value = 'discount_price_with_tax_value'
    } else if (e == 'net_amount') {
      selected_discount_value = 'discount_net_value'
    }
    data_source.map(item => {
      if (item.selected_discount_type != 'discount') {
        item.selected_discount_type = selected_discount_value
      }
    })
    this.setState(
      {
        companyDetails: { ...this.state.companyDetails, discount_type: e },
        dataSource: data_source,
        document_discount_type: e,
      },
      () => {
        this.table?.dataUpdate(data_source, this.state.list_name)
      },
    )
  }

  updateExtraCharges = (object: any) => {
    this.setState(
      {
        document_custom_additional_charges: object.document_custom_additional_charges,
        has_extra_charges: object.has_extra_charges,
      },
      () => {
        var rcm_value = this.state.rcm
          ? parseFloat(this.getTotalTax().toFixed(2)) + this.extraChargesAmount('tax_amount')
          : 0
        this.setState({ rcm_value: rcm_value }, () => {
          if (
            this.state.is_tcs &&
            this.state.tcs_selected_section != undefined &&
            this.state.payment_status == 'pending'
          ) {
            this.onChangeTcsApplicable(this.state.tcs_applicable_on)
          }
          if (
            this.state.is_tds &&
            this.state.tds_selected_section != undefined &&
            this.state.payment_status == 'pending'
          ) {
            this.onChangeTdsTcsSection(this.state.tds_selected_section, 1)
          }

          this.onRoundOffChange(this.state.roundoff)
        })
      },
    )
  }

  getTotal = (products: Item[] = []) => {
    var sum = 0
    let items = products.length > 0 ? products : [...this.state.dataSource]
    items.map(item => {
      sum = sum + getRowTotalV2(item)
    })

    return Number(sum.toFixed(2))
  }

  getTotalTax = (products: Item[] = []) => {
    var sum = 0
    let items = products.length > 0 ? products : [...this.state.dataSource]
    items.map(item => {
      sum = sum + getTaxAmountV2(item)
    })
    return sum
  }

  onPriceListChange = async (
    value: any,
    origin: string = 'price_change',
    updated_product_id: number = -1,
  ) => {
    if (value == undefined) {
      value = 0
    }
    this.setState({ selectedPriceList: value == 0 ? null : value }, () => {
      this.table?.refreshAutocompleteProducts()
    })
    // change the prices of already added items
    var req = {
      items: this.state.dataSource,
      origin: origin,
      selected_price_list: value,
      warehouse_id: this.state.warehouse_id,
    }

    let originalDataSource = [...this.state.dataSource]

    if (updated_product_id != -1) {
      req.items = req.items.filter(
        item =>
          item.product_id == updated_product_id &&
          (item.variant_id == 0 || item.variant_id == undefined),
      )
    }

    var data = await getAPI('product', 'search', req)
    let column_names: any = []
    if (this.state.dataSource.length > 0) {
      column_names = this.state.dataSource[0]?.custom_column_names?.split('::::')
    }

    const results = data?.products?.map((row: Item, index: number) => {
      let custom_column_values: any = []
      column_names?.map((col: any) => {
        custom_column_values.push(this.state.dataSource[index][col])
        row[col] = this.state.dataSource[index][col]
      })
      let prev_product_data
      if (updated_product_id != -1) {
        prev_product_data = this.state.dataSource.filter(
          (data: Item) => data.product_id === updated_product_id,
        )[0]
      } else {
        prev_product_data = this.state.dataSource[index]
      }
      custom_column_values = custom_column_values.join('::::')
      return {
        ...row,
        key: index + 1,
        editorState: EditorState.createWithContent(stateFromHTML(prev_product_data.description)),
        description: prev_product_data.description,
        has_batches: prev_product_data.has_batches,
        batches: prev_product_data.batches,
        serial_numbers: prev_product_data.serial_numbers,
        batch_id: prev_product_data.batch_id,
        units: prev_product_data.units,
        unit_id: prev_product_data.unit_id,
        unit: row.unit,
        custom_column_values: custom_column_values,
        custom_column_names: this.state.dataSource[index].custom_column_names,
        qty: this.state.dataSource[index].qty,
        converted_qty: this.state.dataSource[index].converted_qty,
        total_qty: this.state.dataSource[index].total_qty,
        conversion_rate: this.state.dataSource[index].conversion_rate,
        qtyinstock: row.qty,
        selling_price: this.state.dataSource[index].selling_price,
        product_discount: this.state.dataSource[index].product_discount,
        discount: Number(row.discount),
        id: String(row.product_id) + '_' + String(row.variant_id == undefined ? 0 : row.variant_id),
        actual_price_with_tax: row.price_with_tax,
        actual_unit_price: row.unit_price,
        price:
          this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price / (1 + row.tax / 100)
            : row.price,
        unit_price:
          this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price / (1 + row.tax / 100)
            : row.price,

        price_with_tax:
          this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price
            : row.price_with_tax,

        discount_price_with_tax_value: (
          ((this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price
            : row.price_with_tax) *
            row.discount) /
          100
        ).toFixed(2),
        discount_unit_price_value: (
          ((this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price / (1 + row.tax / 100)
            : row.price) *
            row.discount) /
          100
        ).toFixed(2),
        discount_net_value: (
          ((this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? (row.purchase_price * row.qty) / (1 + (row.tax + row.cess) / 100)
            : row.price * row.qty) *
            row.discount) /
          100
        ).toFixed(2),
        discount_value: (
          ((this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price * row.qty
            : row.price_with_tax * row.qty) *
            row.discount) /
          100
        ).toFixed(2),
        selected_discount_type: row.selected_discount_type || 'discount',
        value:
          row.product_name +
          (row.variant_name == '' || row.variant_name == null ? '' : ' ' + row.variant_name),
        master_data: {
          price_with_tax: row.actual_price_with_tax,
          unit_price: row.unit_price,
          discount: row.discount,
          cess: row.cess,
          tax: row.tax,
          purchase_price: row.purchase_price,
          purchase_unit_price: row.purchase_unit_price,
        },
      }
    })

    let updatedDataSource
    if (updated_product_id != -1) {
      updatedDataSource = originalDataSource.map((item, index) => {
        const updatedItem = results?.find((result: Item) => result.product_id === item.product_id)
        return updatedItem
          ? { ...updatedItem, key: index + 1, position: index + 1 }
          : { ...item, key: index + 1, position: index + 1 }
      })
    } else {
      updatedDataSource = results
    }
    this.setState({ dataSource: updatedDataSource }, () => {
      let newData = this.state.dataSource.map((item, index) => {
        return this.table.onCustomChange(
          item.qty,
          item.key,
          'Quantity',
          'qty',
          this.state.dataSource,
        )
      })
      this.setState({ dataSource: newData }, () => {
        this.table?.dataUpdate(newData, this.state.list_name)
      })
    })
  }

  priceListChangeHandler = async (value: any, origin: string = 'price_change') => {
    if (this.state.priceListWarnShown || !this.state.dataSource.length) {
      this.onPriceListChange(value, origin)
      return
    }
    Modal.confirm({
      title: 'Are you sure you want change PriceList ?',
      content: (
        <>
          <p className="font-size-18">
            <span>
              Item prices, discounts will be updated on changing price list
              <br />
            </span>
          </p>
        </>
      ),
      width: '30%',
      icon: <i className="fad fa-info-circle fa-beat mr-3 mt-2 text-danger"></i>,
      okText: 'Confirm',
      onOk: async () => {
        this.setState(
          {
            priceListWarnShown: true,
          },
          () => {
            this.onPriceListChange(value, origin)
          },
        )
      },
      onCancel: () => {},
    })
  }

  onFocus = () => {
    // console.log('focus')
    // message.warning("This will change the prices of all the items added, based on the selected Price List")
  }

  onCustomHeaderChange = async (e: any, header_id: any) => {
    // console.log(e.target.value, header_id)
    var selected_header_index = this.state.custom_headers.findIndex(
      x => x['header_id'] == header_id,
    )
    var new_custom_headers = [...this.state.custom_headers]
    if (selected_header_index != -1) {
      new_custom_headers[selected_header_index]['value'] = e
      if (e != '' && new_custom_headers[selected_header_index].is_error) {
        new_custom_headers[selected_header_index].is_error = false
      }
    }
    this.setState({ custom_headers: [...new_custom_headers] }, () => {
      // console.log(this.state.custom_headers)
    })
  }

  onDocumentTitleChange = (value: string) => {
    if (value == 'Bill of Supply') {
      if (this.state.export_invoice) {
        this.setState({ document_title: value })
      } else {
        this.setState({ document_title: value }, () => {
          this.onChangeWithTax('withoutTax')
        })
      }
    } else if (value == 'Invoice') {
      if (this.state.companyDetails.gstin.length == 15) {
        this.setState({ document_title: value }, () => {
          this.onChangeWithTax('withTax')
        })
      } else {
        this.setState({ document_title: value }, () => {
          this.onChangeWithTax('withoutTax')
        })
      }
    } else {
      this.setState({ document_title: value })
    }
  }

  onChangeWithTax = (value: string) => {
    let with_tax = value == 'withTax' ? 1 : 0
    if (this.state.export_invoice == 1) {
      if (
        this.state.export_type?.includes('with IGST') ||
        this.state.export_type?.includes('Deemed')
      ) {
        with_tax = 1
      } else {
        with_tax = 0
      }
    }
    if (with_tax == 0) {
      this.onRCMChange(false)
    }
    this.setState({ with_tax: with_tax }, () => {
      this.onWithTaxChange()
    })
  }

  generateTaxLabel() {
    if (this.props?.user?.selectedCompany?.country_code == 'AE') {
      return 'VAT'
    } else {
      if (this.state.immovable_tax_type == 1) {
        return `${this.state.companyDetails.tax_labels.SGST} + ${this.state.companyDetails.tax_labels.CGST}`
      }
      const billing_address = this.state.companyDetails?.billing_address?.filter(
        x => x.is_delete == 0,
      )
      const party_state =
        this.state.party_type == 'customer'
          ? this.state.customer_details?.billing_address?.[0]?.state
          : this.state.vendor_details?.billing?.state
      if (
        (this.state.export_invoice == 1 &&
          (this.state.export_type?.includes('with IGST') ||
            this.state.export_type?.includes('Deemed'))) ||
        this.state.companyDetails.sez == 1
      ) {
        return this.state.companyDetails.tax_labels.IGST
      } else {
        if (
          this.state.customerData.length != 1 ||
          billing_address?.length == 0 ||
          party_state == undefined
        ) {
          return 'Total Tax'
        } else if (party_state != billing_address?.[0].state) {
          return this.state.companyDetails.tax_labels.IGST
        } else {
          return `${this.state.companyDetails.tax_labels.SGST} + ${this.state.companyDetails.tax_labels.CGST}`
        }
      }
    }
  }

  onCouponsVisible = async () => {
    if (this.state.customerData.length == 0) {
      message.error('Please select a customer first')
      return
    }
    if (this.state.dataSource.length == 0) {
      message.error('Please add items first')
      return
    }
    let total_amount = this.getTotalAmount()
    this.setState({ coupons_visible: true, total_amount: total_amount + this.state.extraDiscount })
  }

  onCouponsApply = async (is_edit = false) => {
    if (this.state.coupon_details.coupon_id > 0) {
      const req = {
        coupon: this.state.coupon_details,
        amount: this.getTotalAmount() - this.state.extraDiscount,
        is_edit: is_edit,
        customers: this.state.customerData.map(item => {
          return {
            ...item,
            customer_id: item.id,
            type: this.state.party_type,
          }
        }),
      }
      const verify = await getAPI('v2/company', 'verify_applied_coupon', req)
      if (verify.success) {
        if (verify.valid) {
          this.onApplyCoupon(this.state.coupon_details, verify.discount, verify.message)
        } else {
          message.error(verify.message)
          this.setState({
            coupon_details: {
              coupon_id: -1,
              coupon_code: '',
              discount: 0,
              message: '',
              is_edit: is_edit,
            },
            extraDiscount: 0,
            extraDiscountPer: 0,
          })
        }
      }
    }
  }
  onExtraDiscountChange = (e: any) => {
    let total_amount = this.getTotal() - this.state.rcm_value
    let discountPercent =
      this.state.exta_discount_type == 'discount'
        ? e
        : ((Number(e) * 100) / (total_amount ? total_amount : 1)).toFixed(2)
    let discountAmount =
      this.state.exta_discount_type == 'discount'
        ? ((Number(e) / 100) * (total_amount ? total_amount : 0)).toFixed(2)
        : e
    if (discountAmount > total_amount) {
      message.error('Discount cannot be greater than total amount')
      return
    }
    this.setState(
      {
        extraDiscountPer: discountPercent,
        extraDiscount: discountAmount,
      },
      () => this.onChangeTcsApplicable(this.state.tcs_applicable_on),
    )
  }

  getTotalAmount = (items: Item[] = []) => {
    let products = items.length > 0 ? items : [...this.state.dataSource]

    var total = 0
    if (this.state.with_tax == 1) {
      total =
        Number(this.getTotal(products)) +
        this.extraChargesAmount('total_amount') -
        Number(this.state.extraDiscount) -
        Number(this.state.roundoff_value) -
        Number(this.state.rcm_value) +
        Number(this.state.tcs_amount)
    }
    if (this.state.with_tax == 0) {
      total =
        Number(getTotalNetAmountV2(products)) +
        this.extraChargesAmount('total_amount') -
        Number(this.state.extraDiscount) -
        Number(this.state.roundoff_value) -
        Number(this.state.rcm_value) +
        Number(this.state.tcs_amount)
    }
    return total
  }

  onApplyCoupon = async (coupon: any, discount: any, message: string) => {
    this.setState({
      coupon_details: {
        ...coupon,
        coupon_id: coupon.coupon_id,
        coupon_code: coupon.coupon_code,
        discount: discount,
        message: message,
      },
      extraDiscount: discount,
      coupons_visible: false,
    })
  }
  onChangeTdsTcsSection = (value: any, is_tds: any) => {
    let selected_section = value
    let section_details = []
    // console.log('is_tds', is_tds, value, this.props.tcs_sections)
    // let tcs_sections = this.props.tcs_sections
    // console.log('tcs_sections', tcs_sections)
    if (is_tds) {
      section_details = this.props.tds_sections.filter((item: any) => item.tds_id == value)
    } else {
      section_details = this.props.tcs_sections.filter((item: any) => item.tds_id == value)
    }

    section_details = section_details[0]

    let amount = 0
    if (section_details.apply_on == 'net_amount') {
      amount =
        (Number(
          getTotalNetAmountV2(this.state.dataSource)
            ? getTotalNetAmountV2(this.state.dataSource) + this.extraChargesAmount('net_amount')
            : 0,
        ) *
          section_details.tax) /
        100

      amount = Number(parseFloat(String(amount)).toFixed(2))
    } else {
      amount =
        ((Number(
          this.getTotalAmount(this.state.dataSource)
            ? this.getTotalAmount(this.state.dataSource)
            : 0,
        ) -
          this.state.tcs_amount) *
          section_details.tax) /
        100

      amount = Number(parseFloat(String(amount)).toFixed(2))
    }
    if (is_tds == 0) {
      this.setState(
        {
          tcs_selected_section: selected_section,
          tcs_amount: amount,
          tcs_applicable_on: section_details.apply_on,
        },
        () => {
          this.onRoundOffChange(this.state.roundoff)
          this.onChangeFullyPaid(this.state.fullyPaid)
        },
      )
    } else {
      this.setState(
        {
          tds_selected_section: selected_section,
          tds_amount: amount,
          tds_applicable_on: section_details.apply_on,
        },
        () => {
          this.onChangeFullyPaid(this.state.fullyPaid)
        },
      )
    }
  }
  renderCustomHeaderOptions = (header_id: any) => {
    let options: any = []
    this.state.custom_headers_suggestions?.[header_id]?.map((item: any, index: number) => {
      options.push({
        value: item,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {item}
          </div>
        ),
      })
    })
    return options
  }
  onChangeTcsApplicable = (value: string, products: Item[] = [], state_update: boolean = true) => {
    let amount = 0

    if (this.state.is_tcs && this.state.tcs_selected_section != undefined) {
      let items = products.length > 0 ? products : [...this.state.dataSource]
      if (this.state.tcs_selected_section != undefined) {
        let section_details = this.props.tcs_sections.filter(
          (item: any) => item.tds_id == this.state.tcs_selected_section,
        )[0]
        if (value == 'net_amount') {
          amount =
            (Number(getTotalNetAmountV2(items) ? getTotalNetAmountV2(items) : 0) *
              section_details?.tax) /
            100

          amount = Number(parseFloat(String(amount)).toFixed(2))
        } else {
          amount =
            ((Number(this.getTotalAmount(items) ? this.getTotalAmount(items) : 0) +
              Number(this.state.roundoff_value) -
              this.state.tcs_amount) *
              section_details?.tax) /
            100

          amount = Number(parseFloat(String(amount)).toFixed(2))
        }
      }
      if (state_update) {
        this.setState({ tcs_applicable_on: value, tcs_amount: amount }, () => {
          this.onRoundOffChange(this.state.roundoff)
          this.onChangeFullyPaid(this.state.fullyPaid)
        })
      }
    }
    return amount
  }
  get_prefix_doc_number = async () => {
    // this.setState({ new_prefix: '', new_suffix: '' })
    const data = await getAPI('utils', 'get_prefix_seral_number', {
      prefix: this.state.prefix,
      document_type: this.state.document_type,
      suffix: this.state.suffix,
      is_prefix: this.state.is_prefix,
    })
    if (data) {
      this.setState({
        doc_number: data.doc_number,
        prefix: data.default_prefix,
        // needed when dealing with multiple tabs
        // items: this.state.items.map(item =>
        //   item.key == this.state.activeKey
        //     ? {
        //         ...item,
        //         label: `${prefix}${data.doc_number}`,
        //       }
        //     : item,
        // ),
      })
    }
  }
  refreshPrefixes = async () => {
    const data = await getAPI('utils', 'get_document_prefix', {
      document_type: this.state.document_type,
    })
    if (data) {
      this.setState({ prefixes: data.prefixes })
    }
  }
  refreshSuffixes = async () => {
    const data = await getAPI('utils', 'get_document_suffix', {
      document_type: this.state.document_type,
    })
    if (data) {
      this.setState({ suffixes: data.suffixes })
    }
  }

  refreshNotesOrTerms = async (refreshType: string) => {
    try {
      const isNotes = refreshType === 'notes' ? 1 : 0
      const data = await getAPI('user', 'get_document_notes', {
        is_notes: isNotes,
        document_type: this.state.document_type,
      })
      if (data?.success) {
        const refresh_type = refreshType === 'notes' ? 'document_notes' : 'document_terms'

        this.setState({
          [refresh_type]: data.data,
        } as Pick<DocState, keyof DocState>)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  handleLabelChange = (type: string, value: any) => {
    const labelType = type === 'terms' ? 'termsLabel' : 'notesLabel'
    this.setState({ [labelType]: value } as Pick<DocState, keyof DocState>)
  }

  handleSaveNotesAndTerms = async (type: string, is_new: boolean = false) => {
    if (is_new) {
      const { notesLabel, termsLabel } = this.state

      this.setState({
        showNotesLabelError: type === 'notes' && notesLabel.trim() === '',
        showTermsLabelError: type === 'terms' && termsLabel.trim() === '',
        isNotesLoading: type === 'notes',
        isTermsLoading: type === 'terms',
      })

      if (type === 'notes' && notesLabel.trim() === '') {
        this.setState({ isNotesLoading: false })
        return
      }
      if (type === 'terms' && termsLabel.trim() === '') {
        this.setState({ isTermsLoading: false })
        return
      }

      try {
        const isNotes = type === 'notes'

        await getAPI('user', 'edit_document_notes', {
          document_type: this.state.document_type,
          is_active: 1,
          is_default: 0,
          is_delete: 0,
          is_notes: isNotes,
          label: isNotes ? notesLabel : termsLabel,
          note_id: -1,
          notes: isNotes ? this.state.notes : this.state.terms,
        })

        const updateFlags = isNotes
          ? this.setState({
              notesLabel: '',
              showNotes: false,
              isNotesLoading: false,
              showNotesLabelError: false,
            })
          : this.setState({
              termsLabel: '',
              showTerms: false,
              isTermsLoading: false,
              showTermsLabelError: false,
            })

        this.refreshNotesOrTerms(type)
      } catch (error) {
        // console.error(error)
      }
    } else {
      const { selected_note, selected_term, document_notes, document_terms } = this.state

      const isLoadingKey = type === 'notes' ? 'isNotesLoading' : 'isTermsLoading'
      this.setState(({
        [isLoadingKey]: true,
      } as unknown) as Pick<DocState, keyof DocState>)

      try {
        const documentList = type === 'notes' ? document_notes : document_terms
        const selectedItem = type === 'notes' ? selected_note : selected_term
        const showType = type === 'notes' ? 'showNotes' : 'showTerms'
        const dataKey = type === 'notes' ? 'notes' : 'terms'

        if (documentList.length === 0) {
          this.setState(({ [showType]: true } as unknown) as Pick<DocState, keyof DocState>)
        } else {
          const selectedData: any = documentList[selectedItem ?? 0]
          await getAPI('user', 'edit_document_notes', {
            ...selectedData,
            notes: this.state[dataKey],
          })
          this.refreshNotesOrTerms(type)
        }

        this.setState(({
          [isLoadingKey]: false,
        } as unknown) as Pick<DocState, keyof DocState>)
      } catch (error) {
        console.error(error)
      }
    }
  }

  handleScroll = (e: any) => {
    const bottom =
      Number(e.target.scrollHeight - e.target.scrollTop) - Number(e.target.clientHeight) <= 1

    if (bottom) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.customerSearchAPI(this.state.customerName, false)
      })
    }
  }
  haveChanges = () => {
    return (
      this.state.selectedCustomer != -1 ||
      this.state.export_invoice == 1 ||
      !dayjs(this.state.document_date, this.dateFormat).isSame(dayjs(), 'day') ||
      this.state.document_title != 'Invoice'
    )
  }
  leaveChanges = async () => {
    if (this.state.edit || window.location.href.includes('draft')) {
      this.changeRoute()
      await this.props.dispatch(remove_all_invoices_data())
      return
    }
    const invoices = this.props.invoice_tabs.filter(
      (tab: any) => !tab.data.isClosed && !tab.data.isCreated,
    )

    // change the below condition when multiple tabs are allowed
    if (invoices.length == 0 || !this.haveChanges()) {
      this.changeRoute()
      await this.props.dispatch(remove_all_invoices_data())
    } else if (this.state.dataSource.length > 0 || this.haveChanges()) {
      this.setState({ visible: true })
    }
  }

  deleteDraft = async (doc_count: number) => {
    var req = {
      document_type: 'draft',
      doc_count: doc_count,
    }
    await getAPI('doc', 'burn', req)
    return
  }

  deleteAllDrafts = async () => {
    const activeKey = this.state.activeKey

    const unSavedInvoices = this.props.invoice_tabs.filter((tab: any) => !tab.data.isCreated)

    if (unSavedInvoices.length > 0) {
      const item = unSavedInvoices.map((invoice: any) => {
        const doc_count = invoice.data.auto_draft.doc_count
        if (doc_count) {
          this.deleteDraft(doc_count)
        }
      })
    }

    await this.props.dispatch(remove_all_invoices_data())
    this.changeRoute()
  }

  saveAsDraft = async (saveAll = true) => {
    this.setState({ loadingSave: true })
    if (!saveAll) {
      await this.save('1', true)
      this.setState({ saveDocument: false })
      this.setState({ loadingSave: false })

      return
    } else {
      const activeKey = this.state.activeKey
      if (!this.state.isCreated) {
        await this.save('1', true, true)
      }

      const unSavedInvoices = this.props.invoice_tabs.filter(
        (tab: any) => !tab.data.isCreated && tab.key != activeKey,
      )

      if (unSavedInvoices.length > 0) {
        const item = unSavedInvoices.map((invoice: any) => {
          const invoiceData = invoice.data
          this.setState({ ...invoiceData }, async () => {
            await this.save('1', true, true)
          })
        })
        await this.props.dispatch(remove_all_invoices_data())
      }
      this.setState({ loadingSave: false })

      this.changeRoute('draft')
    }
  }
  startAutoDrafting = () => {
    this.autoDraftInterval = setInterval(() => {
      this.saveToDatabase()
    }, 15000)
  }
  stopAutoDrafting = () => {
    // Clear the interval when component unmounts or when not needed
    clearInterval(this.autoDraftInterval)
  }
  saveToDatabase = async () => {
    if (!this.state.isCreated) {
      // if (this.state.isDrafted) {
      //   this.setState(
      //     {
      //       document_type: 'draft',
      //     },
      //     async () => {
      //       await this.save('1', true, true)
      //     },
      //   )
      // } else {
      //   await this.save('1', true, true)
      // }
      await this.save('1', true, true)
    }
  }
  addSplitPayment = () => {
    if (this.state.fullyPaid) {
      message.error('You cannot add payment mode when fully paid')
      return
    }
    if (this.state.paymentsList.length >= 5) {
      message.error('You can add maximum 5 payment modes')
      return
    }
    if (!this.validateAmount()) {
      return
    }
    if (!this.checkAllValidations()) {
      return
    }
    this.setState({
      paymentsList: [
        ...this.state.paymentsList,
        {
          key: this.state.paymentsList.length + 1,
          ...emptyPaymentDetails,
          payment_method:
            this.state.selected_bank?.id == 0
              ? 'Cash'
              : this.props?.document?.paymentModes?.[0]?.value,
          bank_id: this.state.selected_bank?.id,
        },
      ],
    })
  }
  removeSplitPayment = (data: any) => {
    if (this.state.paymentsList.length == 1) {
      message.error('You cannot remove the last payment record')
      return
    }
    const updatedData = this.state.paymentsList.filter(mode => mode.key !== data.key)
    this.setState({
      paymentsList: updatedData,
      amountReceived: this.state.amountReceived - data.amount,
    })
  }
  updatePaymentModes = (type: string, value: any, key: any) => {
    const data = this.state.paymentsList.map((mode: any) => {
      if (mode.key == key) {
        let bankId: any = ''
        let paymentMode: any = ''
        let amount: number = 0
        let payment_notes: string = ''
        if (type == 'payment_mode') {
          bankId = this.onChangePaymentMethod(value, mode.bank_id)
          paymentMode = value
          amount = mode.amount
          payment_notes = mode.payment_notes
        } else if (type == 'bank_id') {
          const selectedBank = this.state.bank_details.find(({ id }) => id === value)
          if (selectedBank?.bank_name == 'Cash') {
            paymentMode = 'Cash'
            this.setState({
              selected_bank: this.state.fullyPaid ? selectedBank : this.state.selected_bank,
              paymentMethod: this.state.fullyPaid ? 'Cash' : this.state.paymentMethod,
            })
          } else {
            paymentMode = mode.payment_method
          }
          bankId = value
          amount = mode.amount
          payment_notes = mode.payment_notes
        } else if (type == 'amount') {
          paymentMode = mode.payment_method
          bankId = mode.bank_id
          amount = value
          payment_notes = mode.payment_notes
        } else {
          paymentMode = mode.payment_method
          bankId = mode.bank_id
          amount = mode.amount
          payment_notes = value
        }

        return {
          ...mode,
          payment_method: paymentMode,
          bank_id: bankId,
          amount: amount < 0 ? 0 : amount,
          payment_notes: payment_notes,
        }
      } else return { ...mode }
    })
    const totalAmount = data.reduce((sum, mode) => sum + (mode.amount || 0), 0)
    this.setState(
      {
        paymentsList: data,
        amountReceived: totalAmount,
      },
      () => {
        this.validateAmount()
      },
    )
  }
  checkAllValidations = () => {
    if (this.state.paymentsList.length == 1) {
      const data = this.state.paymentsList[0]
      if (data.amount > 0 && (!data.payment_method || data.bank_id == undefined)) {
        message.error('Please check payment mode and bank in the payment details')
        return false
      }
      return true
    }
    const allValid = !this.state.paymentsList.some(payment => {
      if (payment.amount <= 0 && this.state.amountReceived < this.getTotalAmount()) {
        message.error('Payment amount must be greater than 0')
      } else if (!payment.payment_method) {
        message.error('Please select payment mode')
      } else if (payment.bank_id == undefined) {
        message.error('Please select bank')
      }

      return !payment.payment_method || payment.amount <= 0 || payment.bank_id == undefined
    })
    return allValid
  }
  validateAmount = () => {
    if (
      this.state.paymentsList.length > 1 &&
      this.state.amountReceived + this.state.tds_amount > this.getTotalAmount()
    ) {
      message.error('Amount received cannot be greater than total amount')
      return false
    }
    return true
  }
  setNewPrefixSuffix = (value: any, is_prefix: any, input: boolean = true) => {
    if (is_prefix) {
      this.setState(
        {
          prefix: value,
          new_prefix: value,
          is_prefix: is_prefix,
        },
        () => {
          if (input) {
            this.get_prefix_doc_number()
          }
        },
      )
    } else {
      this.setState(
        {
          suffix: value,
          new_suffix: value,
          is_prefix: is_prefix,
        },
        () => {
          if (input) {
            this.get_prefix_doc_number()
          }
        },
      )
    }
  }
  setIsPrefix = (value: any) => {
    this.setState({ is_prefix: value })
  }

  handleCountryOfSupplyChange = () => {
    if (!this.state.export_invoice) return

    const countryName = this.state.selected_customer_shipping_address?.country
    if (!countryName) return

    const countryDetails = this.props.countries.find(({ name }: any) => name == countryName)
    this.setState({
      country: countryDetails.country_id,
      currency: countryDetails.currency_code,
      currency_symbol: countryDetails.currency_symbol,
    })
  }

  handleCustomerShippingAddressChange = (e: any) => {
    this.setState(
      {
        selected_customer_shipping_address: this.state.customer_details.shipping_address.find(
          ({ addr_id }) => addr_id === e,
        ),
      },
      this.handleCountryOfSupplyChange,
    )
  }

  render() {
    const SHOW_FREE_USER_EINVOICE =
      isFreeEInvoiceUser(this.props.company_details) &&
      this.props.document.all_doc_counts?.einvoice < FREE_USER_EINVOICE_LIMIT

    const SHOW_FREE_USER_EWAYBILL =
      store.get('ewaybill') == 0 &&
      this.props.document.all_doc_counts?.eway_bill < FREE_USER_EWAYBILL_LIMIT

    const noOfEwaybillsLeft =
      FREE_USER_EWAYBILL_LIMIT - this.props.document.all_doc_counts?.eway_bill >= 0
        ? FREE_USER_EWAYBILL_LIMIT - this.props.document.all_doc_counts?.eway_bill
        : 0
    const noOfEInvoicesLeft =
      FREE_USER_EINVOICE_LIMIT - this.props.document.all_doc_counts?.einvoice >= 0
        ? FREE_USER_EINVOICE_LIMIT - this.props.document?.all_doc_counts?.einvoice
        : 0

    const { notesLabel, termsLabel, showNotes, showTerms } = this.state
    const {
      isContentMaxWidth,
      isAppMaxWidth,
      isGrayBackground,
      isSquaredBorders,
      isCardShadow,
      isBorderless,
      isTopbarFixed,
      isGrayTopbar,
    } = this.props
    const allDueDates = [
      {
        name: 'Today',
        value: dayjs(this.state.document_date, this.dateFormat).format(this.dateFormat),
      },
      {
        name: 'Net 15',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(15, 'days')
          .format(this.dateFormat),
      },
      {
        name: 'Net 30',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(30, 'days')
          .format(this.dateFormat),
      },
      {
        name: 'Net 60',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(60, 'days')
          .format(this.dateFormat),
      },
      {
        name: 'Net 90',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(90, 'days')
          .format(this.dateFormat),
      },
    ]
    const rangePresets = [
      {
        label: '3 Months',
        value: dayjs(this.state.subscription_start_date, this.dateFormat)
          .add(3, 'months')
          .format(this.dateFormat),
      },
      {
        label: '6 Months',
        value: dayjs(this.state.subscription_start_date, this.dateFormat)
          .add(6, 'months')
          .format(this.dateFormat),
      },
      {
        label: '1 Year',
        value: dayjs(this.state.subscription_start_date, this.dateFormat)
          .add(1, 'year')
          .format(this.dateFormat),
      },
      {
        label: '2 Years',
        value: dayjs(this.state.subscription_start_date, this.dateFormat)
          .add(2, 'year')
          .format(this.dateFormat),
      },
    ]
    const discount_type_items = [
      {
        key: '1',
        label: ' Price With Tax',
      },
      {
        key: '2',
        label: 'Unit Price',
      },
      {
        key: '3',
        label: 'Net Amount',
      },
      {
        key: '4',
        label: 'Total Amount',
      },
    ]

    const dateFooter = (
      <div className="flex flex-wrap gap-1 mt-2 mb-2" style={{ width: '250px' }}>
        {allDueDates.map((item, i) => {
          return (
            <Tag
              key={i}
              className=""
              onClick={() => this.setState({ due_date: item.value, openDatePicker: false })}
              color={
                item.value == dayjs(this.state.due_date, this.dateFormat).format(this.dateFormat)
                  ? 'processing'
                  : 'success'
              }
              style={{ cursor: 'pointer', display: 'inline' }}
            >
              <span className="font-weight-medium">{item.name}</span>
            </Tag>
          )
        })}

        <InputNumber
          className=" inline"
          style={{
            display: 'inline',
          }}
          placeholder="Net Days"
          onChange={(e: any) =>
            this.setState({
              due_date: dayjs(this.state.document_date, this.dateFormat)
                .add(e, 'days')
                .format(this.dateFormat),
            })
          }
          size="small"
          // onClick={e => e.target.select()}
          onFocus={() => this.setState({ openDatePicker: true })}
          onBlur={() => this.setState({ openDatePicker: false })}
          min={0}
          max={365}
        />
      </div>
    )
    const subscriptionDateFooter = (
      <React.Fragment>
        {rangePresets.map((item, i) => {
          return (
            <Tag
              key={i}
              className="m-1"
              onClick={() =>
                this.setState({ subscription_end_date: item.value, openDatePicker: false })
              }
              color={
                item.value ==
                dayjs(this.state.subscription_end_date, this.dateFormat).format(this.dateFormat)
                  ? 'processing'
                  : 'success'
              }
              style={{ cursor: 'pointer' }}
            >
              <span className="font-weight-medium">{item.label}</span>
            </Tag>
          )
        })}
      </React.Fragment>
    )

    const paymentsListColumns = [
      {
        title: 'Notes',
        dataIndex: '',
        key: 'notes',
        render: (text: any, data: any) => {
          return (
            <>
              <TextArea
                rows={1}
                placeholder="Advance received, UTR number etc..."
                onChange={e => this.updatePaymentModes('notes', e.target.value, data.key)}
                className="w-full"
              />
            </>
          )
        },
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '20%',
        render: (text: any, data: any) => {
          return (
            <>
              <InputNumber
                placeholder="Enter Amount"
                disabled={this.state.fullyPaid || this.state.rzp_payment_id != ''}
                onChange={e => this.updatePaymentModes('amount', e, data.key)}
                className="w-full"
                value={text}
              />
            </>
          )
        },
      },
      {
        title: 'Payment Mode',
        dataIndex: 'payment_method',
        key: 'payment_mode',
        width: 180,
        render: (text: any, data: any) => {
          return (
            <>
              <Select
                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                className="w-full"
                style={{ width: '180px' }}
                dropdownStyle={{ minWidth: '180px', maxWidth: '360px' }}
                onChange={e => this.updatePaymentModes('payment_mode', e, data.key)}
                placeholder="Select mode"
                value={text}
              >
                {this.props?.document?.paymentModes.map((item: any) => {
                  if (item.value !== 'TDS') {
                    return (
                      <Option key={item.value} value={item.value}>
                        <span className="block w-full truncate">{item.value}</span>
                      </Option>
                    )
                  }
                  return null
                })}
              </Select>

              <span className="select-borderless-mini absolute block ml-1">
                <Select
                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                  placeholder="Select Bank"
                  className="w-full font-size-12"
                  size="small"
                  style={{ minWidth: '0px', maxWidth: '180px' }}
                  dropdownStyle={{ minWidth: '360px' }}
                  optionFilterProp="children"
                  onChange={e => this.updatePaymentModes('bank_id', e, data.key)}
                  value={data.bank_id}
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  id="selectBank"
                  disabled={data.payment_method == 'Cash'}
                >
                  {this.state.bank_details.map((item, i) => (
                    <Option key={i} value={item.id}>
                      <span className="block w-full truncate">
                        {item.bank_name + ' '} {item.bank_no != '' && '(' + item.bank_no + ')'}
                      </span>
                    </Option>
                  ))}
                </Select>
              </span>
            </>
          )
        },
      },
      // {
      //   title: 'Bank',
      //   dataIndex: 'bank_id',
      //   key: 'bank_id',
      //   width: 180,
      //   render: (text: any, data: any) => {
      //     return (
      //       <>
      //         <Select
      //           suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
      //           placeholder="Select Bank"
      //           className="w-full"
      //           style={{ width: '180px' }}
      //           dropdownStyle={{ minWidth: '180px', maxWidth: '360px' }}
      //           optionFilterProp="children"
      //           onChange={e => this.updatePaymentModes('bank_id', e, data.key)}
      //           value={data.bank_id}
      //           filterOption={(input: any, option: any) =>
      //             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //           }
      //           id="selectBank"
      //           disabled={data.payment_method == 'Cash'}
      //         >
      //           {this.state.bank_details.map((item, i) => (
      //             <Option key={i} value={item.id}>
      //               <span className="block w-full truncate">
      //                 {item.bank_name + ' '} {item.bank_no != '' && '(' + item.bank_no + ')'}
      //               </span>
      //             </Option>
      //           ))}
      //         </Select>
      //       </>
      //     )
      //   },
      // },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        render: (text: any, data: any) => (
          <>
            {Number(text) > 1 && (
              <span
                className="text-gray-600 cursor-pointer mr-1"
                onClick={() => this.removeSplitPayment(data)}
              >
                <i className="fa fa-trash" />
              </span>
            )}
          </>
        ),
      },
    ]
    const invoiceComponent = (
      <>
        {this.state.loaded == true && (
          <div className="mb-5 p-2">
            <div className="mb-8">
              <div className="flex w-full">
                <div className="flex-grow mr-6 w-2/6 select-customer">
                  <span>
                    <SectionHeader
                      title={
                        (this.state.party_type == 'customer' ? 'Customer' : 'Vendor') + ' Details'
                      }
                      addButton={
                        (this.state.edit && this.state.payment_status != 'pending') ||
                        this.state.from_document_type == 'online_order'
                          ? false
                          : true
                      }
                      addButton_onClick={() =>
                        this.state.party_type == 'customer'
                          ? this.customer?.onAdd(this.state.customerName)
                          : this.vendor?.onAdd(this.state.customerName)
                      }
                      addButton_text={
                        'Add new' +
                        (this.state.party_type === 'customer' ? ' Customer' : ' Vendor') +
                        '?'
                      }
                      infoContent={
                        <span>
                          <span>
                            Search your existing{' '}
                            {this.state.party_type === 'customer' ? ' customers' : ' vendors'} using
                            the search box below.
                          </span>
                          <br />
                          <span>
                            Please click on{' '}
                            <span className="text-success">Add new {this.state.party_type}</span> to
                            add a new{' '}
                            {this.state.party_type === 'customer' ? ' Customer' : ' Vendor'}.
                          </span>
                        </span>
                      }
                    />
                    <Card
                      //  @ts-ignore
                      size="document"
                      bordered={false}
                      className="border-radius-small bg-card-azure card-shadow min-h-24"
                    >
                      <div className="flex flex-row">
                        <span className="w-full">
                          <h6 className="text-gray-500">
                            Select {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}
                          </h6>
                          {this.state.payment_status == 'pending' &&
                            this.state.from_document_type != 'online_order' && (
                              <span>
                                <AutoComplete
                                  options={this.state.autocompleteData}
                                  className="mr-2 pb-2 custom-autocomplete-input w-full mb-2"
                                  autoFocus
                                  style={{ width: '100%' }}
                                  // @ts-ignore
                                  dropdownMenuStyle={{ top: 18 }}
                                  popupMatchSelectWidth={640}
                                  // @ts-ignore
                                  trigger="hover"
                                  onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault()
                                      if (
                                        e.target.value !== '' &&
                                        this.state.autocompleteData?.length === 0
                                      ) {
                                        this.state.party_type === 'customer'
                                          ? this.customer?.onAdd(this.state.customerName)
                                          : this.vendor?.onAdd(this.state.customerName)
                                      }
                                    }
                                  }}
                                  dropdownRender={menu => (
                                    <>
                                      {menu}
                                      <div className="bg-gray-75">
                                        <Button
                                          block
                                          type="link-2"
                                          className="font-weight-bolder text-left my-2 arrow-transition"
                                          onClick={() =>
                                            this.state.party_type === 'customer'
                                              ? this.customer?.onAdd(this.state.customerName)
                                              : this.vendor?.onAdd(this.state.customerName)
                                          }
                                        >
                                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                          Add New
                                          {this.state.party_type === 'customer'
                                            ? ' Customer'
                                            : ' Vendor'}
                                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                  notFoundContent={
                                    <>
                                      <div
                                        className="px-4 py-2 text-gray-500 font-weight"
                                        onClick={() =>
                                          this.state.party_type === 'customer'
                                            ? this.customer.onAdd(this.state.customerName)
                                            : this.vendor.onAdd(this.state.customerName)
                                        }
                                      >
                                        <span className="font-weight-bold">
                                          No search results found
                                        </span>
                                        <p className="mb-0">
                                          Please try again with a different search query or{' '}
                                        </p>
                                      </div>
                                    </>
                                  }
                                  onSelect={
                                    this.state.party_type === 'customer'
                                      ? this.onCustomerSelect
                                      : this.onVendorSelect
                                  }
                                  onSearch={this.onCustomerSearch}
                                  value={this.state.customerName}
                                  ref={this.inputEl}
                                  onPopupScroll={this.handleScroll}
                                  id={'addParty'}
                                >
                                  <Input
                                    prefix={
                                      <i className="fa-solid fa-magnifying-glass text-gray-300"></i>
                                    }
                                    placeholder={`Search your ${
                                      this.state.party_type === 'customer' ? 'Customers' : 'Vendors'
                                    }, Company Name, ${
                                      this.props?.user?.selectedCompany?.tax_id_label
                                    }, tags...`}
                                    // extra="search your customers by their name, company name, gstin, or tags"
                                  />
                                </AutoComplete>
                              </span>
                            )}

                          {((this.state.edit && this.state.payment_status != 'pending') ||
                            this.state.from_document_type == 'online_order') && (
                            <span className="">
                              <Input
                                style={{ width: '100%' }}
                                placeholder="Customer Name"
                                value={this.state.name}
                                disabled
                              />
                            </span>
                          )}
                        </span>
                      </div>
                    </Card>
                    {/* <div className="flex flex-wrap gap-1">
                    {this.state.customerData.map((itemTag, i) => (
                      <Popover
                        placement="bottomLeft"
                        title={
                          <>
                            <div className="flex">
                              <UserAvatar
                                name={
                                  this.state.customerData[i]['company_name'] != '' &&
                                  this.state.customerData[i]['company_name'] != null
                                    ? this.state.customerData[i]['company_name']
                                    : this.state.customerData[i]['name']
                                }
                              />
                              <div>
                                <span className="mt-0 font-weight-medium text-gray-900">
                                  {this.state.customerData[i]['name']}
                                </span>
                                {this.state.customerData[i]['name'] !=
                                  this.state.customerData[i]['company_name'] && (
                                  <p className="mt-0 mb-0 text-gray-500">
                                    <CopyText
                                      label="Company Name"
                                      text={this.state.customerData[i]['company_name']}
                                    />
                                  </p>
                                )}
                                {this.state.customerData[i]['company_name'] != null && (
                                  <span
                                    className="font-weight-normal"
                                    onClick={e => e.stopPropagation()}
                                  >
                                    <p className="mt-0 mb-0 text-gray-500">
                                      <CopyText
                                        label="GSTIN"
                                        text={this.state.customerData[i]['gstin']}
                                      />
                                    </p>
                                    <p className="mt-0 mb-0 text-gray-500">
                                      {this.state.customerData[i]['billing']?.state}
                                    </p>
                                  </span>
                                )}

                                {this.props.permissions.ledgers == 1 && (
                                  <Button
                                    onClick={e => {
                                      e.stopPropagation()

                                      this.ledger.showModal({
                                        ...this.state.customerData[i],
                                        id: this.state.customerData[i]['id'],
                                        type: this.state.party_type,
                                      })
                                    }}
                                    className="mt-2 mb-1"
                                    type="check-ledger"
                                    size="small"
                                    shape="round"
                                  >
                                    <span className="font-weight-bold">
                                      Check <FormattedMessage id="button.ledger" />
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </div>
                          </>
                        }
                      >
                        <Tag
                          key={i}
                          closable
                          closeIcon={<i className="fa-solid fa-xmark fa-lg ant-tag-close-icon"></i>}
                          onClose={e => this.tagDelete(e, itemTag)}
                          onClick={e => {
                            let has_permission = this.props.permissions[
                              this.state.party_type + '_edit'
                            ]
                            if (has_permission == 1) {
                              this.state.party_type === 'customer'
                                ? this.customer.onEdit(itemTag.id)
                                : this.vendor.onFill({ vendor_id: itemTag.id })
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                          className="tag-large border border-gray-200 mt-1 p-2 flex items-center max-w-max bg-card-orange"
                        >
                          <div className="flex flex-col">
                            <span className="font-weight-bold leading-none font-size-16">
                              {itemTag.company_name !== '' ? itemTag.company_name : itemTag.name}
                            </span>
                            {/* {itemTag.gstin != '' && (
                              <p className="text-gray-500 font-size-11 leading-none pt-1">
                                {itemTag.gstin}
                              </p>
                            )} */}
                    {/* </div>
                        </Tag>
                      </Popover>
                    ))}
                  </div> */}
                    {/* {!dispatch_to_doc_types.includes(this.state.document_type) &&
                    this.state.customer_details.shipping_address.length > 0 &&
                    this.state.customerData.length == 1 &&
                    this.state.document_type != 'purchase' &&
                    this.state.document_type != 'purchase_order' &&
                    this.state.document_type != 'purchase_return' && (
                      <div className="font-size-14 select-borderless leading-tight mt-1">
                        <span className="text-gray-500 pl-1 font-weight-medium w-4/12">
                          Shipping Address
                        </span>
                        <span className="select-borderless">
                          <Select
                            size={'small'}
                            style={{ maxWidth: 396 }}
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            placeholder="Select Customer Shipping Address"
                            optionFilterProp="children"
                            className="mb-2 max-w-8/12 "
                            dropdownStyle={{ minWidth: '360px', maxWidth: '720px' }}
                            showSearch
                            allowClear
                            onChange={e => {
                              this.setState({
                                selected_customer_shipping_address: this.state.customer_details.shipping_address.find(
                                  ({ addr_id }) => addr_id === e,
                                ),
                              })
                            }}
                            value={
                              this.state.selected_customer_shipping_address
                                ? 'addr_id' in this.state.selected_customer_shipping_address
                                  ? this.state.selected_customer_shipping_address.addr_id
                                  : undefined
                                : undefined
                            }
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.state.customer_details.shipping_address.map((item, i) => (
                              <Option key={i} value={item.addr_id}>
                                <span className="lh-normal">
                                  <span>{item.title && item.title + ' '}</span>
                                  <span>{item.line1 + ' '}</span>
                                  <span>{item.line2 + ' '}</span>
                                  <span>{item.city}</span>
                                  {item.state &&
                                    item.state !== '97-OTHER TERRITORY' &&
                                    item.state !== 'OTHERTERRITORY' && (
                                      <span className="mt-0 mb-0">
                                        {item.city ? ', ' : ''}
                                        {item.state}
                                      </span>
                                    )}
                                  {item.pincode && item.pincode !== 'None' && (
                                    <span className="mt-0 mb-0">
                                      {item.city || item.state ? ', ' : ''}
                                      {item.pincode}
                                    </span>
                                  )}
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </span>
                      </div>
                    )} */}
                  </span>
                </div>

                <div className="flex-grow w-4/6">
                  <SectionHeader title="Other Details" />
                  <Card
                    //  @ts-ignore
                    size="document"
                    bordered={false}
                    className="border-radius-small bg-gray-75 card-shadow min-h-24"
                  >
                    <div className="flex flex-row">
                      <span className="mr-3 mb-2 min-w-36">
                        <h6 className="text-gray-500 line-clamp-1">
                          {this.state.document_title} Date
                        </h6>
                        <DatePicker
                          style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '100%',
                          }}
                          allowClear={false}
                          format={this.dateFormat}
                          value={dayjs(this.state.document_date, this.dateFormat)}
                          onChange={this.onDocumentDateChange}
                          disabledDate={this.disabledBackDates}
                        />
                      </span>

                      <span className="mr-3 mb-2 min-w-36">
                        <h6 className="text-gray-500">
                          Due Date
                          <span>
                            <InfoIcon text=" The invoice due date is the date on which you expect to receive payment from your customer." />
                          </span>
                        </h6>
                        <DatePicker
                          format={this.dateFormat}
                          allowClear={false}
                          showToday={false}
                          value={dayjs(this.state.due_date, this.dateFormat)}
                          renderExtraFooter={() => dateFooter}
                          style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '100%',
                          }}
                          disabledDate={this.disabledDate}
                          onChange={this.onDueDateChange}
                        />
                      </span>

                      <span className="w-full mb-2 shrink">
                        <h6 className="text-gray-500">
                          Reference
                          <InfoIcon text="Reference is commonly used to save information like Purchase Order Number, Eway Bill Number, Sales Person names, Shipment Number etc..." />
                        </h6>
                        <TextArea
                          rows={1}
                          typeof="text"
                          className=""
                          placeholder="Reference, e.g. PO Number, Eway Bill Number, Sales Person names, Shipment Number etc... (Optional)"
                          name="reference"
                          style={{ width: '100%' }}
                          onChange={e => this.onChange(e)}
                          value={this.state.reference}
                        />
                      </span>
                    </div>

                    {this.state.document_type == 'purchase' && (
                      <div className="flex flex-row mt-2">
                        <span className="width-50p mr-3">
                          <h6 className="text-gray-500">Supplier Invoice Date</h6>
                          <DatePicker
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                              width: '100%',
                            }}
                            allowClear={false}
                            format={this.dateFormat}
                            value={dayjs(this.state.supplier_invoice_date, this.dateFormat)}
                            onChange={this.onSupplierInvoiceDateChange}
                          />
                        </span>

                        <span className="width-50p">
                          <h6 className="text-gray-500">Supplier Invoice Serial No.</h6>
                          <TextArea
                            rows={1}
                            typeof="text"
                            className="pr-4 mr-2"
                            placeholder={
                              this.props.invoice_settings?.supplier_invoice_serial_number
                                ? 'Supplier Invoice Serial No.'
                                : '(Optional)'
                            }
                            name="supplier_invoice_serial_number"
                            style={{ width: '100%', borderRadius: '5px' }}
                            onChange={e => this.onChange(e)}
                            value={this.state.supplier_invoice_serial_number}
                          />
                        </span>
                      </div>
                    )}
                  </Card>
                </div>
              </div>
              <div className="block w-full mt-2">
                <div className="flex flex-wrap gap-1">
                  {this.state.customerData.map((itemTag, i) => (
                    <Popover
                      placement="bottomLeft"
                      title={
                        <>
                          <div className="flex">
                            <UserAvatar
                              name={
                                this.state.customerData[i]['company_name'] != '' &&
                                this.state.customerData[i]['company_name'] != null
                                  ? this.state.customerData[i]['company_name']
                                  : this.state.customerData[i]['name']
                              }
                            />
                            <div>
                              <span className="mt-0 font-weight-medium text-gray-900">
                                {this.state.customerData[i]['name']}
                              </span>
                              {this.state.customerData[i]['name'] !=
                                this.state.customerData[i]['company_name'] && (
                                <p className="mt-0 mb-0 text-gray-500">
                                  <CopyText
                                    label="Company Name"
                                    text={this.state.customerData[i]['company_name']}
                                  />
                                </p>
                              )}
                              {this.state.customerData[i]['company_name'] != null && (
                                <span
                                  className="font-weight-normal"
                                  onClick={e => e.stopPropagation()}
                                >
                                  <p className="mt-0 mb-0 text-gray-500">
                                    <CopyText
                                      label="GSTIN"
                                      text={this.state.customerData[i]['gstin']}
                                    />
                                  </p>
                                  <p className="mt-0 mb-0 text-gray-500">
                                    {this.state.customerData[i]['billing']?.state}
                                  </p>
                                </span>
                              )}

                              {this.props.permissions.ledgers == 1 && (
                                <Button
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.analysis.showModal(
                                      this.state.customerData[i],
                                      this.state.party_type,
                                    )
                                  }}
                                  className="mt-2 mb-1"
                                  type="check-ledger"
                                  size="small"
                                  shape="round"
                                >
                                  <span className="font-weight-bold">
                                    Check <FormattedMessage id="button.ledger" />
                                  </span>
                                </Button>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      <Tag
                        key={i}
                        closable
                        closeIcon={<i className="fa-solid fa-xmark fa-lg ant-tag-close-icon"></i>}
                        onClose={e => this.tagDelete(e, itemTag)}
                        onClick={e => {
                          let has_permission = this.props.permissions[
                            this.state.party_type + '_edit'
                          ]
                          if (has_permission == 1) {
                            this.state.party_type === 'customer'
                              ? this.customer.onEdit(itemTag.id)
                              : this.vendor.onFill({ vendor_id: itemTag.id })
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                        className="tag-large border border-gray-200 mt-1 p-2 flex items-center max-w-max bg-card-orange"
                      >
                        <div className="flex flex-col">
                          <span className="font-weight-bold leading-none font-size-16">
                            {itemTag.company_name !== '' ? itemTag.company_name : itemTag.name}
                          </span>
                          {/* {itemTag.gstin != '' && (
                              <p className="text-gray-500 font-size-11 leading-none pt-1">
                                {itemTag.gstin}
                              </p>
                            )} */}
                        </div>
                      </Tag>
                    </Popover>
                  ))}
                </div>

                {!dispatch_to_doc_types.includes(this.state.document_type) &&
                  this.state.customer_details?.shipping_address?.length > 0 &&
                  this.state.customerData.length == 1 &&
                  this.state.document_type != 'purchase' &&
                  this.state.document_type != 'purchase_order' &&
                  this.state.document_type != 'purchase_return' && (
                    <div className="font-size-14 select-borderless leading-tight mt-1">
                      <span className="text-gray-500 pl-1 font-weight-medium w-4/12">
                        Shipping Address
                      </span>
                      <span className="select-borderless">
                        <Select
                          size={'small'}
                          style={{ maxWidth: 450 }}
                          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                          placeholder="Select Customer Shipping Address"
                          optionFilterProp="children"
                          className="mb-2 max-w-8/12 "
                          dropdownStyle={{ minWidth: '360px', maxWidth: '720px' }}
                          showSearch
                          allowClear
                          onChange={e => {
                            this.setState({
                              selected_customer_shipping_address: this.state.customer_details.shipping_address.find(
                                ({ addr_id }) => addr_id === e,
                              ),
                            })
                          }}
                          value={
                            this.state.selected_customer_shipping_address
                              ? 'addr_id' in this.state.selected_customer_shipping_address
                                ? this.state.selected_customer_shipping_address.addr_id
                                : undefined
                              : undefined
                          }
                          filterOption={(input: any, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }}
                        >
                          {this.state.customer_details.shipping_address.map((item, i) => (
                            <Option key={i} value={item.addr_id} className="lh-normal">
                              {(item.title && item.title + ' ') +
                                item.line1 +
                                ' ' +
                                item.line2 +
                                ' ' +
                                item.city +
                                (item.state &&
                                  item.state !== '97-OTHER TERRITORY' &&
                                  item.state !== 'OTHERTERRITORY' &&
                                  (item.city ? ', ' : '') + item.state) +
                                (item.pincode &&
                                  item.pincode !== 'None' &&
                                  (item.city || item.state ? ', ' : '') + item.pincode)}
                            </Option>
                          ))}
                        </Select>
                      </span>
                    </div>
                  )}
              </div>
            </div>

            {/* <Card
              size="document"
              bordered={false}
              className="border-radius-small bg-card-azure card-shadow mb-4 mt-2"
            >
              <div className="row">
                {this.state.payment_status == 'pending' &&
                  this.state.from_document_type != 'online_order' && (
                    <div className="col-12 col-lg-4 col-md-6">
                      <h6 className="">
                        <span className="mr-1">
                          Select {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}
                        </span>
                        <span className="mr-3">
                          <InfoIcon
                            text={
                              <span>
                                <span>
                                  Search your existing customers using the search box below.
                                </span>
                                <br />
                                <span>
                                  Please click on <strong>Add New {this.state.party_type}</strong>{' '}
                                  to add a new customer.
                                </span>
                              </span>
                            }
                          />
                        </span>
                        <Button
                          type="outline"
                          onClick={() =>
                            this.state.party_type == 'customer'
                              ? this.customer?.onAdd(this.state.customerName)
                              : this.vendor?.onAdd(this.state.customerName)
                          }
                          className="font-weight-bold xsmall hidden sm:inline"
                        >
                          <i className="fa-solid fa-circle-plus mr-1"></i>
                          Add New {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}
                        </Button>
                      </h6>
                      <span>
                        <AutoComplete
                          options={this.state.autocompleteData}
                          className="mr-2 pb-2"
                          autoFocus
                          style={{ width: '100%', height: '32px' }}
                          dropdownMenuStyle={{ top: 18 }}
                          popupMatchSelectWidth={640}
                          trigger="hover"
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              if (
                                e.target.value != '' &&
                                this.state.autocompleteData?.length == 0
                              ) {
                                // open add customer modal
                                this.state.party_type == 'customer'
                                  ? this.customer?.onAdd(this.state.customerName)
                                  : this.vendor?.onAdd(this.state.customerName)
                              }
                            }
                          }}
                          dropdownRender={menu => (
                            <>
                              {menu}
                              <div className="bg-gray-75">
                                <Button
                                  block
                                  size=""
                                  type="link-2"
                                  className="font-weight-bolder text-left my-2 arrow-transition"
                                  onClick={() =>
                                    this.state.party_type == 'customer'
                                      ? this.customer?.onAdd(this.state.customerName)
                                      : this.vendor?.onAdd(this.state.customerName)
                                  }
                                >
                                  <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                  Add New
                                  {this.state.party_type == 'customer' ? ' Customer' : ' Vendor'}
                                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                </Button>
                              </div>
                            </>
                          )}
                          notFoundContent={
                            <>
                              <div
                                className="px-4 py-2 text-gray-500 font-weight"
                                onClick={() =>
                                  this.state.party_type == 'customer'
                                    ? this.customer.onAdd(this.state.customerName)
                                    : this.vendor.onAdd(this.state.customerName)
                                }
                              >
                                <span className="font-weight-bold">No search results found</span>
                                <p className="mb-0">
                                  Please try again with a different search query or{' '}
                                </p>
                              </div>
                            </>
                          }
                          onSelect={
                            this.state.party_type == 'customer'
                              ? this.onCustomerSelect
                              : this.onVendorSelect
                          }
                          onSearch={this.onCustomerSearch}
                          value={this.state.customerName}
                          ref={this.inputEl}
                          prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                          onPopupScroll={this.handleScroll}
                          id={'addParty'}
                        >
                          <Input
                            prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                            placeholder={`Search your ${
                              this.state.party_type === 'customer' ? 'customers' : 'vendors'
                            } by company, ${
                              this.props?.user?.selectedCompany?.tax_id_label
                            }, tags...`}
                          />
                        </AutoComplete>

                        {this.state.customerData.map((itemTag, i) => (
                          <Popover
                            placement="bottomLeft"
                            title={
                              <div>
                                <span className="block font-weight-bold text-center">
                                  {this.state.customerData[i]['notes']}
                                </span>
                                <span className="block font-weight-bold text-center">
                                  {this.state.customerData[i]['company_name']}
                                </span>
                                <span className="block font-weight-bold text-center">
                                  {this.state.customerData[i]['gstin']}
                                </span>
                                <span className="block font-weight-bold text-center">
                                  {this.state.customerData[i]['billing']?.state}
                                </span>
                                {this.props.permissions.ledgers == 1 && (
                                  <Button
                                    onClick={e => {
                                      e.stopPropagation()

                                      this.ledger.showModal({
                                        ...this.state.customerData[i],
                                        id: this.state.customerData[i]['id'],
                                        type: this.state.party_type,
                                      })
                                    }}
                                    className="mt-2 mb-1"
                                    type="check-ledger"
                                    size="small"
                                    shape="round"
                                  >
                                    <span className="font-weight-bold">
                                      Check <FormattedMessage id="button.ledger" />
                                    </span>
                                  </Button>
                                )}
                              </div>
                            }
                          >
                            <Tag
                              key={i}
                              closable
                              closeIcon={<i className="fa-solid fa-xmark fa-lg ml-1"></i>}
                              onClose={e => this.tagDelete(e, itemTag)}
                              onClick={e => {
                                let has_permission = this.props.permissions[
                                  this.state.party_type + '_edit'
                                ]
                                if (has_permission == 1) {
                                  this.state.party_type == 'customer'
                                    ? this.customer.onEdit(itemTag.id)
                                    : this.vendor.onFill({ vendor_id: itemTag.id })
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                              className="tag-large mt-2"
                            >
                              <span className="font-weight-bolder">{itemTag.notes}</span>
                            </Tag>
                          </Popover>
                        ))}
                      </span>
                    </div>
                  )}

                {((this.state.edit && this.state.payment_status != 'pending') ||
                  this.state.from_document_type == 'online_order') && (
                  <div className="col-12 col-lg-4 col-md-6">
                    <h6 className="mb-2">Customer</h6>
                    <span className="inline-flex" style={{ width: '100%' }}>
                      <Input
                        style={{ width: '100%', borderRadius: '5px' }}
                        placeholder="Customer Name"
                        value={this.state.name}
                        disabled
                      />
                    </span>
                  </div>
                )}

                {this.state.document_type != 'subscription' && (
                  <div className="col-6 col-lg-2 col-md-6">
                    <h6 className="mb-2">{this.state.document_title} Date</h6>
                    <DatePicker
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      allowClear={false}
                      format={this.dateFormat}
                      value={dayjs(this.state.document_date, this.dateFormat)}
                      onChange={this.onDocumentDateChange}
                    />
                  </div>
                )}

                {this.state.document_type != 'subscription' && (
                  <div className="col-6 col-lg-2 col-md-6">
                    <h6 className="">
                      Due Date
                      <span>
                        <InfoIcon text=" The invoice due date is the date on which you expect to receive payment from your customer." />
                      </span>
                    </h6>
                    <DatePicker
                      format={this.dateFormat}
                      allowClear={false}
                      showToday={false}
                      value={dayjs(this.state.due_date, this.dateFormat)}
                      renderExtraFooter={() => dateFooter}
                      size="default"
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      disabledDate={this.disabledDate}
                      onChange={this.onDueDateChange}
                    />
                  </div>
                )}

                <div className="col-12 col-lg-4 col-md-6">
                  <h6 className="">
                    Reference
                    <InfoIcon text="Reference is commonly used to save information like Purchase Order Number, Eway Bill Number, Sales Person names, Shipment Number etc..." />
                  </h6>
                  <TextArea
                    rows={1}
                    type="text"
                    className="pr-4 mr-2"
                    placeholder="Any text, PO Number etc... (Optional)"
                    name="reference"
                    style={{ width: '100%', borderRadius: '5px' }}
                    onChange={e => this.onChange(e)}
                    value={this.state.reference}
                  />
                </div>

                {!dispatch_to_doc_types.includes(this.state.document_type) &&
                  this.state.customer_details.shipping_address.length > 0 &&
                  this.state.customerData.length == 1 &&
                  this.state.document_type != 'purchase' &&
                  this.state.document_type != 'purchase_order' &&
                  this.state.document_type != 'purchase_return' && (
                    <div className="col-12 col-lg-4 col-md-6 mb-2 mt-2">
                      <h6>Select Customer Shipping Address</h6>
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        placeholder="Select Customer Shipping Address"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        className="mb-2"
                        showSearch
                        allowClear
                        onChange={e => this.handleCustomerShippingAddressChange(e)}
                        value={
                          this.state.selected_customer_shipping_address
                            ? 'addr_id' in this.state.selected_customer_shipping_address
                              ? this.state.selected_customer_shipping_address.addr_id
                              : undefined
                            : undefined
                        }
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.customer_details.shipping_address.map((item, i) => (
                          <Option key={i} value={item.addr_id}>
                            {(item.title != '' ? item.title + ' ' : '') +
                              item.line1 +
                              ' ' +
                              item.line2 +
                              ' ' +
                              item.city +
                              ' ' +
                              (item.state == '97-OTHER TERRITORY' ? '' : item.state) +
                              ' ' +
                              item.pincode}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}

                {this.state.document_type == 'purchase' && (
                  <div className="col-12 col-lg-4 col-md-6 mt-2 mb-2">
                    <h6>Supplier Invoice Date</h6>
                    <DatePicker
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      allowClear={false}
                      format={this.dateFormat}
                      value={dayjs(this.state.supplier_invoice_date, this.dateFormat)}
                      onChange={this.onSupplierInvoiceDateChange}
                    />
                  </div>
                )}

                {this.state.document_type == 'purchase' && (
                  <div className="col-12 col-lg-4 col-md-6 mt-2 mb-2">
                    <h6 className="">Supplier Invoice Serial No.</h6>
                    <TextArea
                      rows={1}
                      type="text"
                      className="pr-4 mr-2"
                      placeholder={
                        this.props.invoice_settings?.supplier_invoice_serial_number
                          ? 'Supplier Invoice Serial No.'
                          : '(Optional)'
                      }
                      name="supplier_invoice_serial_number"
                      style={{ width: '100%', borderRadius: '5px' }}
                      onChange={e => this.onChange(e)}
                      value={this.state.supplier_invoice_serial_number}
                    />
                  </div>
                )}
              </div>
            </Card> */}

            {this.state.custom_headers.length > 0 && this.state.document_type != 'online_order' && (
              <div id="document_custom_headers" className="mb-8">
                <SectionHeader title="Custom Headers" />
                {/* @ts-ignore */}
                <Card size="document" bordered={false} className="border-radius-small bg-gray-75">
                  <div className="grid grid-cols-4 gap-3 justify-between">
                    {this.state.custom_headers.map((item, index) => (
                      <span className="mb-2">
                        <h6 className="">{item.label}</h6>
                        {item.field_type == 'number' ? (
                          <InputNumber
                            className="w-full"
                            value={item.value}
                            status={item.is_error ? 'error' : ''}
                            placeholder={item.label}
                            onChange={e => {
                              // console.log(e)
                              this.onCustomHeaderChange(e, item.header_id)
                            }}
                          />
                        ) : item.field_type == 'date' ? (
                          <DatePicker
                            className="w-full"
                            placeholder={item.label}
                            status={item.is_error ? 'error' : ''}
                            value={
                              item.value != '' && this.dateFormatRegex.test(item.value)
                                ? dayjs(item.value, 'DD-MM-YYYY')
                                : undefined
                            }
                            format={this.dateFormat}
                            onChange={value => {
                              this.onCustomHeaderChange(
                                value == null ? '' : value.format(this.dateFormat),
                                item.header_id,
                              )
                            }}
                          />
                        ) : (
                          <AutoComplete
                            popupClassName="certain-category-search-dropdown"
                            style={{
                              width: '100%',
                            }}
                            options={
                              this.renderCustomHeaderOptions(item.header_id).length > 0
                                ? [...this.renderCustomHeaderOptions(item.header_id)]
                                : []
                            }
                            value={item.value}
                            onChange={e => {
                              this.onCustomHeaderChange(e, item.header_id)
                            }}
                            filterOption={(input, option) =>
                              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            dropdownRender={menu => (
                              <>
                                <div className="d-flex justify-content-between p-2 bg-gray-50 text-gray-500 font-size-14">
                                  Recently Used
                                </div>
                                {menu}
                              </>
                            )}
                          >
                            <TextArea
                              rows={1}
                              className="w-full"
                              placeholder={item.label}
                              status={item.is_error ? 'error' : ''}
                              name={item.name}
                              onChange={e => {
                                this.onCustomHeaderChange(e.target.value, item.header_id)
                              }}
                              onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  this.onCustomHeaderChange(e.target.value + '\n', item.header_id)
                                }
                              }}
                              value={item.value}
                            />
                          </AutoComplete>
                        )}
                        {item.is_error && (
                          <span className="text-danger font-size-12">
                            Please enter {item.label}
                          </span>
                        )}
                      </span>
                    ))}
                  </div>
                </Card>
              </div>
            )}
            {store.get('paid') == 0 && this.state.custom_headers.length == 0 && (
              <div className="mt-3 mb-8">
                <SectionHeader title="Custom Headers" />
                {this.state.suggested_fields.map((item: any, index: number) => (
                  <Tag
                    icon={<i className="fa fa-plus mr-2 text-primary" />}
                    className="cursor-pointer tag-large bg-white"
                    onClick={() => this.subscriptionPlanRef.current.openModal('custom_fields')}
                  >
                    {item.name}
                  </Tag>
                ))}
              </div>
            )}
            {/* subscriptions */}
            {this.state.is_subscription == 1 && (
              <>
                <SectionHeader
                  title="Set your recurring Schedule"
                  right={true}
                  rightText={
                    <div className="flex items-center ml-auto hidden sm:flex space-x-4 font-size-14">
                      <a_link
                        onClick={e => {
                          e.stopPropagation()
                        }}
                        className="font-weight-medium  flex items-center space-x-2"
                      >
                        <span>Send SMS</span>
                        <Switch
                          size="small"
                          checked={this.state.send_sms == 1}
                          onChange={checked => this.setState({ send_sms: checked ? 1 : 0 })}
                        />
                      </a_link>
                      <a_link
                        onClick={e => {
                          e.stopPropagation()
                        }}
                        className="font-weight-medium flex items-center space-x-2 ml-4 pr-2"
                      >
                        <span>Send Email</span>
                        <Switch
                          size="small"
                          checked={this.state.send_email == 1}
                          onChange={checked => this.setState({ send_email: checked ? 1 : 0 })}
                        />
                      </a_link>
                    </div>
                  }
                />
                <Card
                  //  @ts-ignore
                  size="document"
                  bordered={false}
                  className="border-radius-small bg-gray-50 mb-8"
                >
                  <div className="grid grid-cols-4 gap-4 justify-between">
                    <div className="">
                      <h6>Start Date</h6>
                      <DatePicker
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                          width: '100%',
                        }}
                        allowClear={false}
                        format={this.dateFormat}
                        value={dayjs(this.state.subscription_start_date, this.dateFormat)}
                        onChange={(date, string) => {
                          this.onSubscriptionDateChange(date, string, 'start')
                        }}
                        disabled={this.state.invoice_count > 0}
                      />
                    </div>
                    <div className="">
                      <h6>End Date</h6>
                      <DatePicker
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                          width: '100%',
                        }}
                        disabledDate={this.disabledEndDate}
                        allowClear={false}
                        format={this.dateFormat}
                        value={dayjs(this.state.subscription_end_date, this.dateFormat)}
                        onChange={(date, string) => {
                          this.onSubscriptionDateChange(date, string, 'end')
                        }}
                        renderExtraFooter={() => subscriptionDateFooter}
                        disabled={this.state.invoice_count > 0}
                      />
                    </div>

                    <div className="">
                      <h6>Repeat Type</h6>
                      <span className="select-borderless">
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                          onChange={value => this.setState({ repeatType: value })}
                          defaultValue={this.state.repeatType}
                          value={this.state.repeatType}
                          className="w-full"
                          disabled={this.state.invoice_count > 0}
                        >
                          <Option key="1" value="days">
                            Days
                          </Option>
                          <Option key="2" value="weeks">
                            Weeks
                          </Option>
                          <Option key="3" value="months">
                            Months
                          </Option>
                          <Option key="4" value="years">
                            Years
                          </Option>
                        </Select>
                      </span>
                    </div>
                    <div className="">
                      <h6>Repeat Every</h6>
                      <Input
                        type="integer"
                        prefix="Every"
                        value={this.state.repeat_every}
                        disabled={this.state.invoice_count > 0}
                        suffix={
                          <span className="select-borderless">
                            <Select
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              onChange={value => this.setState({ repeatType: value })}
                              defaultValue={this.state.repeatType}
                              value={this.state.repeatType}
                              className="w-full"
                              disabled={this.state.invoice_count > 0}
                            >
                              <Option key="1" value="days">
                                Days
                              </Option>
                              <Option key="2" value="weeks">
                                Weeks
                              </Option>
                              <Option key="3" value="months">
                                Months
                              </Option>
                              <Option key="4" value="years">
                                Years
                              </Option>
                            </Select>
                          </span>
                        }
                        onChange={e =>
                          this.setState({
                            repeat_every: e.target.value ? Number(e.target.value) : 0,
                          })
                        }
                      />
                    </div>
                    <div className="w-4/12 hidden">
                      <h6>Document Type</h6>
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        onChange={value => this.setState({ subscription_document_type: value })}
                        defaultValue={this.state.subscription_document_type}
                        className="w-full"
                      >
                        <Option key="1" value="invoice">
                          Invoice
                        </Option>
                      </Select>
                    </div>
                    {/* <div className="w-2/12">
                      <h6>Send E-mail</h6>
                      <Switch
                        checked={this.state.send_email}
                        onChange={checked => this.setState({ send_email: checked })}
                      />
                    </div>
                    <div className="w-2/12">
                      <h6>Send SMS</h6>
                      <Switch
                        checked={this.state.send_sms}
                        onChange={checked => this.setState({ send_sms: checked })}
                      />
                    </div> */}
                  </div>
                </Card>
              </>
            )}

            {this.state.export_invoice == 1 && (
              <div className="flex mb-4">
                <div className="flex-grow-1 mr-6 w-2/6">
                  <SectionHeader
                    title={
                      <span>
                        {this.state.document_type == 'purchase' ||
                        this.state.document_type == 'purchase_order'
                          ? 'Import Details'
                          : 'Export Details'}
                        <i className="fa-sharp fa-solid fa-plane-departure ml-2"></i>
                      </span>
                    }
                  />
                  <Card
                    //  @ts-ignore
                    size="document"
                    bordered={false}
                    className="border-radius-small bg-gray-75 card-shadow"
                  >
                    <div className="flex flex-row">
                      <span className="w-full mr-3">
                        <h6 className="text-gray-500">
                          {this.state.document_type == 'purchase' ||
                          this.state.document_type == 'purchase_order'
                            ? 'Select Import Type'
                            : 'Select Export Type'}
                        </h6>
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                          style={{ width: '100%' }}
                          placeholder={
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_order'
                              ? 'Select Import Type'
                              : 'Select Export Type'
                          }
                          disabled={
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_order'
                          }
                          onChange={e => {
                            if (
                              this.state.export_invoice &&
                              this.state.document_title == 'Bill of Supply'
                            ) {
                              if (e == 'Export with IGST' || e == 'SEZ with IGST Payment') {
                                message.error(`${e} is not allowed with Bill of Supply`)
                                return
                              }
                            }
                            if (this.state.customerData?.some(val => val?.gstin?.length)) {
                              if (
                                (e == 'Export under bond/LUT' || e == 'Export with IGST') &&
                                !this.state.edit
                              ) {
                                message.error(`${e} is not allowed with GSTIN Customer`)
                                return
                              }
                            }
                            if (e.includes('SEZ')) {
                              this.setState(
                                {
                                  conversion_factor: 1,
                                  country: 75,
                                  currency_symbol: `${this.props.user?.selectedCompany?.currency_symbol}`,
                                  currency: `${this.props.user?.selectedCompany?.currency_code}`,
                                  with_tax: e.includes('LUT') || e.includes('without') ? 0 : 1,
                                },
                                () => {
                                  this.onWithTaxChange()
                                },
                              )
                            }
                            this.setState(
                              {
                                export_type: e,
                                with_tax: e.includes('LUT') || e.includes('without') ? 0 : 1,
                              },
                              () => {
                                this.onWithTaxChange()
                              },
                            )
                          }}
                          value={this.state.export_type}
                        >
                          <Option value="Export under bond/LUT">Export under bond/LUT</Option>
                          <Option value="Export with IGST">Export with IGST</Option>
                          <Option value="SEZ with IGST Payment">SEZ with IGST Payment</Option>
                          <Option value="SEZ without IGST Payment">SEZ without IGST Payment</Option>
                          <Option value="Deemed Export">Deemed Export</Option>
                          {this.state.document_type == 'purchase' && (
                            <Option value="Import">Import</Option>
                          )}
                        </Select>
                      </span>

                      <span className="w-full">
                        <h6 className="text-gray-500">Country of Supply</h6>
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                          showSearch
                          disabled={this.state.export_type?.includes('SEZ')}
                          placeholder="Select Country"
                          optionFilterProp="children"
                          style={{ width: '100%' }}
                          value={this.state.country}
                          defaultValue={178}
                          filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={e =>
                            this.setState({
                              country: e,
                              currency: this.props?.countries.filter(
                                (item: any) => item.country_id == e,
                              )[0].currency_code,
                              currency_symbol: this.props?.countries.filter(
                                (item: any) => item.country_id == e,
                              )[0].currency_symbol,
                            })
                          }
                        >
                          {this.props?.countries.map((item: any, i: number) => (
                            <Option key={i} value={item.country_id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </span>
                    </div>
                  </Card>
                </div>

                {this.state.export_invoice == 1 &&
                  this.state.document_type != 'purchase' &&
                  !this.state.export_type?.includes('SEZ') && (
                    <div className="flex-grow-1 w-4/6">
                      <SectionHeader title="Export Shipping Details (Optional)" />
                      <Card
                        //  @ts-ignore
                        size="document"
                        bordered={false}
                        className="border-radius-small bg-gray-75 card-shadow"
                      >
                        <div className="flex flex-row">
                          <span className="w-4/12 mr-3">
                            <h6 className="text-gray-500">Shipping Bill Date</h6>
                            <DatePicker
                              style={{
                                borderRadius: '5px',
                                cursor: 'pointer',
                                width: '100%',
                              }}
                              onChange={this.onShippingBillDateChange}
                              allowClear={true}
                              format={this.dateFormat}
                              value={
                                this.state.shipping_bill_date != ''
                                  ? dayjs(this.state.shipping_bill_date, this.dateFormat)
                                  : null
                              }
                            />
                          </span>

                          <span className="w-4/12 mr-3">
                            <h6 className="text-gray-500">Shipping bill #</h6>
                            <Input
                              placeholder="Optional"
                              value={this.state.shipping_bill_number}
                              onChange={e => {
                                this.setState({ shipping_bill_number: e.target.value })
                              }}
                              style={{ width: '100%' }}
                            />
                          </span>

                          <span className="w-4/12">
                            <h6 className="text-gray-500">Shipping Port Code</h6>
                            <Input
                              placeholder="Optional"
                              value={this.state.shipping_port_code}
                              onChange={e => {
                                this.setState({ shipping_port_code: e.target.value })
                              }}
                              style={{ width: '100%' }}
                            />
                          </span>
                        </div>
                      </Card>
                    </div>
                  )}
              </div>
            )}

            {(this.state.export_invoice == 1 || this.state.is_multi_currency == 1) &&
              !this.state.export_type?.includes('SEZ') && (
                <div className="flex mb-8">
                  <div className="mr-4 w-1/2">
                    <SectionHeader
                      title={
                        <span>
                          Currency Details
                          <i className="fa-solid fa-circle-dollar ml-2"></i>
                        </span>
                      }
                    />
                    <Card
                      //  @ts-ignore
                      size="document"
                      bordered={false}
                      className="border-radius-small bg-card-forest card-shadow mb-4"
                    >
                      <div className="flex flex-row">
                        <span className="w-full mr-3">
                          <Select
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            showSearch
                            disabled={this.state.export_type?.includes('SEZ')}
                            placeholder="Select Currency"
                            optionFilterProp="children"
                            style={{ width: '100%' }}
                            value={this.state.currency}
                            // defaultValue="177"
                            filterOption={(input: any, option: any) =>
                              option.props.children.props.children[0]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={e => {
                              const symbol = this.props?.countries.filter(
                                (item: any) => item.currency_code === e,
                              )[0].currency_symbol

                              this.setState({ currency: e, currency_symbol: symbol })
                            }}
                          >
                            {this.props?.currencies?.map((item: any, i: number) => (
                              <Option key={i} value={item.currency_code}>
                                <div>
                                  {item.currency_code}
                                  <span className="float-right font-weight-bold">
                                    {item.currency_symbol}
                                  </span>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </span>

                        <span className="w-full">
                          <InputNumber
                            style={{
                              width: '100%',
                            }}
                            prefix={'Conversion rate 1 ' + this.state.currency_symbol + ' = '}
                            disabled={this.state.export_type?.includes('SEZ')}
                            required={this.state.is_multi_currency == 1}
                            value={
                              this.state.export_type?.includes('SEZ')
                                ? 1
                                : this.state.conversion_factor
                            }
                            onBlur={(e: any) => {
                              if (e.target.value == '') {
                                e = 1
                                this.setState({ conversion_factor: parseFloat(e) }, () =>
                                  this.onExportChange(),
                                )
                              }
                            }}
                            onChange={(e: any) => {
                              if (this.state.export_type?.includes('SEZ')) {
                                this.setState({ conversion_factor: 1 }, () => this.onExportChange())
                              } else {
                                // if (e == null) {
                                //   e = 1
                                // }
                                this.setState({ conversion_factor: parseFloat(e) }, () =>
                                  this.onExportChange(),
                                )
                              }
                            }}
                            suffix="INR"
                          />
                        </span>
                      </div>
                    </Card>
                  </div>
                </div>
              )}

            <DndProvider backend={HTML5Backend}>
              <div className="">
                {![481332, 284200, 28331, 1131355, 17141, 96125, 340067].includes(
                  this.props.company_details.company_id,
                ) ||
                ([481332, 284200, 28331, 1131355, 17141, 96125, 340067].includes(
                  this.props.company_details.company_id,
                ) &&
                  this.props.document_settings?.enable_item_headers) ? (
                  <EnhancedTable
                    onRef={(ref: any) => (this.table = ref)}
                    dataUpdate={this.dataUpdate}
                    itemHeadersUpdate={this.itemHeadersUpdate}
                    data={this.state.dataSource}
                    isConversionTracked={this.state.isConversionTracked}
                    item_headers={this.state.item_headers}
                    edit={this.state.edit}
                    convert={this.state.convert}
                    convertFrom={this.state.from_document_type}
                    convertTo={this.state.to_document_type}
                    duplicate={this.state.duplicate}
                    showDescription={this.state.description}
                    onChangeShowDescription={(e: any) => {
                      this.setState({ description: e })
                    }}
                    items_reverse={store.get('items_reverse') || this.state.items_reverse}
                    selectedPriceList={this.state.selectedPriceList || 0}
                    updateExtraCharges={this.updateExtraCharges}
                    custom_additional_charges={this.state.custom_additional_charges}
                    document_custom_additional_charges={
                      this.state.document_custom_additional_charges
                    }
                    country={
                      this.props?.countries.filter(
                        (item: any) => item.country_id === this.state.country,
                      )[0]
                    }
                    conversion_factor={this.state.conversion_factor}
                    is_export={this.state.export_invoice}
                    is_multi_currency={this.state.is_multi_currency}
                    with_tax={this.state.with_tax}
                    currency_symbol={this.state.currency_symbol}
                    export_type={this.state.export_type}
                    product_categories={this.props.product_categories}
                    discount_type={this.state.document_discount_type}
                    document_settings={this.props.document_settings}
                    onChangeWithTax={this.onChangeWithTax}
                    document_type={this.state.document_type}
                    loadingSave={this.state.loadingSave}
                    old_items_before_edit={this.state.old_items_before_edit}
                    warehouse_id={this.state.warehouse_id}
                    onAPICalled={() => {
                      this.fetchDetails()
                    }}
                    onUpdateItems={(value: any, origin: string, updated_product_id: number) =>
                      this.onPriceListChange(value, origin, updated_product_id)
                    }
                    // For Analytics
                    // PartyData={{
                    //   ...this.state.customer_details,
                    //   party_type: this.state.party_type,
                    //   id: this.state.customer_details[this.state.party_type + '_id'],
                    // }}
                    PartyData={{
                      ...this.state.customerData[this.state.customerData.length - 1],
                      party_type: this.state.party_type,
                      menu_name: this.state.list_name,
                    }}
                    customerDiscount={this.state.customerDiscount}
                    allowWarehouses={this.allowWarehouses}
                    movement={this.state.movement}
                    onWarehouseChange={this.onWarehouseChange}
                    price_lists={this.state.price_lists}
                    priceListChangeHandler={this.priceListChangeHandler}
                    onDiscountTypeChange={this.onDiscountTypeChange}
                    discount_type_items={discount_type_items}
                    getItemSuggestionsRef={this.getItemSuggestionsRef}
                    item_header_suggestions={this.state.item_header_suggestions}
                    menu_name={this.state.list_name}
                  />
                ) : (
                  <AgEnhancedTable
                    onRef={(ref: any) => (this.table = ref)}
                    dataUpdate={this.dataUpdate}
                    itemHeadersUpdate={this.itemHeadersUpdate}
                    data={this.state.dataSource}
                    isConversionTracked={this.state.isConversionTracked}
                    item_headers={this.state.item_headers}
                    edit={this.state.edit}
                    convert={this.state.convert}
                    convertFrom={this.state.from_document_type}
                    convertTo={this.state.to_document_type}
                    duplicate={this.state.duplicate}
                    showDescription={this.state.description}
                    onChangeShowDescription={(e: any) => {
                      this.setState({ description: e })
                    }}
                    items_reverse={store.get('items_reverse') || this.state.items_reverse}
                    selectedPriceList={this.state.selectedPriceList || 0}
                    updateExtraCharges={this.updateExtraCharges}
                    custom_additional_charges={this.state.custom_additional_charges}
                    document_custom_additional_charges={
                      this.state.document_custom_additional_charges
                    }
                    country={
                      this.props?.countries.filter(
                        (item: any) => item.country_id === this.state.country,
                      )[0]
                    }
                    conversion_factor={this.state.conversion_factor}
                    is_export={this.state.export_invoice}
                    is_multi_currency={this.state.is_multi_currency}
                    with_tax={this.state.with_tax}
                    currency_symbol={this.state.currency_symbol}
                    export_type={this.state.export_type}
                    product_categories={this.props.product_categories}
                    discount_type={this.state.document_discount_type}
                    document_settings={this.props.document_settings}
                    onChangeWithTax={this.onChangeWithTax}
                    document_type={this.state.document_type}
                    loadingSave={this.state.loadingSave}
                    old_items_before_edit={this.state.old_items_before_edit}
                    warehouse_id={this.state.warehouse_id}
                    onAPICalled={() => {
                      this.fetchDetails()
                    }}
                    onUpdateItems={(value: any, origin: string, updated_product_id: number) =>
                      this.onPriceListChange(value, origin, updated_product_id)
                    }
                    // For Analytics
                    // PartyData={{
                    //   ...this.state.customer_details,
                    //   party_type: this.state.party_type,
                    //   id: this.state.customer_details[this.state.party_type + '_id'],
                    // }}
                    PartyData={{
                      ...this.state.customerData[this.state.customerData.length - 1],
                      party_type: this.state.party_type,
                      menu_name: this.state.list_name,
                    }}
                    customerDiscount={this.state.customerDiscount}
                    allowWarehouses={this.allowWarehouses}
                    movement={this.state.movement}
                    onWarehouseChange={this.onWarehouseChange}
                    price_lists={this.state.price_lists}
                    priceListChangeHandler={this.priceListChangeHandler}
                    onDiscountTypeChange={this.onDiscountTypeChange}
                    discount_type_items={discount_type_items}
                    getItemSuggestionsRef={this.getItemSuggestionsRef}
                    item_header_suggestions={this.state.item_header_suggestions}
                    menu_name={this.state.list_name}
                  />
                )}
              </div>
            </DndProvider>
            {/* </Card> */}

            <div className="flex flex-wrap mb-2">
              <div className="w-full md:w-1/2 pr-8">
                <SectionHeader title={<>Notes, Terms & more... </>} />
                <Collapse
                  accordion
                  className="bg-gray-50 collapse-small mb-8"
                  defaultActiveKey={'1'}
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-weight-bold">
                          Notes
                          <InfoIcon
                            className="ml-3"
                            text="For the same notes to reflect on all invoices, fill in the notes
                  in Settings --&gt; Notes and Terms --&gt; Customer notes"
                          />
                        </span>
                      </>
                    }
                    extra={
                      <>
                        {this.state.document_notes.length == 0 ? (
                          <Button
                            type="secondary"
                            size="small"
                            className="font-weight-bolder"
                            onClick={e => {
                              e.stopPropagation()

                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('new_notes')
                                : this.customNotesRef.current.showDrawer(
                                    this.state.document_type,
                                    1,
                                  )
                            }}
                          >
                            <i className="fa fa-plus mr-1" />
                            New Notes{store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        ) : (
                          <span>
                            <Select
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              style={{ width: '300px' }}
                              onChange={(e: any) => {
                                let notes = ''
                                if (!_.isNil(e)) {
                                  notes = this.state.document_notes[e].notes
                                }
                                this.setState({
                                  selected_note: e,
                                  notes,
                                })
                              }}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                              dropdownStyle={{ minWidth: '500px' }}
                              placement="bottomRight"
                              size={'small'}
                              placeholder="Select Notes"
                              className="widthres"
                              value={
                                this.state.document_notes.length == 0
                                  ? ''
                                  : this.state.selected_note
                              }
                              allowClear={true}
                              dropdownRender={menu => (
                                <>
                                  {menu}
                                  <div className="bg-gray-75">
                                    <Button
                                      block
                                      type="link-2"
                                      className="font-weight-bolder text-left my-2 arrow-transition"
                                      onClick={e => {
                                        e.stopPropagation()
                                        store.get('paid') == 0
                                          ? this.subscriptionPlanRef.current.openModal('new_notes')
                                          : this.customNotesRef.current.showDrawer(
                                              this.state.document_type,
                                              1,
                                            )
                                      }}
                                    >
                                      <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                      Add New Notes{store.get('paid') == 0 && <ProBadge />}
                                      <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                    </Button>
                                  </div>
                                </>
                              )}
                            >
                              {this.state.document_notes.map((item, index) => {
                                return (
                                  <Option key={index} value={index}>
                                    <span>
                                      <span className="font-weight-bold">{item.label}</span>
                                      <span className="text-gray-500 ml-2">{item.notes}</span>
                                    </span>
                                  </Option>
                                )
                              })}
                            </Select>
                          </span>
                        )}
                      </>
                    }
                    key="1"
                  >
                    <div className="mb-3">
                      <TextArea
                        placeholder="Enter your business Notes"
                        rows={5}
                        name="notes"
                        value={this.state.notes}
                        onChange={e => this.onChange(e)}
                        variant="borderless"
                      />
                      {
                        <div className="mt-2">
                          <AIButton
                            keyProp="notes"
                            context={`Generating standard notes for ${this.state.document_type}`}
                            initialText={this.state.notes}
                            featureCategory="notes"
                            aimodalRef={this.ai}
                          />
                        </div>
                      }

                      {this.state.convert && (
                        <span className="mt-1 font-size-14 text-gray-500">
                          To Show {this.state.from_document_type.replace('_', ' ')}{' '}
                          {this.state.invoiceData?.serial_number} notes instead of{' '}
                          {this.state.document_title} notes in document conversion
                          <Popconfirm
                            title="Are you sure want to change notes? Current Changes will be lost"
                            onConfirm={() =>
                              this.setState({ notes: this.state.invoiceData?.notes })
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="small" className="ml-2">
                              Click Here
                            </Button>
                          </Popconfirm>
                        </span>
                      )}
                    </div>
                    {this.state.isNotesEntered &&
                      !this.state.edit &&
                      this.state.notes !== '' &&
                      this.state.document_notes.every(item => this.state.notes !== item.notes) && (
                        <>
                          {this.state.document_notes.length === 0 ? (
                            <span className="mt-2 text-gray-400">
                              To use the notes in the future{'   '}
                              <a
                                className="text-primary"
                                onClick={() => this.handleSaveNotesAndTerms('notes')}
                              >
                                Click here
                              </a>
                            </span>
                          ) : (
                            <span className="mt-2 text-gray-400">
                              To update notes for selected label{'    '}
                              <a
                                className="text-primary"
                                type="link"
                                onClick={() => this.handleSaveNotesAndTerms('notes')}
                              >
                                {this.state.isNotesLoading ? 'Updating your Notes' : 'Click here'}
                              </a>
                            </span>
                          )}
                        </>
                      )}
                    {showNotes && this.state.isNotesEntered && (
                      <div className="flex flex-row my-3">
                        <span className="flex flex-col">
                          <Input
                            placeholder="Enter Label"
                            value={notesLabel}
                            onChange={e => this.handleLabelChange('notes', e.target.value)}
                            style={{ width: '300px', marginRight: '10px' }}
                          />
                          {this.state.showNotesLabelError && (
                            <span className="text-danger mt-1 font-size-14">
                              Label is mandatory
                            </span>
                          )}
                        </span>
                        <Button
                          type="primary"
                          onClick={() => this.handleSaveNotesAndTerms('notes', true)}
                          loading={this.state.isNotesLoading}
                        >
                          {this.state.isNotesLoading ? 'Saving Your Changes' : 'Save'}
                        </Button>
                      </div>
                    )}
                  </Panel>
                </Collapse>
                <Collapse
                  accordion
                  className="bg-gray-50 collapse-small mb-8"
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-weight-bold">
                          Terms & Conditions{' '}
                          <InfoIcon
                            text="For the same terms to reflect on all invoices, fill in the terms
                          in Settings --&gt; Notes and Terms --&gt; Customer Terms and
                          Conditions"
                          />
                        </span>
                      </>
                    }
                    extra={
                      <>
                        {this.state.document_terms.length == 0 ? (
                          <Button
                            type="secondary"
                            size="small"
                            className="font-weight-bolder"
                            onClick={(e: any) => {
                              e.stopPropagation()
                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('new_terms')
                                : this.customNotesRef.current.showDrawer(
                                    this.state.document_type,
                                    0,
                                  )
                            }}
                          >
                            <i className="fa fa-plus mr-1" />
                            New Terms{store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        ) : (
                          <span>
                            <Select
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              style={{ width: '300px' }}
                              onChange={(e: any) => {
                                let terms = ''
                                if (!_.isNil(e)) {
                                  terms = this.state.document_terms[e].notes
                                }

                                this.setState({
                                  selected_term: e,
                                  terms,
                                })
                              }}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                              size={'small'}
                              placeholder="Select Terms"
                              className="widthres"
                              dropdownStyle={{ minWidth: '500px' }}
                              placement="bottomRight"
                              value={
                                this.state.document_terms.length == 0
                                  ? ''
                                  : this.state.selected_term
                              }
                              allowClear={true}
                              dropdownRender={menu => (
                                <>
                                  {menu}
                                  <div className="bg-gray-75">
                                    <Button
                                      block
                                      type="link-2"
                                      className="font-weight-bolder text-left my-2 arrow-transition"
                                      onClick={e => {
                                        e.stopPropagation()
                                        store.get('paid') == 0
                                          ? this.subscriptionPlanRef.current.openModal('new_terms')
                                          : this.customNotesRef.current.showDrawer(
                                              this.state.document_type,
                                              0,
                                            )
                                      }}
                                    >
                                      <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                      Add New Terms{store.get('paid') == 0 && <ProBadge />}
                                      <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                    </Button>
                                  </div>
                                </>
                              )}
                            >
                              {this.state.document_terms.map((item, index) => {
                                return (
                                  <Option key={index} value={index}>
                                    <span>
                                      <span className="font-weight-bold">{item.label}</span>
                                      <span className="text-gray-500 ml-2">{item.notes}</span>
                                    </span>
                                  </Option>
                                )
                              })}
                            </Select>
                          </span>
                        )}
                      </>
                    }
                    key="2"
                  >
                    <div className="mb-3">
                      <TextArea
                        rows={5}
                        placeholder="Enter your business Terms and Conditions"
                        value={this.state.terms}
                        name="terms"
                        onChange={e => this.onChange(e)}
                        variant="borderless"
                      />

                      <div className="mt-2">
                        <AIButton
                          keyProp="terms"
                          context={`Generating standard terms for ${this.state.document_type}`}
                          initialText={this.state.terms}
                          featureCategory="terms"
                          aimodalRef={this.ai}
                        />
                      </div>

                      {this.state.convert && (
                        <span className="mt-1 font-size-14 text-gray-500">
                          To Show {this.state.from_document_type.replace('_', ' ')}{' '}
                          {this.state.invoiceData?.serial_number} terms instead of{' '}
                          {this.state.document_title} terms in document conversion
                          <Popconfirm
                            title="Are you sure want to change terms? Current Changes will be lost"
                            onConfirm={() =>
                              this.setState({ terms: this.state.invoiceData?.terms || '' })
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="small" className="ml-2">
                              Click Here
                            </Button>
                          </Popconfirm>
                        </span>
                      )}
                    </div>
                    {this.state.isTermsEntered &&
                      !this.state.edit &&
                      this.state.terms !== '' &&
                      this.state.document_terms.every(item => this.state.terms !== item.notes) && (
                        <>
                          {this.state.document_terms.length === 0 ? (
                            <span className="mt-2 text-gray-400">
                              To use the terms in the future{'   '}
                              <a
                                className="text-primary"
                                onClick={() => this.handleSaveNotesAndTerms('terms')}
                              >
                                click here
                              </a>
                            </span>
                          ) : (
                            <span className="mt-2 text-gray-400">
                              To update terms for selected label{'   '}
                              <a
                                className="text-primary"
                                // loading={this.state.isTermsLoading}
                                onClick={() => this.handleSaveNotesAndTerms('terms')}
                              >
                                {this.state.isTermsLoading ? 'Updating Your Changes' : 'Click here'}
                              </a>
                            </span>
                          )}
                        </>
                      )}
                    {showTerms && this.state.isTermsEntered && (
                      <div className="flex flex-row my-3">
                        <span className="flex flex-col">
                          <Input
                            placeholder="Enter Label"
                            value={termsLabel}
                            onChange={e => this.handleLabelChange('terms', e.target.value)}
                            style={{ width: '300px', marginRight: '10px' }}
                          />
                          {this.state.showTermsLabelError && (
                            <span className="text-danger mt-1 font-size-14">
                              Label is mandatory
                            </span>
                          )}
                        </span>
                        <Button
                          type="primary"
                          onClick={() => this.handleSaveNotesAndTerms('terms', true)}
                          loading={this.state.isTermsLoading}
                        >
                          {this.state.isTermsLoading ? 'Saving Your Changes' : 'Save'}
                        </Button>
                      </div>
                    )}
                  </Panel>
                </Collapse>

                {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                  (this.state.document_type == 'invoice' ||
                    (this.state.document_type == 'purchase' && !this.state.export_invoice)) &&
                  this.state.with_tax == 1 && (
                    <div className="flex items-center justify-start">
                      <span className="mt-3 mb-3">
                        <Switch
                          className="mr-2"
                          size="small"
                          checked={this.state.rcm == 1}
                          onChange={this.onRCMChange}
                        />
                        <span className="text-gray-700">
                          Reverse Charge Mechanism applicable? <BetaBadge />
                        </span>
                      </span>
                    </div>
                  )}

                {/* <div className="">
                  <Visibility type="custom_fields" className="my-4" visible="true" />
                </div> */}

                {/* {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <div className="">
                    <Visibility type="einvoice+ewaybill" className="mb-8" visible="true" />
                  </div>
                )} */}

                {/* create ewaybill Toggle */}
                {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                  (this.state.document_type == 'invoice' ||
                    this.state.document_type == 'estimate' ||
                    this.state.document_type == 'delivery_challan') &&
                  (SHOW_FREE_USER_EWAYBILL || store.get('ewaybill') == 1) && (
                    <div className="mb-3 flex items-center justify-start gap-3">
                      <span>
                        <Switch
                          className="mr-2"
                          size="small"
                          checked={this.state.is_ewaybill == 1}
                          onChange={e => {
                            this.setState({ is_ewaybill: e ? 1 : 0 })
                          }}
                        />
                        <span className="text-gray-700">Create E-Waybill</span>
                      </span>
                      {store.get('ewaybill') == 0 &&
                        this.props.document.all_doc_counts?.eway_bill >=
                          FREE_USER_EWAYBILL_LIMIT / 2 && (
                          <UpgradeToEinvEwb
                            noOfDocsLeft={noOfEwaybillsLeft}
                            onUpgradeClick={() =>
                              // @ts-ignore
                              this.subscriptionPlanRef.current?.openModal('eway_bill')
                            }
                            size="small"
                            is_ewb={true}
                          />
                        )}
                    </div>
                  )}

                {/* create einvoice Toggle */}
                {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                  (this.state.document_type == 'invoice' ||
                    this.state.document_type == 'sales_return' ||
                    this.state.document_type == 'purchase_return') &&
                  (SHOW_FREE_USER_EINVOICE || store.get('einvoice') == 1) && (
                    <div className="flex items-center justify-start gap-3">
                      <span>
                        <Switch
                          className="mr-2"
                          size="small"
                          checked={this.state.is_create_einvoice == 1}
                          onChange={e => {
                            this.setState({ is_create_einvoice: e ? 1 : 0 })
                          }}
                        />
                        <span className="text-gray-700">Create E-Invoice</span>
                      </span>
                      {store.get('einvoice') == 0 &&
                        this.props.document.all_doc_counts?.einvoice >=
                          FREE_USER_EINVOICE_LIMIT / 2 && (
                          <UpgradeToEinvEwb
                            noOfDocsLeft={noOfEInvoicesLeft}
                            onUpgradeClick={() =>
                              // @ts-ignore
                              this.subscriptionPlanRef.current?.openModal('einvoice')
                            }
                            size="small"
                          />
                        )}
                    </div>
                  )}

                {this.state.document_type != 'online_order' && (
                  <div className="mb-3 mt-4">
                    <h6>
                      <span className="text-gray-500">Attach files</span>
                      <span>
                        <InfoIcon
                          text=" You can attach up to 3 files (3 MB each) to each transaction you
                  create."
                        />
                      </span>
                    </h6>

                    <Upload
                      customRequest={this.customRequest}
                      // onChange={this.handleChange}
                      beforeUpload={this.beforeUpload}
                      fileList={this.state.attachments}
                      maxCount={3}
                      multiple={false}
                      onRemove={this.handleChange}
                    >
                      <Button
                        type="dashed"
                        size="large"
                        disabled={this.state.attachments.length < 3 ? false : true}
                      >
                        <i className="fa-regular fa-arrow-up-from-bracket mr-2"></i>
                        Attach Files (Max: 3)
                      </Button>
                    </Upload>
                  </div>
                )}

                {store.get('online_store') == 1 && (
                  <div className="font-weight-medium mt-4">
                    <Button
                      type="transparent"
                      onClick={() => {
                        if (store.get('paid') == 0) {
                          this.subscriptionPlanRef.current.openModal('coupons')
                          return
                        }
                        this.onCouponsVisible()
                      }}
                    >
                      <i className="fa-light fa-badge-percent mr-2"></i>
                      <span className="text-gray-500">
                        Use Coupons
                        {store.get('online_store') == 0 ? <ProBadge /> : <BetaBadge />}
                      </span>
                    </Button>
                  </div>
                )}
              </div>

              {(this.state.dataSource?.length > -1 || this.state.signatures?.length > -1) && (
                <div className="w-full md:w-1/2">
                  {this.state.dataSource.length > -1 && (
                    <>
                      <SectionHeader
                        //title="Totals"
                        right={true}
                        rightText={
                          <>
                            {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                              (this.state.document_type == 'invoice' ||
                                this.state.document_type == 'sales_return' ||
                                this.state.document_type == 'purchase' ||
                                this.state.document_type == 'purchase_return' ||
                                this.state.document_type == 'subscription') && (
                                <span className="flex items-center">
                                  <span className="italic text-gray-500 font-size-12 mr-4">
                                    Apply TDS, TCS to your {this.state.document_title}
                                  </span>
                                  <span className="flex items-center mr-4">
                                    <Checkbox
                                      className=""
                                      checked={this.state.is_tds == 1}
                                      onChange={e => {
                                        if (e.target.checked && this.state.is_tcs) {
                                          message.error('TCS and TDS cannot be applied together')
                                          return
                                        }
                                        this.setState({ is_tds: e.target.checked ? 1 : 0 })
                                        if (!e.target.checked) {
                                          this.setState(
                                            {
                                              tds_amount: 0,
                                              tds_selected_section: undefined,
                                            },
                                            () => this.onChangeFullyPaid(this.state.fullyPaid),
                                          )
                                        }
                                      }}
                                    >
                                      <span className="font-weight-medium">TDS</span>
                                    </Checkbox>
                                  </span>
                                  <span className="flex items-center">
                                    <Checkbox
                                      className=""
                                      checked={this.state.is_tcs == 1}
                                      onChange={e => {
                                        if (e.target.checked && this.state.is_tds) {
                                          message.error('TCS and TDS cannot be applied together')
                                          return
                                        }
                                        this.setState({ is_tcs: e.target.checked ? 1 : 0 })
                                        if (!e.target.checked) {
                                          this.setState(
                                            {
                                              tcs_amount: 0,
                                              tcs_selected_section: undefined,
                                            },
                                            () => this.onChangeFullyPaid(this.state.fullyPaid),
                                          )
                                        }
                                      }}
                                    >
                                      <span className="font-weight-medium">TCS</span>
                                    </Checkbox>
                                  </span>
                                </span>
                              )}
                          </>
                        }
                      />

                      {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                        this.state.is_tds == 1 &&
                        (this.state.document_type == 'invoice' ||
                          this.state.document_type == 'sales_return' ||
                          this.state.document_type == 'purchase' ||
                          this.state.document_type == 'purchase_return' ||
                          this.state.document_type == 'subscription') && (
                          <div className="mb-8">
                            <SectionHeader
                              title={<>Apply TDS to your {this.state.document_title}</>}
                            />
                            <Card
                              //  @ts-ignore
                              size="document"
                              bordered={false}
                              className="border-radius-small bg-card-forest"
                            >
                              <div className="flex flex-wrap">
                                <div className="w-full flex justify-end">
                                  <Input.Group>
                                    <div className="grid grid-cols-4 gap-3">
                                      <div className="col-span-3">
                                        <Select
                                          // disabled={this.state.payment_status != 'pending'}
                                          showSearch
                                          className="text-left"
                                          placeholder="Select TDS Section"
                                          suffixIcon={
                                            <i className="fa-regular fa-chevron-down"></i>
                                          }
                                          optionFilterProp="children"
                                          style={{
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '720px',
                                          }}
                                          value={this.state.tds_selected_section}
                                          filterOption={(input: any, option: any) =>
                                            (
                                              option.props.children.props.children[0].props
                                                .children +
                                              ' ' +
                                              option.props.children.props.children[1].props
                                                .children +
                                              ' ' +
                                              option.props.children.props.children[2].props.children
                                            )
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          onChange={e => this.onChangeTdsTcsSection(e, 1)}
                                        >
                                          {this.props?.tds_sections?.map((item: any, i: number) => (
                                            <Option key={i} value={item.tds_id}>
                                              <div
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                }}
                                              >
                                                <span className="text-gray-700 font-weight-bold mr-1">
                                                  {item.tax + '%'}
                                                </span>
                                                <span className="mr-2 font-weight-bold text-gray-700">
                                                  {item.section}
                                                </span>
                                                <span className="text-gray-500">{item.name}</span>
                                              </div>
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div className="col-span-1">
                                        <Input
                                          disabled={true}
                                          placeholder={'TDS amount'}
                                          type={'number'}
                                          value={this.state.tds_amount}
                                          className="text-right"
                                        />
                                      </div>
                                    </div>
                                  </Input.Group>
                                </div>
                              </div>
                            </Card>
                            {/* auto apply tds section */}
                            {this.state.recent_tds && !this.state.tds_selected_section && (
                              <p
                                className="text-gray-500 hover:underline cursor-pointer text-sm ml-2 mt-1"
                                onClick={() =>
                                  this.onChangeTdsTcsSection(this.state.recent_tds?.tds_id, 1)
                                }
                              >
                                Do you want to apply the section{' '}
                                <span className="text-gray-700 font-weight-bold">
                                  {this.state.recent_tds.tax + '%'} {this.state.recent_tds.section}{' '}
                                  {this.state.recent_tds.name}
                                </span>
                              </p>
                            )}
                          </div>
                        )}

                      {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                        this.state.is_tcs == 1 &&
                        (this.state.document_type == 'invoice' ||
                          this.state.document_type == 'purchase' ||
                          this.state.document_type == 'purchase_return' ||
                          this.state.document_type == 'subscription' ||
                          this.state.document_type == 'sales_return') && (
                          <div className="mb-8">
                            <SectionHeader
                              title={<>Apply TCS to your {this.state.document_title}</>}
                            />
                            <Card
                              //  @ts-ignore
                              size="document"
                              bordered={false}
                              className="border-radius-small bg-card-forest"
                            >
                              {' '}
                              <Input.Group>
                                <div className="grid grid-cols-4 gap-3 justify-center">
                                  <div className="col-span-2">
                                    <Select
                                      showSearch
                                      className="text-left"
                                      placeholder="Select TCS Section"
                                      optionFilterProp="children"
                                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                      style={{
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '720px',
                                      }}
                                      dropdownStyle={{ minWidth: '360px' }}
                                      value={this.state.tcs_selected_section}
                                      filterOption={(input: any, option: any) =>
                                        (
                                          option.props.children.props.children[0].props.children +
                                          ' ' +
                                          option.props.children.props.children[1].props.children +
                                          ' ' +
                                          option.props.children.props.children[2].props.children
                                        )
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onChange={e => this.onChangeTdsTcsSection(e, 0)}
                                    >
                                      {this.props?.tcs_sections?.map((item: any, i: number) => (
                                        <Option key={i} value={item.tds_id}>
                                          <div style={{ whiteSpace: 'nowrap' }}>
                                            <span className="text-gray-700 font-weight-bold mr-1">
                                              {item.tax + '%'}
                                            </span>
                                            <span className="mr-2 font-weight-bold text-gray-700">
                                              {item.section}
                                            </span>
                                            <span className="text-gray-500">{item.name}</span>
                                          </div>
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="col-span-1">
                                    <Select
                                      placeholder="Apply on Total or Net Amount"
                                      className="text-left"
                                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                      style={{
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '720px',
                                      }}
                                      value={this.state.tcs_applicable_on}
                                      onChange={e => this.onChangeTcsApplicable(e)}
                                    >
                                      <Option value={'net_amount'}>Net Amount</Option>
                                      <Option value={'total_amount'}>Total Amount</Option>
                                    </Select>
                                  </div>
                                  <div className="col-span-1">
                                    <Input
                                      disabled={true}
                                      placeholder={'TCS amount'}
                                      type={'number'}
                                      value={this.state.tcs_amount}
                                    />
                                  </div>
                                </div>
                              </Input.Group>
                            </Card>
                            {/* auto apply tcs section */}
                            {this.state.recent_tcs && !this.state.tcs_selected_section && (
                              <p
                                className="text-gray-500 hover:underline cursor-pointer text-sm ml-2 mt-1"
                                onClick={() =>
                                  this.onChangeTdsTcsSection(this.state.recent_tcs?.tds_id, 0)
                                }
                              >
                                Do you want to apply the section{' '}
                                <span className="text-gray-700 font-weight-bold">
                                  {this.state.recent_tcs.tax + '%'} {this.state.recent_tcs.section}{' '}
                                  {this.state.recent_tcs.name}
                                </span>
                              </p>
                            )}
                          </div>
                        )}

                      <Card
                        //  @ts-ignore
                        size="document"
                        bordered={false}
                        className="border-radius-small bg-card-forest  mb-8"
                      >
                        <div className="flex flex-wrap mb-4">
                          {this.state.coupon_details.coupon_id <= 0 ? (
                            <div className="w-full flex justify-end">
                              <span>
                                <h6 className="font-size-13 text-gray-500 mb-0 text-right">
                                  Extra Discount
                                  <InfoIcon
                                    text={
                                      <span>
                                        Extra discount is directly deducted from the total invoice
                                        amount. It does not affect the{' '}
                                        <strong> tax calculations</strong>. To affect tax
                                        calculation, give product-level discount.
                                      </span>
                                    }
                                  />
                                </h6>
                                <InputNumber
                                  addonBefore={
                                    <span className="inline-block">
                                      <Select
                                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                        value={this.state.exta_discount_type}
                                        onChange={e => this.setState({ exta_discount_type: e })}
                                        className="border-none"
                                      >
                                        <Option value="discount">%</Option>
                                        <Option value="amount">
                                          {this.props.user.selectedCompany?.currency_symbol}
                                        </Option>
                                      </Select>
                                    </span>
                                  }
                                  disabled={this.getTotalAmount() == 0}
                                  onChange={this.onExtraDiscountChange}
                                  style={{ width: '200px' }}
                                  className="mb-2"
                                  min={0}
                                  value={
                                    this.state.exta_discount_type === 'discount'
                                      ? this.state.extraDiscountPer
                                      : this.state.extraDiscount
                                  }
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="w-full flex justify-end">
                              <div className="px-3 py-2 border border-green-500 bg-white rounded">
                                <div>
                                  <span className="text-success font-weight-bold">
                                    <i className="fa-solid fa-badge-percent mr-1"></i>
                                    {this.state.coupon_details.coupon_code}
                                  </span>
                                  <a
                                    className="text-primary ml-2 font-size-14 cursor-pointer"
                                    onClick={() => this.onCouponsVisible()}
                                  >
                                    Change
                                  </a>
                                  <a
                                    className="text-danger ml-2 font-size-14 cursor-pointer"
                                    onClick={() =>
                                      this.setState({
                                        coupon_details: {
                                          coupon_id: -1,
                                          coupon_code: '',
                                          discount: 0,
                                          message: '',
                                          is_edit: false,
                                        },
                                        extraDiscount: 0,
                                        extraDiscountPer: 0,
                                      })
                                    }
                                  >
                                    Remove
                                  </a>
                                </div>
                                <div className="font-size-14 text-gray-500 truncate">
                                  {this.state.coupon_details.message}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="flex flex-wrap justify-end">
                          <div className="w-auto text-right mr-16">
                            <>
                              {this.state.document_custom_additional_charges.map(
                                (item, index) =>
                                  item.total_amount > 0 && (
                                    <h6 key={index} className="mb-0">
                                      <span className="text-gray-500">
                                        {item.name} {item.tax != 0 && '(' + item.tax + '%)'}
                                      </span>
                                    </h6>
                                  ),
                              )}
                            </>

                            {this.state.companyDetails.is_composite == 0 && (
                              <h6 className="mb-0">
                                <span className="text-gray-500">Taxable Amount</span>
                              </h6>
                            )}

                            {this.state.with_tax == 1 && (
                              <>
                                <h6 className="mb-0">
                                  <span className="text-gray-500">{this.generateTaxLabel()}</span>
                                  {this.props.permissions.is_immovable == 1 &&
                                  (this.state.document_type == 'invoice' ||
                                    this.state.document_type == 'sales_return') &&
                                  this.state.export_invoice == 0 ? (
                                    ((this.generateTaxLabel() == 'IGST' &&
                                      this.state.immovable_tax_type == 0) ||
                                      this.state.immovable_tax_type == 1) && (
                                      <Tooltip
                                        title={
                                          this.state.immovable_tax_type == 0
                                            ? `Change to ${this.state.companyDetails.tax_labels.SGST} + ${this.state.companyDetails.tax_labels.CGST}`
                                            : `Change to ${this.state.companyDetails.tax_labels.IGST}`
                                        }
                                      >
                                        <Switch
                                          className="ml-2"
                                          size="small"
                                          checked={this.state.immovable_tax_type == 1}
                                          onChange={e =>
                                            this.setState({
                                              immovable_tax_type: e ? 1 : 0,
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </h6>
                                {getTotalCessAmountV2(this.state.dataSource) != 0 && (
                                  <h6 className="mb-0">
                                    <span className="text-gray-500">Total Cess</span>
                                  </h6>
                                )}
                              </>
                            )}
                            <h6 className="mb-0">
                              {this.state.rcm == 1 && (
                                <span className="mb-4 text-pink">Tax under Reverse Charge</span>
                              )}
                            </h6>

                            <h6 className="mb-0">
                              <span className="text-gray-500">
                                Round Off{' '}
                                <Switch
                                  className="ml-2"
                                  size="small"
                                  checked={this.state.roundoff == 1}
                                  onChange={this.onRoundOffChange}
                                />
                              </span>
                            </h6>
                            {this.state.is_tcs == 1 && (
                              <h6 className="mb-0">
                                <span className="text-gray-500">TCS Amount</span>
                              </h6>
                            )}

                            <h6 className="mb-0">
                              <span className="text-gray-900 font-weight-bolder font-size-21">
                                Total Amount
                              </span>
                            </h6>
                            {this.state.conversion_factor != undefined &&
                              this.state.country != 75 &&
                              (this.state.export_invoice == 1 ||
                                this.state.is_multi_currency == 1) && (
                                <h6>
                                  <span className="text-gray-900 font-weight-bolder font-size-15">
                                    Total Amount (in {this.state.currency})
                                  </span>
                                </h6>
                              )}

                            <h6 className="mb-0">
                              <span className="text-gray-500">Total Discount</span>
                            </h6>
                            {this.state.document_type == 'estimate' && (
                              <div className="inline-flex items-center ml-2 pb-1">
                                <label className="pt-2 font-size-14 font-weight-normal">
                                  Hide Totals
                                </label>
                                <Checkbox
                                  className="ml-2 pt-2"
                                  style={{ borderColor: '#000' }}
                                  checked={this.state.hide_totals == 1}
                                  onChange={e =>
                                    this.setState({ hide_totals: e.target.checked ? 1 : 0 })
                                  }
                                />
                              </div>
                            )}
                          </div>

                          <div className="w-auto text-right">
                            <>
                              {this.state.document_custom_additional_charges.map(
                                (item, index) =>
                                  item.total_amount > 0 && (
                                    <h6 key={index} className="mb-0">
                                      <span className="text-gray-500">
                                        {item.type == -1 ? '(-) ' : ''}
                                        <span className="mr-1 text-gray-500">
                                          {this.props?.user?.selectedCompany?.currency_symbol}
                                        </span>
                                        {Number(item.net_amount).toLocaleString(
                                          'en-IN',
                                          dotOptions,
                                        )}
                                      </span>
                                    </h6>
                                  ),
                              )}
                            </>

                            {this.state.companyDetails.is_composite == 0 && (
                              <h6 className="mb-0">
                                <span className="text-gray-500">
                                  <span className="mr-1 text-gray-500">
                                    {this.props?.user?.selectedCompany?.currency_symbol}
                                  </span>
                                  {(
                                    getTotalNetAmountV2(this.state.dataSource) +
                                    this.extraChargesAmount('net_amount')
                                  ).toLocaleString('en-IN', dotOptions)}
                                </span>
                              </h6>
                            )}

                            {this.state.with_tax == 1 && (
                              <>
                                <h6 className="mb-0">
                                  <span className="text-gray-500">
                                    <span className="mr-1 text-gray-500">
                                      {this.props?.user?.selectedCompany?.currency_symbol}
                                    </span>
                                    {Number(
                                      parseFloat(String(this.getTotalTax())) +
                                        this.extraChargesAmount('tax_amount') -
                                        getTotalCessAmountV2(this.state.dataSource),
                                    ).toLocaleString('en-IN', dotOptions)}
                                  </span>
                                </h6>
                                {getTotalCessAmountV2(this.state.dataSource) != 0 && (
                                  <h6 className="mb-0">
                                    <span className="text-gray-500">
                                      <span className="mr-1 text-gray-500">
                                        {this.props?.user?.selectedCompany?.currency_symbol}
                                      </span>
                                      {Number(
                                        parseFloat(
                                          String(getTotalCessAmountV2(this.state.dataSource)),
                                        ),
                                      ).toLocaleString('en-IN', dotOptions)}
                                    </span>
                                  </h6>
                                )}
                              </>
                            )}

                            <h6 className="mb-0">
                              {this.state.rcm == 1 && (
                                <span className="mb-4 text-pink">
                                  <span className="mr-1">
                                    {this.props?.user?.selectedCompany?.currency_symbol}
                                  </span>
                                  {this.state.rcm_value.toLocaleString('en-IN', dotOptions)}
                                </span>
                              )}
                            </h6>

                            <h6 className="mb-0">
                              {this.state.roundoff_value != 0 && (
                                <span className="text-gray-500">
                                  {(
                                    -1 * Number(parseFloat(String(this.state.roundoff_value)))
                                  ).toLocaleString('en-IN', dotOptions)}
                                </span>
                              )}
                              {this.state.roundoff_value == 0 && (
                                <span className="text-gray-500">0.00</span>
                              )}
                            </h6>
                            {this.state.is_tcs == 1 && (
                              <h6 className="mb-0 text-gray-500">
                                {this.state.tcs_amount.toLocaleString('en-IN', dotOptions)}
                              </h6>
                            )}
                            <h6 className="mb-0">
                              <span className="font-size-21 text-gray-900 font-weight-bolder">
                                <span className="mr-1">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                                {this.getTotalAmount().toLocaleString('en-IN', dotOptions)}
                              </span>
                            </h6>
                            {this.state.conversion_factor != undefined &&
                              this.state.country != 75 &&
                              (this.state.export_invoice == 1 ||
                                this.state.is_multi_currency == 1) && (
                                <h6 className="mb-0">
                                  <span className="text-gray-900 font-weight-bolder">
                                    {this.state.currency_symbol}
                                    {(
                                      this.getTotalAmount() /
                                      (this.getTotalAmount() != 0
                                        ? parseFloat(String(this.state.conversion_factor || 1))
                                        : 1)
                                    ).toFixed(2)}
                                  </span>
                                </h6>
                              )}

                            <h6 className="mb-0">
                              <span className="text-gray-500 font-weight-bolder">
                                <span className="fa mr-1 text-gray-500">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                                {(
                                  Number(this.state.extraDiscount) +
                                  getTotalDiscountV2(
                                    this.state.dataSource,
                                    this.state.document_discount_type,
                                  )
                                ).toLocaleString('en-IN', dotOptions)}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Card>
                    </>
                  )}

                  {this.state.document_type != 'online_order' &&
                    this.state.document_type != 'purchase' &&
                    this.state.document_type != 'purchase_order' && (
                      <>
                        <SectionHeader
                          title={
                            this.state.document_type != 'purchase' &&
                            this.state.document_type != 'purchase_order' &&
                            'Select Bank'
                          }
                          infoContent={
                            <span>
                              <span>Select Bank Details to show in document pdfs.</span>
                              <br />
                              <span>
                                Please click on <strong>Add New Bank</strong> to add new Bank
                                details.
                              </span>
                            </span>
                          }
                          link={true}
                          linkTo={() => this.setState({ bank_form_visible: true })}
                          linkText="Add new Bank"
                        />
                        <Card
                          // @ts-ignore
                          size="document"
                          bordered={false}
                          className="bg-card-indigo border-radius-small mb-8"
                        >
                          <div className="text-left">
                            <>
                              {this.state.bank_details.length > 1 && (
                                <Select
                                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                  placeholder="Select Bank"
                                  optionFilterProp="children"
                                  style={{ width: '100%' }}
                                  onChange={e => {
                                    const selectedBank = this.state.bank_details.find(
                                      ({ id }) => id === e,
                                    )
                                    this.setState({
                                      selected_bank: selectedBank,
                                    })
                                    this.setState({
                                      paymentMethod: 'Cash',
                                      paymentsList: this.state.paymentsList.map(payment => {
                                        return {
                                          ...payment,
                                          payment_method: 'Cash',
                                          bank_id: 0,
                                        }
                                      }),
                                    })

                                    if (selectedBank?.bank_name == 'Cash') {
                                      this.setState({
                                        paymentMethod: 'Cash',
                                      })
                                    }
                                  }}
                                  value={this.state.selected_bank?.id}
                                  defaultValue={this.state.selected_bank?.id}
                                  filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  dropdownRender={menu => (
                                    <>
                                      {menu}
                                      <div className="bg-gray-75">
                                        <Button
                                          block
                                          type="link-2"
                                          className="font-weight-bolder text-left my-2 arrow-transition"
                                          onMouseDown={e => e.preventDefault()}
                                          onClick={() => this.setState({ bank_form_visible: true })}
                                        >
                                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                          Add New Bank
                                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                  id="selectBank"
                                >
                                  {this.state.bank_details.map((item, i) => (
                                    <Option key={i} value={item.id}>
                                      {item.bank_name + ' '}{' '}
                                      {item.bank_no != '' && '(' + item.bank_no + ')'}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </>

                            {this.state.bank_details.length <= 1 &&
                              this.props.permissions.bank_details == 1 && (
                                <Button
                                  type="dashed"
                                  size="large"
                                  onClick={() => this.setState({ bank_form_visible: true })}
                                  block
                                  style={{
                                    color: '#FC3654',
                                    whiteSpace: 'normal',
                                    height: 'auto',
                                  }}
                                >
                                  <br />
                                  <i
                                    className="fa fa-university mr-2 font-size-18"
                                    style={{ color: '#FC3654' }}
                                  />
                                  <span
                                    className="font-size-18 font-weight-bold"
                                    style={{ color: '#FC3654' }}
                                  >
                                    {' '}
                                    Add Bank to Invoice (Optional)
                                  </span>
                                  <br />
                                  <br />
                                </Button>
                              )}
                          </div>
                        </Card>
                      </>
                    )}

                  {this.state.document_type != 'online_order' &&
                    !this.state.edit &&
                    (this.state.document_type == 'invoice' ||
                      this.state.document_type == 'purchase') &&
                    this.props.permissions.record_payment == 1 && (
                      <>
                        <SectionHeader
                          title={
                            (this.state.document_type == 'invoice' ||
                              this.state.document_type == 'purchase') &&
                            'Add payment (Payment Notes, Amount and Mode)'
                          }
                          right={true}
                          rightText={
                            !this.state.edit &&
                            (this.state.document_type == 'invoice' ||
                              this.state.document_type == 'purchase') &&
                            this.props.permissions.record_payment == 1 &&
                            this.state.paymentsList.length <= 1 && (
                              <>
                                <div>
                                  <span className="font-weight-medium float-right">
                                    <Checkbox
                                      disabled={this.state.rzp_payment_id != ''}
                                      className=""
                                      onChange={e => this.onChangeFullyPaid(e.target.checked)}
                                    >
                                      <span className="text-gray-500">Mark as fully paid</span>
                                    </Checkbox>
                                  </span>
                                </div>
                              </>
                            )
                          }
                        />
                        <Table
                          // @ts-ignore
                          columns={paymentsListColumns}
                          dataSource={this.state.paymentsList}
                          size="small"
                          pagination={false}
                          footer={() => (
                            <div className="flex justify-between items-center">
                              <div className="w-1/2 flex gap-x-2 text-gray-600">
                                {!this.state.fullyPaid &&
                                  this.getTotalAmount() == 0 &&
                                  this.state.amountReceived + this.state.tds_amount <
                                    this.getTotalAmount() && (
                                    <Button
                                      type="link"
                                      className="font-size-14 cursor-pointer"
                                      onClick={this.addSplitPayment}
                                    >
                                      <i className="fa-solid fa-plus-circle mr-2" />
                                      Split Payment
                                    </Button>
                                  )}

                                <Button
                                  type="link"
                                  className="cursor-pointer"
                                  onClick={this.addSplitPayment}
                                >
                                  <i className="fa-solid fa-plus-circle mr-2" />
                                  <span className="font-weight-bold">Split Payment</span>
                                </Button>
                              </div>
                              {Number(this.state.amountReceived) + Number(this.state.tds_amount) >
                                0 && (
                                <div className="w-1/2 flex justify-end">
                                  <div>
                                    <div className="font-size-14">
                                      <span className="mr-2 ml-1">Total Amount</span>
                                      <span className="mr-2">
                                        <span>
                                          <span className="mr-1 font-size-12">
                                            {this.props?.user?.selectedCompany?.currency_symbol}
                                          </span>
                                          {Number(this.getTotalAmount()).toLocaleString(
                                            'en-IN',
                                            dotOptions,
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="font-size-14 text-red-400">
                                      <span className="mr-2 ml-1">Balance Amount</span>
                                      <span className="mr-2">
                                        <span>
                                          <span className="mr-1 font-size-12">
                                            {this.props?.user?.selectedCompany?.currency_symbol}
                                          </span>
                                          {(
                                            Number(this.getTotalAmount()) -
                                            (Number(this.state.amountReceived) +
                                              Number(this.state.tds_amount))
                                          ).toLocaleString('en-IN', dotOptions)}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          className="mb-8 payments-table"
                        />
                      </>
                    )}

                  {/*Select signature*/}
                  {this.state.document_type != 'online_order' && (
                    <>
                      <SectionHeader
                        title="Select Signature"
                        link={true}
                        linkTo={() => this.signatureForm.onAdd()}
                        linkText="Add New Signature"
                        video_name={'add_signature'}
                      />
                      <Card
                        //  @ts-ignore
                        size="document"
                        bordered={false}
                        className="border-radius-small bg-card-magenta  mb-4"
                      >
                        <div className="flex flex-wrap">
                          <div className="w-full text-left">
                            <div className="mb-3">
                              {this.state.signatures.length > 1 && (
                                <Select
                                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                  placeholder="Select Signature"
                                  optionFilterProp="children"
                                  style={{ width: '100%' }}
                                  onChange={(e: any) =>
                                    this.setState({
                                      selectedSignature: this.state.signatures.find(
                                        ({ id }) => id === e,
                                      ),
                                    })
                                  }
                                  value={this.state.selectedSignature?.signature_name}
                                  defaultValue={this.state.selectedSignature?.id}
                                  filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  dropdownRender={menu => (
                                    <>
                                      {menu}
                                      <div className="bg-gray-75">
                                        <Button
                                          block
                                          type="link-2"
                                          className="font-weight-bolder text-left my-2 arrow-transition"
                                          onClick={() => this.signatureForm.onAdd()}
                                        >
                                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                          Add New Signature
                                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                >
                                  {this.state.signatures.map((item, i) => (
                                    <Option key={i} value={item.id}>
                                      {item.signature_name}
                                    </Option>
                                  ))}
                                </Select>
                              )}

                              {this.state.signatures.length <= 1 && (
                                <Button
                                  type="dashed"
                                  size="large"
                                  onClick={() => this.signatureForm.onAdd()}
                                  block
                                  style={{
                                    color: '#FC3654',
                                    whiteSpace: 'normal',
                                    height: 'auto',
                                  }}
                                >
                                  <br />
                                  <i
                                    className="fa fa-pencil mr-2 font-size-18"
                                    style={{ color: '#FC3654' }}
                                  />
                                  <span
                                    className="font-size-18 font-weight-bold"
                                    style={{ color: '#FC3654' }}
                                  >
                                    {' '}
                                    Add Signature to Invoice (Optional)
                                  </span>
                                  <br />
                                  <br />
                                </Button>
                              )}
                            </div>
                            <div className="text-right float-right">
                              <h6>Signature on the document</h6>
                              <img src={this.state.selectedSignature?.image} className="h-24" />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </>
                  )}
                </div>
              )}
            </div>

            {/* @ts-ignore */}
            <TutorialVideoModal ref={this.tutorialRef} video_name={'add_signature'} />

            <Affix offsetBottom={18} onChange={this.affix}>
              <div className="px-5 mx-2 pt-10">
                {/*<div className="row mt-3 border-t-gray-100"></div>*/}
                <Card
                  //  @ts-ignore
                  size="document"
                  bordered={true}
                  className="border-radius-small bg-card-white card-shadow-3 mb-4"
                >
                  <div className={`flex items-center justify-between`}>
                    <div className="grid">
                      {this.getTotal() > 0 && (
                        <div className="grid grid-cols-2 gap-x-4 items-center mb-0">
                          <span className="text-gray-500 font-weight-bolder font-size-18">
                            TOTAL
                          </span>
                          <span className="text-gray-900 font-size-18 font-weight-bolder text-left">
                            {this.state.export_invoice == 1 || this.state.is_multi_currency == 1 ? (
                              this.state.currency_symbol
                            ) : (
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                            )}
                            {(
                              this.getTotalAmount() / (this.state.conversion_factor || 1)
                            ).toLocaleString('en-IN', dotOptions)}
                          </span>
                        </div>
                      )}

                      {this.state.with_tax == 1 && this.getTotalTax() > 0 && (
                        <div className="grid grid-cols-2 gap-x-4 items-center mb-0">
                          <span className="text-gray-500 font-weight-bold font-size-12">
                            Includes {this.generateTaxLabel()}
                            {getTotalCessAmountV2(this.state.dataSource) != 0 && ' + Cess'}
                          </span>
                          <span className="text-gray-500 font-size-12 font-weight-bolder text-left">
                            {this.state.export_invoice == 1 || this.state.is_multi_currency == 1 ? (
                              this.state.currency_symbol
                            ) : (
                              <span className="fa mr-1 text-gray-500">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                            )}
                            {Number(
                              (parseFloat(String(this.getTotalTax())) +
                                this.extraChargesAmount('tax_amount') -
                                getTotalCessAmountV2(this.state.dataSource)) /
                                (this.state.conversion_factor || 1),
                            ).toLocaleString('en-IN', dotOptions)}

                            {getTotalCessAmountV2(this.state.dataSource) !== 0 && (
                              <>
                                <span className="text-gray-500 ml-1">
                                  +{' '}
                                  {this.state.export_invoice === 1 ||
                                  this.state.is_multi_currency === 1 ? (
                                    this.state.currency_symbol
                                  ) : (
                                    <span className="fa mr-1 text-gray-500 ml-1">
                                      {this.props?.user?.selectedCompany?.currency_symbol}
                                    </span>
                                  )}
                                </span>
                                <span>
                                  {Number(
                                    parseFloat(
                                      String(getTotalCessAmountV2(this.state.dataSource)),
                                    ) / (this.state.conversion_factor || 1),
                                  ).toLocaleString('en-IN', dotOptions)}
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-center ml-auto">
                      {this.state.showDraftMessage ? (
                        <p className="ml-4 mb-0 text-gray-500 font-weight-medium font-size-14 text-center">
                          <LoadingOutlined />
                          <span className="ml-2">Saving as draft ...</span>
                        </p>
                      ) : (
                        <p className="ml-4 mb-0 text-gray-500 font-weight-medium font-size-14 text-center">
                          {' '}
                        </p>
                      )}
                    </div>
                    <div className="ml-auto float-right select-save">
                      <Button
                        loading={this.state.loadingSave}
                        onClick={() => this.save('1')}
                        type="primary"
                        size="large"
                        className="font-weight-bold float-right arrow-transition"
                        id="saveDocumentButton"
                      >
                        Save
                        <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                      </Button>

                      {this.state.customerData.length < 2 &&
                        !(this.state.is_ewaybill || this.state.is_create_einvoice) &&
                        this.state.document_type != 'subscription' && (
                          <Button
                            loading={this.state.loadingSave}
                            onClick={() => this.save('2')}
                            type="secondary"
                            size="large"
                            className="font-weight-bold float-right hidden sm:block mx-4"
                            id="saveAndPrintDocumentButton"
                          >
                            Save and Print
                          </Button>
                        )}
                      {this.state.document_type != 'subscription' &&
                        !(this.state.is_ewaybill || this.state.is_create_einvoice) &&
                        !this.state.edit &&
                        this.state.customerData.length < 2 && (
                          <Button
                            loading={this.state.loadingSave}
                            type="secondary"
                            size="large"
                            className="font-weight-bold float-right hidden sm:block"
                            onClick={() => this.save('1', true)}
                            id="saveAsDraftButton"
                          >
                            Save as Draft <BetaBadge />
                          </Button>
                        )}
                    </div>
                  </div>
                </Card>
              </div>
            </Affix>
          </div>
        )}
      </>
    )

    const invoiceModal = (
      <>
        <Modal
          title={
            <>
              <i className="fad fa-info-circle text-danger font-size-22 mr-3"></i>Confirm
            </>
          }
          open={this.state.visible}
          closable
          destroyOnClose
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={
            <div className="flex w-full justify-end">
              <Button
                type="danger"
                className=" font-weight-bold"
                onClick={() => this.deleteAllDrafts()}
                loading={this.state.loadingSave}
              >
                Don't Save
              </Button>
              <Button
                className="hidden sm:block font-weight-bold ml-2"
                type="primary"
                loading={this.state.loadingSave}
                onClick={() => this.saveAsDraft()}
              >
                Save as Draft
              </Button>
            </div>
          }
        >
          <div className="ml-4 pl-3 mt-4">
            <p className="mb-4 mt-4 pb-4 font-size-18 font-weight-medium">
              Any unsaved changes will be lost. <br />
              Do you want to save them in drafts?
            </p>
          </div>
        </Modal>
      </>
    )

    return (
      <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
        <Layout
          className={classNames({
            cui__layout__contentMaxWidth: isContentMaxWidth,
            cui__layout__appMaxWidth: isAppMaxWidth,
            cui__layout__grayBackground: isGrayBackground,
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
          })}
        >
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            {this.state.loaded && (
              <FeatureBar
                // @ts-ignore
                save={this.save}
                title={
                  this.state.edit
                    ? `Update ${this.state.document_title}`
                    : `Create ${this.state.document_title}`
                }
                edit={
                  <div className="edit-container">
                    <Input.Group compact>
                      <SerialNumberComponent
                        is_prefix={true}
                        value={this.state.prefix}
                        new_value={this.state.new_prefix}
                        data={this.state.prefixes}
                        onFinish={this.refreshPrefixes}
                        document_type={this.state.document_type}
                        setNewPrefixSuffix={this.setNewPrefixSuffix}
                        is_pos={this.state.is_pos}
                        setIsPrefix={this.setIsPrefix}
                      />
                      {/*<span className="m-1 mt-1"></span>*/}
                      <InputNumber
                        ref={this.docNumberRef}
                        min={1}
                        className="font-weight-bolder"
                        disabled={this.state.is_pos}
                        style={{
                          minWidth: 108,
                          //backgroundColor: '#f8faff',
                        }}
                        value={this.state.doc_number}
                        onChange={e =>
                          this.setState({
                            doc_number: e,
                            items: this.state.items.map(item =>
                              item.key == this.state.activeKey
                                ? {
                                    ...item,
                                    label:
                                      e == null ? this.state.prefix + '' : this.state.prefix + e,
                                  }
                                : item,
                            ),
                          })
                        }
                        onFocus={() => {
                          if (this.docNumberRef.current) {
                            this.docNumberRef.current.style.border = ''
                          }
                        }}
                      />
                      <SerialNumberComponent
                        is_prefix={false}
                        value={this.state.suffix}
                        new_value={this.state.new_suffix}
                        data={this.state.suffixes}
                        onFinish={this.refreshSuffixes}
                        document_type={this.state.document_type}
                        setNewPrefixSuffix={this.setNewPrefixSuffix}
                        is_pos={this.state.is_pos}
                        setIsPrefix={this.setIsPrefix}
                      />
                    </Input.Group>
                  </div>
                }
                loadingSave={this.state.loadingSave}
                leaveChanges={this.leaveChanges}
              />
            )}
          </Layout.Header>

          {this.state.loaded == false && (
            <Layout.Content>
              <Spin
                indicator={
                  <LoadingOutlined
                    spin
                    className="align-center text-center font-weight-bolder"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '48px',
                    }}
                  />
                }
              />
            </Layout.Content>
          )}

          <motion.div
            initial={{ y: '100vh' }} // Start off-screen at the bottom
            animate={{ y: 0 }} // Slide up to its normal position
            transition={{ type: 'spring', stiffness: 360, damping: 48 }} // Smooth spring transition
            className=""
          >
            <Layout.Content>
              <div>
                {' '}
                <Fragment>
                  <Helmet title={this.state.document_title + ' - Swipe'} />
                  <div>
                    <Modal
                      title={
                        <>
                          <i className="fa fa-exclamation-triangle text-warning mr-1" />
                          Warning
                        </>
                      }
                      open={this.state.warning.show_warning}
                      onOk={() =>
                        this.setState(
                          {
                            warning: {
                              ...this.state.warning,
                              show_warning: false,
                              skip_warning: true,
                            },
                          },
                          () => this.save('1', false, false, true),
                        )
                      }
                      onCancel={() =>
                        this.setState(
                          {
                            warning: { ...this.state.warning, show_warning: false },
                            loadingSave: false,
                            loadingPrint: false,
                          },
                          () => {
                            if (this.state.new_serial_number != '') {
                              if (this.docNumberRef.current) {
                                this.docNumberRef.current.style.border = '2px solid red'
                              }
                            }
                          },
                        )
                      }
                      okText="Continue"
                      cancelText={
                        this.state.new_serial_number != '' && this.state.new_doc_number != 0
                          ? 'Change Manually'
                          : 'Close'
                      }
                      closable={false}
                      maskClosable={false}
                      cancelButtonProps={{ type: 'danger' }}
                    >
                      <div>
                        <p className="font-weight-medium">{this.state.warning.message}</p>
                        <p className="text-danger">
                          Click on <b>Continue</b> if you still want to save this document{' '}
                          {this.state.new_doc_number != 0 ? 'with the new serial number' : ''}.
                        </p>
                      </div>
                    </Modal>
                  </div>

                  {this.state.loaded && (
                    <>
                      <SectionHeader
                        title={
                          <div className="flex items-center mt-3">
                            {this.state.document_type == 'invoice' &&
                              this.props?.user?.selectedCompany?.country_code == 'IN' && (
                                <div className="pr-2 pl-2">
                                  <Checkbox
                                    onChange={e => {
                                      this.onDocumentTitleChange(
                                        e.target.checked == true ? 'Bill of Supply' : 'Invoice',
                                      )
                                    }}
                                    disabled={
                                      this.state.companyDetails.is_composite == 1 ||
                                      this.state.export_type == 'Export with IGST' ||
                                      this.state.export_type == 'SEZ with IGST Payment'
                                    }
                                    checked={
                                      this.state.document_title == 'Bill of Supply' ||
                                      this.state.companyDetails.is_composite == 1
                                    }
                                    className="font-size-14"
                                  >
                                    <span className="text-gray-500 font-weight-medium">
                                      Bill of Supply
                                    </span>
                                  </Checkbox>
                                </div>
                              )}
                            {this.state.document_type == 'estimate' && (
                              <div className="pr-2">
                                <span className="select-borderless">
                                  <Select
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    size="small"
                                    value={this.state.document_title}
                                    onChange={e => this.onDocumentTitleChange(e)}
                                  >
                                    <Option value="Quotation">Quotation</Option>
                                    <Option value="Estimate">Estimate</Option>
                                  </Select>
                                </span>
                              </div>
                            )}
                            {(this.state.document_type == 'estimate' ||
                              this.state.document_type == 'purchase' ||
                              this.state.document_type == 'purchase_order' ||
                              this.state.document_type == 'purchase_return' ||
                              this.state.document_type == 'sales_return' ||
                              this.state.document_type == 'delivery_challan' ||
                              this.state.document_type == 'pro_forma_invoice') &&
                              this.state.companyDetails.gstin.length == 15 &&
                              this.state.companyDetails.is_composite == 0 && (
                                <div className="pl-2">
                                  <Checkbox
                                    onChange={(e: any) => {
                                      this.setState({ with_tax: e.target.checked ? 0 : 1 }, () => {
                                        this.onWithTaxChange()
                                      })
                                    }}
                                    checked={this.state.with_tax == 0}
                                    className="font-size-14"
                                  >
                                    <span className="line-clamp-1">
                                      Create {this.state.document_title} Without{' '}
                                      {this.props?.user?.selectedCompany?.labels?.gst}
                                    </span>
                                  </Checkbox>
                                </div>
                              )}

                            {(this.state.document_type == 'invoice' ||
                              this.state.document_type == 'sales_return' ||
                              this.state.document_type == 'pro_forma_invoice' ||
                              this.state.document_type == 'estimate' ||
                              this.state.document_type == 'purchase' ||
                              this.state.document_type == 'purchase_order') &&
                              this.props?.user?.selectedCompany?.country_code == 'IN' && (
                                <div className="pl-2">
                                  <Checkbox
                                    disabled={
                                      store.get('is_export') == 0 &&
                                      this.state.export_docs_count >= FREE_USER_EXPORT_LIMIT
                                    }
                                    checked={this.state.export_invoice == 1}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        this.onExportChange()
                                      } else {
                                        this.setState({
                                          conversion_factor: 1,
                                          export_type: null,
                                        })
                                      }
                                      this.setState(
                                        {
                                          export_invoice: this.state.export_invoice ? 0 : 1,
                                          with_tax:
                                            this.state.document_type == 'purchase'
                                              ? this.state.with_tax
                                              : e.target.checked
                                              ? this.state.with_tax
                                              : this.props.company_details.gstin?.length != 15 ||
                                                (this.props.company_details.is_composite == 1 &&
                                                  !this.state.document_type.includes('purchase'))
                                              ? 0
                                              : 1,
                                          is_multi_currency: e.target.checked
                                            ? 0
                                            : this.state.is_multi_currency,
                                        },
                                        () => {
                                          this.onWithTaxChange()
                                        },
                                      )
                                    }}
                                    className="font-size-14"
                                  >
                                    <Tooltip
                                      title={
                                        store.get('is_export') === 0 && (
                                          <div className="p-4 shadow-lg rounded-lg">
                                            <div className="font-size-16 mb-2 text-center font-semibold text-white">
                                              {this.state.noOfExportDocsLeft <= 0
                                                ? 'Free quota exceeded'
                                                : `${
                                                    this.state.export_docs_count >=
                                                    FREE_USER_EXPORT_LIMIT / 2
                                                      ? 'Only '
                                                      : ''
                                                  }${
                                                    this.state.noOfExportDocsLeft
                                                  } out of ${FREE_USER_EXPORT_LIMIT} ${
                                                    this.state.document_type === 'purchase' ||
                                                    this.state.document_type === 'purchase_order'
                                                      ? 'Import'
                                                      : 'Export/ SEZ'
                                                  } / Multi-currency documents left for FREE!`}
                                            </div>
                                            <p className="font-size-16 mb-1 text-center font-semibold text-white">
                                              Upgrade to the Export Invoices Add-On for unlimited
                                              access. 🚀
                                            </p>
                                            <div className="text-center mt-2">
                                              <Button
                                                type="action"
                                                className="mt-2"
                                                onClick={() => history.push('/pricing')}
                                              >
                                                Subscribe Now! 🚀
                                              </Button>
                                            </div>
                                          </div>
                                        )
                                      }
                                    >
                                      <span className="text-gray-500 font-weight-medium">
                                        {this.state.document_type == 'purchase' ||
                                        this.state.document_type == 'purchase_order'
                                          ? 'Import'
                                          : 'Export/ SEZ'}
                                      </span>
                                    </Tooltip>

                                    <BetaBadge />
                                  </Checkbox>
                                </div>
                              )}

                            <div className="pl-2">
                              {this.state.document_type != 'online_order' && (
                                <Checkbox
                                  disabled={
                                    this.state.export_invoice == 1 ||
                                    (store.get('is_multi_currency') == 0 &&
                                      this.state.export_docs_count >= FREE_USER_EXPORT_LIMIT)
                                  }
                                  checked={this.state.is_multi_currency == 1}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      this.onExportChange()
                                    } else {
                                      this.setState({ conversion_factor: 1 })
                                    }
                                    this.setState(
                                      {
                                        is_multi_currency: this.state.is_multi_currency ? 0 : 1,
                                      },
                                      () => {
                                        if (this.table) {
                                          this.table.edit(this.state.edit)
                                        }
                                      },
                                    )
                                  }}
                                  className="font-size-14"
                                >
                                  <Tooltip
                                    title={
                                      store.get('is_multi_currency') === 0 && (
                                        <div className="p-4 shadow-lg rounded-lg">
                                          <div className="font-size-16 mb-2 text-center font-semibold text-white">
                                            {this.state.noOfExportDocsLeft <= 0
                                              ? 'Free quota exceeded'
                                              : `${
                                                  this.state.export_docs_count >=
                                                  FREE_USER_EXPORT_LIMIT / 2
                                                    ? 'Only '
                                                    : ''
                                                }${
                                                  this.state.noOfExportDocsLeft
                                                } out of ${FREE_USER_EXPORT_LIMIT} ${
                                                  this.state.document_type === 'purchase' ||
                                                  this.state.document_type === 'purchase_order'
                                                    ? 'Import'
                                                    : 'Export/ SEZ'
                                                } / Multi Currency documents left for FREE!`}
                                          </div>
                                          <p className="font-size-16 mb-1 text-center font-semibold text-white">
                                            Upgrade to the Multi Currency Add-On for unlimited
                                            access. 🚀
                                          </p>
                                          <div className="text-center mt-2">
                                            <Button
                                              type="action"
                                              className="mt-2"
                                              onClick={() => history.push('/pricing')}
                                            >
                                              Subscribe Now! 🚀
                                            </Button>
                                          </div>
                                        </div>
                                      )
                                    }
                                  >
                                    <span>Multi Currency</span>
                                  </Tooltip>
                                </Checkbox>
                              )}
                            </div>

                            {this.state.companyDetails.shipping_address.length > 0 && (
                              <div className="pl-2 select-borderless hidden sm:block">
                                <span className="text-gray-500 font-size-14 font-weight-medium ml-2">
                                  {dispatch_to_doc_types.includes(this.state.document_type)
                                    ? 'Dispatch To'
                                    : 'Dispatch From'}
                                </span>
                                <span className="select-borderless">
                                  <Select
                                    size={'small'}
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    placeholder={'Select address'}
                                    optionFilterProp="children"
                                    style={{ maxWidth: 360 }}
                                    listHeight={200}
                                    dropdownStyle={{ minWidth: '360px' }}
                                    onChange={e => {
                                      this.setState({
                                        selected_company_shipping_address: this.state.companyDetails.shipping_address.find(
                                          ({ addr_id }) => addr_id === e,
                                        ),
                                      })
                                    }}
                                    allowClear
                                    value={
                                      this.state.selected_company_shipping_address
                                        ? 'addr_id' in this.state.selected_company_shipping_address
                                          ? this.state.selected_company_shipping_address.addr_id
                                          : undefined
                                        : undefined
                                    }
                                    filterOption={(input: any, option: any) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                      0
                                    }
                                    dropdownRender={menu => (
                                      <>
                                        {menu}
                                        <div className="bg-gray-50">
                                          {/*<Divider className="my-0" />*/}
                                          <Button
                                            block
                                            type="link-2"
                                            className="font-weight-bolder text-left my-2 arrow-transition"
                                            onClick={e => {
                                              e.stopPropagation()
                                              this.EditCompanyForm.onAdd()
                                            }}
                                          >
                                            <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                            Add Company Shipping address
                                            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  >
                                    {this.state.companyDetails.shipping_address.map((item, i) => {
                                      if (
                                        item.is_delete == 0 ||
                                        item.addr_id ==
                                          this.state.selected_company_shipping_address?.addr_id
                                      ) {
                                        return (
                                          <Option key={i} value={item.addr_id}>
                                            <span>
                                              {(item.title != '' ? item.title + ' ' : '') +
                                                item.line1 +
                                                ' ' +
                                                item.line2 +
                                                ' ' +
                                                item.city +
                                                ' ' +
                                                item.state +
                                                ' ' +
                                                item.pincode}
                                            </span>
                                          </Option>
                                        )
                                      }
                                    })}
                                  </Select>
                                </span>
                              </div>
                            )}
                          </div>
                        }
                        right={true}
                        rightText={
                          <div className="flex ml-auto hidden sm:block align-items-bottom mt-3">
                            <a_link
                              onClick={e => {
                                store.get('paid') == 0
                                  ? this.subscriptionPlanRef.current.openModal('custom_fields')
                                  : this.custom_column.onAddCustomHeaders()
                              }}
                              className="font-weight-medium"
                            >
                              <i className="fa-solid fa-plus-circle mr-2" />
                              Custom Headers
                              {store.get('paid') == 0 && <ProBadge />}
                            </a_link>
                            {this.props?.permissions?.role == ADMIN && (
                              <a_link
                                onClick={() => this.invoiceSettingsForm.onOpen()}
                                className="font-weight-medium ml-4 pr-2"
                              >
                                <i className="fa-solid fa-gear mr-2" />
                                Settings
                              </a_link>
                            )}
                          </div>
                        }
                      />
                      {/* @ts-ignore */}
                      <Card size="layout" className="" bordered={true}>
                        {this.state.isCreated && !this.state.is_draft ? (
                          <div
                            style={{
                              display: 'flex',
                              height: '100%',
                              width: '100%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Empty
                              className="m-4"
                              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                              imageStyle={{
                                height: 400,
                              }}
                              description={
                                <span className="font-size-21 font-weight-bold">
                                  {this.state.document_title} Created Successfully!{' '}
                                </span>
                              }
                            >
                              <Button
                                type="action"
                                size="large"
                                onClick={() => this.leaveChanges()}
                                loading={this.state.loadingSave}
                              >
                                {' '}
                                <i className="fa fa-credit-card mr-2" /> Go to{' '}
                                {this.state.document_title}
                              </Button>
                            </Empty>
                          </div>
                        ) : (
                          invoiceComponent
                        )}
                      </Card>
                    </>
                  )}
                  {this.state.loaded && (
                    <FinancialYearModal
                      ref={this.financialYearRef}
                      // @ts-ignore
                      serial_number={this.state.prefix}
                      onFinish={this.refreshPrefixes}
                    />
                  )}

                  {/* // <Tabs
                        //   // hideAdd={this.state.items.length >= 5}
                        //   hideAdd={true}
                        //   type="editable-card"
                        //   tabBarExtraContent={{
                        //     left: (
                        //       <Space align="center" className="mx-3">
                        //         <i
                        //           className="fa-solid fa-sharp fa-chevron-left cursor-pointer"
                        //           onClick={() => this.leaveChanges()}
                        //         />
                        //         <h5 className="mb-0">
                        //           {!this.state.edit && (
                        //             <span className="font-weight-bold">
                        //               Create {this.state.document_title}
                        //             </span>
                        //           )}
                        //           {this.state.edit && (
                        //             <span className="font-weight-bold">
                        //               Update {this.state.document_title}
                        //             </span>
                        //           )}
                        //         </h5>
                        //       </Space>
                        //     ),
                        //     right: (
                        //       <div className="ml-auto hidden sm:block">
                        //         <a_link
                        //           onClick={e => {
                        //             store.get('paid') == 0
                        //               ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        //               : this.custom_column.onAddCustomHeaders()
                        //           }}
                        //           className="font-weight-medium"
                        //         >
                        //           <i className="fa-solid fa-plus-circle mr-2" />
                        //           Custom Headers
                        //           {store.get('paid') == 0 && <ProBadge />}
                        //         </a_link>
                        //         <a_link
                        //           onClick={() => this.invoiceSettingsForm.onOpen()}
                        //           className="font-weight-medium ml-4 pr-2"
                        //         >
                        //           <i className="fa-solid fa-gear mr-2" />
                        //           Settings
                        //         </a_link>
                        //       </div>
                        //     ),
                        //   }}
                        //   onChange={this.onChangeTab}
                        //   activeKey={this.state.activeKey}
                        //   onEdit={this.onEditTab}
                        //   className="mb-0 multi-tab"
                        // >
                        //   {this.state.items.map((item, index) => (
                        //     <TabPane
                        //       tab={item.label}
                        //       key={item.key}
                        //       closable={this.state.items.length > 1}
                        //     >
                        //       <Card size="document" className="" bordered={true}>
                        //         {this.state.isCreated && !this.state.is_draft ? (
                        //           <div
                        //             style={{
                        //               display: 'flex',
                        //               height: '100%',
                        //               width: '100%',
                        //               justifyContent: 'center',
                        //               alignItems: 'center',
                        //             }}
                        //           >
                        //             <Empty
                        //               className="m-4"
                        //               image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        //               imageStyle={{
                        //                 height: 400,
                        //               }}
                        //               description={
                        //                 <span className="font-size-21 font-weight-bold">
                        //                   {this.state.document_title} Created Successfully!{' '}
                        //                 </span>
                        //               }
                        //             >
                        //               <Button
                        //                 type="action"
                        //                 size="large"
                        //                 onClick={() => this.leaveChanges()}
                        //                 loading={this.state.loadingSave}
                        //               >
                        //                 {' '}
                        //                 <i className="fa fa-credit-card mr-2" /> Go to{' '}
                        //                 {this.state.document_title}
                        //               </Button>
                        //             </Empty>
                        //           </div>
                        //         ) : (
                        //           invoiceComponent
                        //         )}
                        //       </Card>
                        //     </TabPane>
                        //   ))}
                        // </Tabs> */}

                  {invoiceModal}
                  <ProductForm
                    onRef={(ref: any) => (this.form = ref)}
                    onAPICalled={(data: any) => {
                      this.table.onAddNew(data)
                    }}
                  />
                  <EnhancedSignatureForm
                    onRef={(ref: any) => (this.signatureForm = ref)}
                    refreshSignatures={this.refreshSignatures}
                  />
                  <CustomColumnsForm
                    onRef={(ref: any) => (this.custom_column = ref)}
                    onAPICalled={() => {
                      this.fetchDetails()
                    }}
                  />
                  {/* @ts-ignore  */}
                  <InvoiceSettingsForm onRef={(ref: any) => (this.invoiceSettingsForm = ref)} />
                  <InvoiceThemeForm onRef={(ref: any) => (this.invoiceThemeForm = ref)} />

                  <NewBankForm
                    visible={this.state.bank_form_visible}
                    user={this.props.user}
                    refreshBanks={this.refreshBanks}
                    dispatch={this.props.dispatch}
                    get_payment_modes={get_payment_modes}
                    onClose={() =>
                      this.setState({
                        bank_form_visible: false,
                      })
                    }
                  />
                  <EnhancedPartyAnalysis onRef={(ref: any) => (this.analysis = ref)} />
                  {/* <EnhancedCompanyForm onRef={ref => (this.companyForm = ref)} /> */}
                  <EnhancedSubscriptionModal onRef={(ref: any) => (this.subscription = ref)} />
                  <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
                  <EnhancedNICCreds onRef={(ref: any) => (this.nic_creds = ref)} />
                  <EnhancedEInvoiceNICCreds
                    onRef={(ref: any) => (this.einvoice_nic_creds = ref)}
                    onApiCalled={() => {}}
                  />
                  <CustomerFrom
                    onRef={(ref: any) => (this.customer = ref)}
                    onAPICalled={(id: any, name: string, customerDetails: any) => {
                      let index = this.state.customerData.findIndex((item, index) => item.id == id)
                      let customerData: any = this.state.customerData
                      if (index != -1) {
                        customerData[index].value = name
                        customerData[index].notes = name
                        customerData[index].discount = customerDetails.customer_details[0].discount
                        customerData[index].phone = ''
                      } else {
                        customerData.push({
                          value: name,
                          id: id,
                          notes: name,
                          phone: '',
                          discount: customerDetails.customer_details[0].discount,
                        })
                      }
                      let customerDiscount = 0
                      if (customerData.length == 1) {
                        customerDiscount = customerData[0].discount
                      }
                      this.setState({
                        customerName: name,
                        selectedCustomer: id,
                        customerData: customerData,
                        customer_details: customerDetails.customer_details[0],
                        customerDiscount: customerDiscount,
                      })
                    }}
                    onExistingCustomer={(name: string) => {
                      this.customer.onClose()
                      this.setState({ customerName: name }, () => {
                        this.onCustomerSearch(name)
                      })
                    }}
                  />
                  <NotesTermsDrawer
                    ref={this.customNotesRef}
                    // @ts-ignore
                    refreshNotesTerms={() => {
                      this.refreshNotesTerms()
                    }}
                  />
                  {/* <CustomNotes
                    ref={this.customNotesRef}
                    // @ts-ignore
                    refreshNotesTerms={() => {
                      this.refreshNotesTerms()
                    }}
                  /> */}
                  <DocumentPrefixes
                    ref={this.documentPrefixesRef}
                    // @ts-ignore
                    onFinish={this.state.is_prefix ? this.refreshPrefixes : this.refreshSuffixes}
                  />
                  {/* @ts-ignore */}
                  <AIModal ref={this.ai} onContinue={this.onAIContinue} />

                  <VendorFrom
                    onRef={(ref: any) => (this.vendor = ref)}
                    onAPICalled={(id: any, name: any) => {
                      let index = this.state.customerData.findIndex((item, index) => item.id == id)
                      let customerData: any = this.state.customerData
                      if (index != -1) {
                        customerData[index].value = name
                        customerData[index].notes = name
                      } else {
                        customerData.push({ value: name, id: id, notes: name, phone: '' })
                      }

                      this.setState({
                        customerName: name,
                        selectedCustomer: id,
                        customerData: customerData,
                      })
                    }}
                    onExistingVendor={(name: string) => {
                      this.vendor.onClose()
                      this.setState({ customerName: name }, () => {
                        this.onCustomerSearch(name)
                      })
                    }}
                  />
                  {this.state.showEwayBill && (
                    <EnhancedEwayBill
                      onRef={(ref: any) => (this.ewaybill = ref)}
                      onCancel={() => {
                        this.setState({ showEwayBill: false })
                        this.changeRoute()
                      }}
                      dispatch={undefined}
                    />
                  )}

                  <EditCompany
                    onRef={(ref: any) => (this.EditCompanyForm = ref)}
                    dispatch={this.props.dispatch}
                    onAPICalled={() => {}}
                  />
                  <PurchaseConfirm
                    ref={this.purchaseConfirmRef}
                    // @ts-ignore
                    purchase_update_columns={this.props.document_settings.purchase_update_columns}
                    products={this.state.dataSource}
                    onPurchaseUpdateConfirm={this.onPurchaseUpdateConfirm}
                  />
                  <CouponsDrawer
                    coupons_visible={this.state.coupons_visible}
                    onClose={() => this.setState({ coupons_visible: false })}
                    onApplyCoupon={this.onApplyCoupon}
                    customerData={this.state.customerData}
                    total_amount={this.state.total_amount}
                  />
                  <InsufficientItemsModal
                    visible={this.state.insufficientStockModal.isOpen}
                    onClose={this.onCloseInsufficientStockModal}
                    items={this.state.insufficientStockModal.items}
                    message={this.state.insufficientStockModal.message}
                    updateInsufficientModalItems={this.updateInsufficientModalItems}
                    document_type={this.state.document_type}
                    dataUpdate={this.dataUpdate}
                    deleteInsufficientItem={this.deleteInsufficientItem}
                  />
                  <GetItemSuggestions ref={this.getItemSuggestionsRef} />
                  {/* <FeatureTour
                    tryNow={''}
                    type="create_doc"
                    visible={this.props?.isDocumentCreated && this.state.loaded}
                    show_for_new={false}
                  /> */}
                </Fragment>
              </div>
              {/*</Card>*/}

              <Button id="leaveCreate" className="hidden" onClick={this.leaveChanges} />

              {store.get('paid') == 0 && <SupportChat />}
            </Layout.Content>

            {this.state.loaded == true && (
              <Layout.Footer>
                <Footer />
              </Layout.Footer>
            )}
          </motion.div>
        </Layout>
      </div>
    )
  }
}

CreateDoc.defaultProps = {
  external_create: false,
}

function mapStateToProps(
  { welcome, document, warehouse, user, permissions, settings }: any,
  ownProps: any,
) {
  const stock_in_warehouses =
    warehouse.warehouses?.filter(
      (item: any) => warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_in'] == 1,
    ) ?? []
  const stock_out_warehouses =
    warehouse.warehouses?.filter(
      (item: any) => warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_out'] == 1,
    ) ?? []

  return {
    companyDetailsAdded: welcome.company_details_added,
    bankDetailsAdded: welcome.bank_details_added,
    company_details: permissions.company_details,
    productsAdded: welcome.products_added,
    invoices_created: welcome.invoices_created,
    permissions: permissions.permission,
    document: document,
    tds_sections: document.tdsSections,
    tcs_sections: document.tcsSections,
    countries: document.countries,
    currencies: document.currencies,
    product_categories: document.categories,
    port_codes: document.portCodes,
    document_settings: user.document_settings,
    invoice_settings: document.invoiceSettings,
    warehouses: warehouse.warehouses,
    custom_columns: document.custom_column_data.custom_columns,
    invoice_tabs: document.invoice_tabs,
    top_fields: document.top_custom_fields,
    user: user,
    stock_in_warehouses,
    stock_out_warehouses,
    default_warehouse_id: warehouse?.default_warehouse_id,
    isContentMaxWidth: settings.isContentMaxWidth,
    isAppMaxWidth: settings.isAppMaxWidth,
    isGrayBackground: true,
    isSquaredBorders: settings.isSquaredBorders,
    isCardShadow: settings.isCardShadow,
    isBorderless: settings.isBorderless,
    isTopbarFixed: true,
    isGrayTopbar: settings.isGrayTopbar,
    all_doc_counts: document?.all_doc_counts,
    isDocumentCreated: document?.all_doc_counts
      ? Object.keys(document?.all_doc_counts).some(key => document?.all_doc_counts?.[key] > 0)
      : false,
  }
}

export default connect(mapStateToProps)(CreateDoc)
