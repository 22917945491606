import { Button, Modal, Spin, message } from 'antd'
import FeatureVideoModal from 'components/modal/featureVideoModal'
import { displayRazorpay } from 'components/other/utility'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPIData, getAPI, trackUserActivity } from 'services/jwt'
import CustomPricing from '../pricing'
import { get_pricing_plans } from 'redux/document/actions'
import { FormattedMessage } from 'react-intl'
import store from 'store'
import { history } from 'index'

const SubscriptionPlanModal = forwardRef((props, ref) => {
  const { pricing_details } = useSelector(state => state.document)
  const { subscription_features } = useSelector(state => state.user)
  const [visible, setVisible] = useState(false)
  const [features, setFeatures] = useState({})
  const [planId, setPlanId] = useState(-1)
  const [featureType, setType] = useState('')
  const [loading, setLoading] = useState(true)
  const [featureData, setFeatureData] = useState({})
  const [requestReceived, setRequestReceived] = useState(false)
  const dispatch = useDispatch()

  const customPricingRef = useRef(null)
  const tutorialRef = useRef()

  const openModal = async (type = '') => {
    setVisible(true)
    // setPlanId(modalData[type].planId)
    setType(type)
    if (!subscription_features || subscription_features.length == 0) {
      getSubscriptionFeatures(type)
    } else {
      const planData = subscription_features.filter(plan => plan.feature_key == type)
      if (planData.length > 0) {
        setFeatureData({
          ...planData[0],
        })
        setPlanId(planData[0].plan_id)
      }
    }
    setLoading(false)
  }

  useImperativeHandle(ref, () => ({
    openModal: openModal,
  }))

  const onClose = () => {
    setVisible(false)
    setPlanId(-1)
    setType('')
  }

  const getSubscriptionFeatures = async type => {
    setLoading(true)
    const data = await getAPIData('v2/leads', 'subscription_features')
    const feature = data?.data?.filter(plan => plan.feature_key == type)
    if (feature.length > 0) {
      setFeatureData({
        ...feature[0],
      })
      setPlanId(feature[0].plan_id)
    }
    dispatch({
      type: 'user/SET_STATE',
      payload: {
        subscription_features: data.data,
      },
    })
    setLoading(false)
  }

  useEffect(() => {
    if (Object.keys(pricing_details).length == 0) {
      dispatch(get_pricing_plans())
    } else {
      const plan = pricing_details.pricing_plans.filter(plan => plan.plan_id == planId)
      if (plan.length > 0) {
        setFeatures(plan[0])
      }
    }
  }, [planId, featureType])

  return (
    <>
      <Modal
        visible={visible}
        centered
        width={720}
        onCancel={onClose}
        footer={null}
        className="promotional-modal"
      >
        {loading ? (
          <div className="flex justify-center items-center" style={{ minHeight: '500px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="mb-4 pb-2">
            <div
              className={`${featureData.title ? 'p-4' : 'px-4'}`}
              style={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                background: `linear-gradient(to bottom right, ${features.web_color +
                  '00'}, ${features.web_color + '15'})`,
              }}
            >
              <div className="mb-4 px-4 pt-4">
                <i className="fa-duotone fa-sparkles fa-2x"></i>
              </div>
              {featureData.title && (
                <h4 className="font-weight-bold mb-1 px-4">{featureData.title}</h4>
              )}
              <p className="mt-0 font-weight-normal text-gray-500 font-size-14 px-4">
                This feature is part of our{' '}
                <span className="font-weight-bolder text-gray-500">{features.web_title} Plan</span>.
              </p>
              {featureData.description && (
                <ul className="list-unstyled px-4 mt-4 mb-0">
                  {featureData.description.split('.').map((point, index) => (
                    <>
                      {point.length > 5 && (
                        <li key={index} className="py-2">
                          <div className="flex items-center">
                            <div>
                              <div className="text-gray-900 font-size-18">
                                <i className="fa-sharp fa-solid fa-circle-check mr-3"></i>
                              </div>
                            </div>
                            <div>
                              <span className="font-weight-normal font-size-17">{point}</span>
                            </div>
                          </div>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              )}
              {/*{featureData.description && (
                <div className="mb-3 text-gray-600 px-4 pt-2 mt-4">{featureData.description}</div>
              )}*/}
              {/* Text buttons */}
              <div className="text-left mb-4 ml-0 pl-0 px-4 mt-4">
                {featureData.blog_link && (
                  <Button
                    className="mr-4 bg-gray-100 font-weight-medium"
                    type="text"
                    onClick={() =>
                      window.open(featureData.blog_link, '_blank', 'noopener noreferrer')
                    }
                  >
                    <i className="fa fa-file-lines fa-fw mr-2" />
                    <span className="text-dark">Learn More</span>
                  </Button>
                )}
                {featureData.video_link && (
                  <Button
                    className="bg-gray-100 font-weight-medium"
                    type="text"
                    onClick={() => tutorialRef.current.showModal(featureData.video_link)}
                  >
                    <i className="fa fa-circle-play fa-fw mr-2" />
                    <span className="text-dark">Watch Video</span>
                  </Button>
                )}
              </div>
            </div>

            <div className="flex justify-between items-center flex-wrap px-4 mt-2 mx-4">
              <div>
                <h5
                  className={`mb-2 ${
                    featureData.title ? 'pt-4' : ''
                  } font-weight-medium text-gray-900`}
                >
                  All features in{' '}
                  <span className="font-weight-bolder">{features.web_title} Plan</span>
                </h5>
                <p className="mt-1 font-weight-normal text-gray-400 font-size-14">
                  {features.sub_title}
                </p>
              </div>
              <Button
                type="link"
                size="large"
                onClick={() => {
                  window.open('/pricing')
                }}
                className="justify-end"
              >
                <span
                  style={{
                    borderBottom: '1px solid rgb(59,100,253)',
                  }}
                  className="font-size-14 font-weight-medium text-primary"
                >
                  View all Plans
                </span>
                <i className="fa-solid fa-arrow-up-right-from-square font-size-14 font-weight-medium text-primary ml-2"></i>
              </Button>
            </div>

            <div className="w-full flex flex-wrap justify-between mb-4 mx-4 mt-2 px-4">
              {features?.features?.map(item => {
                return (
                  <div className="w-1/2 mb-3 text-gray-700 font-weight-medium px-4">
                    <i
                      className={`fa fa-${item.icon} fa-fw font-size-16 font-weight-bold ${
                        item.icon == 'plus' ? 'text-dark' : 'text-green'
                      } mr-3`}
                    ></i>
                    <span
                      className={
                        item.title.toLowerCase().includes(featureType.toLowerCase())
                          ? 'font-weight-bold'
                          : ''
                      }
                    >
                      {item.title}
                    </span>
                    <span className="text-gray-500 font-size-12 ml-2">{item.description}</span>
                  </div>
                )
              })}
            </div>

            {planId != -1 && (
              <div className="px-4 mx-4 mb-2 text-center flex justify-between">
                {/* Contact Sales Button */}
                <Button
                  type="biz-plan"
                  size="large"
                  className="font-weight-medium mb-2"
                  style={{ width: '48%' }}
                  onClick={async event => {
                    if (!requestReceived) {
                      await trackUserActivity({
                        feature_screen: featureData?.feature_key,
                        feature_title: featureData?.title,
                      })
                        .then(res => setRequestReceived(res))
                        .then(() => {
                          if (store.get('paid') == 1) {
                            window.open(
                              `https://app.getswipe.in/help?msg=${featureData.contact_text}`,
                            )
                          }
                        })
                    }
                  }}
                >
                  {requestReceived ? (
                    <span className="mr-2 font-weight-bold font-size-16">
                      <i className="fa-solid fa-circle-check fa-lg text-success mr-2" />
                      Request received!
                    </span>
                  ) : (
                    <span className="mr-2 font-weight-bold font-size-16">
                      <i className="fa-lg font-weight-bold fa-solid fa-headset mr-3"></i>
                      Talk to a specialist
                    </span>
                  )}
                </Button>

                <Button
                  type="biz-plan-bg"
                  size="large"
                  className="font-weight-bold mb-2"
                  style={{ width: '48%' }}
                  onClick={() => {
                    if (planId == 3) {
                      history.push('/pricing')
                    } else {
                      customPricingRef.current.showModal(planId)
                    }
                  }}
                >
                  {planId == 3 ? 'Subscribe Now' : <>{features?.button?.text}</>}
                </Button>
              </div>
            )}
            {requestReceived && (
              <p className="text-gray-500 text-center mb-4">
                <FormattedMessage id="supportMessage" />
              </p>
            )}
          </div>
        )}
      </Modal>

      {featureData.video_link != '' && <FeatureVideoModal ref={tutorialRef} />}

      <CustomPricing
        ref={customPricingRef}
        onSelectPlan={(plan, years, addons) => displayRazorpay(plan, years, addons)}
      />
    </>
  )
})

export default SubscriptionPlanModal
