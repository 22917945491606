import { Button, Spin, message } from 'antd'
import { Loader } from 'components/loaders'
import FeatureVideoModal from 'components/modal/featureVideoModal'
import CustomPricing from 'components/modal/pricing'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAPIData, getAPI, trackUserActivity } from 'services/jwt'
import { displayRazorpay } from './utility'
import { FormattedMessage } from 'react-intl'
import store from 'store'

export default function Experience({ type, onClick, toUpgrade, minHeight = '72vh' }) {
  const tutorialRef = React.createRef()
  // const type_details = experience[type]
  const history = useHistory()

  // const { title, leadText, image, points, buttonText, help_link } = type_details

  const { subscription_features } = useSelector(state => state.user)
  const [featuresList, setFeaturesList] = useState([])
  const [subscriptionPlan, setSubscriptionPlan] = useState({})
  const [pointsList, setPointsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [show_video, setShowVideo] = useState(false)
  const [requestReceived, setRequestReceived] = useState(false)
  const customPricingRef = useRef()
  const subscriptionPlanRef = useRef()

  const dispatch = useDispatch()

  const loadScript = src => {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    setLoading(true)
    const fetchSubscriptionFeatures = async () => {
      const subscription_features = await getAPIData('v2/leads', 'subscription_features')

      if (subscription_features && subscription_features.success) {
        setFeaturesList(subscription_features.data)
        const plan = subscription_features.data.filter(plan => plan.feature_key == type)[0]
        setSubscriptionPlan({
          ...plan,
        })
        const pointsData = plan?.description?.split('.').filter(point => point.trim() !== '')
        setPointsList(pointsData)
        setLoading(false)

        dispatch({
          type: 'user/SET_STATE',
          payload: {
            subscription_features: subscription_features.data,
          },
        })
      }
    }
    if (!subscription_features || subscription_features.length == 0) {
      fetchSubscriptionFeatures()
    } else {
      setFeaturesList(subscription_features)
      const plan = subscription_features?.filter(plan => plan.feature_key == type)[0] || {}
      setSubscriptionPlan({ ...plan })
      const pointsData = plan?.description?.split('.').filter(point => point.trim() !== '') || []
      setPointsList(pointsData)
      setLoading(false)
    }
  }, [])

  return (
    <div
      className="container flex justify-center items-center w-100p"
      style={{ minHeight: minHeight }}
    >
      {loading ? (
        <>
          <Loader text={'Loading...'} visibleText={true} />
        </>
      ) : (
        <div className="flex justify-center px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row items-center w-full max-w-screen-lg">
            <div className="flex justify-center lg:justify-end w-full lg:w-5/12 mb-4 lg:mb-0">
              <img
                src={subscriptionPlan?.experience_image}
                className="object-contain h-full w-full mr-5"
                alt="..."
              />
            </div>

            <div className="flex flex-col flex-grow w-full lg:w-6/12 lg:pl-5">
              <h1 className="font-weight-bolder mb-4 font-size-32">{subscriptionPlan?.title}</h1>
              <p className="lead text-gray-500">{subscriptionPlan?.tagline}</p>
              <ul className="list-none mt-4 mb-0">
                {pointsList?.map((point, index) => (
                  <li key={index} className="py-2">
                    <div className="flex items-center">
                      <div>
                        <div className="text-gray-900 font-size-16">
                          <i className="fa-sharp fa-solid fa-circle-check mr-3"></i>
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-medium text-gray-900 font-size-16">
                          {point}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {subscriptionPlan?.experience_button_text != '' && (
                <Button
                  onClick={onClick}
                  type="action"
                  size="large"
                  className="font-weight-bolder mt-4"
                >
                  <i className="fa fa-plus mr-2" />
                  {subscriptionPlan?.experience_button_text}
                </Button>
              )}
              {toUpgrade && window.location.hostname != 'mac.getswipe.in' && (
                <div className="mt-4">
                  <Button
                    type="sign-up"
                    size="large"
                    onClick={() =>
                      subscriptionPlanRef.current.openModal(
                        subscriptionPlan?.feature_key,
                        subscriptionPlan?.plan_id,
                      )
                    }
                    className="font-weight-bold"
                  >
                    Upgrade Now 🚀
                  </Button>
                </div>
              )}

              {window.location.hostname != 'mac.getswipe.in' && (
                <div className="mt-4">
                  <help_link
                    type="text"
                    size=""
                    onClick={async event => {
                      event.stopPropagation()
                      if (!requestReceived) {
                        await trackUserActivity({
                          feature_screen: subscriptionPlan?.feature_key,
                          feature_title: subscriptionPlan?.title,
                        })
                          .then(res => setRequestReceived(res))
                          .then(() => {
                            if (store.get('paid') == 1) {
                              window.open(
                                `https://app.getswipe.in/help?msg=${subscriptionPlan.contact_text}`,
                              )
                            }
                          })
                      }
                    }}
                    className="font-weight-bold"
                  >
                    {!requestReceived && (
                      <>
                        <i className="fa-solid fa-headset mr-2"></i> Talk to a specialist
                      </>
                    )}
                  </help_link>
                  {requestReceived && (
                    <>
                      <span>
                        <i className="fa-solid fa-circle-check text-success mr-2" />
                        Request received!
                      </span>
                      <p className="text-gray-500">
                        <FormattedMessage id="supportMessage" />
                      </p>
                    </>
                  )}
                </div>
              )}
              {subscriptionPlan?.video_link != '' && (
                <div className="mt-4">
                  <help_link
                    onClick={() => tutorialRef.current.showModal(subscriptionPlan?.video_link)}
                  >
                    <i className="fa-brands fa-youtube mr-1"></i> Watch a quick video here
                  </help_link>
                </div>
              )}
              {/*<FooterComponent />*/}
            </div>
          </div>
        </div>
      )}

      {subscriptionPlan?.video_link != '' && <FeatureVideoModal ref={tutorialRef} />}

      <CustomPricing
        ref={customPricingRef}
        onSelectPlan={(plan, years, addons) => displayRazorpay(plan, years, addons)}
      />
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </div>
  )
}
