import 'firebase/analytics'
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/messaging'
import 'firebase/remote-config'
import { getAPI } from 'services/jwt'
import 'firebase/performance'
import store from 'store'
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBIREKUF69cxvtcR20jiTHsoibyyssKilc',
    authDomain: 'vectorx-business.firebaseapp.com',
    projectId: 'vectorx-business',
    storageBucket: 'vectorx-business.appspot.com',
    messagingSenderId: '448547958118',
    appId: '1:448547958118:web:c8142a4cffc20058892ca7',
    measurementId: 'G-KV3611HNQY',
    databaseURL: 'https://vectorx-business-default-rtdb.firebaseio.com/',
  })
}

firebase.analytics()
export const performance = firebase.performance()

const config = firebase.remoteConfig()
config.settings = {
  minimumFetchIntervalMillis: 36000,
}
config.defaultConfig = {
  web_logo: '/resources/images/logo-w.svg',
  user_updates: [],
  web_version: {
    version: '1.0.0',
    important: false,
    success: false,
  },
}

config
  .fetchAndActivate()
  .then(() => {
    console.log('Remote Config fetched and activated')
  })
  .catch(error => {
    console.error('Error fetching and activating Remote Config:', error)
    if (error.code === 'remoteconfig/storage-open') {
      console.error('IndexedDB error:', error.message)
      // Handle the error, e.g., use local storage as a fallback
    }
  })

export let messaging = null

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}

const pub_key =
  'BCvwQ-l6wDDqP7DFw7QQZCcDwcioixs9pbuCZyJPkXlZ_BqaGKOzW_w5vMOxliLllW24N46clVJd_5I9SAyUAr4'

export const getToken = setTokenFound => {
  if (!messaging) return
  return messaging
    .getToken({ vapidKey: pub_key })
    .then(async currentToken => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken)
        setTokenFound(true)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        const req = {
          device_token: currentToken,
        }
        const data1 = await getAPI('user', 'add_device_token', req)
        // console.log(data1)
      } else {
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const requestPermission = setTokenFound => {
  if (!messaging) return
  return messaging
    .requestPermission()
    .then(function(e) {
      console.log('Notification permission granted.')
      // TODO(developer): Retrieve a Instance ID token for use with FCM.
      // ...
      // getToken(setTokenFound)
      alert('Click on the Lock Icon beside the url to Disable notifications')
    })
    .catch(function(err) {
      console.log('Unable to get permission to notify. ', err)
      alert('Click on the Lock Icon beside the url to Allow notifications')
    })
}

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      // console.log('inside message')
      resolve(payload)
    })
  })

export const fetchAndActivate = async () => {
  await config.fetchAndActivate().catch(error => {
    console.error(error)
  })
}

export const GetLogo = async () => {
  await fetchAndActivate()
  if (store.get('app.settings.theme') == 'swipedark') {
    return config.getValue('web_logo_dark')._value
  }
  return config.getValue('web_logo')._value
}

export const GetBeaconValue = async () => {
  await fetchAndActivate()
  return config.getValue('web_beacon_value')._value
}

export const GetVersion = async () => {
  try {
    await fetchAndActivate()
    let data = config.getValue('web_version')._value
    data = JSON.parse(data)
    return data
  } catch (e) {
    console.log(e)
  }
  return { version: '1.0.0', important: false, success: false }
}

export const fetchUserUpdates = async () => {
  var updates = []
  try {
    await fetchAndActivate()
    // console.log(config.getValue('user_updates_dev')._value)
    updates =
      process.env.REACT_APP_STAGE == 'development'
        ? config.getValue('user_updates_dev')._value
        : config.getValue('user_updates_web')._value // for prod
    updates = JSON.parse(updates)
  } catch (error) {
    console.log(error)
  }
  return updates
}

export default firebase
