import { createBrowserHistory } from 'history'
import './global.scss' // app & third-party component styles

import { LicenseManager } from '@ag-grid-enterprise/core'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import createSagaMiddleware from 'redux-saga'
import Localization from './localization'
import firebase from './pages/firebasejs'
import IntercomComp from './pages/support/IntercomComp'
import SentryComp from './pages/support/SentryComp'
import configureStore from './redux'
import sagas from './redux/sagas'
import AppRouter from './router'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://0db1d1676cd07ef03bdb6b567fc6729b@o4504082381406208.ingest.us.sentry.io/4507980791283712',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/app\.getswipe\.in/,
      /^https:\/\/mac\.getswipe\.in/,
      /^https:\/\/swipe\.pe/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-058571}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Nextspeed_Technologies,_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Swipe}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Swipe}_need_to_be_licensed___{Swipe}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_April_2025}____[v3]_[01]_MTc0NDg0NDQwMDAwMA==de6cdc7fc9fb24a9f880a7a124e6c1fd',
)

const queryClient = new QueryClient()

// middlewares
const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const INTERCOM_APP_ID = 'be01q1gj'

const { store, reduxHistory } = configureStore(history, sagaMiddleware)
sagaMiddleware.run(sagas)

window.addEventListener('error', function(event) {
  if (event?.error?.hasBeenCaught !== undefined) {
    return false
  }
  firebase.analytics().logEvent('exception', event.error)
  if (event && event.error) {
    event.error.hasBeenCaught = true
  }
})

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Localization>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <IntercomComp />
            <SentryComp />
            <Router history={reduxHistory}>
              <AppRouter />
            </Router>
          </IntercomProvider>
        </Localization>
      </QueryClientProvider>
    </Provider>,
  )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.registerServiceWorker()

// serviceWorker.unregister()
export { history, store }
