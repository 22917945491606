import { Button, Checkbox, Input, InputNumber, Modal, Select, Table, Tooltip, message } from 'antd'
import BetaBadge from 'components/badges/beta'
import ProBadge from 'components/badges/pro'
import BatchingForm from 'components/forms/batchingForm'
import InventoryForm from 'components/forms/inventoryForm'
import { Loader } from 'components/loaders'
import { getPrice, getPriceWithTax } from 'components/other/calculation'
import { getExpiryDays } from 'components/other/utility'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { get_batch_settings } from 'redux/document/actions'
import { getAPI } from 'services/jwt'
import store from 'store'
import SubscriptionPlanModal from '../subscription/subscriptionPlan'

dayjs.extend(utc)

const { forwardRef, useRef, useImperativeHandle } = React
const { Option } = Select

const ProductBatches = forwardRef((props, ref) => {
  const batchingForm = useRef()
  const inventoryFormRef = useRef()
  const batchSettings = useSelector(state => state.document.batch_settings) || {}
  const warehouse_permissions = useSelector(state => state.warehouse?.warehouse_permissions) || {}
  const default_warehouse_id = useSelector(state => state.warehouse?.default_warehouse_id)

  const [visible, setVisible] = useState(false)
  const [expiryWarningShowed, setExpiryWarningShowed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [batches, setBatches] = useState([])
  const [batchSearch, setBatchSearch] = useState('')
  const [data, setData] = useState({})
  const [batch_settings, setBatchSettings] = useState({})
  const subscriptionPlanRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    getBatchSettings()
  }, [batchSettings])

  const getBatchSettings = () => {
    if (Object.keys(batchSettings).length == 0 || batchSettings == undefined || !batchSettings) {
      dispatch(get_batch_settings())
    }
    setBatchSettings(batchSettings)
  }

  const getBatches = async (data, fetch) => {
    setVisible(true)
    setLoading(true)
    let batchList = []
    if (fetch) {
      const fetch_batches = await getAPI('product', 'batches', {
        product_id: data.product_id,
        variant_id: data.variant_id || 0,
        warehouse_id: props.warehouse_id,
      })
      if (fetch_batches && fetch_batches.success) {
        let total_batches = fetch_batches.batches
        total_batches.map(_batch => {
          let same_batches = data.batches.filter(b => b.batch_id == _batch.batch_id)
          if (same_batches.length > 0) {
            same_batches.map(same_batch => {
              batchList.push({
                ..._batch,
                ...same_batch,
                qty: _batch.qty,
              })
            })
          } else {
            batchList.push(_batch)
          }
        })
      }
    } else {
      batchList = data.batches
    }

    let batchData = await batchList.map((batch, index) => {
      if (data.batches && data.batches.length > 0 && batch.batch_uid != undefined) {
        let product_batch = data.batches.find(b => batch.batch_uid == b.batch_uid)
        if (product_batch) {
          return {
            ...batch,
            added_qty: product_batch.added_qty || 0,
            is_edit: product_batch.is_edit || 0,
            old_qty: product_batch.old_qty || 0,
            qty:
              product_batch.is_edit && props.document_type != 'purchase'
                ? product_batch.old_qty + batch.qty
                : batch.qty,
            unit_id: product_batch.unit_id ?? batch.unit_id ?? 0,
            conversion_rate: product_batch.conversion_rate ?? batch.conversion_rate ?? 1,
            changed: product_batch.added_qty > 0 ? true : false,
            price_with_tax: product_batch.price_with_tax || batch.price_with_tax || 0,
            price: product_batch.unit_price || batch.unit_price || 0,
            unit_price: product_batch.unit_price || batch.unit_price || 0,
            batch_uid: product_batch.batch_uid || String(batch.batch_id) + '_' + String(0),
            is_duplicate: product_batch.is_duplicate || false,
          }
        }
      }

      return {
        ...batch,
        added_qty: 0,
        is_edit: 0,
        old_qty: batch.old_qty || 0,
        qty: batch.qty,
        unit_id: batch.unit_id || 0,
        conversion_rate: batch.conversion_rate || 1,
        changed: false,
        price_with_tax: batch.price_with_tax,
        price: batch.unit_price,
        unit_price: batch.unit_price,
        batch_uid: batch.batch_uid || String(batch.batch_id) + '_' + String(0),
        is_duplicate: batch.is_duplicate || false,
      }
    })
    if (data.has_batches == 2) {
      if (props.document_type == 'invoice' || props.document_type == 'purchase_return') {
        batchData = batchData.filter(b => b.qty == 1 || b.old_qty == 1)
      } else if (props.document_type == 'sales_return') {
        batchData = batchData.filter(b => b.qty == 0)
      }
    }

    setBatches(batchData)
    setVisible(true)
    setLoading(false)
  }

  const getBatchesOnStockIn = async (data, newQty, batchId) => {
    const fetched_batches = await getAPI('product', 'batches', {
      product_id: data.product_id,
      variant_id: data.variant_id || 0,
    })

    let updated_batches = batches?.map(batch => {
      let fetched_batch = fetched_batches?.batches?.find(b => b.batch_id == batch.batch_id)
      if (fetched_batch) {
        if (batch.batch_id == batchId) {
          return {
            ...batch,
            qty: newQty,
            added_qty: 0,
            old_qty: 0,
          }
        }
      }
      return batch
    })

    setBatches(updated_batches)
  }

  const checkOldProductsBeforeEdit = async product => {
    let old_items = [...props.old_items_before_edit]
    const old_product = old_items.find(
      p => p.product_id === product.product_id && p.variant_id === product.variant_id,
    )

    if (old_product && old_product.batches) {
      const atleast_one_batch_added = product.batches.filter(
        batch => batch.added_qty && batch.added_qty > 0,
      )
      if (atleast_one_batch_added && atleast_one_batch_added.length == 0) {
        const old_product_batches = old_product.batches.reduce((acc, oldBatch) => {
          acc[oldBatch.batch_id] = oldBatch
          return acc
        }, {})

        let final_batches = product.batches.map(batch => {
          if (old_product_batches[batch.batch_id]) {
            batch.old_qty = old_product_batches[batch.batch_id].old_qty
            batch.is_edit = 1
          }
          return batch
        })

        product.batches = final_batches
      }
    }
    return product
  }

  const showModal = async (product, is_edit) => {
    if (is_edit) {
      product = await checkOldProductsBeforeEdit(product)
    }
    getBatchSettings()
    setBatches([])
    setData(product)
    getBatches(product, is_edit || !product.batches)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const checkQuantites = batches => {
    let all_batches = [...batches]

    all_batches = all_batches.reduce((acc, batch) => {
      if (acc[batch.batch_id]) {
        acc[batch.batch_id].qty_check += batch.added_qty
      } else {
        acc[batch.batch_id] = { ...batch, qty_check: batch.added_qty }
      }
      return acc
    }, {})

    all_batches = Object.values(all_batches)

    let check = true
    all_batches.forEach(batch => {
      if (batch.qty_check > batch.qty && batch.qty_check != 0) {
        message.error(
          'Added Quantity must be less than or equal to available quantity' +
            'Check Batch No: ' +
            batch.batch_no,
        )
        check = false
        return
      }
    })

    return check
  }

  const onFinish = async () => {
    if (
      data.has_batches == 1 &&
      !props.document_type.includes('purchase') &&
      props.document_type != 'sales_return' &&
      !checkQuantites([...batches])
    ) {
      return
    }

    await props.onFinishBatchesQty(data, batches)
    onClose()
    setData({})
  }
  const onClose = () => {
    setVisible(false)
    setExpiryWarningShowed(false)
  }
  const showExpiryWarning = (value, record) => {
    setExpiryWarningShowed(true)
    Modal.confirm({
      title: 'Are you sure you want to add ?',
      content: (
        <>
          <p className="font-size-18">
            <span>
              This Batch{' '}
              <span className="font-weight-bold text-danger">
                {getExpiryDays(record.expiry_date)}
              </span>
              . Are you sure you want to add this?
              <br />
            </span>
          </p>
        </>
      ),
      width: '30%',
      icon: <i className="fad fa-info-circle fa-beat mr-3 mt-2 text-danger"></i>,
      okText: 'Confirm',
      onOk: async () => {
        changeQty(value, record)
      },
      onCancel: () => {},
    })
  }
  const changeQty = (value, record) => {
    if (!props.document_type.includes('purchase') && props.document_type != 'sales_return') {
      if (parseFloat(value) > record.qty + record.old_qty) {
        message.error('Insufficient Stock in batch! Please add the stock')
        return
      }
    }
    const newBatches = batches.map(item => {
      if (item.batch_uid == record.batch_uid) {
        if (props.document_type.includes('purchase') && item.purchase_price != 0) {
          item.price = item.purchase_unit_price
          item.unit_price = item.price
          item.price_with_tax = item.purchase_price
        }
        // if (value == 0 && item.is_edit) {
        //   item.qty = item.old_qty
        // }
        return {
          ...item,
          added_qty: parseFloat(value) ? parseFloat(value) : 0,
          changed: data.has_batches == 2 && value == 0 ? false : true,
        }
      } else {
        return { ...item, added_qty: item.added_qty != undefined ? item.added_qty : 0 }
      }
    })

    setBatches(newBatches)
  }

  const onChangeQty = async (value, record) => {
    if (!value) {
      value = 0
    }
    if (value < 0) {
      message.error('Added Quantity must be greater than 0')
      return
    }
    if (record.expiry_date != '' && record.expiry_date != '0000-00-00' && !expiryWarningShowed) {
      let dif_days = dayjs(record.expiry_date, 'DD-MM-YYYY').diff(dayjs(), 'day')
      if (dif_days < batchSettings.expiry_tolerance) {
        showExpiryWarning(value, record)
      } else {
        changeQty(value, record)
      }
    } else {
      changeQty(value, record)
    }
  }

  const onChangePrices = (value, record, type) => {
    if (!value) {
      value = 0
    }
    if (value < 0) {
      message.error('Value must be greater than 0')
      return
    }

    const newBatches = batches.map(item => {
      if (item.batch_uid == record.batch_uid) {
        if (type == 'price') {
          return {
            ...item,
            price: parseFloat(value) ? parseFloat(value) : 0,
            unit_price: parseFloat(value) ? parseFloat(value) : 0,
            price_with_tax: getPriceWithTax({
              ...item,
              price: value,
              unit_price: value,
              tax: data.tax,
              cess: data.cess,
              cess_on_qty_value: 0,
            }),
          }
        }
        if (type == 'price_with_tax') {
          return {
            ...item,
            price_with_tax: parseFloat(value) ? parseFloat(value) : 0,
            price: getPrice({
              ...item,
              price_with_tax: value,
              tax: data.tax,
              cess: data.cess,
              cess_on_qty_value: 0,
            }),
            unit_price: getPrice({
              ...item,
              price_with_tax: value,
              tax: data.tax,
              cess: data.cess,
              cess_on_qty_value: 0,
            }),
          }
        }
      } else {
        return item
      }
    })
    setBatches(newBatches)
  }

  const handleDuplicate = record => {
    let newBatches = [...batches]
    let newBatch = { ...record }

    // get the latest batch_uid
    let all_batches_with_same_id = newBatches.filter(b => b.batch_id == record.batch_id)
    let uid = 0
    if (all_batches_with_same_id.length > 0) {
      uid = all_batches_with_same_id[all_batches_with_same_id.length - 1].batch_uid
    }

    uid = uid.split('_')
    uid = uid[0] + '_' + String(parseInt(uid[1]) + 1)

    newBatch.batch_uid = uid
    newBatch.added_qty = 0
    newBatch.changed = false
    newBatch.is_edit = 0
    newBatch.old_qty = 0
    newBatch.is_duplicate = true

    let index = newBatches.findIndex(b => b.batch_uid == record.batch_uid)
    newBatches.splice(index + 1, 0, newBatch)

    setBatches(newBatches)
  }

  const onChangeUnit = (value, record) => {
    const newBatches = batches.map(item => {
      if (item.batch_uid == record.batch_uid) {
        return {
          ...item,
          unit_id: value,
          unit: data.units?.filter(u => u.unit_id == value)?.[0]?.alternative_unit,
          conversion_rate: data.units?.filter(u => u.unit_id == value)?.[0]?.conversion_rate || 1,
        }
      } else {
        return item
      }
    })
    setBatches(newBatches)
  }

  const selectUnits = (data, batch) => (
    <span className="select-borderless">
      <Select
        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
        placeholder="Unit"
        optionFilterProp="children"
        style={{ maxWidth: '90px' }}
        dropdownStyle={{ minWidth: '300px' }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={data.units?.filter(item => item?.unit_id == batch?.unit_id)?.[0]?.unit_id}
        value={batch?.unit_id == 0 || batch?.unit_id == null ? 0 : batch?.unit_id}
        onChange={value => {
          onChangeUnit(value, batch)
        }}
        // disabled={}
      >
        {data.units?.map((e, i) => (
          <Option key={i} value={e.unit_id}>
            {e.alternative_unit}{' '}
            {data.unit != '' && e.primary_unit == undefined
              ? ` - (1 ${data.units?.[0]?.alternative_unit} = ${e.conversion_rate} ${e.alternative_unit})`
              : ''}
          </Option>
        ))}
      </Select>
    </span>
  )
  const onInputFocus = e => {
    e.target.select()
  }

  let columns = [
    {
      title: data.has_batches == 2 ? batch_settings.serial_number_label : 'Batch No',
      dataIndex: 'batch_no',
      key: 'batch_no',
      width: data.has_batches == 1 ? '10%' : '50%',
      hidden: false,
      render: (text, record) => (
        <>
          {
            <span>
              <span style={{ width: 50 }}>
                {data.has_batches == 1 &&
                !record.is_duplicate &&
                (record.qty + record.old_qty > 0 || props.document_type == 'purchase') &&
                props.document_type != 'purchase_return' &&
                props.document_type != 'sales_return' ? (
                  <Tooltip
                    title={
                      <>
                        <span className="text-white font-size-14 font-weight-medium">
                          Duplicate Batch
                        </span>
                        <p className="text-gray-50 font-size-14">
                          Preferred when you want to use same batch with different Price.
                        </p>
                      </>
                    }
                  >
                    <span
                      className="text-gray-700"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation()
                        handleDuplicate(record)
                      }}
                    >
                      {/* duplicate  */}
                      <i className="fa fa-clone text-primary mr-2" />
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    className="text-gray-700"
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    {/* filling */}
                    {data.has_batches == 1 && (
                      <>
                        {record.is_duplicate ? (
                          <i className="fa fa-xmark text-white mr-2" />
                        ) : (
                          <i className="fa fa-xmark text-danger mr-2" />
                        )}
                      </>
                    )}
                  </span>
                )}
              </span>
              <span className="text-gray-700 font-weight-medium">{record.batch_no}</span>
              {data.has_batches == 1 && !record?.is_edit == 1 && (
                <div className="font-size-12 mt-1">
                  {record.mfg_date != '' && record.mfg_date != '0000-00-00' && (
                    <>
                      <span className="text-gray-500">Mfg Dt: </span>
                      <span className="text-gray-500">{record.mfg_date}</span>
                    </>
                  )}
                  {record.expiry_date != '' && record.expiry_date != '0000-00-00' && (
                    <div
                      className={
                        dayjs(record.expiry_date).isBefore(dayjs())
                          ? 'text-danger'
                          : 'text-gray-500'
                      }
                    >
                      <span>Exp Dt: </span>
                      <span>{record.expiry_date}</span>
                      <p>{getExpiryDays(record.expiry_date)}</p>
                    </div>
                  )}
                </div>
              )}
            </span>
          }
        </>
      ),
    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      key: 'price',
      width: '10%',
      hidden: props.document_type.includes('purchase'),
      render: (text, record) => {
        // console.log(record)
        return (
          <InputNumber
            onFocus={onInputFocus}
            min={0}
            value={record.unit_price}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              onChangePrices(e, record, 'price')
            }}
            className="px-1 border-radius-small bg-gray-50"
            disabled={props.showPrices == false}
          />
        )
      },
    },
    {
      title: 'Price with Tax',
      dataIndex: 'price_with_tax',
      key: 'price',
      width: '10%',
      hidden: props.document_type.includes('purchase'),
      render: (text, record) => (
        <InputNumber
          onFocus={onInputFocus}
          onClick={e => e.stopPropagation()}
          min={0}
          value={record.price_with_tax}
          onChange={e => {
            onChangePrices(e, record, 'price_with_tax')
          }}
          className="px-1 border-radius-small bg-gray-50"
          disabled={props.showPrices == false}
        />
      ),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchase_price',
      key: 'purchase_price',
      width: '10%',
      hidden: !props.document_type.includes('purchase'),
    },
    {
      title: 'Current Qty',
      dataIndex: 'qty',
      key: 'qty',
      width: '10%',
      hidden: data.has_batches == 2,
      render: (text, record) => (
        <>
          <span className={`${record.qty <= 0 ? 'text-red-500' : ''}`}>{record.qty}</span>
        </>
      ),
    },
    {
      title: data.has_batches == 1 ? 'Added Qty' : 'Select',
      dataIndex: 'added_qty',
      key: 'added_qty',
      width: '10%',
      hidden: false,
      render: (text, record) => (
        <>
          <div className="flex items-center">
            {(record.qty + record.old_qty > 0 ||
              props.document_type == 'purchase' ||
              props.document_type == 'sales_return') && (
              <>
                {data.has_batches == 1 && (
                  <InputNumber
                    onFocus={onInputFocus}
                    value={text}
                    disabled={data.has_batches == 2}
                    onChange={value => {
                      onChangeQty(value, record)
                    }}
                    addonAfter={
                      record.changed ? (
                        data.has_alternative_units == 0 ? (
                          data.unit != '' && data.unit !== 'OTH' && data.unit != 'NA' ? (
                            <span className="text-gray-500">{data.unit}</span>
                          ) : null
                        ) : (
                          selectUnits(data, record)
                        )
                      ) : record.unit != '' && record.unit !== 'OTH' && record.unit != 'NA' ? (
                        <span className="text-gray-500">{record.unit}</span>
                      ) : null
                    }
                  />
                )}
                {data.has_batches == 2 && (
                  <Checkbox
                    className="px-1 border-radius-small bg-gray-50"
                    checked={record.added_qty > 0}
                    onChange={e => {
                      onChangeQty(e.target.checked ? 1 : 0, record)
                    }}
                  />
                )}
              </>
            )}

            {record.qty + (record.old_qty ?? 0) <= 0 &&
              props.document_type != 'purchase' &&
              props.document_type != 'sales_return' &&
              !record.batch_added && (
                <div className="flex flex-col mx-auto justify-center items-center gap-1">
                  <p className="text-danger m-0 text-sm">Low Stock</p>
                </div>
              )}
          </div>
        </>
      ),
    },
  ]

  columns = columns.filter(column => !column.hidden)
  return (
    <>
      <Modal
        title={
          <div className="flex justify-between">
            <span className="text-center font-size-20 font-weight-bold gray-700">
              Add from {data.has_batches == 2 ? batch_settings.serial_number_label : 'Batches'}{' '}
              <BetaBadge />
            </span>
            {props.document_type != 'sales_return' && props.document_type != 'purchase_return' ? (
              <div className="flex items-center">
                {data.has_batches == 1 && props.document_type != 'purchase' && (
                  <Button
                    disabled={warehouse_permissions[default_warehouse_id]['stock_in'] == 0}
                    type={
                      warehouse_permissions[default_warehouse_id]['stock_in'] == 0
                        ? 'disabled'
                        : 'success'
                    }
                    size="small"
                    className="font-bold mr-2 my-1 text-xs"
                    onClick={e => {
                      e.stopPropagation()
                      if (inventoryFormRef.current) {
                        inventoryFormRef.current.onFill(
                          {
                            ...data,
                            batches: batches,
                            variant_id: data.variant_id || 0,
                            name: '',
                            warehouse_id: props.warehouse_id,
                          },
                          true,
                        )
                      }
                    }}
                  >
                    <i className="fa-regular fa-circle-plus mr-1" />
                    <FormattedMessage id="button.stockIn" />
                  </Button>
                )}

                <Button
                  onClick={e => {
                    store.get('paid') == 0
                      ? this.subscriptionPlanRef.current.openModal('batches')
                      : batchingForm.current.onAdd(
                          {
                            ...data,
                            product_name: data.product_name + ' ' + data.variant_name,
                          },
                          false,
                        )
                  }}
                  className="font-weight-bold mr-5 float-right text-primary"
                  size="small"
                >
                  <i className="fa fa-plus-circle mr-2" />
                  Add New {data.has_batches == 2 ? batch_settings.serial_number_label : 'Batch'}
                  {store.get('paid') == 0 && <ProBadge />}
                </Button>
              </div>
            ) : (
              <>
                {data.has_batches == 2 && (
                  <p className="text-gray-400 font-size-15">
                    {props.document_type == 'sales_return'
                      ? 'Displaying only Sold out ' + batch_settings.serial_number_label
                      : 'Displaying only available ' + batch_settings.serial_number_label}
                  </p>
                )}
              </>
            )}
          </div>
        }
        centered
        bodyStyle={{ minHeight: '50vh', overflowY: 'auto', borderRadius: 0 }}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={visible}
        onCancel={() => {
          onClose()
        }}
        destroyOnClose
        maskClosable={false}
        onOk={onFinish}
        cancelButtonProps={{
          type: 'danger',
        }}
        okText="Done"
        width={data.has_batches == 1 ? '60%' : '50%'}
      >
        {loading && (
          <Loader size={'medium'} visibleText={true} text={'Loading Batches.Please wait...'} />
        )}
        {!loading && (
          <>
            {/*add search input  */}
            <Input
              placeholder={
                'Search ' +
                (data.has_batches == 2
                  ? batch_settings?.serial_number_label
                    ? batch_settings?.serial_number_label
                    : 'Serial number'
                  : 'Batches')
              }
              value={batchSearch}
              onChange={e => setBatchSearch(e.target.value)}
              className="mb-3"
              style={{
                width: '50%',
              }}
            />

            <Table
              columns={columns}
              dataSource={
                batchSearch == ''
                  ? batches
                  : batches.filter(b =>
                      b.batch_no.toLowerCase().includes(batchSearch.toLowerCase()),
                    )
              }
              onRow={(record, rowIndex) => {
                if (data.has_batches == 2) {
                  return {
                    onClick: event => {
                      onChangeQty(record.added_qty == 0 ? 1 : 0, record)
                    },
                    style: {
                      cursor: 'pointer',
                    },
                    title: record.added_qty == 0 ? 'Click to select' : 'Click to deselect',
                  }
                }
              }}
              pagination={
                batches.length > 10
                  ? {
                      pageSize: 10,
                    }
                  : false
              }
              className="bg-gray=100"
            />
          </>
        )}
        <InventoryForm
          onRef={ref => (inventoryFormRef.current = ref)}
          onAPICalled={() => {}}
          onUpdateBatchModal={async (newQty, batchId) =>
            await getBatchesOnStockIn(data, newQty, batchId)
          }
          identifier="batches"
        />
      </Modal>
      <BatchingForm
        onRef={() => {}}
        ref={batchingForm}
        onAPICalled={() => getBatches(data, true)}
      />

      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </>
  )
})

export default ProductBatches
