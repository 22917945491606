import { history, store as reduxStore } from 'index'
import qs from 'qs'
import { all, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import actions from './actions'

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SET_PRIMARY_COLOR({ payload: { color } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#primaryColor')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`)
    styleEl.setAttribute('id', 'primaryColor')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'primaryColor',
      value: color,
    },
  })
}

export function* SET_THEME({ payload: { theme } }) {
  const nextTheme = theme === 'swipedark' ? 'swipedark' : 'default'
  const changeTheme = () => {
    const styleElement = document.querySelector('#swipetheme')
    if (styleElement) {
      styleElement.remove()
    }

    document.querySelector('html').setAttribute('data-kit-theme', nextTheme)
    document.querySelector('html').setAttribute('dark', nextTheme == 'default' ? 0 : 1)
    let gray_25 = nextTheme == 'default' ? '#ffffff' : '#1d1d1f'
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const tooltipBg = nextTheme == 'default' ? '#ffffff' : '#333333' // Light or dark background for tooltip
    const tooltipText = nextTheme == 'default' ? '#000000' : '#ffffff' // Dark or light text for tooltip

    const css = document.createTextNode(`
      :root { --kit-theme: '${nextTheme}'; }
      .chart-tooltip {
        background-color: ${tooltipBg} !important;
        color: ${tooltipText} !important;
      }
    `)

    styleEl.setAttribute('id', 'swipetheme')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield changeTheme()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'theme',
      value: nextTheme,
    },
  })
}

export function* SETUP() {
  // load settings from url on app load
  const changeSettings = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    Object.keys(query).forEach(key => {
      let value
      switch (query[key]) {
        case 'false':
          value = false
          break
        case 'true':
          value = true
          break
        default:
          value = query[key]
          break
      }
      if (key === 'theme') {
        reduxStore.dispatch({
          type: 'settings/SET_THEME',
          payload: {
            theme: value,
          },
        })
        return
      }
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: key,
          value,
        },
      })
    })
  }
  yield changeSettings(history.location.search)
  yield history.listen(params => {
    const { search } = params
    changeSettings(search)
  })

  // set primary color on app load
  const primaryColor = () => {
    const color = store.get('app.settings.primaryColor')
    if (color) {
      reduxStore.dispatch({
        type: 'settings/SET_PRIMARY_COLOR',
        payload: {
          color,
        },
      })
    }
  }
  yield primaryColor()

  // init theme on app load
  const initTheme = () => {
    const { search } = history.location
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const theme = query.theme || store.get('app.settings.theme') || 'default'
    reduxStore.dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme,
      },
    })

    reduxStore.dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'logo',
        value:
          theme === 'swipedark' ? '/resources/images/logo-b.svg' : '/resources/images/logo-w.svg',
      },
    })
  }
  yield initTheme()

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      })
    }
  }

  // detect viewport width on app load and window resize
  const isMenuToggled = () => {
    const shouldToggle = global.window.innerWidth < 1024
    const prevState = store.get('app.settings.isMenuCollapsed')
    if (shouldToggle || prevState) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: true,
        },
      })
    }
  }

  yield isMobileView(true)
  yield isMenuToggled()
  yield window.addEventListener('resize', () => {
    isMobileView()
    isMenuToggled()
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeLatest(actions.SET_PRIMARY_COLOR, SET_PRIMARY_COLOR),
    takeLatest(actions.SET_THEME, SET_THEME),
    SETUP(), // run once on app load to init listeners
  ])
}
