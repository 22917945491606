import { Button, Card, message } from 'antd'
import CustomPricing from 'components/modal/pricing'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPIData, getAPI, trackUserActivity } from 'services/jwt'
import store from 'store'
import { displayRazorpay } from './utility'
import { FormattedMessage } from 'react-intl'

const Visibility = ({ type, className, visible }) => {
  const { subscription_features } = useSelector(state => state.user)
  const [featuresList, setFeaturesList] = useState([])
  const [subscriptionPlan, setSubscriptionPlan] = useState({})
  const customPricingRef = useRef()
  const subscriptionPlanRef = useRef()
  const [requestReceived, setRequestReceived] = useState(false)

  const dispatch = useDispatch()

  const loadScript = src => {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  useEffect(() => {
    if (visible) {
      const fetchSubscriptionFeatures = async () => {
        const subscription_features = await getAPIData('v2/leads', 'subscription_features')
        if (subscription_features && subscription_features.success) {
          setFeaturesList(subscription_features.data)
          const plan = subscription_features.data.filter(plan => plan.feature_key == type)[0] || {}
          setSubscriptionPlan({
            ...plan,
          })

          dispatch({
            type: 'user/SET_STATE',
            payload: {
              subscription_features: subscription_features.data,
            },
          })
        }
      }
      if (!subscription_features || subscription_features.length == 0) {
        fetchSubscriptionFeatures()
      } else {
        setFeaturesList(subscription_features)
        const plan = subscription_features?.filter(plan => plan.feature_key == type)[0] || {}
        setSubscriptionPlan({ ...plan })
      }
    }
  }, [visible])

  return (
    <>
      {store.get('paid') == 0 && (
        <div className={className}>
          <Card size="visibility" className="bg-card-azure width-100p">
            <div className="flex flex-row justify-between items-center width-100p">
              <div className="">
                <div className="flex flex-row items-center ">
                  <div className="lh-large">
                    <span className="font-weight-bolder font-size-18 text-black">
                      {subscriptionPlan?.short_title}
                      {/*Easily create invoices in 10 Seconds 🤩*/}
                    </span>
                    <p className="mt-0 mb-0 font-size-14 text-black">
                      {subscriptionPlan?.tagline}
                      {/*and share them with your customers!*/}
                    </p>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-col">
                <div>
                  {window.location.hostname != 'mac.getswipe.in' && (
                    <Button
                      type="sign-up"
                      size=""
                      onClick={() =>
                        subscriptionPlanRef.current.openModal(
                          subscriptionPlan?.feature_key,
                          subscriptionPlan?.plan_id,
                        )
                      }
                      className="font-weight-bolder float-right"
                    >
                      Upgrade 🚀
                    </Button>
                  )}

                  <Button
                    type="text"
                    size=""
                    onClick={async event => {
                      event.stopPropagation()
                      if (!requestReceived) {
                        await trackUserActivity({
                          feature_screen: subscriptionPlan?.feature_key,
                          feature_title: subscriptionPlan?.title,
                        })
                          .then(res => setRequestReceived(res))
                          .then(() => {
                            if (store.get('paid') == 1) {
                              window.open(
                                `https://app.getswipe.in/help?msg=${subscriptionPlan.contact_text}`,
                              )
                            }
                          })
                      }
                    }}
                    className="font-weight-bolder float-right mr-2"
                  >
                    {requestReceived ? (
                      <>
                        <i className="fa-solid fa-circle-check text-success mr-2" />
                        Request received!
                      </>
                    ) : (
                      <>
                        <i class="fa-solid fa-headset mr-2"></i> Talk to a specialist
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
          {requestReceived && (
            <p className="text-gray-500 text-center mt-2">
              <FormattedMessage id="supportMessage" />
            </p>
          )}
        </div>
      )}
      <CustomPricing
        ref={customPricingRef}
        onSelectPlan={(plan, years, addons) => displayRazorpay(plan, years, addons)}
      />
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </>
  )
}

export default Visibility
