export const CONSTANTS = {
  // PRICING_BUTTON: 'Get 1 Month Free! 🚀',
  PRICING_BUTTON: 'Subscribe Now! 🚀',
  // GSTIN_REGEX: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$',
  GSTIN_REGEX: '^[0-9A-Z]{15}$',
  HELP_BUTTON_TEXT: 'Hello%20Swipe! I need help with',
  // HELP_BUTTON_TEXT: 'Hello, I am interested in Swipe Freedom Offer!',
}

export const ADMIN = 'admin'

export const STOCK_IN_STOCK_OUT_DOC_TYPES = [
  'invoice',
  'sales_return',
  'purchase_return',
  'purchase',
]
export const FLOATOPTIONS = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

export const LEADS_SHEET_DAILY_TARGET = 140000
export const INVOICE = 'invoice'
export const PURCHASE = 'purchase'
export const DELIVERY_CHALLAN = 'delivery_challan'
export const PRODUCT = 'Product'
export const SERVICE = 'Service'

export const DOCUMENT_SHORTCUTS = {
  SL: { type: 'sales', title: 'Your Bill/Invoice' },
  PU: { type: 'purchases', title: 'Your Bill/Purchase' },
  SR: { type: 'sales_returns', title: 'Sales Return' },
  PR: { type: 'purchase_returns', title: 'Purchase Returns' },
  ES: { type: 'estimates', title: 'Estimate' },
  DC: { type: 'delivery_challans', title: 'Delivery Challan' },
  PF: { type: 'pro_forma_invoices', title: 'Pro Forma Invoice' },
  PO: { type: 'purchase_orders', title: 'Purchase Order' },
  OL: { type: 'online_order', title: 'Online Order' },
  SUB: { type: 'subscriptions', title: 'Subscription' },
}

export const order_types = {
  delivery: 'Delivery',
  pick_up: 'Pickup',
}

export const FREE_USER_EINVOICE_LIMIT = 10
export const FREE_USER_EWAYBILL_LIMIT = 10
export const FREE_USER_EXPORT_LIMIT = 10
export const FREE_USER_BANKRECON_LIMIT = 3
