import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import { PrefixForm } from 'components/forms/documentPrefixes'
const empty_prefix_data = {
  id: -1,
  prefix: '',
  document_type: 'invoice',
  is_default: false,
  is_delete: false,
}
const FinancialYearModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const documentPrefixesRef = useRef(null)
  const prefixFormRef = React.createRef()

  const showModal = () => {
    // setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const onClose = () => {
    setVisible(false)
  }
  useEffect(() => {
    const firstModalDisplayDate = localStorage.getItem('financialModalDisplayDate')
    const currentDate = dayjs().format('YYYY-MM-DD')

    let company_id = 0
    if (localStorage.getItem('company_details')) {
      try {
        company_id = JSON.parse(localStorage.getItem('company_details')).company_id
      } catch (e) {
        company_id = 0
      }
    }
    const isAfterMarch31st = dayjs().isAfter(
      dayjs('01 Apr 24, 12:00 AM', { format: 'DD MMM YY, hh:mm A' }),
    )
    if (!firstModalDisplayDate && company_id < 1002871 && isAfterMarch31st) {
      // Uncomment for showing financial year modal
      // setVisible(true)
      localStorage.setItem('financialModalDisplayDate', currentDate)
    }
  }, [])

  return (
    <>
      <Modal
        title={
          <div className="">
            <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
            <span className="text-gray-500">Update prefix for FY25?</span>{' '}
          </div>
        }
        okText="Yes"
        cancelText="No"
        open={visible}
        onCancel={onClose}
        closable={false}
        maskClosable={false}
        destroyOnClose
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        onOk={() => {
          setVisible(false)
        }}
        footer={
          <>
            <Button
              type="danger"
              onClick={() => {
                setVisible(false)
              }}
            >
              Don't Show
            </Button>
            <Button
              type="action"
              onClick={() => {
                prefixFormRef.current.show_prefix_form(empty_prefix_data)

                setVisible(false)
              }}
            >
              Change Prefix
            </Button>
          </>
        }
      >
        <div>Your current prefix is {props.serial_number}, do you want to update it?</div>
      </Modal>
      <PrefixForm ref={prefixFormRef} onFinish={() => props.onFinish()} />
    </>
  )
})

export default FinancialYearModal
