import React from 'react'
import style from './style.module.scss'
import { CONSTANTS } from 'utils/constants'

const SupportChat = () => {
  return (
    <div className={style.chat}>
      <button
        type="button"
        className={`${style.toggleButton} hidden sm:inline t-20`}
        onClick={event =>
          window.open(
            `https://api.whatsapp.com/send?phone=918121335436&text=${CONSTANTS.HELP_BUTTON_TEXT}`,
          )
        }
      >
        <i className="fa-brands font-weight-bold fa-lg fa-whatsapp" />
        <span className="font-size-16 ml-2">Help?</span>
      </button>

      {/* <a
        onClick={event =>
          window.open(
            `https://api.whatsapp.com/send?phone=918121335436&text=${CONSTANTS.HELP_BUTTON_TEXT}`,
          )
        }
        class="relative inline-block text-lg group"
      >
        <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
          <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
          <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
          <span class="relative">Button Text</span>
        </span>
        <span
          class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
          data-rounded="rounded-lg"
        ></span>
      </a> */}

      {/*<div
        className={classNames(style.container, {
          [style.containerToggled]: isSupportChatOpen,
        })}
      >
        <div className="flex flex-wrap mb-2">
          <div className="text-dark font-size-18 font-weight-bold mr-auto">Support Chat</div>
          <button onClick={toggleSupportChat} type="button" className="btn btn-link p-0 border-0">
            <i className="fe fe-x-square font-size-21 align-middle text-gray-6" />
          </button>
        </div>
      </div>*/}
    </div>
  )
}

export default SupportChat
