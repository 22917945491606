import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, message, Modal, Radio, Row, Select } from 'antd'
import CustomerForm from 'components/forms/customerForm'
import AddressForm from 'components/forms/addressForm'
import { EnhancedShipRocketCreds } from 'components/forms/shiprocketCred'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { getAPI, getAPIData } from 'services/jwt'
import SelectAddress from 'components/other/selectAddress'

export default class EnhancedShiprocketModal extends Component {
  addressFormRef = React.createRef()
  state = {
    shiprocketVisible: false,
    delivery_partners: [],
    shiprocket_order_details: {},
    invoice_details: {},
    courier_company_id: 0,
    shipment_created: false,
    pickup_locations: [],
    selected_pickup_location: '',
    awb_generated: false,
    tracking_details: {},
    shiprocket_errors: [],
    loading: false,
    document_type: '',
    doc_count: 0,
    paymentMethod: 'Prepaid',
    selected_address: undefined,
  }
  getDeliverypartners = async values => {
    // console.log(this.state.shiprocket_order_details)
    var req = {
      ...values,
      doc_count: this.state.doc_count,
      document_type: this.state.document_type,
    }
    const data = await getAPI('user', 'shiprocket_delivery_partners', req)
    if (data.data.status == 200) {
      if (data.data.data.available_courier_companies.length > 0) {
        this.setState({ delivery_partners: data.data.data.available_courier_companies })
      }
    } else {
      message.warning('No Delivery partner found')
      this.setState({ delivery_partners: [] })
    }
  }

  validate = async () => {
    const { customer } = this.state.invoice_details
    const { selected_address } = this.state
    const errors = { billing: [], shipping: [] }
    if (customer.email == '') {
      errors.billing.push('Email ')
    }
    if (customer.phone == '') {
      errors.billing.push('Phone Number')
    }
    if (customer.billing.address_1 == '') {
      errors.billing.push('Address line1')
    }
    if (customer.billing.city == '') {
      errors.billing.push('City')
    }
    if (customer.billing.pincode == '') {
      errors.billing.push('Pincode')
    }
    if (customer.billing.state == '') {
      errors.billing.push('State')
    }
    if (selected_address) {
      if (selected_address.address_1 == '') {
        errors.shipping.push('Address line1')
      }
      if (selected_address.city == '') {
        errors.shipping.push('City')
      }
      if (selected_address.pincode == '') {
        errors.shipping.push('Pincode')
      }
      if (selected_address.state == '') {
        errors.shipping.push('State')
      }
    } else {
      errors.shipping.push('Address')
    }
    const newError = [errors.billing.join(','), errors.shipping.join(',')]

    this.setState({
      shiprocket_errors: newError,
    })
    return errors.billing.length == 0 && errors.shipping.length == 0
  }
  isAddressSelected = () => {
    const address = this.state.invoice_details?.customer?.shipping
    if (
      !address ||
      (address &&
        (address.address_1 == '' ||
          address.city == '' ||
          address.pincode == '' ||
          address.state == ''))
    ) {
      return false
    }
    return true
  }
  editCustomerDetails = async () => {
    let req = {}
    let invoice_details = {}
    if (this.state.document_type == 'invoice') {
      req = {
        document_type: 'invoice',
        new_hash_id: this.state.invoice_details.new_hash_id,
        is_pdf: false,
        identifier: 'shiprocket',
      }
      invoice_details = await getAPI('v2/doc', 'get_invoice', req)
    } else {
      req = { type: 'invoice', serial_number: this.state.invoice_details.serial_number }
      invoice_details = await getAPI('store', 'get_order', req)
    }

    if (invoice_details.success) {
      if (this.state.document_type == 'invoice') {
        this.setState(
          {
            invoice_details: invoice_details.invoice_details,
          },
          () => {
            let newAddress = this.state.invoice_details?.customer?.shipping_address.filter(
              address => address.addr_id == this.state.selected_address?.addr_id,
            )?.[0]
            this.setState({
              selected_address: this.state.selected_address
                ? newAddress
                : this.isAddressSelected()
                ? {
                    ...invoice_details.invoice_details?.customer?.shipping,
                    line1: invoice_details.invoice_details?.customer?.shipping.address_1,
                    line2: invoice_details.invoice_details?.customer?.shipping.address_2,
                  }
                : undefined,
            })
          },
        )
      } else {
        this.setState(
          {
            invoice_details: {
              ...invoice_details.order_details,
              customer: invoice_details.order_details.customer[0],
            },
          },
          () => {
            let newAddress = this.state.invoice_details?.customer?.shipping_address.filter(
              address => address.addr_id == this.state.selected_address?.addr_id,
            )[0]
            this.setState({
              selected_address: this.state.selected_address
                ? newAddress
                : this.isAddressSelected()
                ? {
                    ...invoice_details.order_details?.customer[0]?.shipping,
                    line1: invoice_details.order_details?.customer[0]?.shipping.address_1,
                    line2: invoice_details.order_details?.customer[0]?.shipping.address_2,
                  }
                : undefined,
            })
          },
        )
      }
    }
    this.validate()
  }
  showModal(data, document_type) {
    this.updateCredentials()
    this.setState({ document_type: document_type })
    this.handelShipRocket(data, 'sales', document_type)
  }
  updateCredentials = async () => {
    const data = await getAPIData('user', 'update_shiprocket_creds')
  }

  handelShipRocket = async (data, menuName = 'sales', document_type) => {
    const req = { doc_count: data.doc_count, document_type: document_type }
    this.setState({
      delivery_partners: [],
      shipment_created: false,
      tracking_details: {},
      awb_generated: false,
      shiprocket_errors: [],
      loading: true,
      doc_count: data.doc_count,
    })
    const checkToken = await getAPI('user', 'verify_shiprocket_token', req)
    if ('awb_generated' in checkToken && checkToken.awb_generated) {
      if ('error' in checkToken.tracking.tracking_data) {
        message.error(checkToken.tracking.tracking_data.error)
      } else {
        this.setState({
          awb_generated: true,
          tracking_details: checkToken.tracking.tracking_data,
          shiprocketVisible: true,
        })
      }
    } else {
      if (checkToken.present) {
        this.setState({ shiprocketVisible: true })
        let req = {
          document_type: 'invoice',
          new_hash_id: data.new_hash_id,
          is_pdf: false,
          identifier: 'shiprocket',
        }

        let invoice_details = {}
        if (document_type == 'invoice') {
          invoice_details = await getAPI('v2/doc', 'get_invoice', req)
        } else {
          req = { type: 'invoice', new_hash_id: data.new_hash_id }
          invoice_details = await getAPI('store', 'get_order', req)
        }

        if (invoice_details.success) {
          if (document_type == 'invoice') {
            this.setState(
              {
                invoice_details: invoice_details.invoice_details,
              },
              () => {
                this.setState({
                  selected_address: this.isAddressSelected()
                    ? {
                        ...invoice_details.invoice_details?.customer?.shipping,
                        line1: invoice_details.invoice_details?.customer?.shipping.address_1,
                        line2: invoice_details.invoice_details?.customer?.shipping.address_2,
                      }
                    : undefined,
                })
              },
            )
          } else {
            this.setState(
              {
                invoice_details: {
                  ...invoice_details.order_details,
                  customer: invoice_details.order_details.customer[0],
                },
              },
              () => {
                this.setState({
                  selected_address: this.isAddressSelected()
                    ? {
                        ...invoice_details.order_details?.customer[0]?.shipping,
                        line1: invoice_details.order_details?.customer[0]?.shipping.address_1,
                        line2: invoice_details.order_details?.customer[0]?.shipping.address_2,
                      }
                    : undefined,
                })
              },
            )
          }
        }

        if (checkToken.order_created) {
          this.setState({ shiprocket_order_details: checkToken.data.data })
          this.setState({ shipment_created: checkToken.order_created })
          this.getDeliverypartners({
            pickup: checkToken.data.data.customer_pincode,
            destination: checkToken.data.data.pickup_code,
          })
        } else {
          this.setState({ shipment_created: false, delivery_partners: [] })

          const pickup_locations = await getAPIData('user', 'shiprocket_pickup_locations')
          if (pickup_locations.success) {
            if (pickup_locations.data.status_code == 401) {
              this.setState({ shiprocketVisible: false })
              message.error('Shiprocket token expired! Please generate new token')
            } else {
              this.setState({ pickup_locations: pickup_locations.data.data.shipping_address })
              if (pickup_locations.data.data.shipping_address?.length >= 1) {
                this.setState({
                  selected_pickup_location:
                    pickup_locations.data.data.shipping_address[0].pickup_location,
                })
              }
            }
          }
        }
      } else {
        this.ShipRocketCred.showDrawer(true, menuName)
      }
    }
    this.setState({ loading: false })
  }
  handleShiprocket_online_orders(data, menuName = 'sales') {}
  componentDidMount() {
    this.props.onRef(this)
  }

  componentDidUpdate() {
    // console.log(this.state.shiprocket_order_details.customer_pincode)

    if (this.state.shipment_created && this.state.loading) {
      this.shiprocketFormRef.current.setFieldsValue({
        pickup: this.state.shiprocket_order_details.pickup_code,
        destination: this.state.shiprocket_order_details.customer_pincode,
      })
    }
  }
  shiprocketFormRef = React.createRef()
  createShipmentRef = React.createRef()
  formatDate(date) {
    return dayjs(date, 'DD-MM-YYYY').format('MMMM D, YYYY')
  }
  onChangeAddress = addr_id => {
    const newAddr = this.state.invoice_details.customer.shipping_address.filter(
      address => address.addr_id === addr_id,
    )[0]
    this.setState({ selected_address: newAddr }, () => {
      this.updateAddress(addr_id, 'from')
    })
  }
  updateAddress = async (addr_id = -1, type) => {
    const req = {
      document_type: this.state.invoice_details.document_type,
      doc_count: this.state.invoice_details.doc_count,
      type: type,
      addr_id: addr_id,
    }
    const data = await getAPI('v2/doc', 'update_document_shipping_address', req)

    if (data?.success) {
      message.success(data.message)
    }
  }

  render() {
    // console.log(this.state)
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
      },
    }
    const crateOrderRef = React.createRef()
    const NormalDescriptionItem = ({ title, content }) => (
      <div className=" font-weight-bold site-description-item-profile-wrapper">{content}</div>
    )
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

    return (
      <div>
        <Modal
          title="ShipRocket"
          style={{ top: 9 }}
          bodyStyle={{ minHeight: '50vh' }}
          width={'90vw'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          //width={this.state.awb_generated ? 512 : 1024}
          open={this.state.shiprocketVisible}
          onCancel={() => this.setState({ shiprocketVisible: false })}
          footer={
            !this.state.awb_generated && !this.state.loading
              ? [
                  <Button
                    type="submit"
                    size="large"
                    className={'btn btn-primary'}
                    loading={this.state.loading}
                    onClick={() => {
                      if (!this.state.loading) {
                        if (this.state.shipment_created) {
                          if (this.state.delivery_partners.length != 0) {
                            this.createShipmentRef.current.submit()
                          } else {
                            message.warning('No Delivery Patners Found')
                          }
                        } else {
                          if (this.validate()) {
                            crateOrderRef.current.submit()
                          } else {
                            console.log(this.state.shiprocket_errors)
                          }
                        }
                      }
                    }}
                  >
                    Create {this.state.shipment_created ? 'Shipment' : 'Order'}
                    <i className="fa fa-arrow-right ml-2" />
                  </Button>,
                ]
              : null
          }
        >
          {this.state.loading && <LoadingOutlined />}
          {
            <>
              {!this.state.loading && (
                <div className="flex mb-2">
                  <h6 className="mr-2">{this.state.invoice_details?.customer?.name}</h6>
                  {!this.state.shipment_created && (
                    <Button
                      size="small"
                      type="warning"
                      onClick={e => {
                        this.customer.onEdit(this.state.invoice_details['customer_id'])
                      }}
                    >
                      <i className="fa-solid fa-edit mx-1"></i>
                      Edit
                    </Button>
                  )}
                </div>
              )}
              <div className="card bg-gray-50">
                <div className="card-body pb-1">
                  <div className="row mb-0 ">
                    {!this.state.loading && !this.state.awb_generated && (
                      <div className="col-12 col-md-4 h-full">
                        <div className="mb-2">
                          <h6 className="font-weight-bold font-size-16 mb-0 ml-1">
                            Billing Address
                          </h6>
                          <div className="">
                            <Row>
                              <Card
                                size="small"
                                style={{ width: '100%', borderRadius: '5px', cursor: 'pointer' }}
                                className="mr-2 my-2 mr-4 border-gray-2"
                              >
                                <span className="font-size-14">
                                  <span>
                                    <p className="mb-0">
                                      {this.state.invoice_details?.customer?.billing['address_1']}
                                    </p>
                                    <p className="mb-0">
                                      {this.state.invoice_details?.customer?.billing['address_2']}
                                    </p>
                                    <p className="mb-0">
                                      {this.state.invoice_details?.customer?.billing['city']}
                                    </p>
                                    <p className="mb-0">
                                      {this.state.invoice_details?.customer?.billing['pincode']}
                                    </p>
                                    <p className="font-weight-bold mb-0">
                                      {this.state.invoice_details?.customer?.billing['state']}
                                    </p>
                                  </span>
                                </span>
                                {!this.state.shipment_created && (
                                  <a
                                    onClick={e => {
                                      this.addressFormRef?.current?.showAddressDrawer(
                                        'billing',
                                        {
                                          ...this.state.invoice_details.customer.billing,
                                          line1: this.state.invoice_details.customer.billing
                                            .address_1,
                                          line2: this.state.invoice_details.customer.billing
                                            .address_2,
                                        },
                                        {
                                          party_id: this.state.invoice_details['customer_id'],
                                          party_type: 'customer',
                                          is_party_edit: true,
                                        },
                                      )
                                    }}
                                    className="card-link font-weight-bold text-action mr-3"
                                  >
                                    Edit
                                    <i className="fa fa-pencil-o" />
                                  </a>
                                )}
                              </Card>
                            </Row>
                          </div>
                          {!this.state.shipment_created &&
                            this.state.shiprocket_errors[0] != '' &&
                            this.state.shiprocket_errors.length != 0 && (
                              <p className="text-danger">
                                * Billing {this.state.shiprocket_errors[0]} is required
                              </p>
                            )}
                        </div>

                        <div className="mb-0">
                          <h6 className="font-weight-bold font-size-16 mb-0 ml-1">Shipping To</h6>
                          <div className="">
                            <Row>
                              <div className="w-1/4 mb-2">
                                <SelectAddress
                                  addressType={'shipping'}
                                  address={this.state.invoice_details.customer?.shipping_address}
                                  onFinish={this.editCustomerDetails}
                                  invoiceDetails={this.state.invoice_details}
                                  selectedAddress={this.state.selected_address}
                                  onChangeAddress={this.onChangeAddress}
                                  disabled={this.state.shipment_created}
                                />
                              </div>
                              {/* <Card
                                size="small"
                                style={{ width: '100%', borderRadius: '5px', cursor: 'pointer' }}
                                className="mr-2 my-2 mr-4 border-gray-2"
                              >
                                <span className="">
                                  <span>
                                    <p className="mb-0">
                                      {this.state.invoice_details.customer?.shipping['address_1']}
                                    </p>
                                    <p className="mb-0">
                                      {this.state.invoice_details.customer?.shipping['address_2']}
                                    </p>
                                    <p className="mb-0">
                                      {this.state.invoice_details.customer?.shipping['city']}
                                    </p>
                                    <p className="mb-0">
                                      {this.state.invoice_details.customer?.shipping['pincode']}
                                    </p>
                                    <p className="font-weight-bold mb-0">
                                      {this.state.invoice_details.customer?.shipping['state']}
                                    </p>
                                  </span>
                                </span>
                                {!this.state.shipment_created && (
                                  <a
                                    onClick={e => {
                                      console.log('details', this.state.invoice_details)

                                      this.customer.onEdit(
                                        this.state.invoice_details['customer_id'],
                                      )
                                    }}
                                    className="card-link font-weight-bold text-action mr-3"
                                  >
                                    Edit
                                    <i className="fa fa-pencil-o" />
                                  </a>
                                )}
                              </Card> */}
                            </Row>
                          </div>
                          {!this.state.shipment_created &&
                            this.state.shiprocket_errors[1] != '' &&
                            this.state.shiprocket_errors.length != 0 && (
                              <p className="text-danger">
                                * Shipping {this.state.shiprocket_errors[1]} is required
                              </p>
                            )}
                        </div>
                      </div>
                      //   </div>
                      // </div>
                    )}
                    {!this.state.shipment_created &&
                      !this.state.loading &&
                      !this.state.awb_generated && (
                        <div className="col-md-8 col-12">
                          <h6 className="font-weight-bold font-size-16 ml-1">
                            Enter Package Details
                          </h6>
                          <div className="card bg-lgreen">
                            <div className="card-body pb-1">
                              <Form
                                {...formItemLayout}
                                labelAlign="left"
                                ref={crateOrderRef}
                                name="order_details_form"
                                onFinish={async values => {
                                  let address = this.state.selected_address
                                  let req = {
                                    billing_address: this.state.invoice_details.customer.billing,
                                    shipping_address: {
                                      ...address,
                                      address_1: address?.line1,
                                      address_2: address?.line2,
                                      phone: this.state.invoice_details.customer.phone,
                                      email: this.state.invoice_details.customer.email,
                                    },
                                    products: this.state.invoice_details.items,
                                    sub_total: this.state.invoice_details.total_amount,
                                    doc_count: this.state.doc_count,
                                    document_type: this.state.document_type,
                                    order_date: new Date(),
                                    payment: this.state.paymentMethod,
                                    name: this.state.invoice_details.customer.name,
                                    ...values,
                                    location: this.state.selected_pickup_location,
                                  }
                                  const data = await getAPI('user', 'shiprocket_create_order', req)
                                  if (data && data.success) {
                                    message.success(data.message)
                                    this.setState({ shipment_created: true })
                                    req = {
                                      doc_count: this.state.doc_count,
                                      document_type: this.state.document_type,
                                    }
                                    this.setState({ loading: true })
                                    const order_details = await getAPI(
                                      'user',
                                      'shiprocket_order_details',
                                      req,
                                    )
                                    this.setState({
                                      shiprocket_order_details: order_details?.order_details?.data,
                                    })
                                    this.getDeliverypartners({
                                      pickup: order_details.order_details.data.pickup_code,
                                      destination:
                                        order_details.order_details.data.customer_pincode,
                                    })
                                    this.setState({ loading: false })
                                  }
                                }}
                                size="middle"
                                className="rounded border-gray-2"
                              >
                                <Form.Item
                                  name="location"
                                  // rules={[{ required: true, message: 'please select a option' }]}
                                  label="Pickup Location"
                                >
                                  <Select
                                    placeholder="Select Pickup loacation"
                                    style={{ width: '100%' }}
                                    value={this.state.selected_pickup_location}
                                    onChange={e => {
                                      this.setState({ selected_pickup_location: e })
                                    }}
                                    defaultValue={this.state.selected_pickup_location}
                                  >
                                    {this.state.pickup_locations?.map((item, i) => (
                                      <Option key={i} value={item.pickup_location}>
                                        <div style={{ whiteSpace: 'normal' }}>
                                          {item.pickup_location +
                                            ',' +
                                            item.address +
                                            ',' +
                                            item.address_2 +
                                            ',' +
                                            item.city +
                                            ',' +
                                            item.state +
                                            ',' +
                                            item.pin_code}
                                        </div>
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name="length"
                                  label="Package Length"
                                  size="large"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter the length of the package in cm',
                                      pattern: new RegExp(/^[0-9]+$/),
                                    },
                                  ]}
                                >
                                  <Input placeholder="Length" suffix="CM" />
                                </Form.Item>
                                <Form.Item
                                  name="width"
                                  label="Package Width"
                                  size="large"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter the width of the package in cm',
                                      pattern: new RegExp(/^[0-9]+$/),
                                    },
                                  ]}
                                >
                                  <Input placeholder="Width" suffix="CM" />
                                </Form.Item>
                                <Form.Item
                                  name="height"
                                  label="Package Height"
                                  size="large"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter the height of the package in cm',
                                      pattern: new RegExp(/^[0-9]+$/),
                                    },
                                  ]}
                                >
                                  <Input placeholder="Height" suffix="CM" />
                                </Form.Item>
                                <Form.Item
                                  name="weight"
                                  label="Package Weight"
                                  size="large"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter the weight of the package in kg',
                                      pattern: new RegExp(/^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/),
                                    },
                                  ]}
                                >
                                  <Input placeholder="Weight" suffix="KGs" />
                                </Form.Item>
                                <Form.Item
                                  name="payment_method"
                                  label="Payment Method"
                                  size="large"
                                >
                                  <Radio.Group
                                    onChange={e => {
                                      this.setState({ paymentMethod: e.target.value })
                                    }}
                                    defaultValue={this.state.paymentMethod}
                                  >
                                    <Radio value={'Prepaid'}>Prepaid</Radio>
                                    <Radio value={'COD'}>COD</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                        </div>
                      )}
                    {this.state.shipment_created && (
                      <div className="col-12 col-md-6">
                        <>
                          <Form
                            {...formItemLayout}
                            labelAlign="left"
                            ref={this.shiprocketFormRef}
                            name="pincodeForm"
                            // onFinish={this.getDeliverypartners}
                            size="large"
                          >
                            <Form.Item
                              name="pickup"
                              label="Pickup pincode"
                              size="large"
                              rules={[{ required: true, message: 'Please fill E-mail' }]}
                            >
                              <Input name="pickup" placeholder="pincode" disabled />
                            </Form.Item>

                            <Form.Item
                              name="destination"
                              label="Destination pincode"
                              size="large"
                              rules={[{ required: true, message: 'Please fill Password' }]}
                            >
                              <Input name="destination" placeholder="pincode" disabled />
                            </Form.Item>
                          </Form>
                        </>

                        {this.state.delivery_partners.length > 0 && this.state.shipment_created && (
                          <div className="mt-3">
                            <Form
                              ref={this.createShipmentRef}
                              onFinish={async () => {
                                const req = {
                                  courier_id: this.state.delivery_partners[
                                    this.state.courier_company_id
                                  ].courier_company_id,
                                  doc_count: this.state.doc_count,
                                  document_type: this.state.document_type,
                                }
                                const data = await getAPI('user', 'shiprocket_create_shipment', req)
                                if (data?.success) {
                                  message.success('ShipmentCreated ')
                                  this.setState({ shiprocketVisible: false })
                                }
                              }}
                            >
                              <h6 className="font-weight-bold font-size-18 mb-0 ml-1">
                                Select Courier Partner
                              </h6>
                              <Form.Item>
                                <br />
                                <Radio.Group
                                  onChange={e => {
                                    this.setState({ courier_company_id: e.target.value })
                                  }}
                                  value={this.state.courier_company_id}
                                >
                                  {this.state.delivery_partners.map((partner, index) => (
                                    <Radio value={index}>
                                      <div className="card m-2 p-2">
                                        <p>Courier Partner Name: {partner.courier_name}</p>
                                        <p>Estimated Delivery Date: {partner.etd}</p>
                                        <p>Rating: {partner.rating}</p>
                                        <p> Charges: {partner.freight_charge}</p>
                                      </div>
                                    </Radio>
                                  ))}
                                </Radio.Group>
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </div>
                    )}

                    {/*{!this.state.loading && !this.state.awb_generated && (
                      <div className="col-12 col-md-6">
                        <div className="card bg-lpurple">
                          <div className="card-body">
                            <div className="mb-2">
                              <h6 className="font-weight-bold font-size-18 mb-2 ml-1">
                                Product Details
                              </h6>
                              <div className="card">
                                <div className="card-body">
                                  <ProductsList
                                    data={this.state.invoice_details?.items}
                                    extraDiscount={this.state.invoice_details?.extraDiscount}
                                    document_custom_additional_charges={
                                      this.state.invoice_details?.document_custom_additional_charges
                                    }
                                    document={this.state.invoice_details}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}*/}
                    {this.state.awb_generated && (
                      <div className="col-12">
                        <div className="card bg-lgreen">
                          <div className="card-body">
                            <h6>Shipment Status</h6>
                            <div className="bg-white card p-3">
                              <p className="">
                                <span className="font-weight-bold">Current Status:</span>{' '}
                                {this.state.tracking_details.shipment_track[0].current_status}
                              </p>
                              <p className="">
                                <span className="font-weight-bold">Order Placed on:</span>{' '}
                                {this.state.tracking_details.shipment_track_activities &&
                                  this.formatDate(
                                    this.state.tracking_details.shipment_track_activities[
                                      this.state.tracking_details.shipment_track_activities.length -
                                        1
                                    ].date,
                                  )}
                              </p>
                              <p className="">
                                {' '}
                                <span className="font-weight-bold">AWB Number :</span>{' '}
                                {this.state.tracking_details.shipment_track[0].awb_code}
                              </p>
                              {this.state.tracking_details.shipment_track[0].edd != null && (
                                <p>
                                  {' '}
                                  <span className="font-weight-bold">
                                    Estimated Delivery Date:{' '}
                                  </span>
                                  {this.formatDate(
                                    this.state.tracking_details.shipment_track[0].edd,
                                  )}
                                </p>
                              )}

                              <p className="">
                                {' '}
                                <span className="font-weight-bold">Origin :</span>{' '}
                                {this.state.tracking_details.shipment_track[0].origin}
                              </p>
                              <p className="">
                                {' '}
                                <span className="font-weight-bold">Destination :</span>{' '}
                                {this.state.tracking_details.shipment_track[0].destination}
                              </p>
                              {this.state.tracking_details.shipment_track[0].pickup_date !=
                                null && (
                                <p>
                                  {' '}
                                  <span className="font-weight-bold">Picup Date: </span>
                                  {this.formatDate(
                                    this.state.tracking_details.shipment_track[0].pickup_date,
                                  )}
                                </p>
                              )}
                              <div className="">
                                <a
                                  href={this.state.tracking_details.track_url}
                                  target="_blank"
                                  className="text-primary"
                                >
                                  Click Here to Track order
                                </a>
                              </div>
                              {'document_urls' in this.state.tracking_details && (
                                <div className="flex mt-4">
                                  <Button className="btn btn-primary">
                                    <a href={this.state.tracking_details.document_urls.label_url}>
                                      <i className="fa-regular fa-arrow-down mr-2"></i> Download
                                      Label
                                    </a>
                                  </Button>
                                  <Button className="btn btn-primary ml-2">
                                    <a
                                      href={this.state.tracking_details.document_urls.manifest_url}
                                    >
                                      <i className="fa-regular fa-arrow-down mr-2"></i> Download
                                      Manifest
                                    </a>
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          }
        </Modal>
        <CustomerForm
          onRef={ref => (this.customer = ref)}
          onAPICalled={(a, b, c) => this.editCustomerDetails()}
        />
        <EnhancedShipRocketCreds onRef={ref => (this.ShipRocketCred = ref)} />
        <AddressForm ref={this.addressFormRef} onFinish={this.editCustomerDetails} />
      </div>
    )
  }
}
