import { Font } from '@react-pdf/renderer'

import font_default_bold from 'fonts/default/bold.ttf'
import font_default_regular from 'fonts/default/regular.ttf'
import font_default_semibold from 'fonts/default/semibold.ttf'

// import font_default_bold from 'fonts/retro/bold.ttf'
// import font_default_regular from 'fonts/retro/regular.ttf'
// import font_default_semibold from 'fonts/retro/semibold.ttf'

import font_retro_bold from 'fonts/retro/bold.ttf'
import font_retro_regular from 'fonts/retro/regular.ttf'
import font_retro_semibold from 'fonts/retro/semibold.ttf'

//encrypted fonts for copy paste
import font_encrypted_bold from 'fonts/encrypted/bold_en.ttf'
import font_encrypted_regular from 'fonts/encrypted/regular_en.ttf'
import font_encrypted_semibold from 'fonts/encrypted/semibold_en.ttf'

import font_default_italic from 'fonts/rmitalic.ttf'

import font_telugu_bold from 'fonts/telugu/bold.ttf'
import font_telugu_regular from 'fonts/telugu/regular.ttf'
import font_telugu_semibold from 'fonts/telugu/semibold.ttf'

import font_tamil_bold from 'fonts/tamil/bold.ttf'
import font_tamil_regular from 'fonts/tamil/regular.ttf'
import font_tamil_semibold from 'fonts/tamil/semibold.ttf'

import font_hindi_bold from 'fonts/hindi/bold.ttf'
import font_hindi_regular from 'fonts/hindi/regular.ttf'
import font_hindi_semibold from 'fonts/hindi/semibold.ttf'

import font_gujarati_bold from 'fonts/gujarati/bold.ttf'
import font_gujarati_regular from 'fonts/gujarati/regular.ttf'
import font_gujarati_semibold from 'fonts/gujarati/semibold.ttf'

import font_malayalam_bold from 'fonts/malayalam/bold.ttf'
import font_malayalam_regular from 'fonts/malayalam/regular.ttf'
import font_malayalam_semibold from 'fonts/malayalam/semibold.ttf'

import font_bengali_bold from 'fonts/bengali/bold.ttf'
import font_bengali_regular from 'fonts/bengali/regular.ttf'
import font_bengali_semibold from 'fonts/bengali/semibold.ttf'

import font_odia_bold from 'fonts/odia/bold.ttf'
import font_odia_regular from 'fonts/odia/regular.ttf'
import font_odia_semibold from 'fonts/odia/semibold.ttf'

import font_kannada_bold from 'fonts/kannada/bold.ttf'
import font_kannada_regular from 'fonts/kannada/regular.ttf'
import font_kannada_semibold from 'fonts/kannada/semibold.ttf'

const fonts = {
  English: {
    bold: font_default_bold,
    semibold: font_default_semibold,
    regular: font_default_regular,
  },
  Telugu: {
    bold: font_telugu_bold,
    semibold: font_telugu_semibold,
    regular: font_telugu_regular,
  },
  Tamil: {
    bold: font_tamil_bold,
    semibold: font_tamil_semibold,
    regular: font_tamil_regular,
  },
  Hindi: {
    bold: font_hindi_bold,
    semibold: font_hindi_semibold,
    regular: font_hindi_regular,
  },
  Marathi: {
    bold: font_hindi_bold,
    semibold: font_hindi_semibold,
    regular: font_hindi_regular,
  },
  Gujarati: {
    bold: font_gujarati_bold,
    semibold: font_gujarati_semibold,
    regular: font_gujarati_regular,
  },
  Bengali: {
    bold: font_bengali_bold,
    semibold: font_bengali_semibold,
    regular: font_bengali_regular,
  },
  Odia: {
    bold: font_odia_bold,
    semibold: font_odia_semibold,
    regular: font_odia_regular,
  },
  Kannada: {
    bold: font_kannada_bold,
    semibold: font_kannada_semibold,
    regular: font_kannada_regular,
  },
  Malayalam: {
    bold: font_malayalam_bold,
    semibold: font_malayalam_semibold,
    regular: font_malayalam_regular,
  },
  NoEncryption: {
    bold: font_encrypted_bold,
    semibold: font_encrypted_semibold,
    regular: font_encrypted_regular,
  },
  compact: {
    bold: font_encrypted_bold,
    semibold: font_encrypted_semibold,
    regular: font_encrypted_regular,
  },
  retro: {
    bold: font_retro_bold,
    semibold: font_retro_semibold,
    regular: font_retro_regular,
  },
  stylish: {
    bold: font_default_bold,
    semibold: font_default_semibold,
    regular: font_default_regular,
  },
}

export async function registerFont(invoice, is_ewaybill = false) {
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style

  locale = locale == '' ? 'English' : locale
  const allLocales = Object.keys(fonts)
  if (!allLocales.includes(locale)) locale = 'English'

  // To select font style if language is English
  if (locale == 'English' && !is_ewaybill) {
    locale = font_style
  }

  await Font.register({ family: `helbold_${locale}`, src: fonts[locale].bold })
  await Font.register({ family: `helreg_${locale}`, src: fonts[locale].regular })
  await Font.register({ family: `helitalic_${locale}`, src: font_default_italic })
  await Font.register({ family: `helmed_${locale}`, src: fonts[locale].semibold })
}

export async function registerEnglishFont(locale) {
  locale = locale == '' ? 'English' : locale
  const allLocales = Object.keys(fonts)
  if (!allLocales.includes(locale)) locale = 'English'

  await Font.register({ family: `helbold_${locale}`, src: fonts[locale].bold })
  await Font.register({ family: `helreg_${locale}`, src: fonts[locale].regular })
  await Font.register({ family: `helitalic_${locale}`, src: font_default_italic })
  await Font.register({ family: `helmed_${locale}`, src: fonts[locale].semibold })
}
