import { Avatar, Button, Dropdown, Menu, Tooltip } from 'antd'
import { CompanyAvatar } from 'components/other/utility'
import { history } from 'index'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { getAPIData } from 'services/jwt'
import store from 'store'

const mapStateToProps = state => {
  const { settings } = state
  return {
    theme: settings.theme,
    user: state.user,
  }
}

const ProfileMenu = ({ dispatch, theme }) => {
  const [count, setCount] = useState(7)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const user = useSelector(state => state.user)
  const { showNewMessage } = useIntercom()
  const setTheme = nextTheme => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'swipedark' ? 'swipedark' : 'light',
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'logo',
        value:
          nextTheme === 'swipedark'
            ? '/resources/images/logo-b.svg'
            : '/resources/images/logo-w.svg',
      },
    })
  }

  const handleDropdownVisibleChange = visible => {
    if (visible) {
      setIsHovered(false)
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    setDropdownVisible(visible)
  }

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const handleHelpAndSupport = () => {
    if (store.get('paid') == 1) {
      showNewMessage('Hello Swipe. I need help with')
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I need help with `,
        '_blank',
      )
    }
  }

  const menu = (
    <Menu onClick={() => handleDropdownVisibleChange(false)}>
      <Menu.Item selectable={false}>
        <span>
          <div className="feature-2 flex items-center mt-2 mb-2" onClick={e => e.preventDefault()}>
            {userDetails.profile_image && userDetails.profile_image !== '' ? (
              <Avatar src={userDetails.profile_image} shape="circle" size="default" />
            ) : (
              <CompanyAvatar size="large" className="avatar-container" name={store.get('name')} />
            )}

            <div className="company_name_text flex flex-col align-self-center ml-2">
              <span className="font-weight-bold font-size-16">
                <FormattedMessage id="topBar.profileMenu.hello" /> {store.get('name')}
              </span>
              <p className="text-gray-500 mb-0">{store.get('billing_status')}</p>
            </div>
          </div>

          {/* <span className="font-weight-bold">
            <FormattedMessage id="topBar.profileMenu.hello" /> {store.get('name')}
          </span>
          <p className="text-gray-900 mb-0">{store.get('email')}</p>
          <p className="text-gray-900 mb-1 ls-2">{store.get('mobile')}</p>*/}
        </span>

        {/* <div>
          <span className="mr-5 text-gray-500">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />
          </span>
          <span className="font-weight-medium float-right">{store.get('billing_status')}</span>
        </div>*/}
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item selectable={false}>
        <div>
          <span className="mr-3 text-gray-500 mb-2">
            <FormattedMessage id="topBar.profileMenu.phone" />
          </span>
          <span className="font-weight-bold float-right">{store.get('mobile')}</span>
        </div>

        {store.get('email') != '' && (
          <div>
            <span className="mr-3 text-gray-500">
              <FormattedMessage id="topBar.profileMenu.email" />
            </span>
            <span className="font-weight-medium float-right">{store.get('email')}</span>
          </div>
        )}

        {/*        {store.get('start_date') && (
          <div>
            <span className="mr-3 text-gray-500">
              <FormattedMessage id="topBar.profileMenu.startDate" />
            </span>
            <span className="font-weight-medium float-right">{store.get('start_date')}</span>
          </div>
        )}*/}
        {store.get('end_date') && (
          <div>
            <span className="mr-3 text-gray-500">
              <FormattedMessage id="topBar.profileMenu.validity" />
            </span>
            <span className="font-weight-medium float-right">{store.get('end_date')}</span>
          </div>
        )}
        <div>
          <span className="mr-3 text-gray-500">
            <FormattedMessage id="topBar.profileMenu.role" />
          </span>
          <span className="font-weight-medium float-right">{store.get('role')}</span>
        </div>

        {user?.selectedCompany?.country_code == 'IN' && (
          <div>
            <span className="mr-1 ">
              <Link to="/wallet_credits" className="text-gray-500">
                Credits
              </Link>
            </span>
            <span className="font-weight-medium text-green float-right">
              <i className="fa-solid fa-coins mr-1" />
              {store.get('wallet_credits') / 100}
            </span>
          </div>
        )}
      </Menu.Item>

      {store.get('paid') == 0 && !window.location.hostname.includes('mac.getswipe.in') && (
        <>
          <Menu.Divider />
          <Menu.Item>
            <Button
              type="upgrade"
              size=""
              block
              onClick={() => history.push('/pricing')}
              className="font-weight-bold my-1"
            >
              Upgrade Now! 🚀
            </Button>
          </Menu.Item>
        </>
      )}

      <Menu.Divider />

      {/*<Menu.Item>
        <p className="mr-3 text-gray-500 mb-1">
          <FormattedMessage id="topBar.profileMenu.quickSupport" />
        </p>
        <Button
          rel="noopener noreferrer"
          onClick={() =>
            window.open('https://www.whatsapp.com/download/', '_blank', 'noopener,noreferrer')
          }
          className="hidden sm:block float-right font-weight-bold mx-2 "
        >
          <img src="/resources/images/wa2.png" className="height-22 mr-2 mx-3" alt="Swipe" />
        </Button>

        <Button
          rel="noopener noreferrer"
          onClick={() => window.open('https://anydesk.com/en', '_blank', 'noopener,noreferrer')}
          className="hidden sm:block float-left mx-2"
        >
          <img src="/resources/images/anydesk.svg" className="height-22 mx-3" alt="Swipe" />
        </Button>
      </Menu.Item>*/}

      <Menu.Divider />

      {window.location.hostname != 'mac.getswipe.in' && (
        <Menu.Item>
          <a href="https://app.getswipe.in/pricing/" target="_blank" rel="noopener noreferrer">
            <i className="fa-solid text-gray-900 fa-handshake-angle fa-fw mr-2" />
            Check pricing plans
          </a>
        </Menu.Item>
      )}

      <Menu.Item>
        <a onClick={() => setTheme(theme === 'default' ? 'swipedark' : 'default')}>
          {theme == 'default' ? (
            <span>
              <i className="fa-solid fa-moon text-gray-500 fa-fw mr-2" />
              Dark Mode
            </span>
          ) : (
            <span>
              <i className="fa-solid fa-sun text-gray-500 fa-fw mr-2" />
              Light Mode
            </span>
          )}
        </a>
      </Menu.Item>

      <Menu.Item>
        <a
          onClick={() => {
            let shortCutKey = new KeyboardEvent('keydown', {
              key: '?',
              ctrlKey: true,
            })
            window.dispatchEvent(shortCutKey)
          }}
        >
          <i className="fa-solid text-gray-900 fa-keyboard fa-fw mr-2" />
          Keyboard Shortcuts
        </a>
      </Menu.Item>

      <Menu.Item>
        <a onClick={handleHelpAndSupport}>
          <i className="fa-solid fa-headphones text-gray-900 fa-fw mr-2" />
          Help & Support
        </a>
      </Menu.Item>
      <Menu.Item>
        <Link to="/user">
          <i className="fa-solid fa-gear text-gray-900 fa-fw mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a onClick={logout}>
          <i className="fa-solid text-gray-900 fa-sign-out fa-fw mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>

      <Menu.Item>
        <Link to="/user?tab=referral">
          <span className="text-green-600 font-bold">
            <i className="fa-solid  fa-coins fa-fw mr-2" />
            Refer & Get
            <span>
              <i className="fa-solid fa-indian-rupee-sign font-size-12 ml-2 mr-0.5" />
              1000 🤩
            </span>
          </span>
        </Link>
      </Menu.Item>

      {user?.selectedCompany?.country_code == 'IN' && (
        <Menu.Item>
          <div className="flex my-2">
            <span className="font-weight-bold mr-2 mr-auto text-gray-500">Available on</span>

            <a
              href="https://play.google.com/store/apps/details?id=in.swipe.app"
              className="mr-2 float-right"
              target="_blank"
            >
              <img src="/resources/images/google_play.png" className="h-9" />
            </a>

            <a
              href="https://apps.apple.com/in/app/swipe-invoicing-and-payments/id6451307318"
              className="float-right"
              target="_blank"
            >
              <img src="/resources/images/appstore.png" className="h-9" />
            </a>
          </div>
        </Menu.Item>
      )}
    </Menu>
  )

  const handleTooltipVisibleChange = visible => {
    setIsHovered(visible)
  }
  const getUserDetails = async () => {
    const data = await getAPIData('user', 'get_user')
    if (data && data.success) {
      setUserDetails({ ...data.user })
    }
  }
  useEffect(() => {
    getUserDetails()
  }, [])
  return (
    <Tooltip
      title="Profile"
      trigger={['hover']}
      placement="top"
      visible={isHovered}
      onVisibleChange={handleTooltipVisibleChange}
    >
      <Dropdown
        overlay={menu}
        overlayStyle={{ minWidth: 360 }}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleDropdownVisibleChange}
      >
        <Button type="text" className="px-2 ml-1 rounded-full">
          <i className="fa-solid fa-circle-user fa-fw" />
        </Button>
      </Dropdown>
      {isDropdownVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.25)', // Adjust the opacity as needed
            zIndex: 999, // Ensure the overlay appears below the dropdown
          }}
        />
      )}
    </Tooltip>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
