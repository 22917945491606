import { Button, DatePicker, Menu, Modal, Select, Tabs } from 'antd'
import { NewBadge } from 'components/badges/new'
import ProBadge from 'components/badges/pro'
import EnhancedExport from 'components/modal/export'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import NoAccess from 'components/noaccess'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component, lazy, Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import store from 'store'
import DayBook from './DayBook'
import ExpenseReport from './ExpenseReport'
const { TabPane } = Tabs

const { Option } = Select
dayjs.extend(utc)
const { RangePicker } = DatePicker

const urlParams = new URLSearchParams(window.location.search)
var activeTab = urlParams.get('tab')
var activeTabKey = '1'
if (activeTab == 'gstr_1') {
  activeTabKey = '35'
} else if (activeTab == 'profit_loss') {
  activeTabKey = '1'
} else {
  activeTabKey = '1'
}

class Reports extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      defaultActiveKey: activeTabKey,
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      selectedCustomer: { name: '', id: -1 },
      selectedItem: { name: '', id: -1 },
      autocompleteData: [],
      itemAutoCompleteData: [],
      report: {
        name: 'Day Book',
        disabled: 0,
        type: 'day_book',
        index: 0,
        data: {
          product_name: '',
          product_id: -1,
          variant_name: '',
          variant_id: 0,
        },
      },
      dates: store.get('reportsDateRange_' + this.props.company_details.company_id)
        ? store.get('reportsDateRange_' + this.props.company_details.company_id)
        : dayjs('01-01-2021', 'DD-MM-YYYY').format(this.dateFormat) +
          ' - ' +
          dayjs()
            .endOf('month')
            .format(this.dateFormat),
      searchType: 'Product Name',
      search: '',
      download_menu: 'sales_summary',
      openKeys: ['0'],
      selectedKeys: ['0'],
      open: false,
    }
  }

  changeReportType = async (key, report) => {
    this.setState({ defaultActiveKey: key, report: report })
  }
  showModal = () => {
    this.setState({
      open: true,
    })
  }
  componentDidMount() {
    store.set(
      'reportsDateRange_' + this.props.company_details.company_id,
      dayjs()
        .startOf('year')
        .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .endOf('month')
          .format(this.dateFormat),
    )
    store.set(
      'reportsTooltips',
      store.get('reportsTooltips') != undefined ? store.get('reportsTooltips') : true,
    )
    store.set(
      'reportsSettings',
      store.get('reportsSettings') != undefined ? store.get('reportsSettings') : true,
    )
    store.set(
      'reportsPurchasePriceType',
      store.get('reportsPurchasePriceType') != undefined ||
        store.get('reportsPurchasePriceType') != 'purchase'
        ? store.get('reportsPurchasePriceType')
        : 'current',
    )
  }

  callback = key => {
    window.scrollTo(0, 0)
    this.setState({
      transactions: [],
      total: 0,
      page: 0,
      num_records: 10,
      selectedItem: { name: '', id: -1 },
      selectedCustomer: { name: '', id: -1 },
    })
  }

  filterReports = reports => {
    return reports.reduce((acc, report) => {
      if (!report.hide) {
        const newReport = { ...report }
        if (newReport.children) {
          newReport.children = this.filterReports(newReport.children)
        }
        acc.push(newReport)
      }
      return acc
    }, [])
  }

  render() {
    let reportsNew = [
      {
        name: 'Day Book',
        disabled: 0,
        menu_name: 'day_book',
        type: 'day_book',
        index: 0,
        new: false,
        main_menu: true,
      },
      {
        name: 'Transaction Reports',
        disabled: 1,
        children: [
          {
            name: 'Sales',
            disabled: 0,
            menu_name: 'sales',
            type: 'transaction_report',
            party_type: 'customer',
            index: 1,
            new: false,
          },
          {
            name: 'Purchases',
            disabled: 0,
            menu_name: 'purchases',
            type: 'transaction_report',
            party_type: 'vendor',
            index: 2,
            new: false,
          },
          {
            name: 'Sale Returns / Credit Notes',
            disabled: 0,
            menu_name: 'sales_returns',
            type: 'transaction_report',
            party_type: 'customer',
            index: 3,
            new: false,
          },
          {
            name: 'Purchase Returns / Debit Notes',
            disabled: 0,
            menu_name: 'purchase_returns',
            type: 'transaction_report',
            party_type: 'vendor',
            index: 4,
            new: false,
          },
          // {
          //   name: "Daybook",
          //   disabled: 0
          // },
          // {
          //   name: "Profit & Loss",
          //   disabled: 0
          // },
          {
            name: 'Estimates',
            disabled: 0,
            menu_name: 'estimates',
            type: 'transaction_report',
            party_type: 'customer',
            index: 5,
            new: false,
          },
          {
            name: 'Delivery Challans',
            disabled: 0,
            menu_name: 'delivery_challans',
            type: 'transaction_report',
            party_type: 'customer',
            index: 6,
            new: false,
            hide: this.props?.user?.selectedCompany?.country_code != 'IN',
          },
          {
            name: 'Pro Forma Invoices',
            disabled: 0,
            menu_name: 'pro_forma_invoices',
            type: 'transaction_report',
            party_type: 'customer',
            index: 7,
            new: false,
          },
          {
            name: 'Purchase Orders',
            disabled: 0,
            menu_name: 'purchase_orders',
            type: 'transaction_report',
            party_type: 'vendor',
            index: 8,
            new: false,
          },
          {
            name: 'Expense',
            disabled: 0,
            menu_name: 'expense_report',
            type: 'expense_report',
            party_type: 'vendor',
            document_type: 'expense',
            index: 20,
            new: false,
          },
          {
            name: 'Indirect Income',
            disabled: 0,
            menu_name: 'income_report',
            type: 'income_report',
            party_type: 'vendor',
            document_type: 'indirect_income',
            index: 21,
            new: false,
          },
          {
            name: 'Category-Wise Expense',
            disabled: 0,
            menu_name: 'expense_report',
            type: 'expense_report',
            is_category_group: true,
            party_type: 'vendor',
            document_type: 'expense',
            index: 47,
            new: false,
          },
          {
            name: 'Category-Wise Indirect Income',
            disabled: 0,
            menu_name: 'expense_report',
            type: 'income_report',
            is_category_group: true,
            party_type: 'vendor',
            document_type: 'indirect_income',
            index: 48,
            new: false,
          },
        ],
      },
      {
        name: 'Bill-wise Item Reports',
        disabled: 1,
        children: [
          {
            name: 'Sales',
            disabled: 0,
            menu_name: 'sales',
            type: 'transaction_wise_item_report',
            party_type: 'customer',
            index: 9,
            new: false,
          },
          {
            name: 'Purchases',
            disabled: 0,
            menu_name: 'purchases',
            type: 'transaction_wise_item_report',
            party_type: 'vendor',
            index: 10,
            new: false,
          },
          {
            name: 'Sale Returns / Credit Notes',
            disabled: 0,
            menu_name: 'sales_returns',
            type: 'transaction_wise_item_report',
            party_type: 'customer',
            index: 11,
            new: false,
          },
          {
            name: 'Purchase Returns / Debit Notes',
            disabled: 0,
            menu_name: 'purchase_returns',
            type: 'transaction_wise_item_report',
            party_type: 'vendor',
            index: 12,
            new: false,
          },
          {
            name: 'Estimates',
            disabled: 0,
            menu_name: 'estimates',
            type: 'transaction_wise_item_report',
            party_type: 'customer',
            index: 13,
            new: false,
          },
          {
            name: 'Delivery Challans',
            disabled: 0,
            menu_name: 'delivery_challans',
            type: 'transaction_wise_item_report',
            party_type: 'customer',
            index: 14,
            new: false,
            hide: this.props?.user?.selectedCompany?.country_code != 'IN',
          },
          {
            name: 'Pro Forma Invoices',
            disabled: 0,
            menu_name: 'pro_forma_invoices',
            type: 'transaction_wise_item_report',
            party_type: 'customer',
            index: 15,
            new: false,
          },
          {
            name: 'Purchase Orders',
            disabled: 0,
            menu_name: 'purchase_orders',
            type: 'transaction_wise_item_report',
            party_type: 'vendor',
            index: 16,
            new: false,
          },
          // {
          //   name: "Expenses",
          //   disabled: 0
          // },
        ],
      },

      {
        name: 'Item Reports',
        disabled: 1,
        children: [
          {
            name: 'Stock Summary',
            disabled: 0,
            type: 'stock_report',
            index: 17,
            new: false,
          },

          {
            name: 'Item-wise Discount',
            disabled: 0,
            type: 'item_wise_discount',
            index: 19,
            new: false,
          },
          {
            name: 'Stock Value',
            disabled: 0,
            type: 'stock_value',
            index: 20,
            new: false,
          },
          {
            name: 'Inventory Timeline',
            disabled: 0,
            type: 'daily_stock_report',
            index: 50,
            new: false,
          },
          {
            name: 'Low Stock',
            disabled: 0,
            type: 'low_stock_report',
            index: 51,
            new: true,
          },
          // {
          //   name: 'Stock Transactions Summary',
          //   disabled: 0,
          //   type: 'stock_transactions_report',
          //   index: 51,
          //   new: false,
          // },
        ],
      },
      {
        name: 'Party Reports',
        disabled: 1,
        type: 'party_report',
        children: [
          {
            name: 'All Customers',
            disabled: 0,
            type: 'party_report',
            party_type: 'customer',
            index: 21,
            new: false,
          },
          {
            name: 'All Vendors',
            disabled: 0,
            type: 'party_report',
            party_type: 'vendor',
            index: 22,
            new: false,
          },
          {
            name: 'Customer Statement',
            disabled: 0,
            type: 'ledger',
            party_type: 'customer',
            index: 23,
            new: false,
          },
          {
            name: 'Vendor Statement',
            disabled: 0,
            type: 'ledger',
            party_type: 'vendor',
            index: 24,
            new: false,
          },
          {
            name: 'Customer wise Items',
            disabled: 0,
            type: 'party_wise_items',
            party_type: 'customer',
            index: 25,
            new: false,
          },
          {
            name: 'Vendor wise Items',
            disabled: 0,
            type: 'party_wise_items',
            party_type: 'vendor',
            index: 26,
            new: false,
          },
        ],
      },
      {
        name: 'Profit & Loss(PL) Reports',
        disabled: 1,
        children: [
          {
            name: 'P&L Statement',
            disabled: 0,
            type: 'pl_report',
            index: 36,
            new: false,
          },
          {
            name: 'Sales P&L Report',
            disabled: 0,
            menu_name: 'sale_profit_loss',
            type: 'profit_loss',
            party_type: 'customer',
            index: 37,
            new: false,
          },
          {
            name: 'Sale Items P&L Report',
            disabled: 0,
            menu_name: 'sale_item_profit_loss',
            type: 'profit_loss',
            party_type: 'customer',
            index: 38,
            new: false,
          },
          {
            name: 'Item-wise P&L (Purchases)',
            disabled: 0,
            type: 'item_wise_profit_loss',
            index: 18,
            new: false,
          },
          {
            name: 'Item-wise P&L Report (Purchase Price)',
            disabled: 0,
            type: 'item_wise_profit_loss_purchase_price',
            index: 42,
            new: false,
          },
          {
            name: 'Category-wise P&L Report (Purchase Price)',
            disabled: 0,
            type: 'category_wise_profit_loss_purchase_price',
            index: 43,
            new: false,
          },
          {
            name: 'Customer-wise P&L Report',
            disabled: 0,
            type: 'party_profit_loss_report',
            party_type: 'customer',
            index: 44,
            new: false,
          },
        ],
        new: false,
      },
      {
        name: 'Payments Reports',
        disabled: 1,
        children: [
          {
            name: 'Payment Timeline',
            disabled: 0,
            type: 'payment_timeline',
            index: 45,
            new: false,
            searchType: 'party',
            party_type: 'customer',
          },
          {
            name: 'Payment Settlements',
            disabled: 0,
            type: 'payment_settlements',
            index: 46,
            new: false,
            searchType: 'party',
            party_type: 'customer',
          },
          {
            name: 'Payouts',
            disabled: 0,
            type: 'settlement_payouts',
            index: 47,
            new: false,
            hide: store.get('is_payment_gateway') == 0,
          },
        ],
        new: false,
      },
    ]

    reportsNew = this.filterReports(reportsNew)

    let tax_reports = {
      name: 'Taxes',
      disabled: 1,
      children: [
        store.get('company_details')?.is_composite == 1
          ? {}
          : {
              name: 'GSTR - 1',
              type: 'gstr_1',
              disabled: 0,
              index: 26,
              new: false,
            },
        store.get('company_details')?.is_composite == 1
          ? {}
          : {
              name: 'GSTR - 2B',
              type: 'gstr_2',
              disabled: 0,
              index: 27,
              new: false,
            },
        store.get('company_details')?.is_composite == 1
          ? {}
          : {
              name: 'GSTR - 3B',
              type: 'gstr_3',
              disabled: 0,
              index: 28,
              new: false,
            },
        store.get('company_details')?.is_composite == 1
          ? {
              name: 'GST - CMP-08',
              type: 'gst_cmp',
              disabled: 0,
              index: 45,
              new: false,
            }
          : {},
        store.get('company_details')?.is_composite == 1
          ? {
              name: 'GSTR - 4',
              type: 'gstr_4',
              disabled: 0,
              index: 46,
              new: false,
            }
          : {},
        {
          name: 'Sale Summary by HSN',
          type: 'hsn_summary',
          disabled: 0,
          index: 30,
          new: false,
        },
        {
          name: 'TDS Receivable',
          type: 'tds_report',
          disabled: 0,
          payment_type: 'in',
          index: 31,
          new: false,
        },
        {
          name: 'TDS Payable',
          type: 'tds_report',
          disabled: 0,
          payment_type: 'out',
          index: 32,
          new: false,
        },
        {
          name: 'TCS Receivable',
          type: 'tcs_report',
          disabled: 0,
          payment_type: 'in',
          index: 33,
          new: false,
        },
        {
          name: 'TCS Payable',
          type: 'tcs_report',
          disabled: 0,
          payment_type: 'out',
          index: 34,
          new: false,
        },
      ],
    }

    if (this.props?.user?.selectedCompany?.country_code == 'IN') {
      reportsNew.splice(1, 0, tax_reports)
    }

    if (this.props.integrations?.batching == 1 || this.props.integrations?.serial_number == 1) {
      reportsNew.splice(6, 0, {
        name: 'Batch Reports',
        disabled: 1,
        children: [
          {
            name: 'Batch Expiry',
            disabled: 0,
            type: 'batch_expiry_report',
            index: 10,
            isCommon: true,
            searchType: 'products',
          },
          {
            name: 'Batch Stock Summary',
            disabled: 0,
            type: 'stock_report',
            index: 11,
            has_batches: true,
          },
          {
            name: 'Batch-wise P&L (Purchases)',
            disabled: 0,
            type: 'item_wise_profit_loss',
            index: 18,
            has_batches: true,
          },
          {
            name: 'Batch-wise P&L Report (Purchase Price)',
            disabled: 0,
            type: 'item_wise_profit_loss_purchase_price',
            index: 12,
            has_batches: true,
          },
        ],
      })
    }
    // filter out empty children from taxes
    reportsNew[1].children = reportsNew[1].children.filter(child => {
      return Object.keys(child).length !== 0
    })

    const PLReport = lazy(() => import('./PLReport'))
    const TransactionReports = lazy(() => import('./TransactionReports'))
    const TransactionWiseItemReports = lazy(() => import('./TransactionWiseItemReports'))
    const StockReports = lazy(() => import('./StockReports'))
    const ItemWiseProfitandLoss = lazy(() => import('./ItemWiseProfitandLoss'))
    const PartyReports = lazy(() => import('./PartyReports'))
    const LedgerReports = lazy(() => import('./LedgerReports'))
    const GSTReport1 = lazy(() => import('./GSTReport1'))
    const GSTReport2 = lazy(() => import('./GSTR2'))
    const HSNSummary = lazy(() => import('./HSNSummary'))
    const TDSReports = lazy(() => import('./TDSReports'))
    const GSTReport3B = lazy(() => import('./GSTReport3'))
    const GSTCMP = lazy(() => import('./GSTCMP'))
    const GSTR4 = lazy(() => import('./GSTR4'))
    const ProfitLossReports = lazy(() => import('./ProfitLossReports'))
    const CommonReports = lazy(() => import('./commonReports'))
    const PaymentReports = lazy(() => import('./paymentReports'))

    return (
      <>
        {this.props.permissions.reports == 1 && (
          <div>
            <Modal
              open={this.state.open}
              width="100%"
              onCancel={e => {
                // history.push('/list/sales')
                this.setState({
                  open: false,
                })
              }}
              closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
              destroyOnClose
              centered
              footer={null}
              maxHeight={100}
            >
              <div className="px-2 py-2 height-80vh">
                <div className="flex flex-wrap">
                  <div className="mr-auto pr-3">
                    <div className="text-nowrap inline-block">
                      <div className="kit__utils__heading">
                        <h3>
                          <span className="mr-3 font-weight-bolder">
                            <FormattedMessage id="reports.heading" />
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap font-size-16">
                    <div className="inline-flex items-center float-right">
                      {/* {this.state.report.type == 'gstr_1' && ( */}
                      {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                        <>
                          <Button
                            onClick={e => {
                              store.get('paid') == 0 || store.get('paid') == 2
                                ? this.subscriptionPlanRef.current.openModal('reports')
                                : this.setState(
                                    { download_menu: 'gst_json' },
                                    this.export.showModal(),
                                  )
                            }}
                            data-beacon-id="gstr_1_json"
                            className="hidden sm:inline mr-2"
                          >
                            <i className="fa fa-arrow-down mr-1" />
                            <FormattedMessage id="button.gstr1JSON" />
                            {store.get('paid') == 0 && <ProBadge />}
                          </Button>

                          <Button
                            onClick={e => {
                              store.get('paid') == 0 || store.get('paid') == 2
                                ? this.subscriptionPlanRef.current.openModal('reports')
                                : this.setState({ download_menu: 'gst' }, this.export.showModal())
                            }}
                            data-beacon-id="gstr_1_excel"
                            className="hidden sm:inline mr-2"
                          >
                            <i className="fa fa-arrow-down mr-1" />
                            <FormattedMessage id="button.gstr1Report" />
                            {store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        </>
                      )}
                      {/* )} */}

                      {/* {this.state.report.type == 'transaction_report' &&
                        this.state.report.menu_name == 'sales' && ( */}
                      <Button
                        onClick={e => {
                          store.get('paid') == 0 || store.get('paid') == 2
                            ? this.subscriptionPlanRef.current.openModal('reports')
                            : this.setState({ download_menu: 'sales' }, this.export.showModal())
                        }}
                        data-beacon-id="sales_report"
                        className="hidden sm:inline mr-2"
                      >
                        <i className="fa fa-file-text-o mr-1" />{' '}
                        <FormattedMessage id="button.salesReport" />
                        {store.get('paid') == 0 && <ProBadge />}
                      </Button>
                      {/* )} */}
                    </div>
                  </div>
                  <div className="flex flex-wrap font-size-16">
                    <div className="inline-flex items-center float-right"></div>
                  </div>
                </div>

                <div className="flex report-menu">
                  <div>
                    <Menu
                      defaultOpenKeys={['0']}
                      defaultSelectedKeys={['0']}
                      selectedKeys={this.state.selectedKeys}
                      openKeys={this.state.openKeys}
                      onOpenChange={openKeys => {
                        //only allow one submenu open at a time
                        if (
                          openKeys.length > 0 &&
                          openKeys[openKeys.length - 1] !==
                            this.state.openKeys[this.state.openKeys.length - 1]
                        ) {
                          this.setState({
                            openKeys: [openKeys[openKeys.length - 1]],
                            report: reportsNew[openKeys[openKeys.length - 1]]?.children?.[0],
                            selectedKeys: [
                              String(reportsNew[openKeys[openKeys.length - 1]]?.children[0]?.index),
                            ],
                          })
                        }
                      }}
                      onClick={e => {
                        let index = reportsNew.findIndex(report => report.index == e.key)
                        if (reportsNew[index]?.main_menu) {
                          this.setState({
                            report: reportsNew[index],
                            selectedKeys: [String(reportsNew[index].index)],
                            openKeys: [String(index)],
                          })
                        }
                      }}
                      style={{ width: 200, height: '100%' }}
                      mode="inline"
                    >
                      {reportsNew.map((report, index) => {
                        return report?.children ? (
                          <>
                            <Menu.SubMenu
                              key={index}
                              title={
                                <>
                                  <span
                                    className={
                                      report.disabled
                                        ? `font-size-16 font-weight-bold text-gray-600 px-0 mt-3 ${
                                            report.name == 'Profit & Loss(PL) Reports'
                                              ? 'feature-3'
                                              : ''
                                          }`
                                        : `mt-3  ${
                                            report.name == 'Profit & Loss(PL) Reports'
                                              ? 'feature-3'
                                              : ''
                                          }`
                                    }
                                  >
                                    {report.name} <span>{report.new && <NewBadge />} </span>
                                  </span>
                                </>
                              }
                              style={{
                                margin: 0,
                              }}
                            >
                              {report.children.map((submenu, index2) => {
                                return (
                                  <Menu.Item
                                    key={submenu.index}
                                    onClick={e => {
                                      if (submenu.name != this.state.report.name) {
                                        this.setState({
                                          report: submenu,
                                          selectedKeys: [String(submenu.index)],
                                        })
                                      }
                                    }}
                                    title={submenu.name}
                                  >
                                    <span>
                                      {submenu.name} <span>{submenu.new && <NewBadge />}</span>
                                    </span>
                                  </Menu.Item>
                                )
                              })}
                            </Menu.SubMenu>
                          </>
                        ) : (
                          <Menu.Item
                            key={report.index}
                            onClick={e => {
                              if (report.name != this.state.report.name) {
                                this.setState({
                                  report: report,
                                  selectedKeys: [String(report.index)],
                                })
                              }
                            }}
                            title={report.name}
                            className="mt-3 px-0 no-submenu"
                          >
                            {report.name} <span>{report.new && <NewBadge />}</span>
                          </Menu.Item>
                        )
                      })}
                    </Menu>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      padding: '0 20px',
                      overflow: 'auto',
                      height: 'calc(90vh - 50px)',
                    }}
                    id="style-9"
                  >
                    {this.state.report?.disabled != 1 && this.state.report?.type == 'pl_report' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <PLReport report={this.state.report} />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'transaction_report' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <TransactionReports report={this.state.report} />
                        </Suspense>
                      )}
                    {this.state.report.disabled != 1 && this.state.report.type == 'expense_report' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ExpenseReport report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 && this.state.report.type == 'income_report' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ExpenseReport report={this.state.report} />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'day_book' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <DayBook />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'transaction_wise_item_report' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <TransactionWiseItemReports report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'stock_report' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <StockReports report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'stock_transactions_report' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <StockReports report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'item_wise_profit_loss' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ItemWiseProfitandLoss report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'item_wise_profit_loss_purchase_price' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ItemWiseProfitandLoss report={this.state.report} />
                        </Suspense>
                      )}
                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'category_wise_profit_loss_purchase_price' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ItemWiseProfitandLoss report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'item_wise_discount' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ItemWiseProfitandLoss report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'stock_value' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ItemWiseProfitandLoss
                          report={this.state.report}
                          changeReport={this.changeReportType}
                        />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'low_stock_report' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ItemWiseProfitandLoss
                            report={this.state.report}
                            changeReport={this.changeReportType}
                          />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'daily_stock_report' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ItemWiseProfitandLoss report={this.state.report} />
                        </Suspense>
                      )}
                    {this.state.report.disabled != 1 && this.state.report.type == 'party_report' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <PartyReports report={this.state.report} />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'ledger' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <LedgerReports report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'party_wise_items' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <LedgerReports report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 &&
                      this.state.report.type == 'party_profit_loss_report' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <PartyReports report={this.state.report} />
                        </Suspense>
                      )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'gstr_1' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <GSTReport1 report={this.state.report} />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'gstr_2' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <GSTReport2 report={this.state.report} />
                      </Suspense>
                    )}

                    {this.state.report.disabled != 1 && this.state.report.type == 'hsn_summary' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <HSNSummary report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 &&
                      (this.state.report.type == 'tds_report' ||
                        this.state.report.type == 'tcs_report') && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <TDSReports report={this.state.report} />
                        </Suspense>
                      )}
                    {this.state.report.disabled != 1 && this.state.report.type == 'gstr_3' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <GSTReport3B report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 && this.state.report.type == 'gst_cmp' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <GSTCMP report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 && this.state.report.type == 'gstr_4' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <GSTR4 report={this.state.report} />
                      </Suspense>
                    )}

                    {/* Profit Loss Reports */}
                    {this.state.report.disabled != 1 && this.state.report.type == 'profit_loss' && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ProfitLossReports report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 && this.state.report.isCommon == true && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <CommonReports report={this.state.report} />
                      </Suspense>
                    )}
                    {this.state.report.disabled != 1 &&
                      (this.state.report.type == 'payment_timeline' ||
                        this.state.report.type == 'payment_settlements' ||
                        this.state.report.type == 'settlement_payouts') && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <PaymentReports report={this.state.report} />
                        </Suspense>
                      )}
                  </div>
                </div>

                <EnhancedExport
                  onRef={ref => (this.export = ref)}
                  menuName={this.state.download_menu}
                />
                <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
                <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
              </div>
            </Modal>
          </div>
        )}
        {this.props.permissions.reports == 0 && <NoAccess />}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    integrations: state.permissions.integrations,
    user: state.user,
    company_details: state.permissions.company_details,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Reports)
