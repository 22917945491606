import { LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Menu, Tooltip, theme } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import store from 'store'
const { useToken } = theme

import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import { CompanyAvatar } from 'components/other/utility'
import { history } from 'index'
import { GetLogo } from 'pages/firebasejs'
import { changeCompany, currentAccount, getAPI } from 'services/jwt'
import { v4 as uuidv4 } from 'uuid'

function ChangeCompany({ dispatch, ...props }) {
  const { token } = useToken()
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  }

  const [selectedCompany, setCompany] = useState('')
  const user = useSelector(state => state.user)
  const [visible, setVisible] = useState(false)
  const [logo, setLogo] = useState('/resources/images/logo5.svg')
  const [loading, setLoading] = useState(false)
  const [companiesList, setCompaniesList] = useState([])
  const [companyIndices, setCompanyIndices] = useState([])
  const subscriptionRef = useRef(null)

  const handleRef = ref => {
    subscriptionRef.current = ref
  }

  const getCurrentCompanyIndex = () => {
    const currentCompanyIndex = user.companies.findIndex(item => item.current_company === 1)
    return currentCompanyIndex
  }

  useEffect(() => {
    const currentCompanyIndex = getCurrentCompanyIndex()
    let currentCompanyName = ''
    let updatedCompaniesList = [...user.companies]

    let companyIndexes = {}
    updatedCompaniesList
      .sort((a, b) => {
        return a.company_id - b.company_id
      })
      .forEach((item, index) => {
        if (index < 9) {
          companyIndexes[item.company_id] = index + 1
        }
      })
    setCompanyIndices(companyIndexes)

    if (currentCompanyIndex >= 0) {
      currentCompanyName = user.companies[currentCompanyIndex]?.organization_name
      // Move current company to the top
      const currentCompany = updatedCompaniesList.splice(currentCompanyIndex, 1)[0]
      updatedCompaniesList.unshift(currentCompany)
    }

    setCompany(currentCompanyName)
    setCompaniesList(updatedCompaniesList)

    if ((props.companyDetailsAdded || props.bankDetailsAdded) && props.invoices_created) {
      store.set('app.settings.tutorialTouched', true)
    }

    const getLogo = async () => {
      const logo = await GetLogo()
      if (logo != null && logo != undefined && logo != '') {
        setLogo(logo)
      }
    }
    getLogo()
  }, [user])

  const handleMenuClick = (e, current_company) => {
    if (current_company === 1) {
      return
    }

    setLoading(true)
    user.companies.every(async (element, index, array) => {
      if (element['company_id'] == e) {
        changeCompany(element)
      }
      return true
    })
  }

  const handleVisibleChange = flag => {
    if (flag) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    const currentCompanyIndex = getCurrentCompanyIndex()
    let updatedCompaniesList = [...user.companies]
    if (currentCompanyIndex >= 0) {
      const currentCompany = updatedCompaniesList.splice(currentCompanyIndex, 1)[0]
      updatedCompaniesList.unshift(currentCompany)
    }
    updatedCompaniesList = updatedCompaniesList.map((item, index) => {
      return {
        ...item,
        key: index,
      }
    })

    setCompaniesList(updatedCompaniesList)
    setVisible(flag)
  }

  const handleSearch = value => {
    let list = user.companies.filter(
      item =>
        item.company_name.toLowerCase().includes(value.toLowerCase()) ||
        item.organization_name?.toLowerCase().includes(value.toLowerCase()),
    )
    setCompaniesList(list)
  }

  const addNewBussiness = () => {
    setVisible(false)
    history.push('/add_new_company')
  }

  const menu = (
    <Menu
      onClick={e => e.domEvent.stopPropagation()}
      style={{ maxHeight: '50vh', overflowY: 'auto', scrollbarWidth: 'none' }}
    >
      {user?.companies?.length > 5 && (
        <Menu.Item
          key="search"
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            borderRadius: '8px',
            backgroundColor: token.colorBgElevated,
          }}
          className="my-2"
        >
          <Input
            prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
            type="text"
            placeholder={`Search your companies (${user.companies.length})`}
            onChange={e => {
              e.stopPropagation()
              handleSearch(e.target.value)
            }}
            onClick={e => e.stopPropagation()}
            onKeyDown={e => {
              if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
                e.stopPropagation()
              }
            }}
          />
        </Menu.Item>
      )}
      {companiesList.length === 0 && (
        <Menu.Item key="no_company" style={{ margin: '10px 20px' }}>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="ml-3">
                <span className="font-weight-medium font-size-16">No Companies Found</span>
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      <>
        {companiesList?.map((item, index) => (
          <Menu.Item
            key={item.key}
            onClick={() => handleMenuClick(item.company_id, item.current_company)}
            className={`${item.current_company === 1 && 'bg-lgreen'} `}
          >
            <div className="flex justify-between items-center py-1">
              <div className="flex items-center">
                {item.logo && item.logo !== 'None' ? (
                  <CompanyAvatar
                    shape="round"
                    className="mr-2 avatar-container"
                    size="large"
                    tick={item.current_company === 1 && true}
                    src={item.logo}
                    name={item.logo}
                  />
                ) : (
                  item.organization_name?.length > 0 && (
                    <CompanyAvatar
                      name={
                        item.organization_name?.length > 0
                          ? item.organization_name
                          : item.company_name
                      }
                      size="large"
                      className="mr-2 avatar-container"
                      tick={item.current_company === 1 && true}
                    />
                  )
                )}

                <div className="ml-2">
                  <span>
                    <span
                      className={`font-weight-${
                        item.current_company === 0 ? 'medium' : 'bold'
                      } line-clamp-1 lh-normal`}
                    >
                      {item.organization_name}{' '}
                    </span>
                  </span>
                  <p className="mt-0 mb-0 font-size-12 text-gray-500">
                    <span className="line-clamp-1">{item.company_name || 'Swipe Company'}</span>
                  </p>
                  {item.current_company === 1 && (
                    <div className="flex flex-row items-center mt-0 mb-0 pl-1 font-size-12">
                      <Tooltip title="Edit Company Details">
                        <a_link
                          className="text-center mr-3 text-gray-500"
                          onClick={e => {
                            e.stopPropagation()
                            setVisible(false)
                            window.open('/user')
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square mr-1" />
                          Edit
                        </a_link>
                      </Tooltip>
                      <Tooltip title="Share Company & Bank details">
                        <a_link
                          className="cursor-pointer text-center text-gray-500"
                          onClick={e => {
                            e.stopPropagation()
                            window.open(
                              'https://wa.me/send/?' +
                                '&text=' +
                                item.company_name +
                                '%20%0A%0AGSTIN%0A' +
                                item.gstin +
                                '%20%0A%0AAccount%20Number%0A' +
                                item.account_number +
                                '%20%0A%0AIFSC%20Code%0A' +
                                item.ifsc +
                                '%20%0A%0ABank%20Name%0A' +
                                item.bank_name +
                                '%20%0A%0ABranch%20Name%0A' +
                                (item.upi ? '%20%0A%0AUPI%20ID%0A' + item.upi : '') +
                                '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)',
                            )
                          }}
                        >
                          <i className="fa-sharp fa-solid fa-paper-plane mr-1"></i>
                          Share
                        </a_link>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              {item.current_company !== 1 && companyIndices[item.company_id] && (
                <Tooltip
                  title={`Switch to ${item.organization_name || item.company_name}`}
                  placement="right"
                >
                  <Button type="text" className="" size="small">
                    <span>CTRL + {companyIndices[item.company_id]}</span>
                  </Button>
                </Tooltip>
              )}
            </div>
          </Menu.Item>
        ))}
      </>
      <div className="dropdown-button-wrapper bg-lpurple">
        <Button
          type="link-2"
          className="font-weight-bolder text-left arrow-transition"
          onClick={addNewBussiness}
        >
          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
          Add new Company
          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
        </Button>
      </div>
    </Menu>
  )

  return (
    <div>
      {loading && (
        <div
          style={{
            minHeight: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0,0,0,0.1)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 30,
            }}
            spin
          />
        </div>
      )}

      {/* Companies List Dropdown */}
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomLeft"
        autoFocus={true}
        onOpenChange={() => handleVisibleChange(!visible)}
        open={visible}
        overlayStyle={{ minWidth: 360, maxWidth: 360, overflowY: 'auto', maxHeight: 540 }}
        dropdownRender={menu => <div>{!loading && menu}</div>}
      >
        <Tooltip title={'Change Company'} placement="right">
          <div className="flex items-center cursor-pointer ml-2" onClick={e => e.preventDefault()}>
            {user.selectedCompany?.logo && user.selectedCompany?.logo !== 'None' ? (
              <CompanyAvatar
                size=""
                className="avatar-container h-10 w-10"
                src={user.selectedCompany.logo}
                name={user.selectedCompany.logo}
              />
            ) : (
              user?.selectedCompany?.organization_name?.length > 0 && (
                <CompanyAvatar
                  size=""
                  className="avatar-container h-10 w-10"
                  name={user.selectedCompany.organization_name}
                />
              )
            )}
            <div className="flex flex-col justify-center ml-1">
              <span className="font-weight-bold font-size-14 leading-none align-text-top">
                {user?.selectedCompany?.organization_name}
              </span>
              {user?.companies?.length > 1
                ? user.selectedCompany.organization_name != undefined && (
                    <p className="text-gray-500 font-size-11 pt-1 font-weight-medium leading-none">
                      <i className="fa-solid fa-shuffle mr-1"></i> Change Company
                    </p>
                  )
                : selectedCompany && (
                    <p className="text-gray-500 font-size-11 pt-1 font-weight-medium leading-none">
                      <i className="fa-solid fa-plus mr-1"></i> Add Another Company
                    </p>
                  )}
            </div>
          </div>
        </Tooltip>
      </Dropdown>

      <EnhancedSubscriptionModal onRef={handleRef} />
    </div>
  )
}

const mapStateToProps = state => {
  const { menu, settings, user, permissions, welcome } = state
  return {
    menuData: menu.menuData,
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    role: user.role,
    companies: user.companies,
    user: user,
    states: state.document.states,
    tds_sections: state.document.tdsSections,
    countries: state.document.countries,
    companyDetailsAdded: welcome.company_details_added,
    bankDetailsAdded: welcome.bank_details_added,
    productsAdded: welcome.products_added,
    invoices_created: welcome.invoices_created,
    permissions: permissions.permission,
  }
}

export default connect(mapStateToProps)(ChangeCompany)
