import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import { Button, Modal } from 'antd'
import EnhancedProductAnalysis from 'components/modal/productAnalysis'
import { Item } from 'redux/types'

const emptyProductDetails = {
  product_name: '',
  product_id: 0,
}

const ExistingProductModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [productDetails, setProductDetails] = useState({ ...emptyProductDetails })
  const [name, setName] = useState('')
  const productAnalysisRef = useRef<any | null>()
  const showModal = (product_details: Item) => {
    setVisible(true)
    setProductDetails(product_details)
    setName(name)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const onCancel = () => {
    setVisible(false)
    setProductDetails({ ...emptyProductDetails })
    setName('')
  }
  const handleProductAnalysisRef = (ref: any) => {
    productAnalysisRef.current = ref
  }
  useEffect(() => {
    return () => {
      productAnalysisRef.current = null
    }
  }, [])
  return (
    <>
      <Modal
        maskClosable={false}
        open={visible}
        closable
        title={
          <>
            <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
            Warning
          </>
        }
        footer={
          <>
            <Button type="danger" onClick={onCancel}>
              Close
            </Button>
          </>
        }
        destroyOnClose
        onCancel={onCancel}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      >
        <p>
          <span className="text-gray-700 font-size-16">
            The product with this name{' '}
            <span className="font-weight-bold">{productDetails?.product_name}</span> already exists.{' '}
            <a
              className="hover-underline mr-2 block font-weight-bold text-primary"
              onClick={() => {
                if (productAnalysisRef.current) {
                  productAnalysisRef?.current?.showModal(productDetails, productDetails.product_id)
                }
                // onCancel()
              }}
            >
              View Product
            </a>
          </span>{' '}
        </p>

        <EnhancedProductAnalysis
          onRef={handleProductAnalysisRef}
          menu="product"
          onProductUpdate={() => {
            onCancel()
          }}
        />
      </Modal>
    </>
  )
})

export default ExistingProductModal
