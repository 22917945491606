import { Button, Space } from 'antd'

import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'

import IntercomSupportProvider from 'pages/support/interCom'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import style from './style.module.scss'

const mapStateToProps = state => {
  const { menu, settings, user, permissions, welcome } = state
  return {
    menuData: menu.menuData,
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    theme: settings.theme,
    role: user.role,
    companies: user.companies,
    user: user,
    states: state.document.states,
    tds_sections: state.document.tdsSections,
    countries: state.document.countries,
    companyDetailsAdded: welcome.company_details_added,
    bankDetailsAdded: welcome.bank_details_added,
    productsAdded: welcome.products_added,
    invoices_created: welcome.invoices_created,
    permissions: permissions.permission,
    notification_count: state.document.notification_count,
  }
}

const FeatureBar = ({ dispatch, user, theme, logo, ...props }) => {
  // const [logo, setLogo] = useState(logo)
  const selectedCompany = user?.selectedCompany?.organization_name
  const subscriptionRef = useRef(null)

  const [isCreateDropdownVisible, setIsDropdownVisible] = useState(false)

  const subscriptionPlanRef = useRef()
  const handleRef = ref => {
    subscriptionRef.current = ref
  }

  return (
    <div className={`${style.FeatureBar} flex items-center justify-between `}>
      <div
        className="feature-2 flex flex-row items-center cursor-pointer mr-3"
        onClick={e => e.preventDefault()}
      >
        <span className="">
          <Space align="center">
            <Button
              size="large"
              type="text"
              className="rounded-full"
              onClick={() => props.leaveChanges()}
            >
              <i className="fa-regular fa-sharp fa-chevron-left fa-lg text-gray-500 cursor-pointer" />
            </Button>
          </Space>
        </span>
        <div className="company_name_text flex flex-col align-self-center justify-start ml-1 mr-8">
          <span className="font-size-18 font-weight-bold">{props?.title}</span>
          <div className="text-gray-500 font-size-12 mt-0 mb-0 font-weight-bold">
            {/* # {props?.serial_number}
            <br /> */}
            {selectedCompany}
          </div>
        </div>
        <div className="flex items-center flex-grow-1 justify-content-left">{props?.edit}</div>
      </div>

      <div className="flex items-center">
        {props.permissions.intercom == 1 && <IntercomSupportProvider size="small" />}
        <Button
          className="hidden sm:block font-weight-bold mr-4"
          loading={props.loadingSave}
          type="secondary"
          onClick={() => props.save('1', true)}
        >
          Save as Draft
        </Button>
        <Button
          className="hidden sm:block font-weight-bold mr-4"
          loading={props.loadingSave}
          onClick={() => props.save('2')}
          type="secondary"
        >
          Save and Print
        </Button>
        <Button
          loading={props.loadingSave}
          onClick={() => props.save('1')}
          type="primary"
          className="font-weight-bold arrow-transition"
        >
          Save
          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
        </Button>
      </div>

      <EnhancedSubscriptionModal onRef={handleRef} />
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(FeatureBar))
