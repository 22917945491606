import 'firebase/analytics'
import * as firebase from 'firebase/app'
import { lazy, Suspense, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import store from 'store'

import { Beacon } from '@beacon.li/bar'
import { notification } from 'antd'
import { EnhancedNoAccessModal } from 'components/modal/noaccess'
import NetworkDetector from 'Hoc/NetworkDetector'
import withGlobalShortcuts from 'Hoc/Shortcuts'
import Layout from 'layouts'
import queryString from 'query-string'
import { update_notification_count } from 'redux/document/actions'
import { allowedHostnames } from 'services/axios'
import { getAPI, onClickNotification } from 'services/jwt'
import { trackUser } from 'utils/netcore'

const posRoutes = [
  {
    path: '/pos',
    Component: lazy(() => import('pages/pos')),
    exact: true,
  },
]

const routes = [
  // Dashboards
  {
    path: '/sales',
    Component: lazy(() => import('pages/doc/list')),
    exact: true,
  },
  {
    path: '/home',
    Component: lazy(() => import('pages/analytics')),
    exact: true,
  },
  {
    path: '/expenses',
    Component: lazy(() => import('pages/expenses')),
    exact: true,
  },
  {
    path: '/indirect_income',
    Component: lazy(() => import('pages/expenses')),
    exact: true,
  },
  {
    path: '/paymentsTimeline',
    Component: lazy(() => import('pages/paymentsTimeline')),
    exact: true,
  },
  {
    path: '/paymentLinks',
    Component: lazy(() => import('pages/paymentsTimeline/paymentLinks')),
    exact: true,
  },
  {
    path: '/journals',
    Component: lazy(() => import('pages/journals')),
    exact: true,
  },
  {
    path: '/pos',
    Component: lazy(() => import('pages/pos')),
    exact: true,
  },
  {
    path: '/user',
    Component: lazy(() => import('pages/user')),
    exact: true,
  },
  {
    path: '/userNotifications',
    Component: lazy(() => import('pages/userNotifications')),
    exact: true,
  },
  {
    path: '/onlineorderpos/:id+',
    Component: lazy(() => import('pages/onlineorders/orderToPos')),
    exact: true,
  },
  {
    path: '/inventory',
    Component: lazy(() => import('pages/inventory')),
    exact: true,
  },
  {
    path: '/inventory/warehouse/:id+',
    Component: lazy(() => import('pages/inventory/Warehouse/stocks')),
    exact: true,
  },
  {
    path: '/vendor',
    Component: lazy(() => import('pages/vendors')),
    exact: true,
  },
  {
    path: '/analytics',
    Component: lazy(() => import('pages/analytics')),
    exact: true,
  },
  {
    path: '/reports',
    Component: lazy(() => import('pages/reports/reportsV2')),
    exact: true,
  },
  {
    path: '/drive',
    Component: lazy(() => import('pages/myDrive')),
    exact: true,
  },
  {
    path: '/products',
    Component: lazy(() => import('pages/allproducts')),
    exact: true,
  },
  {
    path: '/packingLists',
    Component: lazy(() => import('pages/packingList')),
  },
  {
    path: '/create/packingList/:id+',
    Component: lazy(() => import('pages/packingList/create')),
    exact: true,
  },
  {
    path: '/edit/packingList/:id+',
    Component: lazy(() => import('pages/packingList/create')),
    exact: true,
  },
  {
    path: '/create/:id+',
    Component: lazy(() => import('pages/doc/create')),
    exact: true,
  },
  {
    path: '/list/:id+',
    Component: lazy(() => import('pages/doc/list/')),
    exact: true,
  },
  {
    path: '/edit/:doc_type/:id+',
    Component: lazy(() => import('pages/doc/create/')),
    exact: true,
  },
  {
    path: '/duplicate/:doc_type/:id+',
    Component: lazy(() => import('pages/doc/create/')),
    exact: true,
  },
  {
    path: '/convert/:from_doc_type/:to_doc_type/:id+',
    Component: lazy(() => import('pages/doc/create/')),
    exact: true,
  },
  {
    path: '/save_print/:id+',
    Component: lazy(() => import('pages/doc/saveAndPrint')),
    exact: true,
  },
  {
    path: '/customer',
    Component: lazy(() => import('pages/customers')),
    exact: true,
  },
  {
    path: '/customer/ledger/:id+',
    Component: lazy(() => import('pages/customers/ledger')),
    exact: true,
  },
  {
    path: '/view/ledger/customer/:id+',
    Component: lazy(() => import('pages/payment/shareLedger')),
    exact: true,
  },
  {
    path: '/view/ledger/vendor/:id+',
    Component: lazy(() => import('pages/payment/shareLedger')),
    exact: true,
  },
  {
    path: '/view/payments/:id',
    Component: lazy(() => import('pages/payment/viewPayment')),
    exact: true,
  },
  {
    path: '/l/create/payments/:id',
    Component: lazy(() => import('pages/payment/publicPaymentLink')),
    exact: true,
  },
  {
    path: '/l/view/:id/:id',
    Component: lazy(() => import('pages/invoice/docLinkPreview')),
    exact: true,
  },
  {
    path: '/l',
    Component: lazy(() => import('pages/invoice/docLinkPreview')),
    exact: true,
  },
  {
    path: '/onlineorders',
    Component: lazy(() => import('pages/onlineorders')),
    exact: true,
  },
  {
    path: '/onlineorders/1',
    Component: lazy(() => import('pages/onlineorders')),
    exact: true,
  },
  {
    path: '/onlineorders/2',
    Component: lazy(() => import('pages/onlineorders')),
    exact: true,
  },

  {
    path: '/onlineorders/3',
    Component: lazy(() => import('pages/onlineorders')),
    exact: true,
  },
  {
    path: '/onlineorders/4',
    Component: lazy(() => import('pages/onlineorders')),
    exact: true,
  },
  {
    path: '/onlineorders/5',
    Component: lazy(() => import('pages/onlineorders')),
    exact: true,
  },
  {
    path: '/analytics/sales',
    Component: lazy(() => import('pages/inventory/Primary')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/gmail_login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/verify-email',
    Component: lazy(() => import('pages/auth/gmailLogin')),
    exact: true,
  },
  {
    path: '/auth/admin/login',
    Component: lazy(() => import('pages/auth/adminLogin')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/reset/:id',
    Component: lazy(() => import('pages/auth/reset')),
    exact: true,
  },
  {
    path: '/auth/company',
    Component: lazy(() => import('pages/auth/bankdetails')),
    exact: true,
  },
  {
    path: '/notfound',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/tutorials',
    Component: lazy(() => import('pages/tutorials')),
    exact: true,
  },
  {
    path: '/ewaybills/create/:id+',
    Component: lazy(() => import('pages/ewayBills/ewaybill')),
    exact: true,
  },
  {
    path: '/ewaybills',
    Component: lazy(() => import('pages/ewayBills')),
    exact: true,
  },
  {
    path: '/einvoices',
    Component: lazy(() => import('pages/einvoices')),
    exact: true,
  },
  {
    path: '/settlements',
    Component: lazy(() => import('pages/settlements')),
    exact: true,
  },
  {
    path: '/pricing',
    Component: lazy(() => import('pages/pricing')),
    exact: true,
  },
  {
    path: '/add_new_company',
    Component: lazy(() => import('pages/company')),
    exact: true,
  },
  {
    path: '/wallet_credits',
    Component: lazy(() => import('pages/wallet_credits')),
    exact: true,
  },
  {
    path: '/campaigns',
    Component: lazy(() => import('pages/campaigns')),
    exact: true,
  },
  {
    path: '/coupons',
    Component: lazy(() => import('pages/coupons')),
    exact: true,
  },
  {
    path: '/no_access',
    Component: lazy(() => import('components/noaccess')),
    exact: true,
  },
  {
    path: '/customers/bulkedit',
    Component: lazy(() => import('pages/bulkEdit/customerBulkEdit')),
    exact: true,
  },
  {
    path: '/products/bulkedit',
    Component: lazy(() => import('pages/bulkEdit/productBulkEdit')),
    exact: true,
  },
  {
    path: '/upload',
    Component: lazy(() => import('pages/import')),
    exact: true,
  },
  {
    path: '/vendors/bulkedit',
    Component: lazy(() => import('pages/bulkEdit/vendorBulkEdit')),
    exact: true,
  },
  {
    path: '/inventory/stockin/bulkedit',
    Component: lazy(() => import('pages/bulkEdit/inventoryBulkEdit')),
    exact: true,
  },
  {
    path: '/inventory/stockout/bulkedit',
    Component: lazy(() => import('pages/bulkEdit/inventoryBulkEdit')),
    exact: true,
  },
  {
    path: '/vyapar_import',
    Component: lazy(() => import('components/forms/importOne')),
    exact: true,
  },
  {
    path: '/bulk_import',
    Component: lazy(() => import('components/forms/importOne')),
    exact: true,
  },
  {
    path: '/swipeteam/auth/login',
    Component: lazy(() => import('pages/agentLeads/Login')),
    exact: true,
  },
  {
    path: '/swipeteam/list/leads',
    Component: lazy(() => import('pages/agentLeads/leads')),
    exact: true,
  },
  {
    path: '/swipe_tally',
    Component: lazy(() => import('components/tally/index')),
    exact: true,
  },
  {
    path: '/share/drive/:id+',
    Component: lazy(() => import('pages/myDrive/sharedFile')),
    exact: true,
  },
  {
    path: '/inventoryTimeline',
    Component: lazy(() => import('pages/inventory/Timeline')),
  },
  {
    path: '/auth/shopifyLogin',
    Component: lazy(() => import('pages/auth/shopifyLogin')),
  },
  {
    path: '/help',
    Component: lazy(() => import('pages/support/supportRoute')),
  },
  {
    path: '/bankRecon',
    Component: lazy(() => import('pages/bankReconciliation')),
  },
  {
    path: '/razorpay/callback',
    Component: lazy(() => import('pages/razorpay/codeCallback')),
  },
  {
    path: '/razorpay',
    Component: lazy(() => import('pages/razorpay')),
  },
  {
    path: '/inventory_transfer',
    Component: lazy(() => import('pages/inventory/inventoryTransfer')),
  },
]

const mergeRouter = [...routes, ...posRoutes]

const mapStateToProps = (state, ownProps) => {
  return {
    routerAnimation: state.settings.routerAnimation,
  }
}

const Router = ({ history, routerAnimation }) => {
  const userDetails = useSelector(state => state.user.userDetails)
  const selectedCompany = useSelector(state => state.user.selectedCompany)
  let defaultUrl = useSelector(state => state.permissions.default_url)
  const curLocationHref = window.location?.href
  const { query: curQueryParams } = curLocationHref
    ? queryString.parseUrl(curLocationHref)
    : { query: {} }
  let { url: defaultBaseUrl, query: defaultQueryParams } = queryString.parseUrl(defaultUrl)
  const paramsString = queryString.stringify({ ...defaultQueryParams, ...curQueryParams })
  const url = defaultBaseUrl + (paramsString ? '?' + paramsString : '')
  const isCompanyUpdating = useSelector(state => state.user.isCompanyUpdating)

  const dispatch = useDispatch()
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: 'AIzaSyBIREKUF69cxvtcR20jiTHsoibyyssKilc',
        authDomain: 'vectorx-business.firebaseapp.com',
        projectId: 'vectorx-business',
        storageBucket: 'vectorx-business.appspot.com',
        messagingSenderId: '448547958118',
        appId: '1:448547958118:web:c8142a4cffc20058892ca7',
        measurementId: 'G-KV3611HNQY',
      })
    }
    firebase.analytics()
    Beacon.setRouter(path => {
      history.push(path)
    })
  }, [])

  const msgListner = event => {
    // Triggered when received notigication if app is in foreground
    // Triggered when notification is clicked if app is in background
    if (event.data && event.data.type === 'notificationclick') {
      const notificationData = event.data.notificationData
      if (notificationData.notification_uid || notificationData.topic) {
        getAPI('notifications', 'update_notification/web', {
          notification_uid: notificationData.notification_uid,
          notification_topic: notificationData?.topic,
        })
        dispatch(update_notification_count(-1))
      }
    } else if (event.data && event.data.notification && event.type == 'message') {
      dispatch(update_notification_count(1))
      notification.info({
        message: event.data.notification.title,
        description: event.data.notification.body,
        onClick: () => {
          onClickNotification(event.data.data)
        },
        role: event.data.data.notification_type,
      })
    }
  }

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', msgListner)
    }
    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener('message', msgListner)
      }
    }
  }, [])

  useEffect(() => {
    if (userDetails?.user_id && selectedCompany?.company_id && !isCompanyUpdating) {
      trackUser({ userDetails, selectedCompany })
    }
  }, [userDetails, selectedCompany, isCompanyUpdating])

  var mergeRouter = []
  // const OnlineStoreComponent = lazy(() => import('pages/onlinestore'))
  if (store.get('posFlag')) mergeRouter = [...routes, ...posRoutes]
  else mergeRouter = [...routes]

  // Get the current hostname
  const currentHostname = window.location.hostname

  // Check if the current hostname is in the allowed list
  const isHostnameAllowed = allowedHostnames.some(hostname => currentHostname.includes(hostname))

  return (
    <Layout>
      <Route
        render={state => {
          const { location } = state
          return (
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                appear
                classNames={routerAnimation}
                timeout={routerAnimation === 'none' ? 0 : 300}
              >
                <Switch location={location}>
                  <Route exact path="/" render={() => <Redirect to={url} />} />
                  <Route exact path="/sales" render={() => <Redirect to={url} />} />
                  {mergeRouter.map(({ path, Component, exact }) => (
                    <Route
                      path={path}
                      key={path}
                      exact={exact}
                      render={() => {
                        return (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <Component />
                            </Suspense>
                          </div>
                        )
                      }}
                    />
                  ))}
                  <Redirect to="/notfound" />
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          )
        }}
      />
      {store.get('noaccess') ? <EnhancedNoAccessModal /> : null}
    </Layout>
  )
}

export default withGlobalShortcuts(NetworkDetector(connect(mapStateToProps)(Router)))
