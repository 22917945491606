import {
  Affix,
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Pagination,
  Popover,
  Row,
  Select,
  Table,
  Tooltip,
  message,
  notification,
} from 'antd'
import ProBadge from 'components/badges/pro'
import InventoryForm from 'components/forms/inventoryForm'
import ProductForm from 'components/forms/productForm'
import VariantForm from 'components/forms/variantForm'
import FilterHome from 'components/kit/widgets/Lists/FilterHome'
import { Loader } from 'components/loaders'
import ProductBarcode from 'components/modal/barcode'
import BillOfMaterials from 'components/modal/bom'
import { EnhancedDelete } from 'components/modal/delete'
import DeleteConfirmationModal from 'components/modal/delete/deleteAll'
import { EnhancedExport } from 'components/modal/exportConfirm'
import EnhancedImport from 'components/modal/import'
import EnhancedProductAnalysis from 'components/modal/productAnalysis'
import ProductImagesCarousel from 'components/modal/productAnalysis/productImagesCarousel'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import { EnhancedBulkUpload } from 'components/modal/upload'
import { UserAvatar } from 'components/other/utility'
import MultiSelectDropdown from 'components/other/MultiSelectDropdown'
import Batches from 'components/other/batches'
import Experience from 'components/other/experience'
import hash from 'hash.js'
import { history } from 'index'
import React, { Component } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramShareButton,
} from 'react-share'
import { get_categories } from 'redux/document/actions'
import { deleteAPI, downloadAPI, getAPI } from 'services/jwt'
import store from 'store'

import styled from 'styled-components'
import ManageColumns from 'components/other/manageColumns'
const StyledTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-footer {
    padding: 0 !important;
  }
`

const { Option } = Select
const urlParams = new URLSearchParams(window.location.search)
var myParam = urlParams.get('filter')

export const DisabledInventoryTooltip = () => (
  <p className="mt-2">
    <span>Track Inventory for Services is OFF.</span>
    <br />
    <br />
    <Button
      size="small"
      onClick={e => {
        e.stopPropagation()
        window.location.href = '/user?tab=settings'
      }}
      type="primary"
      className="font-weight-normal"
    >
      Go to Inventory Settings -&gt;
    </Button>
  </p>
)

export class Product extends Component {
  formRef = React.createRef()
  subscriptionPlanRef = React.createRef()

  constructor(props) {
    super(props)

    this.allType = ['Product', 'Price', 'Type', 'Category', 'HSN/SAC Code', 'Tax']

    this.state = {
      products: [],
      confirmFlag: false,
      priceListConfirmFlag: false,
      importData: [],
      num_records: 10,
      page: 0,
      loading: false,
      total: 0,
      searchType: 'Product',
      search: '',
      timeout: null,
      sorter: '',
      sort_type: 'product_name',
      edit: false,
      barcodeData: { product_id: 123 + '' },
      price_list_id: 0,
      is_price_list: 0,
      product_link: '',
      custom_columns: [],
      selected_category: 'All',
      categories: [],
      is_low_stock: myParam == 'low_stock' ? true : false,
      loading_products: false,
      show_share: false,
      selectedProduct: {},
      expandedKeys: [],
      selected_user_ids: [],
      showDeleteAllModal: false,
      verified: false,
      priceList: [],
      itemPrices: [],
      price_list_loading: false,
      show_reverse: false,
      show_reverse_map: {},
      selected_categories: [],
      experience_loading: false,
      columns: [],
      _columns: [],
      extra_columns: [],
      variant_custom_columns: [],
    }
  }

  componentDidMount() {
    this.callData(this.state.page, this.state.num_records)
    if (myParam == 'low_stock') {
      this.setState({ is_low_stock: true })
    }
    if (this.props.categories.length == 0) {
      this.props.dispatch(get_categories())
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }
  toggleReverse = unit => {
    this.setState(prevState => {
      const show_reverse_map = { ...prevState.show_reverse_map }
      show_reverse_map[unit.alternative_unit] = !show_reverse_map[unit.alternative_unit]
      return { show_reverse_map }
    })
  }
  async callConfirmAPI(flag) {
    if (flag) this.setState({ loading: true })
    else this.setState({ loadingCancel: true, confirmFlag: false })
    const req = {
      is_confirm: flag,
    }
    const data = await getAPI('product', 'confirm', req)
    this.setState({ loading: false, loadingCancel: false })
    if (data) {
      this.callData(this.state.page, this.state.num_records)
      this.setState({ loading: false, loadingCancel: false })
    } else {
      this.setState({ loading: false, loadingCancel: false })
    }
  }

  async priceListCallConfirmAPI(flag) {
    if (flag) this.setState({ loading: true })
    else this.setState({ loadingCancel: true })
    const req = {
      is_confirm: flag,
      price_list_id: this.state.price_list_id,
    }
    const data = await getAPI('product', 'confirm_price_list', req)
    this.setState({ loading: false, loadingCancel: false, priceListConfirmFlag: false })
    if (data) {
      this.callData(this.state.page, this.state.num_records)
      this.setState({ loading: false, loadingCancel: false, priceListConfirmFlag: false })
    } else {
      this.setState({ loading: false, loadingCancel: false, priceListConfirmFlag: false })
    }
  }

  callData = async (page, records) => {
    this.setState({ loading_products: true })
    const req = {
      num_records: records,
      page: page,
      search: this.state.search,
      search_type: this.state.searchType,
      category: this.state.selected_category,
      sorter:
        this.state.sorter || store.get('sort_orders')
          ? store.get('sort_orders').products != ''
            ? store.get('sort_orders')?.products == 'ascend'
              ? 'asc'
              : 'desc'
            : ''
          : '',
      sort_type: this.state.sort_type,
      is_low_stock: this.state.is_low_stock,
      user_ids: this.state.selected_user_ids,
      selected_categories: this.state.selected_categories,
      hide_zero_qty: this.state.hide_zero_qty,
    }
    if (this.state.is_low_stock) {
      req.sorter = 'asc'
      req.sort_type = 'qty'
    }
    const data = await getAPI('product', 'get', req)

    if (req.search == this.state.search && data) {
      const results = data.products.map((row, index) => ({
        ...row,
        key: index,
        variants: row.variants.map((variant, index) => {
          let variant_custom_cols = {}
          variant.custom_fields?.forEach(v_col => {
            variant_custom_cols[v_col['name']] = v_col['value']
          })
          return {
            ...variant,
            key: index,
            units: row.units,
            variant_id: variant.id,
            product_name: row.product_name + ' ' + variant.name,
            ...variant_custom_cols,
          }
        }),
      }))

      // console.log(results)
      this.setState(
        {
          products:
            results && results.length > 0
              ? [{ ...results[0], isHovered: true }, ...results.slice(1)]
              : results,
          edit: data.edit,
          confirmFlag: false,
          importData: [],
          total: data.total,
          page: page,
          num_records: records,
          is_price_list: data.price_list,
          _columns: data.columns,
          columns: data.columns.filter(item => item.active),
          extra_columns: data.columns.filter(item => !item.disabled),
          variant_custom_columns: data.variant_custom_columns,
        },
        () => {
          this.props.onTotalChange('totalProducts', data?.total)
          if (store.get('paid') && store.get('online_store')) {
            if (store.get('custom_domain') == '') {
              this.setState({
                product_link: 'myswipe.store/' + store.get('online_store_url') + '/p/',
              })
            } else {
              this.setState({ product_link: store.get('custom_domain') + '/store/p/' })
            }
          }
          if (store.get('paid') && !store.get('online_store')) {
            if (store.get('custom_domain') == '') {
              this.setState({
                product_link: 'myswipe.store/' + store.get('online_store_url') + '/p2/',
              })
            } else {
              this.setState({
                product_link: store.get('custom_domain') + '/store/p2/',
              })
            }
          }
        },
      )
    }
    this.setState({ loading_products: false, experience_loading: true })
  }

  onImportDataChangePriceList = data => {
    const results = data.products.map((row, index) => ({
      ...row,
      key: index,
    }))
    this.setState({
      priceListConfirmFlag: true,
      importData: results,
      price_list_id: data.price_list_id,
    })
  }

  onImportDataChange = data => {
    const results = data.products.map((row, index) => ({
      ...row,
      key: index,
    }))
    this.setState({ confirmFlag: true, importData: results, custom_columns: data.custom_columns })
  }

  onPaginationChange = current => {
    this.callData(current - 1, this.state.num_records)
  }

  onAPICalled = (data, isAdd = false) => {
    this.callData(this.state.page, this.state.num_records)
    if (isAdd && data.has_batches != 0) {
      this.handleAddBatches(data)
    }
  }

  handleDelete = async id => {
    const data = await deleteAPI('product', 'delete/' + id)

    if (data) {
      this.callData(this.state.page, this.state.num_records)
    }
  }

  searchProducts = () => {
    this.setState({ page: 0 }, () => {
      this.callData(0, this.state.num_records)
    })
  }

  onCancelDeleteAllModal = () => {
    this.setState({ showDeleteAllModal: false })
  }

  handleDownload = async () => {
    const data = await downloadAPI('product', 'download', {})
    if (data) {
      this.setState({ flag: false })
    }
  }

  getStatusClass = (data, low_stock_qty = -1) => {
    if (low_stock_qty == -1) {
      if (data < 0) {
        return 'bg-negative'
      } else if (data == 0 && data < 5) {
        return 'bg-gray-50'
      } else {
        return 'bg-positive'
      }
    } else {
      if (data <= low_stock_qty) {
        return 'bg-negative'
      } else if (Math.abs(data - low_stock_qty) <= 5) {
        return 'bg-gray-50'
      } else {
        return 'bg-positive'
      }
    }
  }

  getCategoryColor = category => {
    if (category == '') {
      return 'rgb(255, 255, 255)'
    }
    let categoryhash = hash
      .sha256()
      .update(category)
      .digest('hex')
    // get 6 digit number by dividing the hash with max value of 6 digit hex number
    categoryhash = parseInt(categoryhash, 16)
    const color = categoryhash % 16777215

    let index = categoryhash % 3

    let r = parseInt(color.toString().slice(0, 2), 16)
    let g = parseInt(color.toString().slice(2, 4), 16)
    let b = parseInt(color.toString().slice(4, 6), 16)
    let rgb = [r, g, b]
    rgb[index] = Math.max(0, Math.round(rgb[index] * 0.5))

    return `rgb(${r}, ${g}, ${b})`
  }

  onChange = (pagination, filters, sorter, extra) => {
    let table_sorter = ''
    let sorter_type = undefined
    if ('order' in sorter) {
      if (sorter['order']) {
        table_sorter = sorter['order'] == 'ascend' ? 'asc' : 'desc'
        sorter_type = sorter.field
      }
      store.set('sort_orders', {
        ...store.get('sort_orders'),
        products: sorter['order'] ? sorter['order'] : '',
      })
    }
    if (filters.qty) {
      this.setState(
        {
          is_low_stock: filters.qty.includes('low_stock'),
          hide_zero_qty: filters.qty.includes('hide_zero_qty'),
          sort_type: 'qty',
          sorter: table_sorter,
          selected_user_ids: filters.user_name ? filters.user_name : [],
        },
        () => {
          store.set('sort_orders', { products: 'ascend' })
          this.callData(this.state.page, this.state.num_records)
        },
      )
    } else {
      this.setState(
        {
          is_low_stock: false,
          hide_zero_qty: false,
          selected_user_ids: filters.user_name ? filters.user_name : [],
          sort_type: sorter_type,
          sorter: table_sorter,
        },
        () => {
          this.callData(this.state.page, this.state.num_records)
        },
      )
    }
  }
  onDownloadItems = async () => {
    const hide = message.loading({
      content: 'Downloading...',
      duration: 0,
    })
    const data = await downloadAPI('product', 'download', {})
    if (data) {
      message.success('Downloaded Successfully')
      this.setState({ loading: false })
      hide()
    } else {
      message.error('Something went wrong')
      this.setState({ loading: false })
      hide()
    }
  }

  handleAddBOM = async item => {
    const data = await getAPI('product', 'check_bom', {
      product_id: item.product_id,
      variant_id: item.variant_id,
    })
    if (data?.success) {
      if (data.exists) {
        notification.open({
          message: 'Error',
          description: data.message,
          duration: 10,
          icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
          },
        })
      } else {
        this.bom.addBOM(item)
      }
    }
  }

  handleAddBatches = async item => {
    this.batches?.onShow(item)
  }

  handlePriceList = async data => {
    this.setState({ price_list_loading: true })
    const priceListData = await getAPI('product', 'get_price_lists_data', {
      product_id: data.product_id,
      variant_id: data.variant_id ? data.variant_id : 0,
    })
    if (priceListData.success) {
      this.setState({
        priceList: priceListData.product_details[0].price_lists,
        itemPrices: priceListData.product_details[0].item_prices,
      })
    }
    this.setState({ price_list_loading: false })
  }

  onChangeColumns = checkedValues => {
    const { _columns } = this.state
    const newColumns = _columns.filter(column => checkedValues.includes(column.dataIndex))
    this.setState(
      { columns: newColumns, extra_columns: newColumns.filter(item => !item.disabled) },
      () => {
        this.onColumnsSave()
      },
    )
  }

  onColumnsSave = async () => {
    const { columns } = this.state

    const req = {
      type: 'products',
      columns: columns,
    }
    const data = await getAPI('v2/reports', 'get_columns', req)
    // if (data?.success) {
    //   message.success('Columns updated successfully')
    // }
  }

  render() {
    const { priceList, itemPrices } = this.state
    let ActionItems = [
      this.props.permissions.product_edit == 1 && {
        key: '1',
        label: (
          <a
            size="small"
            className="font-weight-bold"
            onClick={() => {
              store.get('paid') == 0
                ? this.subscriptionPlanRef.current.openModal('bulk_edit')
                : history.push('/products/bulkedit')
            }}
          >
            <i className="fa fa-edit fa-lg mr-3  fa-fw" />
            <span className="">Bulk Edit {store.get('paid') == 0 && <ProBadge />}</span>
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a
            size="small"
            className="font-weight-bold"
            onClick={e => {
              store.get('paid') == 1
                ? this.setState({ loading: true }, () => {
                    this.onDownloadItems()
                  })
                : this.subscriptionPlanRef.current.openModal('download_items')
            }}
          >
            <i className="fa fa-circle-down fa-lg mr-3  fa-fw" />
            <span className="">
              <FormattedMessage id="button.downloadItems" />
              {store.get('paid') != 1 && <ProBadge />}
            </span>
          </a>
        ),
      },
      this.props.permissions.products_upload == 1 && {
        key: '3',
        label: (
          <a
            size="small"
            className="font-weight-bold"
            onClick={e =>
              store.get('paid') == 0
                ? this.subscriptionPlanRef.current.openModal('upload_items')
                : // : this.import.showModal()
                  history.push('/upload')
            }
          >
            <i className="fa-solid fa-arrow-up fa-lg mr-3  fa-fw" />
            <span className="">
              Upload Items
              {store.get('paid') != 1 && <ProBadge />}
            </span>
          </a>
        ),
      },
      // this.props.permissions.product_edit == 1 && {
      //   key: '4',
      //   label: (
      //     <a size="small" className="font-weight-bold" onClick={() => this.priceListform.onFill()}>
      //       <i className="fa-solid fa-dollar fa-lg mr-3  fa-fw" />
      //       <span className="">
      //         <FormattedMessage id="button.addPriceList" />
      //         {store.get('paid') != 1 && <BizBadge />}
      //       </span>
      //     </a>
      //   ),
      // },
      this.props.permissions.product_delete == 1 && {
        key: '5',
        label: (
          <a
            size="small"
            className="font-weight-bold text-danger"
            onClick={() => this.setState({ showDeleteAllModal: true })}
          >
            <i className="fa-solid fa-trash fa-lg mr-3  fa-fw" />
            <span className="text-danger">Delete All Items</span>
          </a>
        ),
      },
    ]
    if (this.props.permissions.products_upload == 0) {
      ActionItems.filter(item => item.key != 3)
    }
    if (this.props.permissions.product_edit == 0) {
      ActionItems.filter(item => item.key != 1)
    }
    const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    const menu = (
      <React.Fragment>
        <div className="card cui__utils__shadow mt-2 border-0">
          <div className="card-body p-0">
            <FilterHome status={true} />
          </div>
        </div>
      </React.Fragment>
    )

    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const dropdownMenu = currentData => (
      <Menu>
        {currentData.variants.length == 0 &&
          this.props.permissions.inventory_edit == 1 &&
          currentData.shopify_product_id.length == 0 && (
            <Menu.Item
              key={3}
              disabled={
                currentData.product_type === 'Service' &&
                this.props.invoice_settings.track_stock_for_services === 0
              }
              size="small"
              className="font-weight-bold"
              onClick={e => {
                e.domEvent.stopPropagation()
                if (currentData.has_batches === 2) {
                  this.handleAddBatches({
                    ...currentData,
                    variant_id: 0,
                    name: '',
                  })
                  return
                }
                this.inventoryForm.onFill({ ...currentData, variant_id: 0, name: '' }, true)
              }}
            >
              <Tooltip
                title={
                  currentData.product_type === 'Service' &&
                  this.props.invoice_settings.track_stock_for_services === 0 ? (
                    <DisabledInventoryTooltip />
                  ) : null // No tooltip when the item is not disabled
                }
              >
                <div>
                  <i className="fa-regular fa-circle-arrow-down fa-lg text-forest mr-3 " />
                  <span className="hidden sm:inline font-weight-bold">
                    <FormattedMessage id="button.stockIn" />
                  </span>
                </div>
              </Tooltip>
            </Menu.Item>
          )}

        {currentData.variants.length == 0 &&
          this.props.permissions.inventory_edit == 1 &&
          currentData.shopify_product_id.length == 0 && (
            <Menu.Item
              key={4}
              disabled={
                currentData.product_type === 'Service' &&
                this.props.invoice_settings.track_stock_for_services === 0
              }
              size="small"
              className="font-weight-bold"
              onClick={e => {
                e.domEvent.stopPropagation()
                if (currentData.has_batches == 2) {
                  this.handleAddBatches({
                    ...currentData,
                    variant_id: 0,
                    name: '',
                  })
                  return
                }
                this.inventoryForm.onFill({ ...currentData, variant_id: 0, name: '' }, false)
              }}
            >
              <Tooltip
                title={
                  currentData.product_type === 'Service' &&
                  this.props.invoice_settings.track_stock_for_services === 0 ? (
                    <DisabledInventoryTooltip />
                  ) : null // No tooltip when the item is not disabled
                }
              >
                <div>
                  <i className="fa-regular fa-circle-arrow-up fa-lg text-danger mr-3 " />
                  <span className="hidden sm:inline font-weight-bold">
                    <FormattedMessage id="button.stockOut" />
                  </span>
                </div>
              </Tooltip>
            </Menu.Item>
          )}

        {this.props.permissions.bom == 1 && currentData.variants.length == 0 && (
          <Menu.Item
            key={1}
            onClick={e => {
              e.domEvent.stopPropagation()
              this.handleAddBOM({
                ...currentData,
                variant_id: 0,
                name: '',
              })
            }}
          >
            <i className="fa-regular fa-object-group fa-fw mr-3"></i>
            <span className="hidden sm:inline font-weight-bold">
              {currentData.has_bom ? 'Edit Group' : <FormattedMessage id="button.bom" />}
            </span>
          </Menu.Item>
        )}

        {(this.props.integrations.batching == 1 || this.props.integrations.serial_number == 1) &&
          currentData.has_batches != 0 &&
          currentData.variants.length == 0 && (
            <Menu.Item
              key={2}
              onClick={e => {
                e.domEvent.stopPropagation()
                this.handleAddBatches({
                  ...currentData,
                  variant_id: 0,
                  name: '',
                })
              }}
            >
              <i className="fa-duotone fa-boxes-stacked mr-3"></i>
              <span className="hidden sm:inline font-weight-bold">
                <FormattedMessage id="button.batches" />
              </span>
            </Menu.Item>
          )}

        {/* <Menu.Item
          key={5}
          onClick={e => {
            e.domEvent.stopPropagation()
            this.setState({ show_share: true, selectedProduct: currentData })
          }}
        >
          <i className="fa-solid fa-share fa-fw mr-3 " />
          <span className="hidden sm:inline font-weight-bold">
            <FormattedMessage id="button.share" />
          </span>
        </Menu.Item> */}
        <Menu.Item
          key={10}
          onClick={e => {
            e.domEvent.stopPropagation()
            this.form.onFill(currentData, true)
          }}
        >
          <i className="fa-solid fa-clone fa-fw mr-3 " />
          <span className="hidden sm:inline font-weight-bold">Clone</span>
        </Menu.Item>
        {this.props.permissions.product_delete == 1 && currentData.shopify_product_id.length == 0 && (
          <Menu.Item
            key={6}
            onClick={e => {
              e.domEvent.stopPropagation()

              this.delete.showModal(currentData)
            }}
          >
            <a className="text-danger">
              <i className="fa-solid fa-trash fa-fw mr-3" />
              <span className="font-weight-bold">Delete </span>
            </a>
          </Menu.Item>
        )}
      </Menu>
    )

    const priceListCol = [
      { title: <FormattedMessage id="table.name" />, dataIndex: 'name', key: 'name' },

      {
        title: <FormattedMessage id="table.price" />,
        dataIndex: 'unit_price',
        key: 'price',
      },
      {
        title: <FormattedMessage id="table.priceWithTax" />,
        dataIndex: 'price_with_tax',
        key: 'price_with_tax',
      },
      {
        title: <FormattedMessage id="table.tax" />,
        dataIndex: 'tax',
        key: 'tax',
      },
      {
        title: <FormattedMessage id="table.discount" />,
        dataIndex: 'discount',
        key: 'discount',
      },
    ]

    let columns = this.state.columns?.map(item => {
      if (item.dataIndex == 'product_name') {
        return {
          ...item,
          key: 'product_id',
          defaultSortOrder: store.get('sort_orders')?.products,
          width: window.innerWidth * 0.2,
          render: (text, data) => (
            <>
              {/* {data.product_images?.length > 0 && (
                <div
                  onClick={event => {
                    event.stopPropagation()
                    this.productImagesRef.onFillImages(data.product_images)
                  }}
                >
                  <Avatar.Group
                    maxCount={1}
                    maxStyle={{ color: '#fc3654', backgroundColor: '#fde3cf' }}
                    className="float-right align-middle ml-2"
                  >
                    {data.product_images?.map((item, index) => (
                      <Avatar shape="circle" size="default" src={item.url} />
                    ))}
                  </Avatar.Group>
                </div>
              )} */}

              <span>
                <a>
                  <span className="flex flex-wrap items-center justify-start">
                    {data.product_images?.length == 0 ? (
                      <span>
                        <UserAvatar name={text} />
                      </span>
                    ) : (
                      <span
                        onClick={event => {
                          event.stopPropagation()
                          this.productImagesRef.onFillImages(data.product_images)
                        }}
                      >
                        <UserAvatar
                          shape="round"
                          className="mr-2"
                          size=""
                          src={data.product_images[0]?.url}
                          name={text}
                        />
                      </span>
                    )}
                    <span>
                      <span
                        className="text-gray-900 font-weight-medium hover-underline"
                        onClick={e => {
                          e.stopPropagation()
                          if (data.variants && data.variants.length == 0) {
                            this.productAnalysis.showModal(data, data.product_id, data)
                          }
                        }}
                      >
                        {text}
                      </span>
                      <span className="font-size-12">
                        <p className="mt-0 mb-0">
                          <span className="text-gray-500 mr-1">{data.product_type}</span>
                          <span
                            className="mr-1"
                            style={{
                              color: `${this.getCategoryColor(data.product_category)}`,
                            }}
                          >
                            {data.product_category}
                          </span>
                          {data.hsn_code != '' && (
                            <span className="text-purple">{data.hsn_code}</span>
                          )}
                          {data.barcode_id != '' && data.variants.length == 0 && (
                            <a
                              onClick={e => {
                                e.stopPropagation()
                                this.barcode.showModal(data)
                              }}
                              className="ml-2"
                            >
                              <i className="fa fa-barcode fa-lg" />
                            </a>
                          )}

                          {this.state.search != '' && !this.state.loading && (
                            <span className="font-size-10 mr-1">
                              <a className="mr-1 pl-1  font-weight-medium text-gray-500">
                                {data.reason != '' && (
                                  <>
                                    {data.reason != '' ? (
                                      <>
                                        {data.reason.split(':')[0] + ':'}
                                        <b>{data.reason.split(':')[1]}</b>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}
                              </a>
                            </span>
                          )}
                        </p>
                      </span>
                    </span>
                  </span>
                </a>

                <span className="absolute top-1 right-0">
                  {data.shopify_product_id.length == 1 && (
                    <span>
                      <Tooltip placement="top" title={'This Product is handled from shopify'}>
                        <span className="text-gray-400 ml-4">
                          <i className="fa-brands fa-shopify"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                  {data.not_for_sale == 1 && (
                    <span>
                      <Tooltip placement="top" title={'This Product is not for sale'}>
                        <span className="text-gray-400 ml-4">
                          <i className="fa-duotone fa-square-fragile text-danger"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                  {store.get('online_store') == 1 && (
                    <span>
                      {data.show_online == 0 && (
                        <Tooltip placement="top" title={'Offline'}>
                          <span className="ml-2">
                            <i className="fa-duotone fa-solid fa-globe text-danger"></i>
                          </span>
                        </Tooltip>
                      )}
                    </span>
                  )}
                  {data.has_bom == 1 && data.variants.length == 0 && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.handleAddBOM({
                          ...data,
                          variant_id: 0,
                          name: '',
                        })
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={'This Product has group. Click to view the group'}
                      >
                        <span>
                          <i className="fa-regular fa-cube  mx-2 text-primary"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}

                  {data.has_batches != 0 && data.variants.length == 0 && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.handleAddBatches({
                          ...data,
                          variant_id: 0,
                          name: '',
                        })
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={'This Product has batches. Click to view the batches'}
                      >
                        <span>
                          <i className="fa-light fa-cubes  mx-2"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </span>
              </span>
            </>
          ),
        }
      }

      if (item.dataIndex == 'qty') {
        return {
          ...item,
          width: window.innerWidth * 0.1,
          filters: [
            { text: 'Low Stock', value: 'low_stock' },
            { text: 'Hide 0 Qty', value: 'hide_zero_qty' },
          ],
          filterIcon: filtered => (
            <i className="fa fa-lg fa-filter" style={{ color: filtered ? 'purple' : '' }} />
          ),
          render: (text, data) => {
            const content = data.units.map(unit => {
              const showReverse = this.state.show_reverse_map[unit.alternative_unit] || false
              return (
                unit.alternative_unit != data.unit && (
                  <div onClick={e => e.stopPropagation()}>
                    <Form>
                      <Row className="mb-2" gutter={0}>
                        {showReverse ? (
                          <Col span={16}>
                            <Form.Item
                              label=""
                              name="primary_unit"
                              colon={false}
                              labelAlign="left"
                              labelCol={{ flex: '0 0 auto' }}
                              className="w-full"
                            >
                              <InputNumber
                                placeholder="Conversion Rate"
                                addonBefore={`1 ${data.unit} = `}
                                addonAfter={`${unit.alternative_unit}`}
                                defaultValue={unit.conversion_rate}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        ) : (
                          <Col span={16}>
                            <Form.Item
                              label=""
                              name="alternate_unit"
                              colon={false}
                              labelAlign="left"
                            >
                              <InputNumber
                                placeholder="Conversion Rate"
                                addonBefore={`1 ${unit.alternative_unit} = `}
                                addonAfter={`${data.unit}`}
                                defaultValue={(1 / unit.conversion_rate).toFixed(2)}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={4}>
                          <Form.Item label="">
                            <Tooltip
                              title={
                                'Click to toggle between 1 primary unit = x unit to 1 alternative unit = x primary unit'
                              }
                              placement="bottom"
                            >
                              <span
                                className="bg-white text-gray-600 border p-2 rounded bg-white text-gray-600 cursor-pointer"
                                onClick={e => {
                                  e.stopPropagation()
                                  this.toggleReverse(unit)
                                }}
                              >
                                <i className="fa-solid fa-arrow-right-arrow-left font-size-18"></i>
                              </span>
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )
              )
            })
            return {
              props: {
                className: this.getStatusClass(text, data.low_stock),
              },
              children:
                data.variants.length == 0 &&
                (data.product_type == 'Service' &&
                this.props.invoice_settings.track_stock_for_services == 0 ? (
                  <Tooltip title={<DisabledInventoryTooltip />}>
                    <span className="font-weight-bolder"> - </span>
                  </Tooltip>
                ) : data.has_alternative_units ? (
                  <Popover title="Alternative Units" content={content} trigger={['hover']}>
                    <span className="font-weight-bolder" onClick={e => e.stopPropagation()}>
                      {text}
                      {data.unit != 'OTH' && (
                        <span className="font-size-12 font-weight-bold text-purple ml-2">
                          {data.unit}
                        </span>
                      )}
                    </span>
                  </Popover>
                ) : (
                  <span className="font-weight-bolder" onClick={e => e.stopPropagation()}>
                    {text}
                    {data.unit != 'OTH' && (
                      <span className="font-size-12 font-weight-bold text-purple ml-2">
                        {data.unit}
                      </span>
                    )}
                  </span>
                )),
            }
          },
        }
      }

      if (item.dataIndex == 'price') {
        return {
          ...item,
          key: 'product_id',
          width: window.innerWidth * 0.12,
          render: (text, data) => {
            var content = (
              <div>
                <Table
                  columns={priceListCol}
                  dataSource={itemPrices}
                  pagination={false}
                  loading={this.state.price_list_loading}
                />
              </div>
            )
            if (store.get('price_list') == 0) {
              content = (
                <div className="flex flex-col items-center">
                  <p>Price lists will be shown here</p>
                  <ProBadge />
                </div>
              )
            }
            return (
              data.has_batches != 1 && (
                <Popover
                  content={content}
                  title="Price Lists"
                  trigger="click"
                  onClick={e => e.stopPropagation()}
                  onOpenChange={e => {
                    if (store.get('price_list') == 1) {
                      this.handlePriceList(data)
                    }
                  }}
                >
                  <p className="mb-0">
                    <span className="font-weight-bold ls-2">
                      <span className="mr-1">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {parseFloat(text).toLocaleString('en-IN', dotOptions)}
                    </span>
                    {data.discount > 0 && (
                      <Popover
                        title={
                          <>
                            <div>Discount Amount: {data.discount_amount}</div>
                            <div>
                              {' '}
                              Unit Price after Discount:{' '}
                              {this.props?.user?.selectedCompany?.currency_symbol}
                              {data.unit_price_after_discount} /-
                            </div>
                            <div>
                              {' '}
                              Price with tax after Discount:{' '}
                              {this.props?.user?.selectedCompany?.currency_symbol}
                              {data.price_after_discount} /-
                            </div>
                          </>
                        }
                        placement="top"
                      >
                        <span className="text-green ml-1">({data.discount.toFixed(2)}%)</span>
                      </Popover>
                    )}
                  </p>
                  {data.is_price_with_tax == 1 ? (
                    <p className="font-size-10 mb-0 mt-0">
                      {data.tax != 0 && (
                        <>
                          <span className="font-weight-bold">{data.tax}% </span>
                          <FormattedMessage id="text.withTax" />
                        </>
                      )}
                    </p>
                  ) : (
                    <p className="font-size-10 mb-0 mt-0">
                      {data.tax != 0 && (
                        <>
                          <span className="font-weight-bold">{data.tax}% </span>
                          <FormattedMessage id="text.withoutTax" />
                        </>
                      )}
                    </p>
                  )}
                </Popover>
              )
            )
          },
        }
      }

      if (item.dataIndex == 'unit_price_after_discount') {
        return {
          ...item,
          width: window.innerWidth * 0.12,
          key: 'unit_price_after_discount',
          render: (text, data) =>
            data.variants.length == 0 &&
            data.has_batches != 1 && (
              <>
                <span className="ls-2">
                  <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>

                  {text.toLocaleString('en-IN', dotOptions)}
                </span>
              </>
            ),
        }
      }

      if (item.dataIndex == 'price_after_discount') {
        return {
          ...item,
          width: window.innerWidth * 0.12,
          key: 'unit_price_after_discount',
          render: (text, data) =>
            data.variants.length == 0 &&
            data.has_batches != 1 && (
              <>
                <span className="ls-2">
                  <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>

                  {text.toLocaleString('en-IN', dotOptions)}
                </span>
              </>
            ),
        }
      }

      if (item.dataIndex == 'purchase_price') {
        return {
          ...item,
          width: window.innerWidth * 0.12,
          key: 'purchase_price',
          hidden: this.props.permissions.show_purchase_price == 0,
          render: (text, data) =>
            data.variants.length == 0 &&
            data.has_batches != 1 && (
              <>
                <span className="ls-2">
                  <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>

                  {(data.is_purchase_price_with_tax == 1
                    ? data.purchase_price
                    : data.purchase_unit_price
                  ).toLocaleString('en-IN', dotOptions)}
                </span>
                {data.is_purchase_price_with_tax == 1 ? (
                  <p className="font-size-10 mb-0 mt-0">
                    {data.tax != 0 && (
                      <>
                        <span className="font-weight-bold">{data.tax}% </span>
                        <FormattedMessage id="text.withTax" />
                      </>
                    )}
                  </p>
                ) : (
                  <p className="font-size-10 mb-0 mt-0">
                    {data.tax != 0 && (
                      <>
                        <span className="font-weight-bold">{data.tax}% </span>
                        <FormattedMessage id="text.withoutTax" />
                      </>
                    )}
                  </p>
                )}
              </>
            ),
        }
      }

      if (item.dataIndex == 'user_name') {
        return {
          ...item,
          width: window.innerWidth * 0.12,
          filters: this.props.all_users.map(user => {
            return { text: user.name, value: user.user_id }
          }),
          filterIcon: filtered => (
            <i className="fa fa-lg fa-filter" style={{ color: filtered ? 'purple' : '' }} />
          ),
        }
      }

      return {
        ...item,
        width: window.innerWidth * 0.1,
      }
    })
    columns = [
      ...columns,
      {
        title: this.state.confirmFlag ? (
          <>
            <span className="float-right mr-2">
              <FormattedMessage id="table.status" />
            </span>
          </>
        ) : (
          <>
            {/* <span className="float-right mr-2">
              <FormattedMessage id="table.action" />
            </span> */}
          </>
        ),
        dataIndex: this.state.confirmFlag ? 'status' : '',
        align: 'right',
        fixed: 'right',
        width: window.innerWidth * 0.04,
        render: (text, currentData) =>
          !this.state.confirmFlag ? (
            <>
              {currentData.isHovered && (
                <div className="hover-onshow-card">
                  <Card
                    size="hover"
                    bordered={false}
                    className="border-radius-small bg-white card-shadow-4"
                    style={{ width: 'auto' }}
                  >
                    <span className="flex justify-start items-center">
                      {currentData.barcode_id != '' && currentData.variants.length == 0 && (
                        <Tooltip title="View Barcode">
                          <Button
                            type="view-button"
                            size="small"
                            onClick={event => {
                              event.stopPropagation()
                              this.barcode.showModal(currentData)
                            }}
                            className="mr-2 font-weight-bold"
                          >
                            <i className="fa fa-barcode" />
                          </Button>
                        </Tooltip>
                      )}
                      {this.state.edit && currentData.shopify_product_id.length == 0 && (
                        <Tooltip title="Edit Item">
                          <Button
                            type="warning"
                            size="small"
                            disabled={currentData.is_shopify}
                            onClick={event => {
                              event.stopPropagation()
                              this.form.onFill(currentData)
                            }}
                            className="mr-2"
                          >
                            <i className="fa-solid fa-edit mr-1" />
                            <span className="hidden sm:inline font-weight-bold">
                              <FormattedMessage id="button.edit" />
                            </span>
                          </Button>
                        </Tooltip>
                      )}

                      {store.get('paid') == 1 && (
                        <Tooltip title="Share">
                          <Button
                            type="send-button"
                            size="small"
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({ show_share: true, selectedProduct: currentData })
                            }}
                            className=""
                          >
                            <i className="fa-solid fa-share fa-fw mr-1 " />
                            <span className="hidden sm:inline font-weight-bold">
                              <FormattedMessage id="button.share" />
                            </span>
                          </Button>
                        </Tooltip>
                      )}

                      {currentData.shopify_product_id.length > 0 && (
                        <Popover title="Shopify Products Cannot be edited" placement="top">
                          <Button
                            type="ghost"
                            size="small"
                            disabled={true}
                            style={{ cursor: 'not-allowed' }}
                            className="mr-2 bg-gray-300 text-black border-1"
                          >
                            <i className="fa-solid fa-edit ml-2" />
                            <span className="hidden sm:inline font-weight-bold">
                              <FormattedMessage id="button.edit" />
                            </span>
                          </Button>
                        </Popover>
                      )}
                    </span>
                  </Card>
                </div>
              )}

              <Tooltip title="More">
                <a className="dropdown" onClick={event => event.stopPropagation()}>
                  <Dropdown
                    overlay={dropdownMenu(currentData)}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button
                      type="info"
                      size="small"
                      className="dropdown-toggle dropdown-toggle-noarrow ml-4"
                    >
                      <i className="fa-solid fa-ellipsis fa-xl"></i>
                    </Button>
                  </Dropdown>
                </a>
              </Tooltip>
            </>
          ) : (
            <span></span>
          ),
        // className: 'text-left',
      },
    ].filter(col =>
      (col.dataIndex == 'product_id' && this.state.confirmFlag) ||
      (col.dataIndex == 'barcode_id' && !this.state.confirmFlag) ||
      col.hidden
        ? false
        : true,
    )

    // inset record in columns index1
    columns.splice(1, 0, Table.EXPAND_COLUMN)

    if (this.state.custom_columns.length > 0 && this.state.confirmFlag) {
      this.state.custom_columns.map(item => {
        columns.push({
          title: item.name,
          dataIndex: item.name,
          key: item.name,
          render: (text, data) => <span>{data[item.name]}</span>,
        })
      })
    }
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].dataIndex == 'qty' && this.state.is_low_stock) {
        columns[i].defaultFilteredValue = ['low_stock']
        // sort order
        columns[i].sortOrder = 'ascend'
      }
    }
    const priceListColumns = [
      {
        title: <FormattedMessage id="table.product" />,
        dataIndex: 'product_name',
        key: 'product_name',
      },
      {
        title: <FormattedMessage id="table.price" />,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: <FormattedMessage id="table.priceWithTax" />,
        dataIndex: 'price_with_tax',
        key: 'price_with_tax',
      },
      {
        title: <FormattedMessage id="table.discount" />,
        dataIndex: 'discount',
        key: 'discount',
        render: text => (
          <a className="" href="#" onClick={e => e.preventDefault()}>
            {text}
          </a>
        ),
        responsive: ['md'],
      },
      {
        title: <FormattedMessage id="table.tax" />,
        dataIndex: 'tax',
        key: 'product_id',
        render: text => (
          <a className="" href="#" onClick={e => e.preventDefault()}>
            {text}
          </a>
        ),
        responsive: ['md'],
      },
    ]

    const expandedRowRender = record => {
      if (record.has_batches != 0) {
        return expandedBatchesRender(record)
      }

      const handleAddVariants = e => {
        e.stopPropagation()
        if (this.props.permissions.product_edit == 1) {
          store.get('paid') == 0
            ? this.subscriptionPlanRef.current.openModal('variants')
            : this.variantForm.onEditClick({}, record)
        } else {
          message.error("You don't have an edit access. Please contact admin.")
        }
      }

      const dropdownMenuVariants = currentData => {
        return (
          <Menu>
            {this.props.permissions.inventory_edit == 1 &&
              currentData.shopify_variant_id.length == 0 && (
                <Menu.Item
                  key={3}
                  disabled={
                    record.product_type === 'Service' &&
                    this.props.invoice_settings.track_stock_for_services === 0
                  }
                  size="small"
                  className="font-weight-bold mr-2 my-1"
                  onClick={e => {
                    e.domEvent.stopPropagation()
                    if (currentData.has_batches === 2) {
                      this.handleAddBatches({
                        ...currentData,
                        product_name: record.product_name + '_' + currentData.name,
                        product_id: record.product_id,
                        variant_id: currentData.id,
                      })
                      return
                    }
                    this.inventoryForm.onFill(
                      { ...currentData, variant_id: currentData.id, units: record.units },
                      true,
                    )
                  }}
                >
                  <Tooltip
                    title={
                      record.product_type === 'Service' &&
                      this.props.invoice_settings.track_stock_for_services === 0 ? (
                        <DisabledInventoryTooltip />
                      ) : null // No tooltip when the item is not disabled
                    }
                    placement="right" // Adjust the tooltip placement as needed
                  >
                    <div>
                      <i className="fa-regular fa-circle-arrow-down fa-lg text-forest mr-3 " />
                      <span className="hidden sm:inline font-weight-bold">
                        <FormattedMessage id="button.stockIn" />
                      </span>
                    </div>
                  </Tooltip>
                </Menu.Item>
              )}

            {this.props.permissions.inventory_edit == 1 &&
              currentData.shopify_variant_id.length == 0 && (
                <Menu.Item
                  key={4}
                  disabled={
                    record.product_type === 'Service' &&
                    this.props.invoice_settings.track_stock_for_services === 0
                  }
                  size="small"
                  className="font-weight-bold my-1"
                  onClick={e => {
                    e.domEvent.stopPropagation()
                    if (currentData.has_batches == 2) {
                      this.handleAddBatches({
                        ...currentData,
                        product_name: record.product_name + '_' + currentData.name,
                        product_id: record.product_id,
                        variant_id: currentData.id,
                      })
                      return
                    }
                    this.inventoryForm.onFill(
                      { ...currentData, variant_id: currentData.id, units: record.units },
                      false,
                    )
                  }}
                >
                  <Tooltip
                    title={
                      record.product_type === 'Service' &&
                      this.props.invoice_settings.track_stock_for_services === 0 ? (
                        <DisabledInventoryTooltip />
                      ) : null // No tooltip when the item is not disabled
                    }
                    placement="right" // Adjust the tooltip placement as needed
                  >
                    <div>
                      <i className="fa-regular fa-circle-arrow-up fa-lg text-danger mr-3 " />
                      <span className="hidden sm:inline font-weight-bold">
                        <FormattedMessage id="button.stockOut" />
                      </span>
                    </div>
                  </Tooltip>
                </Menu.Item>
              )}
            {this.props.permissions.bom == 1 && (
              <Menu.Item
                key={1}
                onClick={e => {
                  e.domEvent.stopPropagation()
                  this.handleAddBOM({
                    ...currentData,
                    product_name: record.product_name,
                    product_id: record.product_id,
                    variant_id: currentData.id,
                  })
                }}
              >
                <i className="fa-regular fa-object-group fa-fw mr-3"></i>
                <span className="hidden sm:inline font-weight-bold">
                  {currentData.has_bom ? 'Edit Group' : <FormattedMessage id="button.bom" />}
                </span>
              </Menu.Item>
            )}
            {(this.props.integrations.batching == 1 ||
              this.props.integrations.serial_number == 1) &&
              currentData.has_batches != 0 && (
                <Menu.Item
                  key={2}
                  onClick={e => {
                    e.domEvent.stopPropagation()
                    this.handleAddBatches({
                      ...currentData,
                      product_name: record.product_name + '_' + currentData.name,
                      product_id: record.product_id,
                      variant_id: currentData.id,
                    })
                  }}
                >
                  <i className="fa-duotone fa-boxes-stacked mr-3"></i>
                  <span className="hidden sm:inline font-weight-bold">
                    <FormattedMessage id="button.batches" />
                  </span>
                </Menu.Item>
              )}
            <Menu.Item
              key={5}
              onClick={e => {
                e.domEvent.stopPropagation()
                this.setState({ show_share: true, selectedProduct: currentData })
              }}
            >
              <i className="fa-solid fa-share fa-fw mr-3 " />
              <span className="hidden sm:inline font-weight-bold">
                <FormattedMessage id="button.share" />
              </span>
            </Menu.Item>

            {this.props.permissions.product_delete == 1 &&
              currentData.shopify_variant_id.length == 0 && (
                <Menu.Item
                  key={6}
                  onClick={e => {
                    e.domEvent.stopPropagation()

                    this.variantForm.handleDelete(
                      currentData.id,
                      currentData.company_id,
                      currentData.product_id,
                    )
                  }}
                >
                  <a className="text-danger">
                    <i className="fa-solid fa-trash fa-fw mr-3" />
                    <span className="font-weight-bold">Delete </span>
                  </a>
                </Menu.Item>
              )}
          </Menu>
        )
      }
      const columns1 = [
        {
          title: <FormattedMessage id="table.name" />,
          dataIndex: 'name',
          width: '31%',
          key: 'name',
          render: (text, item) => {
            return (
              <div className="flex justify-between">
                <span className="text-gray-900 font-weight-medium">
                  <span
                    className="hover-underline"
                    onClick={event => {
                      event.stopPropagation()
                      this.productAnalysis.showModal(record, record.product_id, item)
                    }}
                  >
                    {text}
                  </span>
                  {item.shopify_variant_id.length > 0 && (
                    <span>
                      <Tooltip placement="top" title={'This Product is handled from shopify'}>
                        <span className="text-gray-400 ml-2">
                          <i className="fa-brands fa-shopify"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}

                  {item.not_for_sale == 1 && (
                    <span>
                      <Tooltip placement="top" title={'This Product is not for sale'}>
                        <span className="text-gray-400 ml-2">
                          <i className="fa-solid fa-eye-slash"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}

                  {item.has_bom == 1 && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.handleAddBOM({
                          ...item,
                          variant_id: item.id,
                          name: '',
                        })
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={'This Product has group. Click to view the group'}
                      >
                        <span>
                          <i className="fa-regular fa-cube  mx-2 text-primary"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}

                  {item.has_batches != 0 && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.handleAddBatches({
                          ...item,
                          variant_id: item.id,
                          name: '',
                        })
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={'This Product has batches. Click to view the batches'}
                      >
                        <span>
                          <i className="fa-light fa-cubes  mx-2"></i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </span>
                <div className="flex items-center">
                  {item.variant_images?.length > 0 && (
                    <Avatar.Group
                      maxCount={2}
                      maxStyle={{ color: '#fc3654', backgroundColor: '#fde3cf' }}
                      className="float-right align-middle mr-2"
                    >
                      {item.variant_images.map((item, index) => (
                        <Avatar shape="circle" size="default" src={item.url} />
                      ))}
                    </Avatar.Group>
                  )}

                  {item.has_batches == 1 && (
                    <Button
                      type="action-3"
                      size="small"
                      onClick={e => {
                        e.stopPropagation()
                        this.handleAddBatches({
                          ...item,
                          variant_id: item.id,
                        })
                      }}
                      className="mr-2"
                    >
                      {item.batches.length == 0 ? (
                        <i className="fa fa-plus mr-1" />
                      ) : (
                        <span className="font-weight-bold text-danger mr-1">{`(${item.batches.length})`}</span>
                      )}
                      <span className="hidden sm:inline font-weight-bold">
                        <FormattedMessage id="button.batches" />{' '}
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            )
          },
        },
        {
          title: <FormattedMessage id="table.quantity" />,
          dataIndex: 'qty',
          key: 'qty',
          render: (text, item) => {
            return {
              props: {
                className: this.getStatusClass(item.qty, item.low_stock),
              },
              children: !(
                record.product_type === 'Service' &&
                this.props.invoice_settings.track_stock_for_services === 0
              ) ? (
                <span className="font-weight-bold">
                  {item.qty}
                  {record.unit !== 'OTH' && (
                    <span className="font-size-12 font-weight-bold text-purple ml-2">
                      {record.unit}
                    </span>
                  )}
                </span>
              ) : (
                <Tooltip title={<DisabledInventoryTooltip />}>
                  <span className="font-weight-bolder"> - </span>
                </Tooltip>
              ),
            }
          },
        },
        {
          title: 'Selling Price (Disc %)',
          dataIndex: 'price_with_tax',
          key: 'price_with_tax',
          render: (text, item) => {
            const content = (
              <div>
                <Table
                  columns={priceListCol}
                  dataSource={itemPrices}
                  pagination={false}
                  loading={this.state.price_list_loading}
                />
              </div>
            )

            return (
              <Popover
                content={content}
                title="Price Lists"
                trigger="click"
                onClick={e => e.stopPropagation()}
                onOpenChange={e => {
                  this.handlePriceList(item)
                }}
              >
                <span>
                  <span className="font-weight-bold">
                    <span className="mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {parseFloat(item.price_with_tax).toFixed(2)}
                  </span>
                  {item.discount != 0 && (
                    <span className="text-green"> ({parseFloat(item.discount).toFixed(2)}%)</span>
                  )}
                </span>
              </Popover>
            )
          },
        },
        {
          title: 'Purchase Price',
          dataIndex: 'purchase_price',
          key: 'purchase_price',
          render: (text, item) => {
            return (
              <span>
                {this.props.user.selectedCompany.currency_symbol}{' '}
                {parseFloat(item.purchase_price).toFixed(2)}
              </span>
            )
          },
        },
      ]

      if (this.state.variant_custom_columns.length > 0) {
        this.state.variant_custom_columns.map(col_item => {
          columns1.push({
            title: col_item.name,
            dataIndex: col_item.name,
            key: col_item.name,
            render: (text, data) => <span>{data[col_item.name]}</span>,
          })
        })
      }

      columns1.push({
        title: 'Actions',
        align: 'right',
        key: 'id',
        render: (text, item) => {
          return (
            <div onClick={e => e.stopPropagation()}>
              {this.state.edit && (
                <span className="float-right">
                  {item.barcode_id != '' && (
                    <Button
                      type="action-3"
                      size="small"
                      onClick={event => {
                        this.barcode.showModal({
                          ...item,
                          product_name: record.product_name,
                        })
                      }}
                      className="mr-2 font-weight-bold"
                      style={{ width: '34.28px' }}
                    >
                      <i className="fa fa-barcode mx-1" />
                    </Button>
                  )}
                  {item.shopify_variant_id.length == 0 && (
                    <Button
                      type="warning"
                      size="small"
                      onClick={e => {
                        e.stopPropagation() || this.variantForm.onEditClick(item, record)
                      }}
                      className="mr-2"
                    >
                      <i className="fa-solid fa-edit" />
                      <span className="hidden sm:inline font-weight-bold ml-1">
                        <FormattedMessage id="button.edit" />
                      </span>
                    </Button>
                  )}
                  {item.shopify_variant_id.length > 0 && (
                    <Popover title="Shopify Variants Cannot be edited" placement="top">
                      <Button
                        type="ghost"
                        size="small"
                        disabled={true}
                        style={{ cursor: 'not-allowed' }}
                        className="mr-2 bg-gray-300 text-black border-1"
                      >
                        <i className="fa-solid fa-edit mr-1" />
                        <span className="hidden sm:inline font-weight-bold">
                          <FormattedMessage id="button.edit" />
                        </span>
                      </Button>
                    </Popover>
                  )}

                  <Dropdown
                    overlay={dropdownMenuVariants(item)}
                    placement="bottomRight"
                    trigger={['click']}
                    onClick={e => e.stopPropagation()}
                  >
                    <Button size="small" type="info">
                      <i className="fa-solid fa-chevron-down font-weight-bolder" />
                    </Button>
                  </Dropdown>
                </span>
              )}
            </div>
          )
        },
      })

      return (
        <div className={`rounded`}>
          <div
            className={record.variants.length > 0 ? 'hidden' : 'w-full mb-2 flex justify-center'}
          >
            <Button
              type="primary"
              size="medium"
              className="mr-2 float-right"
              onClick={e => handleAddVariants(e)}
            >
              <i className="fa fa-plus mr-1" />
              <span className="hidden sm:inline font-weight-bold">Add Variant</span>
            </Button>
          </div>
          {record.variants.length > 0 && (
            <Table
              columns={columns1}
              dataSource={record.variants}
              pagination={false}
              onRow={(variantRecord, rowIndex) => {
                return {
                  onClick: event => {
                    event.stopPropagation()
                    this.productAnalysis.showModal(record, record.product_id, variantRecord)
                  },
                }
              }}
              footer={() => (
                <div className="flex p-2">
                  <Button
                    type="action-o"
                    size="medium"
                    className="mr-2 "
                    onClick={e => handleAddVariants(e)}
                  >
                    <i className="fa fa-plus-circle mr-2" />
                    <span className="hidden sm:inline font-weight-bold">Add Variant</span>
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      )
    }

    const expandedBatchesRender = record => {
      const batch_columns = [
        {
          title: <FormattedMessage id="table.batch" />,
          dataIndex: 'batch_no',
          width: '30%',
          key: 'batch_no',
          render: (text, item) => {
            return <span className="text-gray-900 font-weight-medium">{text}</span>
          },
        },
        {
          title: <FormattedMessage id="table.quantity" />,
          dataIndex: 'qty',
          key: 'qty',
          render: (text, item) => {
            return {
              props: {
                className: this.getStatusClass(item.qty),
              },
              children: (
                <span className="font-weight-bold">
                  {item.qty}
                  {record.unit !== 'OTH' && (
                    <span className="font-size-12 font-weight-bold text-purple ml-2">
                      {record.unit}
                    </span>
                  )}
                </span>
              ),
            }
          },
          hidden: record.has_batches == 2,
        },
        {
          title: 'Manufacturing Date',
          dataIndex: 'mfg_date',
          key: 'mfg_date',
          hidden: record.has_batches == 2,
        },
        {
          title: 'Expiry Date',
          dataIndex: 'expiry_date',
          key: 'expiry_date',
          hidden: record.has_batches == 2,
        },
        {
          title: 'Selling Price (Disc %)',
          dataIndex: 'price_with_tax',
          key: 'price_with_tax',
          render: (text, item) => {
            return (
              <span>
                <span className="font-weight-bold">
                  {this.props.user.selectedCompany.currency_symbol}
                  {parseFloat(item.price_with_tax).toFixed(2)}
                </span>
              </span>
            )
          },
          hidden: record.has_batches == 2,
        },
        {
          title: 'Purchase Price',
          dataIndex: 'purchase_price',
          key: 'purchase_price',
          render: (text, item) => {
            return (
              <span>
                {this.props.user.selectedCompany.currency_symbol}{' '}
                {parseFloat(item.purchase_price).toFixed(2)}
              </span>
            )
          },
          hidden: record.has_batches == 2,
        },
      ].filter(col => !col.hidden)

      return (
        <div className={`p-3 pb-2 ${record.batches.length > 0 ? 'bg-gray-100' : ''} rounded`}>
          <div className={record.batches.length > 0 ? 'hidden' : 'w-full mb-2 flex justify-center'}>
            <Button
              type="primary"
              size="medium"
              className="mr-2 float-right"
              onClick={e => {
                e.stopPropagation()

                this.props.integrations.batching == 1 || this.props.integrations.serial_number == 1
                  ? this.handleAddBatches({
                      ...record,
                      variant_id: 0,
                      name: '',
                    })
                  : this.subscription.showModal()
              }}
            >
              <i className="fa fa-plus mr-1" />
              <span className="hidden sm:inline font-weight-bold">Add Batches</span>
            </Button>
          </div>
          {record.batches.length > 0 && (
            <Table
              columns={batch_columns}
              dataSource={record.batches}
              pagination={false}
              footer={() => (
                <div className="flex justify-end">
                  <Button
                    type="action-o"
                    size="medium"
                    className="mr-2 float-right"
                    onClick={e => {
                      e.stopPropagation()
                      this.props.integrations.batching == 1 ||
                      this.props.integrations.serial_number == 1
                        ? this.handleAddBatches({
                            ...record,
                            variant_id: 0,
                            name: '',
                          })
                        : this.subscription.showModal()
                    }}
                  >
                    <i className="fa fa-plus-circle mr-2" />
                    <span className="hidden sm:inline font-weight-bold">Add/ Edit Batches</span>
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      )
    }

    return (
      <div className="pl-1">
        {this.props.all_doc_counts && this.props.all_doc_counts['products'] == 0 ? (
          <Experience type={'products'} toUpgrade={false} onClick={() => this.form.onAdd()} />
        ) : (
          <>
            {this.state.priceListConfirmFlag == true ? (
              <React.Fragment>
                <EnhancedBulkUpload
                  columns={priceListColumns}
                  data={this.state.importData}
                  title={'Confirm Uploading PriceList'}
                  callConfirmAPI={flag => this.priceListCallConfirmAPI(flag)}
                  loading={this.state.loading}
                  loadingCancel={this.state.loadingCancel}
                  confirmFlag={this.state.priceListConfirmFlag}
                  onClose={() => {
                    this.setState({ priceListConfirmFlag: false })
                  }}
                />
              </React.Fragment>
            ) : (
              <>
                {this.state.confirmFlag ? (
                  <React.Fragment>
                    <EnhancedBulkUpload
                      columns={columns}
                      data={this.state.importData}
                      title={'Confirm Uploading Products'}
                      callConfirmAPI={flag => this.callConfirmAPI(flag)}
                      loading={this.state.loading}
                      loadingCancel={this.state.loadingCancel}
                      confirmFlag={this.state.confirmFlag}
                      onClose={() => {
                        this.setState({ confirmFlag: false })
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="flex items-center justify-between mb-6">
                      <div>
                        <Input.Group compact>
                          <Input
                            prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                            type="text"
                            className=""
                            style={{ width: 360 }}
                            data-beacon-id="search_products_item_wise"
                            placeholder="Search products, category, description"
                            onChange={e => {
                              clearTimeout(this.state.timeout)
                              this.setState({
                                search: e.target.value,
                                timeout: setTimeout(this.searchProducts, 1200),
                              })
                            }}
                          />
                          {/* <Select
                        showSearch
                        data-beacon-id="product_categories"
                        allowClear={true}
                        style={{ width: 200, fontSize: '12px' }}
                        placeholder="Select Category"
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        optionFilterProp="children"
                        className="mr-2"
                        onChange={e => {
                          this.setState({ selected_category: e }, () =>
                            this.callData(this.state.page, this.state.num_records),
                          )
                        }}
                        value={this.state.selected_category}
                        filterOption={(input, option) =>
                          option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        listHeight={200}
                        dropdownStyle={{ minWidth: '300px' }}
                      >
                        <Option value={'All'} key={0}>
                          {'All'}
                        </Option>
                        {this.props.categories.map(item => (
                          <Option value={item.category_name} key={item.category_name}>
                            {item.category_name}
                          </Option>
                        ))}
                      </Select> */}
                          <MultiSelectDropdown
                            title={'Select Category'}
                            label_id={'category_id'}
                            label={'category_name'}
                            list={this.props.categories}
                            onChange={selected_categories => {
                              // console.log(selected_categories)
                              this.setState({ selected_categories: selected_categories }, () => {
                                this.callData(0, this.state.num_records)
                              })
                            }}
                            showLeftBorder={false}
                          />
                        </Input.Group>
                      </div>
                      <div className="flex">
                        {!this.state.loading &&
                          this.state._columns.filter(e => !e.disabled).length > 0 && (
                            <Popover
                              content={
                                <ManageColumns
                                  onChange={columns => this.onChangeColumns(columns)}
                                  columns={this.state._columns}
                                  onSave={this.onColumnsSave}
                                />
                              }
                              trigger={['click']}
                              placement="bottomLeft"
                            >
                              <Button type="text" shape="circle" className="rounded-full mr-4">
                                <span>
                                  <i className="fa-solid font-weight-bold fa-sliders"></i>
                                </span>
                              </Button>
                            </Popover>
                          )}

                        <Dropdown
                          menu={{
                            items: ActionItems,
                          }}
                        >
                          <Button className="mr-4 font-weight-bold" type="secondary">
                            <FormattedMessage id="button.actions" />
                            <i className="fa-solid fa-chevron-down ml-2" />
                          </Button>
                        </Dropdown>

                        <Button
                          onClick={() => this.form.onAdd()}
                          className="hidden sm:inline font-weight-bold"
                          type="primary"
                          data-beacon-id="add_new_item"
                        >
                          <i className="fa-solid fa-plus mr-2" />{' '}
                          <FormattedMessage id="button.addNewItem" />
                        </Button>
                      </div>
                    </div>

                    <div className="mb-4">
                      {/*<Card size="table" className="border-radius-small mt-3 mb-2">*/}
                      <span className="hover-table-actions">
                        <StyledTable
                          columns={columns}
                          dataSource={this.state.products}
                          scroll={{
                            x:
                              (100 + this.state.extra_columns?.filter(e => e.active).length * 6 <
                              150
                                ? 100 + this.state.extra_columns?.filter(e => e.active).length * 8
                                : 150) + '%',
                          }}
                          pagination={false}
                          className="products-table"
                          onRow={(record, rowIndex) => {
                            if (this.state.edit) {
                              return {
                                // onClick: event => {
                                //   this.form.onFill(record)
                                // },
                                onClick: event => {
                                  event.stopPropagation()
                                  if (record.variants && record.variants.length == 0) {
                                    this.productAnalysis.showModal(
                                      record,
                                      record.product_id,
                                      record,
                                    )
                                  }
                                },
                                onMouseEnter: event => {
                                  record.isHovered = true
                                  this.setState({
                                    onTableRowHover: true,
                                    products:
                                      rowIndex != 0 && this.state.products[0]?.isHovered
                                        ? [
                                            { ...this.state.products[0], isHovered: false },
                                            ...this.state.products.slice(1),
                                          ]
                                        : this.state.products,
                                  })
                                },
                                onMouseLeave: event => {
                                  record.isHovered = false
                                  this.setState({
                                    onTableRowHover: false,
                                    products: [
                                      { ...this.state.products[0], isHovered: false },
                                      ...this.state.products.slice(1),
                                    ],
                                  })
                                },
                              }
                            }
                          }}
                          footer={() => (
                            <>
                              {this.state.experience_loading &&
                                this.state.products.length > 0 &&
                                this.props.all_doc_counts &&
                                this.props.all_doc_counts['products'] == 1 && (
                                  <div>
                                    <Experience
                                      type={'products'}
                                      toUpgrade={false}
                                      onClick={() => this.form.onAdd()}
                                    />
                                  </div>
                                )}
                              {this.state.products.length != 0 &&
                                this.props.all_doc_counts &&
                                this.props.all_doc_counts['products'] == 2 && (
                                  <Affix offsetBottom={54}>
                                    <div className="inline-flex items-center float-right pt-2 pb-2">
                                      <Pagination
                                        size="small"
                                        showSizeChanger={false}
                                        pageSize={this.state.num_records}
                                        total={this.state.total}
                                        current={this.state.page + 1}
                                        onChange={this.onPaginationChange}
                                      />
                                      <Select
                                        optionFilterProp="children"
                                        className="mr-2"
                                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                        defaultValue={this.state.num_records}
                                        onChange={value => this.callData(this.state.page, value)}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {[10, 20, 50, 100].map((e, i) => (
                                          <Option key={i} value={e}>
                                            {e}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Affix>
                                )}
                            </>
                          )}
                          style={{ cursor: 'pointer' }}
                          size="small"
                          locale={{
                            emptyText: (
                              <Empty
                                className="m-4"
                                //image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                // image="../../../welcome3.png"
                                // imageStyle={{
                                //   height: 256,
                                // }}
                                description={
                                  !this.state.loading_products && (
                                    <span className="font-size-21 font-weight-bold">
                                      <FormattedMessage id="products.addText" />
                                    </span>
                                  )
                                }
                              >
                                {!this.state.loading_products && (
                                  <Button
                                    onClick={() => this.form.onAdd()}
                                    className="mr-2 font-weight-bold"
                                    type="action"
                                  >
                                    <i className="fa fa-plus mr-1" />{' '}
                                    <FormattedMessage id="button.addNewItem" />
                                  </Button>
                                )}
                              </Empty>
                            ),
                          }}
                          onChange={this.onChange}
                          expandable={{
                            expandedRowRender: expandedRowRender,
                            expandedRowKeys: this.state.expandedKeys,
                            onExpand: (expanded, record) => {
                              //expand only one row at a time
                              if (expanded) {
                                this.setState({
                                  expandedKeys: [record.key],
                                })
                              } else {
                                this.setState({
                                  expandedKeys: [],
                                })
                              }
                            },
                            columnWidth: window.innerWidth * 0.05,
                          }}
                          expandIcon={({ expanded, onExpand, record }) =>
                            expanded ? (
                              <Button
                                size="small"
                                type="transparent"
                                className="text-danger"
                                onClick={e => {
                                  onExpand(record, e)
                                  e.stopPropagation()
                                }}
                              >
                                <span className="text-danger">
                                  Close <i className="fa-solid fa-chevron-up"></i>
                                </span>
                              </Button>
                            ) : record.has_batches == 0 ? (
                              <Button
                                type="info"
                                size="small"
                                onClick={event => {
                                  event.stopPropagation()

                                  store.get('paid') == 0
                                    ? this.subscriptionPlanRef.current.openModal('variants')
                                    : onExpand(record, event)
                                }}
                                className="mr-2"
                              >
                                {record.variants.length == 0 ? (
                                  <i className="fa fa-plus mr-1" />
                                ) : (
                                  <span className="font-weight-bold text-danger mr-1">{`(${record.variants.length})`}</span>
                                )}
                                <span className="hidden sm:inline font-weight-bold">
                                  <FormattedMessage id="button.variants" />{' '}
                                </span>
                              </Button>
                            ) : record.has_batches == 1 ? (
                              <Button
                                type="action-3"
                                size="small"
                                onClick={event => {
                                  event.stopPropagation()

                                  this.props.integrations.batching == 1 ||
                                  this.props.integrations.serial_number == 1
                                    ? onExpand(record, event)
                                    : this.subscription.showModal()
                                }}
                                className="mr-2"
                              >
                                {record.batches.length == 0 ? (
                                  <i className="fa fa-plus mr-1" />
                                ) : (
                                  <span className="font-weight-bold text-danger mr-1">{`(${record.batches.length})`}</span>
                                )}
                                <span className="hidden sm:inline font-weight-bold">
                                  <FormattedMessage id="button.batches" />{' '}
                                </span>
                              </Button>
                            ) : (
                              <></>
                            )
                          }
                          loading={{
                            spinning: this.state.loading_products,
                            indicator: <Loader text={'Loading Products...'} visibleText={true} />,
                          }}
                        />
                      </span>

                      {/*</Card>*/}
                    </div>
                  </React.Fragment>
                )}

                {/*<ProductForm
                  onRef={ref => (this.form = ref)}
                  onAPICalled={this.onAPICalled}
                  dispatch={this.props.dispatch}
                />*/}

                <InventoryForm
                  onRef={ref => (this.inventoryForm = ref)}
                  onAPICalled={this.onAPICalled}
                  identifier="products_list"
                />

                <VariantForm
                  onRef={ref => (this.variantForm = ref)}
                  onAPICalled={this.onAPICalled}
                />
                <EnhancedExport onRef={ref => (this.export = ref)} menuName="product" />
                <EnhancedImport
                  onRef={ref => (this.import = ref)}
                  onDataChange={this.onImportDataChange}
                  menu="product"
                  callData={() => this.callData(0, this.state.num_records)}
                />
                <EnhancedProductAnalysis
                  onRef={ref => (this.productAnalysis = ref)}
                  menu="product"
                  onProductUpdate={() => {
                    this.callData(this.state.page, this.state.num_records)
                  }}
                />

                <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
                <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
                <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
                <EnhancedDelete
                  onRef={ref => (this.delete = ref)}
                  document_type={'Product/Service'}
                  handleDelete={this.handleDelete}
                />
                <BillOfMaterials onRef={ref => (this.bom = ref)} onAPICalled={this.onAPICalled} />

                <Batches onRef={ref => (this.batches = ref)} onAPICalled={this.onAPICalled} />

                <Modal
                  title={<span>Share Product</span>}
                  open={this.state.show_share}
                  footer={null}
                  onCancel={() => this.setState({ show_share: false, selectedProduct: {} })}
                  onClose={() => this.setState({ show_share: false, selectedProduct: {} })}
                  width={380}
                  closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
                  centered
                >
                  <div className="flex flex-col">
                    <div>
                      <FacebookShareButton
                        url={this.state.product_link + this.state.selectedProduct.product_id}
                        style={{
                          width: '100%',
                        }}
                      >
                        {/* <FacebookIcon size={32} round /> */}
                        <div className="flex justify-between items-center my-3">
                          <div>
                            <span>
                              <i
                                className="fa-brands fa-facebook align-middle font-size-28 mr-2"
                                style={{
                                  color: '#4267B2',
                                }}
                              ></i>
                            </span>
                            <span className="font-size-18 align-middle font-weight-bold">
                              Share on Facebook
                            </span>
                          </div>

                          <span>
                            <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                          </span>
                        </div>
                      </FacebookShareButton>
                    </div>

                    <div>
                      <TelegramShareButton
                        url={this.state.product_link + this.state.selectedProduct.product_id}
                        style={{
                          width: '100%',
                        }}
                      >
                        {/* <TelegramIcon size={32} round /> */}
                        <div className="flex justify-between items-center my-3">
                          <div>
                            <span>
                              <i
                                className="fa-brands fa-telegram font-size-28 align-middle mr-2"
                                style={{
                                  color: '#0088cc',
                                }}
                              ></i>
                              <span className="font-size-18 align-middle font-weight-bold">
                                Share on Telegram
                              </span>
                            </span>
                          </div>

                          <span>
                            <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                          </span>
                        </div>
                      </TelegramShareButton>
                    </div>

                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        onClick={() => {
                          store.get('custom_domain')
                            ? window.open(
                                'https://api.whatsapp.com/send?' +
                                  '&text=Hello, Check the product here: ' +
                                  '%0A' +
                                  this.state.product_link +
                                  this.state.selectedProduct.product_id,
                                +'%0A' + 'Thank You ! ' + '%0A' + store.get('organization_name'),
                              )
                            : window.open(
                                'https://api.whatsapp.com/send?text=Hello, Check the product here: ' +
                                  '%0A' +
                                  this.state.product_link +
                                  this.state.selectedProduct.product_id +
                                  '%0A' +
                                  'Thank You ! ' +
                                  '%0A' +
                                  store.get('organization_name') +
                                  '%0A' +
                                  'Powered By Swipe' +
                                  '%0A' +
                                  'app.getswipe.in',
                              )
                        }}
                      >
                        <div className="flex justify-between items-center my-3">
                          <div>
                            <span>
                              <i
                                className="fa-brands fa-whatsapp align-middle  font-size-28 mr-2"
                                style={{
                                  color: '#25D366',
                                }}
                              />
                              <span className="font-sidivze-18 align-middle font-weight-bold">
                                Share on Whatsapp
                              </span>
                            </span>
                          </div>

                          <span>
                            <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <EmailShareButton
                        url={this.state.product_link + this.state.selectedProduct.product_id}
                        style={{
                          width: '100%',
                        }}
                      >
                        {/* <EmailIcon size={32} round /> */}
                        <div className="flex justify-between items-center my-3">
                          <div>
                            <span>
                              <i
                                className="fa-solid fadiv-circle-envelope align-middle font-size-28 mr-2"
                                style={{
                                  color: '#4285F4',
                                }}
                              ></i>
                              <span className="font-size-18 align-middle font-weight-bold">
                                Share on E-Mail
                              </span>
                            </span>
                          </div>

                          <span>
                            <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                          </span>
                        </div>
                      </EmailShareButton>
                      <FacebookMessengerShareButton
                        url={this.state.product_link + this.state.selectedProduct.product_id}
                        style={{
                          width: '100%',
                        }}
                      >
                        {/* <FacebookMessengerIcon size={32} round /> */}
                        <div className="flex justify-between items-center my-3">
                          <div>
                            <span>
                              <i
                                className="fa-brands fa-facebook-messenger align-middle font-size-28 mr-2"
                                style={{
                                  color: '#0084FF',
                                }}
                              ></i>
                              <span className="font-size-18 align-middle font-weight-bold">
                                Share on Messenger
                              </span>
                            </span>
                          </div>

                          <span>
                            <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                          </span>
                        </div>
                      </FacebookMessengerShareButton>
                    </div>

                    <CopyToClipboard
                      text={this.state.product_link + this.state.selectedProduct.product_id}
                      onCopy={() => message.success('Copied')}
                    >
                      <div
                        className="flex justify-between items-center flex-nowrap my-3 border rounded px-2 py-3"
                        style={{
                          cursor: 'pointer',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <div>
                          <span>
                            {/* <i
                          className="fa-solid fa-link align-middle font-size-24 mr-2"
                          style={{
                            color: '#0084FF',
                          }}
                        ></i> */}
                            <span className="font-size-16 align-middle font-weight-bold text-truncate">
                              {this.state.product_link + this.state.selectedProduct.product_id}
                            </span>
                          </span>
                        </div>
                        <span>
                          <i className="fa-solid fa-copy text-gray-10 font-size-18"></i>
                        </span>
                      </div>
                    </CopyToClipboard>
                  </div>
                </Modal>
                <DeleteConfirmationModal
                  showDeleteAllModal={this.state.showDeleteAllModal}
                  source="products"
                  onCancel={this.onCancelDeleteAllModal}
                  callData={this.callData}
                  apiBlueprint={'v2/product'}
                  apiFunction={'delete_all_products'}
                />
              </>
            )}
          </>
        )}
        <ProductForm
          onRef={ref => (this.form = ref)}
          onAPICalled={this.onAPICalled}
          dispatch={this.props.dispatch}
        />
        <ProductImagesCarousel onRef={ref => (this.productImagesRef = ref)} />
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    permissions: state.permissions.permission,
    all_users: state.permissions.all_users,
    categories: state.document.categories,
    integrations: state.permissions.integrations,
    invoice_settings: state.document.invoiceSettings,
    all_doc_counts: state.document.all_doc_counts,
    user: state.user,
  }
}

export default connect(mapStateToProps)(Product)
