import React, { useRef } from 'react'
import { Select, InputNumber, Input, Divider, Button } from 'antd'
import DocumentPrefixes from '../forms/documentPrefixes'

const SerialNumberComponent = props => {
  const documentPrefixesRef = useRef()
  return (
    <>
      {(props.is_prefix || (!props.is_prefix && props.data.length > 0)) && (
        <Select
          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
          value={props.value}
          disabled={props.is_pos}
          style={{
            width: 135,
            textAlign: 'right',
          }}
          allowClear={props.is_prefix ? false : true}
          onChange={e => {
            props.setNewPrefixSuffix(e, props.is_prefix)
          }}
          onBlur={e => {
            if (e.target.value != '') {
              {
                props.setNewPrefixSuffix(e.target.value, props.is_prefix)
              }
            }
          }}
          dropdownStyle={{ minWidth: '300px' }}
          dropdownRender={menu => (
            <>
              <div className="d-flex p-2 mt-2">
                <Input
                  placeholder={`Add Custom ${props.is_prefix ? 'Prefix' : 'Suffix'}`}
                  className="w-100"
                  value={props.new_value}
                  onChange={e => props.setNewPrefixSuffix(e.target.value, props.is_prefix, false)}
                />
              </div>
              <Divider />
              {menu}
              <div className="bg-gray-75">
                <Button
                  block
                  size=""
                  type="link-2"
                  className="font-weight-bolder text-left my-2 arrow-transition"
                  onMouseDown={e => e.preventDefault()}
                  onClick={() => {
                    props.setIsPrefix(true)
                    documentPrefixesRef.current.show_drawer(props.document_type, props.is_prefix)
                  }}
                >
                  <i className="fa-solid fa-circle-plus mr-2"></i>
                  Add New {props.is_prefix ? 'Prefix' : 'Suffix'}
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>
              </div>
            </>
          )}
        >
          {props?.data?.map((item, i) => {
            return (
              <Option key={i} value={props.is_prefix ? item.prefix : item.suffix}>
                {props.is_prefix ? item.prefix : item.suffix}
              </Option>
            )
          })}
        </Select>
      )}
      <DocumentPrefixes ref={documentPrefixesRef} onFinish={props.onFinish} />
    </>
  )
}

export default SerialNumberComponent
