import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode.react'
import { Socket } from 'socket.io-client'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'

import { config, socket } from 'services/axios'

interface QRCodeComponentProps {
  onFinish: (data: any) => void
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ onFinish }) => {
  const [sessionId, setSessionId] = useState<string>('')
  const [status, setStatus] = useState<string>('pending')

  useEffect(() => {
    // let session_id = uuidv4()
    let session_id = '1234567890'
    setSessionId(session_id)
    console.log('session_id', session_id)

    if (socket) {
      socket.on('connect', () => {
        console.log('Connected to WebSocket server')
      })

      socket.on('statusUpdate', data => {
        console.log('connecting socket', data)
        if (data.sessionId === session_id) {
          setStatus(data.status)
          if (data.status === 'completed') {
            onFinish(data)
          }
        }
      })

      socket.on('message', msg => {
        console.log('Received:', msg)
      })

      socket.on('disconnect', () => {
        console.log('Disconnected from server')
      })
    }
  }, [sessionId])

  return (
    <div style={{ textAlign: 'center' }}>
      {status === 'pending' && sessionId && (
        <QRCode value={`swipebilling://app.getswipe.in/home/document_upload_ai`} size={150} />
      )}
      {status === 'scanned' && <p>Waiting for file upload...</p>}
      {status === 'uploaded' && <p>File uploaded successfully!</p>}
    </div>
  )
}

export default QRCodeComponent
